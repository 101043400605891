import axios from "axios";
import { tokenService } from "./token.service";

export const tablesService = {
  getService,
  deleteService,
  newService,
  editService,
  postService,
  getDocument,
};

function getService(table, params) {
  if (params == undefined) {
    params = "";
  }
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${process.env.VUE_APP_API_URL}/${table}/${params}`,
        tokenService.getAuthentication()
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function newService(table, item) {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${process.env.VUE_APP_API_URL}/${table}/`,
        item,
        tokenService.getAuthentication()
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function editService(table, id, item) {
  if (id == undefined) {
    id = "";
  }

  return new Promise((resolve, reject) => {
    axios
      .patch(
        `${process.env.VUE_APP_API_URL}/${table}/${id}`,
        item,
        tokenService.getAuthentication()
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function deleteService(table, id) {
  return new Promise((resolve, reject) => {
    axios
      .delete(
        `${process.env.VUE_APP_API_URL}/${table}/${id}`,
        tokenService.getAuthentication()
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function postService(table, item, params) {
  if (params == undefined) {
    params = "";
  }

  return new Promise((resolve, reject) => {
    axios
      .post(
        `${process.env.VUE_APP_API_URL}/${table}/${params}`,
        item,
        tokenService.getAuthentication()
      )
      .then((response) => {
        if (response.status === 202 || response.status === 201) {
          resolve(true);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function getDocument(table, params) {
  if (params == undefined) {
    params = "";
  }
  return new Promise((resolve, reject) => {
    axios({
      url: `${process.env.VUE_APP_API_URL}/curriculum/pdf`,
      method: "GET",
      responseType: "blob", // important
      headers: {
        Authorization: "Bearer " + tokenService.getToken(),
      },
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Curriculum - " + params.name + ".pdf");
        document.body.appendChild(link);
        link.click();
        resolve(true);
      })
      .catch(function(error) {
      });
  });
}
