<template>
  <v-card>
    <v-card v-if="!loading" class="pa-5">
      <v-data-table 
        :headers="headers" 
        :items="empresas" 
        :items-per-page="15" 
        :search="search"
        :footer-props="{
          'items-per-page-text':'Elementos por página',
          pageText: '{0}-{1} de {2}' }"
      >
        <template v-slot:top>
          <v-row no-gutters>

            <Select label="Tipo" class="ma-3" v-model="tipo" :items="tipos" item-value="id" item-text="nombre"></Select>
          </v-row>
        </template>
        <template v-slot:item.action="{ item }">
          <v-btn color="primary" @click="empresa_url(item.id)">Ver detalles</v-btn>
        </template>
      </v-data-table>
    </v-card>

    <div v-else>
      <v-row justify="space-around">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </v-row>
    </div>
  </v-card>
</template>

<script>
import Select from '../../components/forms/Select.vue'
export default {
  components: { Select },
  data: () => ({
    
    tipos: [{id: 0, nombre: 'Empresa'}, {id:1, nombre: 'Sucursal'}],
    tipo: 0,
    
  }),
  watch:{
    estado_seleccionado(){
    this.$store.dispatch("tables/get", {endpoint: 'practica/all/por_estado', table: "practicas_por_estado", params: `?estado_id=${this.estado_seleccionado}`});

    }
  },
  computed: {
    headers(){ return [
      {
        text: "Nombre",
        align: "left",
        sortable: true,
        value: "nombre"
      },
      {
        text: "Rut",
        align: "left",
        sortable: true,
        value: "rut"
      },
      {
        text: "Usuario",
        align: "left",
        sortable: true,
        value: "alumno.username"
      },

      { text: "Acciones", value: "action", sortable: false, width: "8%" }
    ]},
    empresas() {
      return this.$store.state.tables.empresas_alumno.items;
    },
  },
  created() {
    this.$store.dispatch("tables/get", { endpoint: 'empresas/alumno', table: "empresas_alumno" });

  },
  methods: {
    empresa_url(id) {
      this.$router.push("/empresas/" + id);
    },
  }
};
</script>

<style>
</style>