<template>
  <v-container>
    <v-data-iterator
      :items="logs"
      :items-per-page.sync="itemsPerPage"
      :page="page"
      :loading="loading"
      sort-by="fecha_sistema"
      :sort-desc="true"
      hide-default-footer
    >
      <template v-slot:default="props">
        <v-row>
          <v-col v-for="item in props.items" :key="item.id" cols="12">
            <v-card class="mb-5">
              <v-card-title>
                <v-chip class="mr-3 white--text" color="primary">{{`${item.alumno.first_name} ${item.alumno.last_name}`}}</v-chip>
                <v-row justify="end"
                  ><p class="mr-5 mt-3 font-italic " :style="{fontSize:'14px'}">
                    {{
                      moment(
                        item.created_at,
                        moment.HTML5_FMT.DATETIME_LOCAL_MS
                      ).format("dddd, D [de] MMMM, YYYY")
                    }}
                  </p></v-row
                >
              </v-card-title>
              <v-card-text>
                <dark-card>{{ item.mensaje }}</dark-card>
              <v-row justify="end">
                <button-primary :onClick="()=>go(item.practica)">
                  Ir a la práctica
                </button-primary>
              </v-row>
                
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </template>

      <template v-if="n_items != 0" v-slot:footer>
        <v-row class="ma-2" align="center" justify="center">
          <span class="grey--text">Solicitudes por página</span>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                dark
                text
                color="primary"
                class="ml-2"
                v-bind="attrs"
                v-on="on"
              >
                {{ itemsPerPage }}
                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(number, index) in itemsPerPageArray"
                :key="index"
                @click="updateItemsPerPage(number)"
              >
                <v-list-item-title>{{ number }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-spacer></v-spacer>

          <span class="mr-4 grey--text"
            >Página {{ page }} de {{ numberOfPages }}</span
          >
          <v-btn
            fab
            small
            elevation="0"
            dark
            color="primary"
            class="mr-1"
            @click="formerPage"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn
            fab
            small
            elevation="0"
            dark
            color="primary"
            class="ml-1"
            @click="nextPage"
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-row>
      </template>

      <template v-slot:no-data>
        <v-row class="ma-2" align="center" justify="center">
          <notFound width="256" height="256" :fill="svgTheme"></notFound>
        </v-row>
        <v-row class="ma-2" align="center" justify="center">No se ha encontrado ningún mensaje.</v-row>
      </template>

      <template v-slot:loading>
        <v-row class="ma-2" align="center" justify="center">
          <v-row justify="space-around">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-row>
        </v-row>
      </template>
    </v-data-iterator>
  </v-container>
</template>

<script>
import DarkCard from "../../components/content/DarkCard.vue";
import notFound from "@/components/icons/notFound";
import ButtonPrimary from '@/components/forms/ButtonPrimary.vue';

export default {
  data: () => ({
    itemsPerPageArray: [4, 8, 12],
    page: 1,
    itemsPerPage: 4,
  }),
  components: {
    notFound,
    DarkCard,
    ButtonPrimary,
  },
  computed: {
    n_items() {
      var pages = 0;
      try {
        pages = this.ofertas.length;
      } catch (error) {
        pages = 0;
      }
      return pages;
    },
    numberOfPages() {
      var pages = 0;
      try {
        pages = Math.ceil(this.ofertas.length / this.itemsPerPage);
      } catch (error) {
        pages = 0;
      }
      return pages;
    },
    logs() {
      return this.$store.state.tables.logs.items;
    },
    loading() {
      return this.$store.state.tables.logs.loading;
    },
    svgTheme() {
      return this.$vuetify.theme.dark ? "white" : "black";
    },
  },
  created() {
    this.$store.dispatch("tables/get", { endpoint: `logs`, table: "logs" });
  },
  methods: {
    go(token){
      this.$router.push(`/practica/${token}`)
    },
    nextPage() {
      if (this.page + 1 <= this.numberOfPages) this.page += 1;
    },
    formerPage() {
      if (this.page - 1 >= 1) this.page -= 1;
    },
    updateItemsPerPage(number) {
      this.itemsPerPage = number;
    },
  },
};
</script>

<style></style>
