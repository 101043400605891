import axios, { AxiosRequestConfig } from 'axios';
import { Promise } from 'es6-promise';


export const tokenService = {
    authenticate,
    authenticateAdmin,
    isAuthenticated,
    getAuthentication,
    getNewToken,
    storeToken,
    storeRefreshToken,
    clear, 
    parseToken,
    getToken
};

let LOCAL_STORAGE_TOKEN = 'token';
let LOCAL_STORAGE_REFRESH_TOKEN = 'refresh_token';



function authenticate(username, password) {

    return new Promise((resolve, reject) => {
        axios
            .post(`${process.env.VUE_APP_API_URL}/token/`,   {username: username, password: password } )
            .then(response => {
                this.storeToken(response.data.access);
                this.storeRefreshToken(response.data.refresh);
                localStorage.setItem('id', tokenService.parseToken(response.data.access).user_id);
                resolve(response.data.access);
            })
            .catch((error) => {

                reject(error);
            });
    });
}


function authenticateAdmin(username, password) {

    return new Promise((resolve, reject) => {
        axios
            .post(`${process.env.VUE_APP_API_URL}/token_admin/`,   {username: username, password: password } )
            .then(response => {

                this.storeToken(response.data.access);
                this.storeRefreshToken(response.data.refresh);
                localStorage.setItem('id', tokenService.parseToken(response.data.access).user_id);
                resolve(response.data.access);
            })
            .catch((error) => {
                this.clear()
                reject(error);
            });
    });
}

function parseToken(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

function isAuthenticated() {
    return getToken() !== null;
}

function getAuthentication() {
    return {
        headers: {'Authorization': 'Bearer ' + getToken() }
    };
}

function getNewToken() {
    return new Promise((resolve, reject) => {
        axios
            .post(`${process.env.VUE_APP_API_URL}/token/refresh/`, { refresh: getRefreshToken() })
            .then(response => {
                this.storeToken(response.data.access);
                resolve(response.data.access);
            })
            .catch((error) => {
                this.clear()
                reject(error);
            });
    });
}


function storeToken(token) {
    localStorage.setItem(LOCAL_STORAGE_TOKEN, token);
}

function storeRefreshToken(refreshToken) {
    localStorage.setItem(LOCAL_STORAGE_REFRESH_TOKEN, refreshToken);
}

function clear() {
    localStorage.removeItem(LOCAL_STORAGE_TOKEN);
    localStorage.removeItem(LOCAL_STORAGE_REFRESH_TOKEN);
    localStorage.removeItem('user');
}

function getRefreshToken() {
    return localStorage.getItem(LOCAL_STORAGE_REFRESH_TOKEN);
}

function getToken() {
    return localStorage.getItem(LOCAL_STORAGE_TOKEN);
}
