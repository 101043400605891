<template>
  <div>
    <DarkCard title="Supervisor" icon="mdi-account-tie">
      <div>
        <v-row >
          <v-col>
            <h5 class="mb-0 font-weight-bold">Nombre:</h5>
            <p class="mb-0 font-italic">
              {{ supervisor.nombre + " " + supervisor.apellido }}
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <h5 class="mb-0 font-weight-bold">Email:</h5>
            <p class="mb-0 font-italic">{{ supervisor.email }}</p>
          </v-col>
          <v-col>
            <h5 class="mb-0 font-weight-bold">Teléfono:</h5>
            <p class="mb-0 font-italic">{{ supervisor.telefono }}</p>
          </v-col>
        </v-row>
      </div>
    </DarkCard>
  </div>
</template>

<script>
import DarkCard from "@/components/content/DarkCard.vue";
export default {
  data: () => ({
    supervisor: {},
  }),

  components: {
    DarkCard,
  },
  props: ["data"],
  watch: {
    data: {
      // the callback will be called immediately after the start of the observation
      immediate: true,
      deep: true,
      handler(val, oldVal) {
        this.supervisor = val;
      },
    },
  },
};
</script>

<style></style>
