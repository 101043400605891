<template>
  <v-row justify="center">
    <v-col :cols="12" :lg="width" class="maxWidth">
      <slot />
    </v-col>
  </v-row>
</template>

<script>
export default {
    props:["width"]
};
</script>

<style>
.maxWidth{
  max-width: 1300px;
}
</style>
