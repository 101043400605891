<template>
  <v-container>
    <v-data-iterator
      :items="ofertas"
      :items-per-page.sync="itemsPerPage"
      :page="page"
      :loading="loading"
      sort-by="fecha_sistema"
      :sort-desc="true"
      hide-default-footer
    >
      <template v-slot:default="props">

        <v-row>
            <v-col v-for="item in props.items" :key="item.name" cols="12">
              <v-card class="pa-5 mb-5">
            
                <oferta-detail :oferta="item"></oferta-detail>

                   <v-row justify="center">
                <v-btn class="mr-5 white--text" color="green" @click="aprobar(item.id)">Aprobar</v-btn>
                <v-btn class="ml-5 white--text" color="red" @click="remove(item.id)">Rechazar</v-btn>
              </v-row>
              </v-card>
            </v-col>
          </v-row>
      </template>

      <template v-if="n_items != 0" v-slot:footer>
        <v-row class="ma-2" align="center" justify="center">
          <span class="grey--text">Solicitudes por página</span>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn dark text color="primary" class="ml-2" v-bind="attrs" v-on="on">
                {{ itemsPerPage }}
                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(number, index) in itemsPerPageArray"
                :key="index"
                @click="updateItemsPerPage(number)"
              >
                <v-list-item-title>{{ number }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-spacer></v-spacer>

          <span class="mr-4 grey--text">Página {{ page }} de {{ numberOfPages }}</span>
          <v-btn fab small elevation="0" dark color="primary" class="mr-1" @click="formerPage">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn fab small elevation="0" dark color="primary" class="ml-1" @click="nextPage">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-row>
      </template>

      <template v-slot:no-data>
        <v-row class="ma-2" align="center" justify="center">
          <notFound width="256" height="256" ></notFound
        ></v-row>

        <v-row class="ma-2" align="center" justify="center"
          >No hay ofertas pendientes</v-row
        >
      </template>

      <template v-slot:loading>
        <v-row class="ma-2" align="center" justify="center">
          <v-row justify="space-around">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
          </v-row>
        </v-row>
      </template>
    </v-data-iterator>
  </v-container>
</template>

          
<script>
import OfertaDetail from '../../components/ofertas/OfertaDetail.vue'
import notFound from "@/components/icons/notFound";

export default {
  data: () => ({
    itemsPerPageArray: [4, 8, 12],
    page: 1,
    itemsPerPage: 4,
  }),
  components: {
     notFound, OfertaDetail 
  },
  computed: {
    n_items() {
      var pages = 0;
      try {
        pages = this.ofertas.length;
      } catch (error) {
        pages = 0;
      }
      return pages;
    },
    numberOfPages() {
      var pages = 0;
      try {
        pages = Math.ceil(this.ofertas.length / this.itemsPerPage);
      } catch (error) {
        pages = 0;
      }
      return pages;
    },
    ofertas() {
      return this.$store.state.tables.ofertas_admin.items;
    },
    loading() {
      return this.$store.state.tables.ofertas_admin.loading;
    },
     svgTheme() {
      return this.$vuetify.theme.dark ? "white" : "black";
    },
  },
  created() {
    this.$store.dispatch("tables/get", { endpoint: `ofertas`,table: "ofertas_admin" });
  },
  methods: {
    nextPage() {
      if (this.page + 1 <= this.numberOfPages) this.page += 1;
    },
    formerPage() {
      if (this.page - 1 >= 1) this.page -= 1;
    },
    updateItemsPerPage(number) {
      this.itemsPerPage = number;
    },
   
    remove(id) {
      this.$store.dispatch("tables/delete", {
        endpoint: `ofertas`,
        table: "ofertas_admin",
        id: id,
        editedIndex: this.editedIndex,
        error_message: "Ha ocurrido un error al eliminar la oferta.",
        success_message: "Oferta eliminada correctamente.",
      });
    },

    aprobar(id) {
      this.$store.dispatch("tables/post", {
        endpoint: `ofertas/${id}`,
        table: "ofertas_admin",
        item: {},
        reload: true,
      });
    },
  },
};
</script>

<style>
</style>