<template>
  <v-container>
    <div v-if="revision">
      <v-row >
        <v-col :cols="12" md="6">
          <Alumno v-if="show_alumno" :data="practica.alumno" />
          <MarcaAlumnos :alumno="practica.alumno" v-if="is_admin"/>
          <Convalidacion
                v-if="practica.convalidada && practica.tareas_realizadas"
                :practica="practica"
              ></Convalidacion>
        </v-col>
        <v-col :cols="12" md="6">
          <Practica
            :tipo="practica.tipo"
            :estado="practica.estado.nombre"
            :modo="practica.modo"
            :progreso="practica.progreso"
            :categoria="practica.categoria"
            :hide_progreso="hide_progreso"
            :fecha_inicio="practica.fecha_inicio"
            :fecha_termino="practica.fecha_termino"
          />
          <Empresa :data="practica.sucursal" />
          <Supervisor
          v-if="practica.supervisor && !hide_supervisor"
          :data="practica.supervisor"
          />       
        </v-col>
      </v-row>

    </div>
    <div v-if="!revision">
      <v-row>
        <v-col :cols="12">
          <Alumno v-if="show_alumno" :data="practica.alumno" />
          <Practica
            :tipo="practica.tipo"
            :estado="practica.estado.nombre"
            :modo="practica.modo"
            :progreso="practica.progreso"
            :categoria="practica.categoria"
            :hide_progreso="hide_progreso"
            :fecha_inicio="practica.fecha_inicio"
            :fecha_termino="practica.fecha_termino"
          />

          <Empresa :data="practica.sucursal" />
          <Supervisor
            v-if="practica.supervisor && !hide_supervisor"
            :data="practica.supervisor"
          />
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import Practica from "./sub_components/practica";
import Alumno from "./sub_components/alumno";
import Supervisor from "./sub_components/supervisor";
import Empresa from "./sub_components/empresa";
import MarcaAlumnos from "@/components/MarcaAlumnos.vue";
import Convalidacion from "./sub_components/convalidacion";

export default {
  props: [
    "practica",
    "show_alumno",
    "hide_progreso",
    "hide_supervisor",
    "revision",
  ],

  components: {
    Practica: Practica,
    Alumno: Alumno,
    Supervisor: Supervisor,
    Empresa: Empresa,
    MarcaAlumnos: MarcaAlumnos,
    Convalidacion: Convalidacion,
  },
  computed: {
    is_admin() {
      return this.$store.state.authentication.user.is_staff;
    },
  }
};
</script>
