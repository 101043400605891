import { tokenService, userService } from '../services';
import router from '../router';
import Vue from 'vue'

const user = localStorage.getItem('user');
const initialState = user
    ? { status: { loggedIn: true }, user }
    : { status: { loggedIn: false }, user: null };

export const authentication = {
    namespaced: true,
    state: initialState,
    actions: {
        login({ dispatch, commit }, { username, password }) {
            commit('loginRequest', { username });

            return tokenService.authenticate(username, password)
                .then(
                    token => {
                        userService.getCurrentUser()
                            .then(
                                user => {
                                    commit('loginSuccess', user);
                                    const currentPath = router.currentRoute.path;
                                    //Mandar a la pagina default solo si si se está en /
                                    //si no está en / entonces solo cargará la pagina previa
                                    if (user.is_staff && currentPath == '/' ) {
                                        router.push('/admin_revisiones');
                                    } else if (!user.is_staff && currentPath == '/') {
                                        router.push('/user_dashboard');
                                    }
                                },
                                error => {
                                    commit('loginFailure', error)
                                    Vue.prototype.$keycloak.logout()
                                }
                            );
                    },
                    error => {
                        commit('loginFailure', error);
                        Vue.prototype.$keycloak.logout()
                    }
                );
        },
        logout({ commit }) {
            tokenService.clear();
            Vue.prototype.$keycloak.logout()
            commit('logout');
            router.push('/');
        }
    },
    mutations: {
        loginRequest(state, user) {
            state.user = user;
        },
        loginSuccess(state, user) {
            state.status = { loggedIn: true };
            state.user = user;
        },
        loginFailure(state) {
            state.status = { loggedIn: false };
            state.user = null;
        },
        logout(state) {
            state.status = { loggedIn: false };
            state.user = null;
        }
    }
}
