<template>
  <v-card>
    <v-container>
      <v-row align="center"
      justify="center">
        <v-col cols="12" md="6">
          <h2 v-if="paso==1" class="pa-5">Bienvenido a tu primer encuentro con el mundo laboral, tu próxima práctica es la de tipo Industrial.</h2>
          <h2 v-if="paso==2" class="pa-5">Felicidades, has terminado exitosamente tu Práctica Industrial, el próximo paso es la de tipo Profesional.</h2>
          <h2 v-if="paso==3" class="pa-5">Bienvenido a tu primer encuentro con el mundo laboral, tu próxima práctica es la de tipo Profesional.</h2>

        <v-row justify="space-around">
        <v-btn rounded v-if="paso==1 || paso==2 || paso==3" elevation="0" color="primary" v-on:click="newPractica">INSCRIBIR PRÁCTICA</v-btn>
        </v-row>

        </v-col>
        <v-col cols="12" md="6">
          <intro></intro>
        </v-col>
      </v-row>
    </v-container>

  </v-card>
</template>

<script>
import intro from "./BienvenidaIcon";

export default {
  props:["paso"],
  components: {
    intro: intro
  },
  methods: {
    newPractica() {
      this.$router.push({
        name: "Inscribir Práctica",
      });
    }
  }
};
</script>

<style>
</style>