<template>
  <v-container>
    <v-row>
      <v-col :cols="12" :md="oneline ? 12 : 6" :class="oneline ? 'pb-0' : ''">
        <ChipList
          icon="mdi-order-bool-ascending-variant"
          title="Tipo de tareas realizadas"
          :items="evaluation.tareas"
        />
        <ChipList
          icon="mdi-food"
          title="Beneficios obtenidos"
          :items="evaluation.beneficios"
        />
        <ChipList
          icon="mdi-database"
          title="Bases de Datos utilizadas"
          :items="evaluation.bds"
        />
        <ChipList
          icon="mdi-language-javascript"
          title="Lenguajes utilizados"
          :items="evaluation.lenguajes"
        />
        <ChipList
          icon="mdi-language-ruby-on-rails"
          title="Librerías utilizadas"
          :items="evaluation.librerias"
        />
        <ChipList
          icon="mdi-book-open-variant"
          title="Asignaturas que le sirvieron"
          :items="evaluation.asignaturas"
        />
        <ChipList
          icon="mdi-microsoft-visual-studio-code"
          title="Editores utilizados"
          :items="evaluation.ides"
        />
      </v-col>

      
      <v-col :cols="12" :md="oneline ? 12 : 6" :class="oneline ? 'pt-0' : ''">

        <ChipList
          icon="mdi-office-building"
          title="Como se obtuvo la práctica:"
          :items="[evaluation.como_conseguiste]"
        />
        <div class="mb-2" v-if="is_user">
          <Title text="Organigrama de la empresa o institución"></Title>
          <div v-if="is_admin">
            <ButtonPrimary 
                :disabled="button_disabled(evaluation.organigrama)"
                :href="href_url(evaluation.organigrama)" 
                target="_blank"  
                >Organigrama</ButtonPrimary>
          </div>
          <div v-else>
            <ButtonPrimary
                :disabled="button_disabled(evaluation.organigrama)"
                  :onClick="
                    () =>
                      download(evaluation.organigrama,
                        'organigrama '+ extraerNombreYExtension(evaluation.organigrama)[0] + '.' +extraerNombreYExtension(evaluation.organigrama)[1]
                      )
                  "
                  >Organigrama</ButtonPrimary>
          </div>
        </div>
        <div v-if="is_user">

          <div v-if="is_admin">

            <DarkCard title="Comentario a la empresa (privado)" >
              {{evaluation.observacion_empresa_privado}}
            </DarkCard>
            <DarkCard title="Comentario al Sistema de Prácticas" >
              {{evaluation.observacion_sistema}}
            </DarkCard>
          </div>
          <DarkCard title="Comentario a la formación entregada por el Departamento de Informática USM" >
            {{evaluation.observacion_usm}}
          </DarkCard>
          <DarkCard title="Opinión sobre la empresa" >
            {{evaluation.observacion_empresa_publica}}
          </DarkCard>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ChipList from "../../content/ChipList.vue";
import DarkCard from '@/components/content/DarkCard.vue'
import Title from '@/components/content/Title.vue'
import ButtonPrimary from "@/components/forms/ButtonPrimary.vue";
import axios from "axios";

export default {
  components: {
    ChipList,
    DarkCard,
    Title,
    ButtonPrimary
  },
  props: ["evaluation", "oneline"],
  computed: {
    is_admin() {
      try {
        return this.$store.state.authentication.user.is_staff;
      } catch (error) {
        return false;
      }
    },
    is_user() {
      try {
        return this.$store.state.authentication.user;
      } catch (error) {
        return false;
      }
    },
  },
  methods: {
    button_disabled(url) {
      return url === null;
    },
    href_url(url) {
      return `${process.env.VUE_APP_MEDIA_URL}/${url}`;
    },
    download(url, name) {
      axios({
        url: `${process.env.VUE_APP_MEDIA_URL}/${url}`,
        method: "GET",
        responseType: "blob",
      }).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", name);
        document.body.appendChild(fileLink);

        fileLink.click();
      });
    },
    extraerNombreYExtension(ruta) {
      // Divide la ruta en partes por '/'
      const partes = ruta.split('/');
      let usuario = partes[0]
      let extension = partes[1].split('.')[1]

      return [usuario, extension];
    }
  },
};
</script>

<style></style>
