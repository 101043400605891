<template>
  <ValidationProvider v-slot="{ errors }" :name="name" :rules="rules">
    <v-select
      :items="items"
      rounded
      :disabled="disabled"
      :error-messages="errors"
      :value="value"
      @input="handle"
      :prepend-inner-icon="icon"
      :item-value="itemValue"
      :item-text="itemText"
      filled
      @change="onChange"
      :label="label"
    ></v-select>
  </ValidationProvider>
</template>

<script>
import {ValidationProvider} from "vee-validate";

export default {
  components: {
    ValidationProvider,
  },
  props: ["value", "items", "label", "icon", "rules", "itemValue", "itemText", "name", "disabled", "onChange"],
  methods: {
    handle: function (e) {
      this.$emit("input", e);
    },
  },
};
</script>
