<template>
  <v-row v-if="empresa">
    <v-col cols="12" md="4">
        <v-card class="pa-5">
        <v-container>
          <v-row align="center" justify="center">
            <h3 class="text-center">{{ empresa.nombre }}</h3>
          </v-row>
          <v-row align="center" justify="center">
            <p class="font-italic">{{ empresa.rut }}</p>
          </v-row>

          <v-row align="center" justify="center">
            <v-rating
              color="primary"
              background-color="grey darken-1"
              fullIcon="mdi-star"
              half-icon="mdi-star-half-full"
              half-increments
              emptyIcon="mdi-star-outline"
              :value="empresa.nota.promedio"
              hover
              readonly
            ></v-rating>
          </v-row>
          <v-row align="center" justify="center">
            <p class="font-italic">
              {{ empresa.nota.promedio }} ({{ empresa.nota.total }})
            </p>
          </v-row>
        </v-container>


        <v-container>
          <h3 class="mb-3">Sucursales</h3>
          <dark-card v-for="item in empresa.sucursales" :key="item.direccion">
            {{ item.direccion }}, {{ item.region.name }}, {{ item.pais.name }}
          </dark-card>

          <div v-if="empresa.sucursales.length == 0">
            <v-row class="ma-2" align="center" justify="center">
              <notFound width="200" height="200"></notFound
            ></v-row>

            <v-row class="ma-2" align="center" justify="center"
              >No hay sucursales</v-row
            >
          </div>

        </v-container>
</v-card>
    </v-col>

    <v-col cols="12" md="8">

        <v-container>
          <h3 class="mb-3">Comentarios</h3>

          <v-card class="pa-3" v-for="item in empresa.comentarios" :key="item.comentario">
            {{ item.comentario }}
          </v-card>

          <div v-if="empresa.comentarios.length == 0">
             <v-card class="pa-3" >
               Aún no se ha realizado ningún comentario sobre esta empresa
          </v-card>
          </div>
        </v-container>

    </v-col>
  </v-row>
</template>

<script>
import NotFound from "../../../components/icons/notFound.vue";
import DarkCard from "@/components/content/DarkCard.vue";

export default {
  components: {
    DarkCard,
    NotFound,
  },
  data: () => ({
    tab: null,
    panel: null,
  }),
  computed: {
    empresa() {
      return this.$store.state.tables.empresas_detail.items;
    },
    loading() {
      return this.$store.state.tables.empresas_detail.loading;
    },
  },

  created() {
    this.$store.dispatch("tables/get", {
      endpoint: `empresas/${this.$route.params.id}`,
      table: "empresas_detail",
    });
  },

  methods: {},
};
</script>

<style></style>
