<template>
  <ValidationProvider
    v-slot="{ errors }"
    :name="name"
    :rules="rules"
  >
    <v-autocomplete
      filled
      rounded
      :value="value"
      @input="handle"
      :items="items"
      :error-messages="errors"
      :item-value="itemValue"
      :item-text="itemText"
      chips
      :disabled="disabled"
      :label="label"
      multiple
      deletable-chips
      
      
    ></v-autocomplete>
  </ValidationProvider>
</template>

<script>

import {ValidationProvider,} from "vee-validate";

export default {
  components: {
    ValidationProvider,
  },

  props: ["value", "items", "disabled", "label", "name", "itemValue", "itemText", "rules"],
  methods: {
    handle: function (e) {
      this.$emit("input", e);
    },
  },
};
</script>

<style>
</style>