<template>
    <v-card>
        <v-stepper v-model="actualPage" alt-labels>
            <v-stepper-header style="height: 80%">
                <v-stepper-step
                    :complete="actualPage > 1"
                    step="1"
                    color="white"
                >
                    Tipo
                </v-stepper-step>
                <v-divider />
                <v-stepper-step
                    :complete="actualPage > 2"
                    step="2"
                    color="white"
                >
                    Categoria
                </v-stepper-step>
                <v-divider />
                <v-stepper-step
                    :complete="actualPage > 3"
                    step="3"
                    color="white"
                >
                    Requisitos
                </v-stepper-step>
                <v-divider />
                <v-stepper-step
                    :complete="actualPage > 4"
                    step="4"
                    color="white"
                >
                    Datos
                </v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
                <!-- Paso 1: Tipo de Práctica -->
                <!-- Tipo 0 -> Industrial -->
                <!-- Tipo 1 -> Profesional -->

                <v-stepper-content class="my_stepper" step="1">
                    <Title
                        class="ml-3"
                        text="Escoge el tipo de práctica que quieres realizar"
                        :center="true"
                    />
                    <v-row>
                        <v-col cols="12" lg="6">
                            <v-card
                                class="pa-5 hoverable"
                                @click="() => changePage(2, 0)"
                            >
                                <v-row justify="center">
                                    <practice-1 width="80%"></practice-1>
                                </v-row>
                                <h3 class="text-center mt-5">
                                    Iniciar una práctica en una empresa o institución
                                </h3>
                            </v-card>
                        </v-col>

                        <v-col cols="12" lg="6">
                            <v-card
                                class="pa-5 hoverable"
                                @click="() => changePage(2, 2)"
                            >
                                <v-row justify="center">
                                    <practice-2 width="80%"></practice-2>
                                </v-row>
                                <h3 class="text-center mt-5">
                                    Convalidar una práctica
                                </h3>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-stepper-content>

                <!-- Paso 2: Categoria de la práctica -->
                <!-- Una práctica comun puede ser Normal o Investigativa-->
                <!-- Una práctica convalidada puede ser Trabajo profesional, Trabajo social, Investigativa o Emprendimiento -->

                <v-stepper-content class="my_stepper" step="2">
                    <Title
                        class="ml-3"
                        text="Escoge en que categoria estaria tu práctica"
                        :center="true"
                    />
                    <v-row>
                        <v-col cols="12" :lg="getColSize()" v-if="this.tipoPractica == 0">
                            <v-card
                                class="pa-5 hoverable"
                                @click="() => changePage(3, 1)"
                            >
                                <v-row justify="center">
                                    <practice-1 width="100%" height="40vh"></practice-1>
                                </v-row>
                                <h3 class="text-center mt-5">
                                    Realizarás una práctica común en una empresa
                                </h3>
                            </v-card>
                        </v-col>

                        <v-col cols="12" :lg="getColSize()" v-if="this.tipoPractica == 2">
                            <v-card
                                class="pa-5 hoverable"
                                @click="() => changePage(3, 2)"
                            >
                                <v-row justify="center">
                                    <practice-2 width="100%" height="40vh"></practice-2>
                                </v-row>
                                <h3 class="text-center mt-5">
                                    Trabajo profesional realizado en una empresa o institución
                                </h3>
                            </v-card>
                        </v-col>
                        <v-col cols="12" :lg="getColSize()">
                            <v-card
                                class="pa-5 hoverable"
                                @click="() => changePage(3, 3)"
                            >
                                <v-row justify="center">
                                    <Investigativa width="100%" height="40vh" ></Investigativa>
                                </v-row>
                                <h3 class="text-center mt-5" v-if="this.tipoPractica == 0">
                                    Realizarás una práctica investigastiva
                                </h3>
                                <h3 class="text-center mt-5" v-if="this.tipoPractica == 2">
                                    Es una práctica investigativa
                                </h3>
                            </v-card>
                        </v-col>
                        <v-col cols="12" :lg="getColSize()" v-if="this.tipoPractica == 2">
                            <v-card
                                class="pa-5 hoverable"
                                @click="() => changePage(3, 4)"
                            >
                                <v-row justify="center">
                                    <Social width="100%" height="40vh"></Social>
                                </v-row>
                                <h3 class="text-center mt-5">
                                    Trabajo Social
                                </h3>
                            </v-card>
                        </v-col>
                        <v-col cols="12" :lg="getColSize()" v-if="this.tipoPractica == 2">
                            <v-card
                                class="pa-5 hoverable"
                                @click="() => changePage(3, 5)"
                            >
                                <v-row justify="center">
                                    <Emprendimiento width="100%" height="40vh"></Emprendimiento>
                                </v-row>
                                <h3 class="text-center mt-5">
                                    Emprendimiento
                                </h3>
                            </v-card>
                        </v-col>
                    </v-row>
                    <BottomArrowsStep
                        backText="Atrás"
                        :backAction="() => changePage(1, null)"
                    />
                </v-stepper-content>

                <!-- Paso 3: Información sobre requerimientos de Práctica -->

                <v-stepper-content class="my_stepper" step="3">
                    <v-card class=" ma-3">
                        <v-row align="center"  justify="center"> 
                            <v-col cols="7">
                            <Requisitos
                                :tipoPractica="tipoPractica"
                                :categoria="practicaToCreate.categoria"
                            />
                        <!------------------------------------->
                            </v-col>
                            <v-col v-if="desktop()" cols="5">
                                <practice-1     v-if="practicaToCreate.categoria == 1"/>
                                <practice-2     v-if="practicaToCreate.categoria == 2"/> 
                                <Investigativa  v-if="practicaToCreate.categoria == 3"/>
                                <Social         v-if="practicaToCreate.categoria == 4"/>
                                <Emprendimiento v-if="practicaToCreate.categoria == 5"/>
                            </v-col

                                
                        ></v-row>
                    </v-card>

                    <BottomArrowsStep
                        nextText="Continuar"
                        backText="Atrás"
                        :backAction="() => changePage(2, this.tipoPractica)"
                        :nextAction="() => (actualPage = 4)"
                    />
                </v-stepper-content>

                <!-- Paso 4: Formulario de inscripción de Práctica -->

                <v-stepper-content class="my_stepper" step="4">
                    <FormularioPractica
                        :practicaToCreate="practicaToCreate"
                        :tipoPractica="tipoPractica"
                        :practicas="practicas"
                        :backAction="() => changePage(3, this.practicaToCreate.categoria)"
                        :nextAction="() => (finishModal = true)"
                    ></FormularioPractica>

                    <ActionModal
                        :active="finishModal"
                        title="¿Segur@ que desea ingresar la práctica?"
                        :successAction="newPractica"
                        :cancelAction="() => (finishModal = false)"
                    >
                        Asegurese que los datos esten correctos, la práctica será ingresada.
                    </ActionModal>
                </v-stepper-content>
            </v-stepper-items>
        </v-stepper>
    </v-card>
</template>

<script>
import FormularioPractica from "./components/FormularioPractica";
import Requisitos from "./components/Requisitos";
import ActionModal from "@/components/ActionModal";
import Title from "@/components/forms/Title";
import BottomArrowsStep from "@/components/forms/BottomArrowsStep";
import Practice1 from "@/components/icons/Practice1.vue";
import Practice2 from "@/components/icons/Practice2.vue";
import Investigativa from "@/components/icons/Investigativa.vue";
import Emprendimiento from "@/components/icons/Emprendimiento.vue";
import Social from "@/components/icons/Social.vue";

export default {
    components: {
        BottomArrowsStep,
        Title,
        ActionModal,
        FormularioPractica,
        Practice1,
        Practice2,
        Investigativa,
        Emprendimiento,
        Social,
        Requisitos,
    },
    data: () => ({
        finishModal: false,
        actualPage: 1,
        tipoPractica: null,
        practicaToCreate: {
            categoria: null,
            fecha_inicio: null,
            fecha_termino: null,
            supervisor: {},
        },
    }),
    created() {
        this.$store.dispatch("tables/get", {
            endpoint: "practicas/alumno",
            table: "practicas",
        });
        this.practicaToCreate.campus = this.current_user.campus.id;
        this.practicaToCreate.carrera = this.current_user.carrera.id;
    },
    computed: {
        practicas() {
            return this.$store.state.tables.practicas.items;
        },
        current_user() {
            return this.$store.state.authentication.user;
        },
        loading() {
            return this.$store.state.tables.practicas.loading;
        },
    },

    watch: {
        practicas: function () {
            // wait, también tiene que ser para carrera.id == 2??
            if (this.practicas.paso == 1 && this.current_user.carrera.id != 3) {
                this.practicaToCreate.tipo = 1;
            }
            if (this.practicas.paso == 2 || this.current_user.carrera.id == 3) {
                this.practicaToCreate.tipo = 2;
            } else {
                this.practicaToCreate.tipo = 1;
            }
        },
    },

    methods: {
        //Funcion que llama a crear una practica
        desktop () {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return false
          case 'sm': return false
          case 'md': return false
          case 'lg': return true
          case 'xl': return true
        }

        return false
      },
        newPractica() {
            this.finishModal = false;
            if (this.tipoPractica == 0) {
                Object.keys(this.practicaToCreate).forEach((k) => {
                    if (typeof this.practicaToCreate[k] === "object") {
                        return toString(this.practicaToCreate[k]);
                    }

                    this.practicaToCreate[k] = "" + this.practicaToCreate[k];
                });

                this.$store.dispatch("tables/post", {
                    endpoint: "practicas",
                    table: "practica",
                    item: this.practicaToCreate,
                    redirect: "/user_dashboard",
                });
            } else {
                let formData = new FormData();
                formData.append("curriculum", this.practicaToCreate.curriculum);
                formData.append("boletas", this.practicaToCreate.boletas);
                formData.append("carta", this.practicaToCreate.carta);
                formData.append("sucursal", this.practicaToCreate.sucursal);
                formData.append("tareas_realizadas", this.practicaToCreate.tareas_realizadas);
                formData.append("consideracion", this.practicaToCreate.consideracion);
                formData.append("categoria", this.practicaToCreate.categoria);
                formData.append("modo", this.practicaToCreate.modo);
                formData.append("tipo", this.practicaToCreate.tipo);
                formData.append("carrera", this.practicaToCreate.carrera);
                formData.append("campus", this.practicaToCreate.campus);
                formData.append(
                    "fecha_inicio",
                    this.practicaToCreate.fecha_inicio
                );
                formData.append(
                    "fecha_termino",
                    this.practicaToCreate.fecha_termino
                );
                formData.append("convalidada", true);

                this.$store.dispatch("tables/post", {
                    endpoint: "practicas",
                    table: "practica",
                    item: formData,
                    files: true,
                    redirect: "/user_dashboard",
                });
            }
        },

        //Cambio de pagina del Stepper manteniendo el estado
        changePage(newPage, value) {
            switch (newPage) {
                case 1:
                    this.actualPage = newPage;
                    this.tipoPractica = null;
                    this.practicaToCreate.categoria = null;
                    break;
                case 2:
                    this.actualPage = newPage;
                    this.tipoPractica = value;
                    break;
                case 3:
                    this.actualPage = newPage;
                    this.practicaToCreate.categoria = value;
                    break;
                case 4:
                    this.actualPage = newPage;
                    break;

                default:
                    break;
            }
        },
        getColSize() {
            if (this.tipoPractica == 0) {
                return 6;
            } else {
                return 3;
            }
        },
    },
};
</script>

<style scoped>
.theme--dark .hoverable:hover {
    background-color: rgba(255, 255, 255, 0.5);
}

.theme--light .hoverable:hover {
    background-color: rgba(0, 0, 0, 0.07);
}
.my_stepper {
    min-height: 500px;
}

.v-stepper__wrapper {
    min-height: 500px !important;

    height: 100% !important;
}
</style>
