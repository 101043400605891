import moment from "moment";
moment.locale("es");



export function formatDate(date) {
    return moment(date).format("dddd, D [de] MMMM, YYYY");
}

export function formatDatetime(date) {
    return moment(date).format("dddd, D [de] MMMM, YYYY - HH:mm");
}

export function formatJustDate(date) {
    return moment(date).format("DD/MM/YY");
}

export function formatJustHour(date) {
    return moment(date).format("HH:mm");
}

