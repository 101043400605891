<template>
  <v-container>
    <v-row justify="space-around">
      <logo></logo>
    </v-row>
<v-card>


     <v-stepper v-model="actual_page" alt-labels>

        <v-stepper-header>
            <v-stepper-step :complete="actual_page > 1" step="1" color="white"
              >Bienvenida</v-stepper-step
            >
            <v-divider></v-divider>

            <v-stepper-step :complete="actual_page > 2" step="2" color="white"
              >Datos</v-stepper-step
            >
            <v-divider></v-divider>

            <v-stepper-step :complete="actual_page > 3" step="3" color="white"
              >Confirmación</v-stepper-step
            >

          </v-stepper-header>

      <v-stepper-items>

          <v-stepper-content step="1">
          <v-container>
              <p>Estimado Supervisor, bienvenido al Sistema de Prácticas del Departamento de Informática de la UTFSM</p>

                <p>Nuestro/a estudiante {{practica.alumno.first_name}} {{practica.alumno.last_name}} ha ingresado una Práctica {{practica.tipo}} de una duración de {{practica.modo}} 
                  que será realizada en {{practica.sucursal.empresa.nombre}}.
                </p>

                <p>La práctica dará inicio el dia {{formatDate(practica.fecha_inicio)}}.
                </p>

                <p>El rol del supervisor de una práctica es estar al tanto del trabajo realizado por el estudiante, y a la hora de que éste finalice su práctica, se le
                  enviará un nuevo correo donde deberá completar una evaluación del practicante.</p>

              <BottomArrowsStep
                    nextText="Continuar"
                    :nextAction="nextStep"
                  />
          </v-container>
        </v-stepper-content>


        <v-stepper-content step="2">
      <v-container v-if="practica">
        <v-row no-gutters v-if="!error">

          <v-col cols="12">
<v-container>
     <p>Por favor, verifique que sus datos esten correctos, si hay algún error puede editarlos con el botón 'Editar mis datos'</p>

              <DarkCard>
                <div>
                  <v-row no-gutters>
                    <v-col>
                      <h5 class="mb-0 font-weight-bold">Nombre:</h5>
                      <p class="mb-0 font-italic">
                        {{ supervisor.nombre + " " + supervisor.apellido }}
                      </p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <h5 class="mb-0 font-weight-bold">Email:</h5>
                      <p class="mb-0 font-italic">{{ supervisor.email }}</p>
                    </v-col>
                    <v-col>
                      <h5 class="mb-0 font-weight-bold">Teléfono:</h5>
                      <p class="mb-0 font-italic">{{ supervisor.telefono }}</p>
                    </v-col>
                  </v-row>
                </div>
              </DarkCard>
                 <v-row justify="end" class="mr-5">
              <v-btn text color="primary" @click="onOpenModal"
                >Editar mis datos</v-btn
              >
            </v-row>
            </v-container>
          </v-col>
        </v-row>
      </v-container>

<BottomArrowsStep
                    backText="Atrás"
              nextText="Continuar"
              :backAction="previousStep"
              :nextAction="nextStep"
                  />
        </v-stepper-content>


         <v-stepper-content step="3">
          <v-container>
              <p>Al confirmar la práctica, el estudiante deberá ingresar una bitácora diaria con el trabajo realizado. Recuerde que la práctica ingresada es del tipo {{practica.tipo}},
                la cual tiene como objetivo {{descripcion(practica.tipo)}}
              </p>


         
                  <ConfirmButton
                nextText="Estoy de acuerdo y quiero confirmar la práctica"
                backText="No quiero confirmar la práctica"
                :withoutIcons="true"
                :backAction="() => (cancelModal = true)"
                :nextAction="() => (successModal = true)"
              />
                   <BottomArrowsStep
                    backText="Atras"
                    :backAction="previousStep"
                  />

          </v-container>
        </v-stepper-content>


      </v-stepper-items>



     </v-stepper>
     </v-card>
    

    <ValidationObserver v-slot="{ invalid }">
      <ActionModal
        :backDisabled="true"
        :active="supervisorModal"
        title="Datos del Supervisor"
        :successAction="onSupervisorModal"
        :cancelAction="onCancelModal"
        :nextDisabled="invalid"
      >
        <text-field
          v-model="supervisor.nombre"
          label="Nombre"
          rules="required"
          name="El nombre"
        ></text-field>
        <text-field
          v-model="supervisor.apellido"
          label="Apellido"
          rules="required"
          name="El apellido"
        ></text-field>
        <text-field
          v-model="supervisor.email"
          label="E-mail"
          rules="email|required"
          name="El email"
        ></text-field>
        
        <text-field
          v-model="supervisor.telefono"
          label="Telefono"
          rules="required|max:18|telefono"
          name="El telefono"
        ></text-field>
      </ActionModal>
    </ValidationObserver>

    <ActionModal
      :active="successModal"
      title="Confirmación Práctica"
      :successAction="confirmPractica"
      :cancelAction="() => (successModal = false)"
    >
      ¿Está seguro que desea confirmar la práctica?
    </ActionModal>

    <ActionModal
      :active="cancelModal"
      title="Confirmación Práctica"
      :successAction="cancelPractica"
      :cancelAction="() => (cancelModal = false)"
    >
      ¿Está seguro que no desea confirmar la práctica?
    </ActionModal>

    <v-card v-if="error" class="pa-5 ma-5" height="500">
      <system-message
        :error="true"
        text="Ha ocurrido un error con tu solicitud"
      ></system-message>
    </v-card>
  </v-container>
</template>

<script>
import BottomArrowsStep from '../../components/forms/BottomArrowsStep.vue'
import { ValidationObserver } from "vee-validate";
import { formatDate } from "@/services/utils.service";

import ActionModal from "@/components/ActionModal";
import logo from "@/components/icons/logoDI";
import DarkCard from "@/components/content/DarkCard";
import ConfirmButton from "@/components/forms/ConfirmButton";
import SystemMessage from "@/components/content/SystemMessage";
import TextField from "@/components/forms/TextField";

export default {
  components: {
    ValidationObserver,
    logo,
    DarkCard,
    ActionModal,
    ConfirmButton,
    SystemMessage,
    TextField, BottomArrowsStep
  },
  computed: {
    svgTheme() {
      return this.$vuetify.theme.dark ? "white" : "black";
    },
    practica() {
      return this.$store.state.tables.confirmation_by_token.items;
    },
    error() {
      return this.$store.state.tables.confirmation_by_token.error;
    },
  },
  data: () => ({
    actual_page: 1,
    supervisorModal: false,
    successModal: false,
    cancelModal: false,
    supervisor_old: {
      nombre: "",
      apellido: "",
      telefono: "",
      email: "",
    },
    supervisor: {
      nombre: "",
      apellido: "",
      telefono: "",
      email: "",
    },
  }),

  created() {
    this.$store.dispatch("tables/get", {
      endpoint: `practicas/${this.$route.params.token}/confirmar`,
      table: "confirmation_by_token",
    });
  },
  methods: {
    descripcion(tipo){
      if(tipo == 'Industrial'){
        return 'introducir al estudiante en un ambiente laboral, no se puede esperar que el estudiante desarrolle un trabajo perfecto, si no más bien que sirva como una guía de introducción para el.'
      } else if(tipo == 'Profesional'){
        return 'que nuestro estudiante logre desarrollar un trabajo pre-profesional en la organización.'
      }
    },
    formatDate,
    confirmPractica() {
      this.$store.dispatch("tables/post", {
        endpoint: `practicas/${this.$route.params.token}/confirmar`,
        table: "confirmar_practica",
        item: {
          confirmed: true,
          supervisor: this.supervisor,
        },
        redirect: "/success",
      });
    },

    cancelPractica() {
      this.$store.dispatch("tables/post", {
        table: "confirmar_practica",
        endpoint: `practicas/${this.$route.params.token}/confirmar`,
        item: {
          confirmed: false,
          supervisor: this.supervisor,
        },
        redirect: "/success",
      });
    },
  nextStep() {
      this.actual_page = this.actual_page + 1;
    },
    previousStep() {
      this.actual_page = this.actual_page - 1;
    },
    onOpenModal(){
      this.supervisorModal = true;
      this.supervisor_old.nombre = this.supervisor.nombre
      this.supervisor_old.apellido = this.supervisor.apellido 
      this.supervisor_old.email = this.supervisor.email 
      this.supervisor_old.telefono = this.supervisor.telefono 
    },
    onCancelModal() {
      this.supervisor.nombre = this.supervisor_old.nombre;
      this.supervisor.apellido = this.supervisor_old.apellido;
      this.supervisor.email = this.supervisor_old.email;
      this.supervisor.telefono = this.supervisor_old.telefono;
      this.supervisorModal = false;

    },
    onSupervisorModal() {
      this.supervisorModal = false;
    },
  },

  watch: {
    practica(value) {
      this.supervisor.nombre = value.supervisor.nombre;
      this.supervisor.apellido = value.supervisor.apellido;
      this.supervisor.email = value.supervisor.email;
      this.supervisor.telefono = value.supervisor.telefono;
    },
  },
};
</script>

<style scoped>
.v-card > *:first-child:not(.v-btn):not(.v-chip), .v-card > .v-card__progress + *:not(.v-btn):not(.v-chip) {
    border-radius: 10px 10px 0px 0px !important;
}
</style>
