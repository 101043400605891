<template>
  <div v-if="items">
    <v-row>
      <v-col>
        <Title v-if="title" :text="title" :icon="icon"></Title>
        <v-chip class="mr-2 mb-2" :color="isDarkTheme? 'rgba(0, 0, 0, 0.2)': 'rgba(0, 0, 0, 0.05)'" v-for="item in items" :key="item">{{
          item
        }}</v-chip>

          <v-chip v-if="items.length == 0" class="mr-2 mb-2" :color="isDarkTheme? 'rgba(0, 0, 0, 0.2)': 'rgba(0, 0, 0, 0.05)'" >No hay información</v-chip>

      </v-col>
    </v-row>
  </div>
</template>

<script>
import Title from "./Title.vue";
export default {
  components: { Title },
  props: ["items", "title", 'icon'],
    computed:{
    isDarkTheme() {
      return this.$vuetify.theme.dark ? true : false;
    },
  }
};
</script>

<style></style>
