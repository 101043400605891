<template>
  <v-sheet>
    <v-card>
      <div v-if="log && log.length > 0">
        <v-list
          class="overflow-y-auto"
          min-height="500"
          max-height="500"
          :class="scrollbarTheme"
        >
          <div v-for="(item, i) in log" :key="i" class="pa-3">
            <v-row no-gutters>
              <v-col>
                <v-chip
                  class="mr-3 mb-2 white--text"
                  v-if="item.tipo"
                  :key="item.tipo"
                  :color="
                    item.tipo == 'sistema'
                      ? 'primary'
                      : item.tipo == 'admin'
                      ? 'green'
                      : 'blue'
                  "
                  >{{
                    item.tipo == "sistema"
                      ? "Sistema"
                      : item.tipo == "admin"
                      ? "Administrador"
                      : "Alumno"
                  }}</v-chip
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="pl-3 pr-3 pb-0 pt-0">
                <dark-card :noborder="true">
                  <pre class="font-italic mb-0" v-text="item.mensaje"></pre>
                </dark-card>
              </v-col>

              <v-col cols="12">
                <v-row justify="end" align="end">
                  <p
                    class="font-italic mr-5  mb-0"
                    v-text="formatDatetime(item.created_at)"
                  ></p>
                </v-row>
              </v-col>
            </v-row>
          </div>
        </v-list>
      </div>

      <div class="pa-5" v-else>
        <v-row class="ma-2" align="center" justify="center">
          <notFound width="256" height="256" ></notFound>
        </v-row>
        <v-row class="ma-2" align="center" justify="center"
          >No se ha encontrado ningún mensaje.</v-row
        >
      </div>

      <v-form @submit.prevent="enviarLog" @submit="enviarLog" v-on:keydown.enter.prevent="enviarLog" v-if="practica.estado.id != 4 && practica.estado.id != 5 && practica.estado.id != 6 && practica.estado.id != 8 && practica.estado.id != 9 && practica.estado.id != 10">
        <v-row no-gutters>
          <v-text-field
            class="ma-3"
            filled
            rounded
            v-model="mensaje"
            label="Ingrese Mensaje"
            single-line
            hide-details
          ></v-text-field>
          <v-btn
            fab
            elevation="0"
            color="primary"
            @click="enviarLog"
            class="ma-3"
            ><v-icon dark>send</v-icon></v-btn
          >
        </v-row>
      </v-form>
    </v-card>
  </v-sheet>
</template>

<script>
import DarkCard from "../../content/DarkCard.vue";
import NotFound from "../../icons/notFound.vue";

import { formatDatetime } from "@/services/utils.service";

export default {
  props: ["practica"],
  data: () => ({
    mensaje: "",
  }),
  components: { NotFound, DarkCard },
  computed: {
    log() {
      return this.$store.state.tables.log.items;
    },
    isDarkTheme() {
      return this.$vuetify.theme.dark ? true : false;
    },
    scrollbarTheme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
  },

  created() {
    this.$store.dispatch("tables/get", {
      endpoint: `practicas/${this.practica.token}/logs`,
      table: "log",
    });
  },

  methods: {
    formatDatetime,
    enviarLog() {
      if (!(this.mensaje === 0 || !this.mensaje.trim())) {
        this.$store.dispatch("tables/new", {
          endpoint: `practicas/${this.practica.token}/logs`,
          table: "log",
          item: { mensaje: this.mensaje },
          error_message: "Ha ocurrido un error al añadir el Log.",
          success_message: "Log creado correctamente.",
        });
      }

      this.mensaje = "";
    },
  },
};
</script>

<style scoped>
.card500 {
  min-height: 500px;
}

.blackBackground {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 16px;
}

.whiteBackground {
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 16px;
}
</style>
