<template>
  <ValidationProvider v-slot="{ errors }" :name="name" rules="required">
    <v-autocomplete
      :items="items"
      prepend-inner-icon="domain"
      :error-messages="errors"
      :value="value"
      @input="handle"
      rounded
      :item-value="itemValue"
      :item-text="itemText"
      filled
      :label="label"
      :filter="filter"
      @change="onChange"
    >
      <template v-if="kind != undefined" v-slot:item="data">
        <template v-if="notObject(data.item)">
          <v-list-item-content v-text="data.item"></v-list-item-content>
        </template>
        <template v-else>
          <v-list-item-content v-if=" kind == 'sucursal' ">

            <v-list-item-title v-html="data.item.direccion"></v-list-item-title>
            <v-list-item-subtitle
              v-html="data.item.pais.name"
            ></v-list-item-subtitle>
            <v-list-item-subtitle
              v-html="data.item.region.name"
            ></v-list-item-subtitle>

          </v-list-item-content>
          <v-list-item-content v-if="kind == 'empresa'">
            <v-list-item-title v-html="data.item.nombre"></v-list-item-title>
            <v-list-item-subtitle v-html="data.item.rut"></v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </template>
    </v-autocomplete>
  </ValidationProvider>
</template>

<script>

import {ValidationProvider} from "vee-validate";




export default {
  components: {
    ValidationProvider,
  },

  props: [
    "filter",
    "onChange",
    "kind",
    "value",
    "items",
    "label",
    "name",
    "itemValue",
    "itemText",
  ],
  methods: {
    notObject(data) {
      return typeof data !== "object";
    },
    handle: function(e) {
      this.$emit("input", e);
    },
  },
};
</script>

<style></style>
