<template>
  <v-btn :class="classButton" :disabled="disabled" elevation="0" :color="nocolor? '' : 'primary'" @click="onClick" :href="href" :target="target" class="ma-3"
    ><v-icon v-if="iconLeft" left dark>{{ iconLeft }}</v-icon> <slot /> <v-icon v-if="iconRight" right dark>{{ iconRight }}</v-icon> </v-btn
  >
</template>

<script>
export default {
  props: ["onClick", "iconLeft", "iconRight", "disabled", "classButton", "nocolor", "href", "target"],
};
</script>

<style>
</style>