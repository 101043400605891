<template>
  <v-container>
    <v-row justify="space-between">
      
      <ButtonPrimary
        v-if="backText"
        classButton="rounded mt-5"
        :nocolor="true"
        :iconLeft="withoutIcons?  '': 'mdi-chevron-left'"
        :onClick="backAction"
      >
        {{ backText }}
      </ButtonPrimary>

      <div v-else></div>

      <ButtonPrimary
      v-if="nextText"
        classButton="rounded mt-5"
        :iconRight="withoutIcons? '': 'mdi-chevron-right'"
        :onClick="nextAction"
        :disabled="nextDisabled"
      >
        {{ nextText }}
      </ButtonPrimary>
      <div v-else></div>
    </v-row>
  </v-container>
</template>

<script>
import ButtonPrimary from "./ButtonPrimary";

export default {
  components:{
    ButtonPrimary
  },
  props: ["nextText", "backText", "backAction", "nextAction", "nextDisabled", "withoutIcons"],
};
</script>

<style></style>
