<template>
  <v-container v-if="practica && bitacoras">
    <v-overlay :value="loading">
        <v-progress-circular
            :size="70"
            :width="7"
            color="primary"
            indeterminate
        ></v-progress-circular>
    </v-overlay>
    <v-row>
      <v-col cols="12" sm="4">
        <v-date-picker
          :min="practica.fecha_inicio"
          :max="practica.fecha_termino"
          first-day-of-week="1"
          background-color="primary"
          locale="es"
          color="primary"
          :events="dateFunctionEvents"
          v-model="date"
          @change="pick"
          full-width
        ></v-date-picker>

        <v-btn
          v-if="
            bitacoras &&
              practica.estado.id == 1 &&
              new Date(date) <= new Date() &&
              new Date(date) >= new Date(practica.fecha_inicio) && !revision
          "
          rounded
          class="mt-5"
          depressed
          x-large
          color="primary"
          width="100%"
          @click.stop="dialog = true"
          >Añadir bitacora del día</v-btn
        >

        <v-row no-gutters justify="space-around">
        <v-col 
          class="d-flex justify-center">
          <v-btn
            v-if="
              bitacoras &&
                practica.estado.id != 11 &&
                new Date(date) <= new Date() &&
                new Date(date) >= new Date(practica.fecha_inicio)
            "
            class="mt-5"
            depressed
            large
            color="primary"
            width="80%"
            @click.stop="generar_pdf_bitacoras()"
          > Descargar bitácoras<br>en PDF 
          </v-btn>
        </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="8">
        <v-card
          color="primary"
          class="pa-3 upper_borders white--text"
          style="width: 100%"
        >
          <v-container>
            <v-row justify="space-between">
              <h3>
                Bitácora {{ moment(date).format("dddd, D [de] MMMM, YYYY") }}
              </h3>
            </v-row>
          </v-container>
        </v-card>
        <v-card
          elevation="0"
          class="no_upper_borders"
          style="min-height: 380px"
        >
          <v-list
            class="overflow-y-auto"
            max-height="500"
            :class="scrollbarTheme"
          >
            <v-card
              elevation="0"
              style="padding: 10px; margin: 10px"
              v-for="bitacora in selected_bitacora"
              :key="bitacora.id"
            >
              <v-row no-gutters>
                <v-divider class="ma-4"></v-divider>
                <h2>
                  {{
                    moment(bitacora.hora_inicio, [
                      moment.ISO_8601,
                      "HH:mm",
                    ]).format("HH:mm")
                  }}
                  a
                  {{
                    moment(bitacora.hora_termino, [
                      moment.ISO_8601,
                      "HH:mm",
                    ]).format("HH:mm")
                  }}
                </h2>
                <v-divider class="ma-4"></v-divider>
              </v-row>

              <v-row>
                <v-col
                  cols="12"
                  :lg="bitacoras && practica.estado.id == 1 ? 9 : 12"
                >
                  <v-row no-gutters class="pl-2 pb-2">
                    <h5>Actividad desarrollada:</h5>
                  </v-row>
                  <DarkCard>
                    <pre class="font-italic mb-0">{{ bitacora.actividad_desarrollada }}</pre>
                  </DarkCard>

                  <v-row no-gutters class="pl-2 pb-2 pt-4">
                    <h5>Actividad Pendiente:</h5>
                  </v-row>

                  <DarkCard>
                    <pre class="font-italic mb-0">{{ bitacora.actividad_pendiente }}</pre>
                  </DarkCard>
                </v-col>
                <v-col
                  cols="12"
                  lg="3"
                  v-if="bitacoras && practica.estado.id == 1 && !revision"
                >
                  <v-row>
                    <v-col class="text-center">
                      <v-btn
                        class="ma-1"
                        elevation="0"
                        fab
                        @click="editItem(bitacora)"
                      >
                        <v-icon>edit</v-icon>
                      </v-btn>
                      <h5>Editar</h5>
                    </v-col>
                    <v-col class="text-center">
                      <v-btn
                        class="ma-1"
                        elevation="0"
                        fab
                        @click="deleteItem(bitacora)"
                      >
                        <v-icon color="red">delete</v-icon>
                      </v-btn>
                      <h5>Eliminar</h5>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card>
          </v-list>
          <v-container
            v-if="selected_bitacora.length == 0"
            style="min-height: 280px"
          >
            <v-row justify="space-around">
              <v-theme-provider root>
                <notFound width="128" height="128" :fill="svgTheme"></notFound>
              </v-theme-provider>
            </v-row>
            <v-row justify="space-around">
              <h4>No hay bitácoras para mostrar</h4>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog" max-width="950px" content-class="round ">
      <div ref="dialogDiv">
      <v-card>
        <v-card-title>
          <h4>{{ formTitle }}</h4>
        </v-card-title>
        <ValidationObserver v-slot="{ invalid }">
          <v-card-text class="pa-0 pl-3 pr-3">
            <h3 class="text-center" v-if="dialogDelete == true">
              ¿Estás seguro?
            </h3>

            

            <v-row v-if="dialogDelete != true" justify="space-around">
              <v-col cols="12" md="6" align="center">
                <h3 class="mb-3">Desde las:</h3>
                <ValidationProvider
                  v-slot="{ errors }"
                  name="La hora de inicio"
                  rules="required"
                >
                  <v-time-picker
                    v-model="editedItem.hora_inicio"
                    ampm-in-title
                    :allowed-minutes="allowedStep"
                    :aria-errormessage="errors"
                    min="6:00"
                    max="22:00"
                    format="24hr"
                    scrollable
                    header-color="transparent"
                    color="primary"
                    width="260"
                  ></v-time-picker>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" md="6" align="center">
                <h3 class="mb-3">Hasta las:</h3>
                <ValidationProvider
                  v-slot="{ errors }"
                  name="La hora de inicio"
                  rules="required"
                >
                  <v-time-picker
                    v-model="editedItem.hora_termino"
                    ampm-in-title
                    :allowed-minutes="allowedStep"
                    :min="editedItem.hora_inicio"
                    :aria-errormessage="errors"
                    max="22:00"
                    format="24hr"
                    scrollable
                    header-color="transparent"
                    color="primary"
                    width="260"
                  ></v-time-picker>
                    <!-- :change="changeTime1()" -->
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row v-if="dialogDelete != true">
              <v-col cols="12" md="6">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="La actividad desarrollada"
                  rules="required|min:20|max:250"
                >
                  <v-textarea
                    :error-messages="errors"
                    filled
                    rounded
                    :counter="250"
                    v-model="editedItem.actividad_desarrollada"
                    label="Actividad Desarrollada:"
                  ></v-textarea>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" md="6">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="La actividad pendiente"
                  rules="max:250"
                >
                  <v-textarea
                    filled
                    :error-messages="errors"
                    rounded
                    :counter="250"
                    v-model="editedItem.actividad_pendiente"
                    label="Actividad Pendiente:"
                  ></v-textarea>
                </ValidationProvider>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <div class="flex-grow-1"></div>
            <div v-if="dialogDelete === true">
              <BottomArrowsStep
                nextText="Eliminar"
                backText="Cancelar"
                :backAction="close"
                :nextAction="remove"
                :withoutIcons="true"
              />
            </div>
            <div v-else-if="dialogDelete === false">
              <BottomArrowsStep
                nextText="Añadir"
                backText="Cancelar"
                :backAction="close"
                :nextAction="save"
                :withoutIcons="true"
                :nextDisabled="invalid"
              />
            </div>
          </v-card-actions>
        </ValidationObserver>
      </v-card>
      </div>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from 'axios';

import BottomArrowsStep from "@/components/forms/BottomArrowsStep.vue";
import DarkCard from "@/components/content/DarkCard.vue";
import notFound from "@/components/icons/notFound";
import { tablesService } from "@/services";

import moment from "moment";
moment.locale("es");

import {ValidationProvider, ValidationObserver } from "vee-validate";


export default {
  components: {
    notFound,
    DarkCard,
    BottomArrowsStep,
    ValidationProvider,
    ValidationObserver,
  },
  props: ["practica", "fecha_inicio", 'revision'],
  data: () => ({
    editedIndex: -1,
    dialogDelete: false,
    dialog: false,
    date: moment().format("YYYY-MM-DD"),
    selected_bitacora: [],
    editedItem: {
      hora_inicio: "8:00",
      hora_termino: "17:00",
      actividad_pendiente: "",
      actividad_desarrollada: "",
    },
    removeId: null,
    editId: null,
    defaultItem: {
      hora_inicio: "8:00",
      hora_termino: "17:00",
      actividad_pendiente: "",
      actividad_desarrollada: "",
    },
    loading: false,
  }),
  computed: {
    formTitle() {
      if (this.dialogDelete) {
        return "Eliminar Bitácora";
      } else if (this.editedIndex === -1) {
        return "Nueva Bitácora";
      } else if (this.editedIndex > -1) {
        return "Editar Bitácora";
      }

      return "";
    },
    svgTheme() {
      return this.$vuetify.theme.dark ? "white" : "black";
    },
    scrollbarTheme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
    bitacoras() {
      return this.$store.state.tables.bitacoras[this.practica.tipo].items;
    },
  },
  updated() {
    if (this.dialog){
      const el = this.$refs.dialogDiv
      if (el){
        el.scrollIntoView({behavior: 'smooth'});
      }
    }
  },
  created() {
    this.$store.dispatch("tables/get", {
      endpoint: `practicas/${this.practica.token}/bitacoras`,
      table: "bitacoras",
      subtable: this.practica.tipo,
    });
    this.date = moment(this.fecha_inicio).format("YYYY-MM-DD");
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    // whenever question changes, this function will run
    bitacoras: function() {
      this.selected_bitacora = [];
      if (this.bitacoras) {
        this.bitacoras.forEach((bitacora) => {
          if (moment(bitacora.fecha).isSame(this.date, "day")) {
            this.selected_bitacora.push(bitacora);
          }
        });
      }

      this.$forceUpdate();
    },
  },
  methods: {
    /*changeTime1() {
      //this.editedItem["hora_termino"] = moment(this.editedItem.hora_inicio,'hh:mm').add(1, 'hours').format('hh:mm');
    },*/
    editItem(item) {
      this.editId = item.id;
      this.dialogDelete = false;
      this.editedIndex = this.selected_bitacora.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.removeId = item.id;
      this.dialogDelete = true;
      this.editedIndex = this.selected_bitacora.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    allowedStep: (m) => m % 5 === 0,

    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.dialogDelete = false;
      }, 300);
    },

    save() {
      if (this.editedIndex > -1) {
        // Edited save
        this.$store.dispatch("tables/edit", {
          endpoint: `practicas/${this.practica.token}/bitacoras`,
          table: "bitacoras",
          subtable: this.practica.tipo,
          id: this.editId,
          item: {
            hora_inicio: this.editedItem.hora_inicio,
            hora_termino: this.editedItem.hora_termino,
            actividad_desarrollada: this.editedItem.actividad_desarrollada,
            actividad_pendiente:
              this.editedItem.actividad_pendiente != ""
                ? this.editedItem.actividad_pendiente
                : "No hay actividades pendientes.",
          },
          editedIndex: this.editedIndex,
        }).then(() => {
          tablesService.getService(`practicas/${this.practica.token}/horas`).then(
            (data) => {
              this.practica.progreso = data.horas;
            });
        });
      } else {
        // New save
        this.editedItem["practica"] = this.practica.token;
        this.editedItem["fecha"] = this.date;

        if (this.editedItem.actividad_pendiente == "")
          this.editedItem["actividad_pendiente"] =
            "No hay actividades pendientes.";
        this.$store.dispatch("tables/new", {
          endpoint: `practicas/${this.practica.token}/bitacoras`,
          table: "bitacoras",
          subtable: this.practica.tipo,
          item: this.editedItem,
          error_message: "Ha ocurrido un error al añadir la bitácora.",
        }).then(() => {
          tablesService.getService(`practicas/${this.practica.token}/horas`).then(
            (data) => {
              this.practica.progreso = data.horas;
            });
        });
      }
      this.close();
    },

    remove() {
      this.$store.dispatch("tables/delete", {
        endpoint: `practicas/${this.practica.token}/bitacoras`,
        table: "bitacoras",
        subtable: this.practica.tipo,
        id: this.removeId,
        editedIndex: this.editedIndex,
        error_message: "Ha ocurrido un error al eliminar la bitácora.",
        success_message: "Bitácora eliminada correctamente.",
      });
      this.close();
    },

    dateFunctionEvents(date) {
      var nEvent = 0;
      if (this.bitacoras) {
        this.bitacoras.forEach((bitacora) => {
          if (moment(bitacora.fecha).isSame(date, "day")) {
            nEvent = 1;
          }
        });
      }

      if (nEvent > 0) return Array.from({ length: nEvent }, (v, k) => "green");

      return false;
    },

    pick() {
      this.selected_bitacora = [];

      if (this.bitacoras) {
        this.bitacoras.forEach((bitacora) => {
          if (moment(bitacora.fecha).isSame(this.date, "day")) {
            this.selected_bitacora.push(bitacora);
          }
        });
      }
    },
    generar_pdf_bitacoras(){
      try {
        this.loading = true;
        this.download(
          `${process.env.VUE_APP_API_URL}/practicas/${this.practica.token}/bitacoras/pdf/`,
          `Bitacoras_${this.practica.alumno.rol}_${this.practica.tipo}.pdf`
        )
      } catch {
        this.loading = false;
        window.alert('Error al convertir tus bitácoras a PDF')
      }
    },
    download(url, name) {
      axios({
        url: url,
        method: "GET",
        responseType: "blob",
      }).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", name);
        document.body.appendChild(fileLink);

        fileLink.click();
        this.loading = false;
      });
    },
  },
};
</script>

<style>

.v-time-picker-clock__container{
    flex-basis: unset !important;
    padding: 0px !important;
    margin-top: 20px !important;
}
.theme--dark .v-time-picker-title {
  color: #ffffff;
  justify-content: center;
}
.theme--light .v-time-picker-title {
  color: #000;
  justify-content: center;

}
.v-picker--time > .v-picker__title {
    padding: 0px !important;
}
.theme--dark.v-stepper {
  background-color: transparent !important;
}

.v-card .v-picker__title {
  border-radius: 10px 10px 0px 0px !important;
}

.v-card {
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
  box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.2), 0 0px 0px 0 rgba(0, 0, 0, 0.19);
  border-radius: 10px !important;
}
.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.2), 0 0px 0px 0 rgba(0, 0, 0, 0.19);
  /* border-radius: 10px !important; */
}

.row{
  margin: 0px
}

.upper_borders {
  border-radius: 10px 10px 0px 0px !important;
}

.no_upper_borders {
  border-radius: 0px 0px 10px 10px !important;
}

.theme--dark.v-picker__body {
  background-color: rgba(0, 0, 0, 0.2) !important;
}
.theme--light.v-picker__body {
  background-color: rgba(0, 0, 0, 0.03) !important;
}
.v-picker--time > .v-picker__body {
  background-color: transparent !important;
}

.v-card.v-picker--time {
  background-color: transparent !important;
}

.light::-webkit-scrollbar {
  width: 15px;
}

.light::-webkit-scrollbar-track {
  background: #e6e6e6;
  border-left: 1px solid #dadada;
}

.light::-webkit-scrollbar-thumb {
  background: #b0b0b0;
  border: solid 3px #e6e6e6;
  border-radius: 7px;
}

.light::-webkit-scrollbar-thumb:hover {
  background: black;
}

.dark::-webkit-scrollbar {
  width: 15px;
}

.dark::-webkit-scrollbar-track {
  background: #202020;
}

.dark::-webkit-scrollbar-thumb {
  background: #3e3e3e;
  border: solid 3px #202020;
  border-radius: 7px;
}

.dark::-webkit-scrollbar-thumb:hover {
  background: white;
}
</style>
