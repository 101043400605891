<template>
  <v-container v-if="!loading">

      <v-row class="mb-5">

        <Profile :curriculum="perfil"></Profile>
           
      </v-row>
    <v-row justify="center">

      <v-container>
      <v-expansion-panels v-model="panel" v-if="practicas">
        <v-expansion-panel :key="1" v-if="practicas.industrial">
          <v-expansion-panel-header>
            <v-card class="pa-5">
              <v-row justify="space-between" align="center">
                <div>
                  <h2>Práctica Industrial</h2>
                </div>
                <v-chip class="ma-2" color="primary">
                  {{
                    practicas.industrial
                      ? practicas.industrial.estado.nombre
                      : "No iniciada"
                  }}
                </v-chip>
              </v-row>
            </v-card>
          </v-expansion-panel-header>
          <v-expansion-panel-content>

            <Practica :practica="practicas.industrial" :alumno="perfil.alumno"></Practica>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel :key="2" v-if="practicas.profesional">
          <v-expansion-panel-header>
            <v-card class="pa-5">
              <v-row justify="space-between" align="center">
                <div>
                  <h2>Práctica Profesional</h2>
                </div>
                <v-chip class="ma-2" color="primary">
                  {{
                    practicas.profesional
                      ? practicas.profesional.estado.nombre
                      : "No iniciada"
                  }}
                </v-chip>
              </v-row>
            </v-card>
          </v-expansion-panel-header>
          <v-expansion-panel-content>

            <Practica class="mb-5" :practica="practicas.profesional"  :alumno="perfil.alumno"></Practica>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-card class="pa-5" v-else>
         <h3>¡No hay prácticas ingresadas!</h3>
      </v-card>
      </v-container>
    </v-row>

  </v-container>
</template>

<script>
import Practica from "@/components/practicas/PracticaDetail";
import Profile from "./components/StepperInfo";

export default {
  components: {
    Profile,
    Practica
  },
  data: () => ({
    tab: null,
    panel: null,
  }),
  computed: {
    practicas() {
      return this.$store.state.tables.practicas.items;
    },
    loading() {
      return this.$store.state.tables.practicas.loading;
    },
    perfil() {
      return this.$store.state.tables.perfil.items;
    },
  },
  watch: {
    practicas: function() {
      if (this.practicas.paso == 2 || this.practicas.paso == 3) {
        this.panel = 1;
      }
    },
  },
  created() {
    this.$store.state.tables.practicas.items = null;
    this.$store.dispatch("users/getCurrentUser");
    this.$store.dispatch("tables/get", { endpoint: `practicas/${this.$route.params.username}/alumno`, table: "practicas"});
    this.$store.dispatch("tables/get", { endpoint: `alumnos/${this.$route.params.username}`, table: "perfil"});
  },

  methods: {
    newPractica(tipo) {
      this.$router.push({
        name: "Inscribir Práctica",
        params: { tipo_practica: tipo },
      });
    },
  },
};
</script>

<style>
.round {
  border-radius: 20px !important;
}
</style>
