<template>
  <v-container>
    <v-card>
        Error
    </v-card>
     
  </v-container>
</template>

<script>
export default {

}
</script>

<style>

</style>