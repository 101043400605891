<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    :disabled="disabled"
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <ValidationProvider
        v-slot="{ errors }"
        :name="name"
        :rules="rules"
      >
        <v-text-field
          :value="value"
          @input="handle"
          :error-messages="errors"
          filled
          rounded
          readonly
          :disabled="disabled"
          :label="label"
          prepend-inner-icon="event"
          v-bind="attrs"
          v-on="on"
        ></v-text-field>
      </ValidationProvider>
    </template>

    <v-date-picker
      :disabled="disabled"
      :value="value"
      :show-current="false"
      @input="handleCalendar"
    ></v-date-picker>
  </v-menu>
</template>

<script>
import { required } from "vee-validate/dist/rules";
import {ValidationProvider} from "vee-validate";

export default {
  components: {
    ValidationProvider,
  },
  data: () => ({
    menu: false,
  }),
  props: ["value", "disabled", "rules", "label", "name"],
  methods: {
    handle: function (e) {
      this.$emit("input", e);
    },
    handleCalendar: function (e) {
      this.menu = false;
      this.$emit("input", e);
    },
  },
};
</script>

<style>
</style>