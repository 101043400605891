<template>
<Layout :width="12">
    <v-card>
        <v-stepper v-model="actual_page" alt-labels>
            <v-stepper-header>
                <v-stepper-step
                    :complete="actual_page > 1"
                    step="1"
                    color="white"
                    >Bienvenida</v-stepper-step
                >
                <v-divider></v-divider>

                <v-stepper-step
                    :complete="actual_page > 2"
                    step="2"
                    color="white"
                    >Datos</v-stepper-step
                >
                <v-divider></v-divider>

                <v-stepper-step
                    :complete="actual_page > 3"
                    step="3"
                    color="white"
                    >Sistema</v-stepper-step
                >
            </v-stepper-header>

            <v-stepper-items>
                <v-stepper-content step="1">
                    <v-container>
                        <p>
                            Las prácticas son el primer paso formal de
                            acercamiento a la realidad laboral y una forma de
                            obtener experiencia profesional. Es una forma de
                            contacto personal y pre-profesional con una Empresa
                            u organización que puede abrirte puertas para un
                            futuro laboral
                        </p>

                        <p>Hay dos tipos de prácticas</p>

                        <p>
                            <span class="font-weight-black"
                                >Practica Industrial:
                            </span>
                            Su objetivo es introducirte en un ambiente laboral.
                        </p>
                        <p>
                            <span class="font-weight-black"
                                >Practica Profesional:
                            </span>
                            Su objetivo es logres desarrollar un trabajo
                            pre-profesional en una organización.
                        </p>

                        <p>
                            Si eres un alumno de
                            <span class="font-italic"
                                >Ingeniería Civil Informática o Ingeniería
                                Informática</span
                            >, debes realizar ambas prácticas, donde siempre la
                            primera es de tipo Industrial. Tienes la opción que
                            tu Práctica Industrial sea de 1 o 2 meses y la
                            Profesional de 2 o 3 meses. Entre ambas prácticas
                            debes acumular 4 meses de trabajo.
                        </p>

                        <p>
                            Si eres un alumno de
                            <span class="font-italic"
                                >Ingeniería en Ejecución en Informatica</span
                            >, solo debes realizar la Práctica Profesional, la
                            cual debe de ser de 2 meses de duración
                        </p>

                        <BottomArrowsStep
                            nextText="Continuar"
                            :nextAction="nextStep"
                        />
                    </v-container>
                </v-stepper-content>

                <v-stepper-content step="2">
                    <v-container>
                        <p>Primero, cuentanos sobre ti</p>
                        <AlumnoForm
                            v-model="alumno"
                            :backAction="previousStep"
                            :nextAction="nextStep"
                        />
                    </v-container>
                </v-stepper-content>

                <v-stepper-content step="3">
                    <v-container>
                        <p>En este sistema podrás:</p>
                        <v-card class="pa-2">
                            <span class="font-weight-black"
                                >• Ingresar y dar inicio a tus prácticas.</span
                            >
                            <br />
                        </v-card>
                        <v-card class="pa-2"
                            ><span class="font-weight-black"
                                >• Ingresar tus bitácoras.</span
                            ><br
                        /></v-card>
                        <v-card class="pa-2"
                            ><span class="font-weight-black"
                                >• Generar solicitudes automáticas a los
                                problemas más concurrentes.</span
                            ><br
                        /></v-card>
                        <v-card class="pa-2"
                            ><span class="font-weight-black"
                                >• Ponerte en contacto con un administrador en
                                caso de tener algún problema con alguna de tus
                                prácticas.</span
                            ><br
                        /></v-card>
                        <v-card class="pa-2"
                            ><span class="font-weight-black"
                                >• Ver las empresas ingresadas en el sistema, su
                                valoración y comentarios.</span
                            ><br
                        /></v-card>
                        <v-card class="pa-2"
                            ><span class="font-weight-black"
                                >• Ver las estadísticas de las tecnologías más
                                utilizadas en las prácticas.</span
                            ><br
                        /></v-card>

                        <BottomArrowsStep
                            backText="Atrás"
                            nextText="Empezar"
                            :backAction="previousStep"
                            :nextAction="finalizar"
                        />
                    </v-container>
                </v-stepper-content>
            </v-stepper-items>
        </v-stepper>
    </v-card>
    </Layout>
</template>

<script>
import BottomArrowsStep from "@/components/forms/BottomArrowsStep.vue";
import AlumnoForm from "./AlumnoForm.vue";
import Layout from '@/components/content/Layout.vue';

export default {
    components: {
        BottomArrowsStep,

        AlumnoForm,
        Layout,
    },
    data: () => ({
        actual_page: 1,
        receive_email: false,
        alumno: {
            first_name: "",
            region_id: 2830,
            receive_email: 0,
        },
    }),

    computed: {
        logged() {
            return this.$store.state.authentication.status.loggedIn;
        },
    },

    methods: {
        finalizar() {
            if (this.receive_email) {
                this.alumno.receive_email = 1;
            } else {
                this.alumno.receive_email = 0;
            }
            this.$store.dispatch("users/edit", {
                item: this.alumno,
                reload: true,
            });
        },
        nextStep() {
            this.actual_page = this.actual_page + 1;
        },
        previousStep() {
            this.actual_page = this.actual_page - 1;
        },
    },
};
</script>

<style scoped>
.v-card > *:first-child:not(.v-btn):not(.v-chip),
.v-card > .v-card__progress + *:not(.v-btn):not(.v-chip) {
    border-radius: 10px 10px 0px 0px !important;
}
</style>
