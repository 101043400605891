<template>
    <div>
        <v-overlay :value="loading">
                    <v-progress-circular
                        :size="70"
                        :width="7"
                        color="primary"
                        indeterminate
                    ></v-progress-circular>
        </v-overlay>
        <v-row no-gutters width="70vw">
            <v-col cols="12">
                        <h3 class="mb-5">Rango de Fechas</h3>
                        <p class="mb-5">Seleccione el rango de fechas en que quiere obtener la información</p>
            </v-col>
            <v-col cols="12" lg="6">
            <v-menu v-model="menu1" :close-on-content-click="false" max-width="290">
                <template v-slot:activator="{ on, attrs }">
                <v-text-field class="pa-1"
                    rounded
                    filled
                    :value="format_date_initial"
                    clearable
                    label="Fecha de inicio"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    @click:clear="clearInicio"
                ></v-text-field>
                </template>
                <v-date-picker v-model="date_initial" @change="selectType"  min="2008-01-01" :max="initial_max_date()" locale="es-CL"></v-date-picker>
            </v-menu>
            </v-col>
            <v-col cols="12" lg="6">
            <v-menu v-model="menu2" :close-on-content-click="false" max-width="290">
                <template v-slot:activator="{ on, attrs }">
                <v-text-field class="pa-1"
                    rounded
                    filled
                    :value="format_date_final"
                    clearable
                    label="Fecha de termino"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    @click:clear="clearTermino"
                ></v-text-field>
                </template>
                <v-date-picker v-model="date_final" @change="selectType"  :min="temino_min_date()" :max="new Date().toISOString()" locale="es-CL"></v-date-picker>
            </v-menu>
            </v-col>
        </v-row>
        <div class="row">
            <ExporterButton
                v-for="(button) in exportadores_disponibles"
                :title="button.title"
                :description="button.descripcion"
                :iconLeft="button.icon"
                :onClick="() => downloadPDF(button.url)"
                :disabled="datesSelected()"
            />
            
        </div>
    </div>
  </template>
  
  <script>
  import ButtonPrimary from "@/components/forms/ButtonPrimary.vue";
  import ExporterButton from "@/components/forms/ExporterButton.vue";
  import axios from "axios";
  import moment from "moment";

  export default {
    components: {
        ButtonPrimary,
        ExporterButton
    },
    data: () => ({
        menu1: false,
        menu2: false,
        date_initial: null,
        date_final: null,
        loading: false,


        exportadores_disponibles: [
            {   title: "Supervisores",
                icon: "mdi-account-supervisor",
                url: "supervisores",
                descripcion: "Nombre, correo y empresa de los supervisores de los prácticantes"
            },
            {   title: "Prácticas en curso",
                icon: "mdi-progress-clock",
                url: "practicas_en_curso",
                descripcion: "Datos de los alumnos y de las prácticas que se encuentran en curso en el periodo indicado"
            },
            {   title: "Prácticas Aprobadas",
                icon: "mdi-check-circle",
                url: "practicas_aprobadas",
                descripcion: "Datos de los alumnos y de las prácticas que se encuentran en aprobadas en el periodo indicado"
            },
            {   title: "Prácticas Convalidadas",
                icon: "mdi-text-box-check",
                url: "practicas_convalidadas",
                descripcion: "Datos de los alumnos y de las prácticas que se hayan sido convalidadas"
            },
            {   title: "Jefes de RRHH",
                icon: "mdi-account-tie",
                url: "jefes_rrhh",
                descripcion: "Nombre, correo y empresa de los jefes de RRHH de los prácticantes"
            },
            {   title: "Comentarios de Alumnos",
                icon: "mdi-comment-account",
                url: "comentarios_alumnos",
                descripcion: " incluye comentarios al DI y comentarios Sistema de Prácticas"
            },
            {   title: "Notas alumnos anonimos",
                icon: "mdi-clipboard-list",
                url: "notas_alumnos_anonimos",
                descripcion: "Incluye las notas puestas en la evaluación del alumno sin información personal"
            },
            {   title: "Notas alumnos con nombre",
                icon: "mdi-clipboard-list",
                url: "notas_alumnos",
                descripcion: "Incluye las notas puestas en la evaluación del alumno con nombre, rut y rol"
            },
            {   title: "Alumnos con marcas",
                icon: "mdi-flag",
                url: "alumnos_marcas",
                descripcion: "Incluye información con las marcas puestas entre las fechas indicadas, vienen negativas, neutras y positivas"
            },
            {   title: "Alumnos con riesgos",
                icon: "mdi-alert-circle",
                url: "alumnos_riesgos",
                descripcion: "Incluye información de los alumnos cuya práctica fue marcada con reparos por el supervisor o tienen una nota alarmante"
            },
        ]
    }),
  
    computed: {
        format_date_initial() {
        return this.date_initial
            ? moment(this.date_initial).format("dddd, Do MMMM, YYYY")
            : "";
        },

        format_date_final() {
        return this.date_final
            ? moment(this.date_final).format("dddd, Do MMMM, YYYY")
            : "";
        },
    },
    methods: {
        downloadPDF(exportador) {

            this.download(
                `${process.env.VUE_APP_API_URL}/exportadores/${exportador}/`
            )
        },
        download(url) {
            this.loading = true;
            axios({
                    url: url,
                    method: "GET",
                    responseType: "blob",
                    params: {
                        fecha_inicio: this.date_initial,
                        fecha_fin: this.date_final
                    }
            }).then((response) => {
                    const contentDisposition = response.headers['content-disposition'];
                    let fileName = 'downloaded-file.xlsx'; // Valor por defecto

                    if (contentDisposition) {
                        const fileNameMatch = contentDisposition.match(/filename="?([^"]+)"?/);
                        if (fileNameMatch && fileNameMatch.length === 2) {
                            fileName = fileNameMatch[1];
                        }
                    }

                    const blob = new Blob([response.data], { type: response.data.type });
                    const fileURL = window.URL.createObjectURL(blob);
                    const fileLink = document.createElement('a');

                    fileLink.href = fileURL;
                    fileLink.setAttribute('download', fileName);
                    document.body.appendChild(fileLink);
                    this.loading = false;
                    fileLink.click();
                    document.body.removeChild(fileLink); // Limpieza
            });
        },

        clearInicio() {
            this.date_initial = null;
            this.selectType();
        },
        clearTermino() {
            this.date_final = null;
            this.selectType();
        },
        selectType() {
            this.menu1 = false;
            this.menu2 = false;
        },
        datesSelected() {
            return !(this.date_initial && this.date_final);
        },

        initial_max_date() {

            if (this.date_final) {
                return this.date_final;
            } else {
                return new Date().toISOString();
            }
        },

        temino_min_date() {
            if (this.date_initial) {
                return this.date_initial;
            } else {
                return "2008-01-01";
            }
        }
        
            
    },
  };
  </script>
  
  <style>
  </style>