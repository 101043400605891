<template>
<v-container>
  <v-card class="pa-3">

      <v-data-table 
        :headers="headers" 
        :items="empresas" 
        :items-per-page="15" 
        :search="search"
        :footer-props="{
          'items-per-page-text':'Elementos por página',
          pageText: '{0}-{1} de {2}'}"
      >
        <template v-slot:top>
          <v-row no-gutters>
            <v-text-field
              class="ma-3"
              filled
              rounded
              v-model="search"
              prepend-inner-icon="search"
              label="Buscar"
              single-line
              hide-details
            ></v-text-field>
          </v-row>
        </template>
        <template v-slot:item.action="{ item }">
          <v-btn color="primary"  @click="empresa_url(item.id)">Ver Detalles</v-btn>
        </template>
      </v-data-table>


  </v-card>
  </v-container>
</template>

<script>

export default {
  data: () => ({

    headers: [
      {
        text: "Empresa",
        align: "left",
        sortable: true,
        value: "nombre"
      },
      {
        text: "RUT",
        align: "left",
        sortable: true,
        value: "rut",
        width: "25%"
      },

      { text: "Acciones", value: "action", sortable: false, width: "8%" }
    ],

    search: "",
    itemsPerPageArray: [12, 24, 32],
    page: 1,
    itemsPerPage: 12,
  }),

  computed: {
    n_items() {
      var pages = 0;
      try {
        pages = this.empresas.length;
      } catch (error) {
        pages = 0;
      }
      return pages;
    },
    numberOfPages() {
      var pages = 0;
      try {
        pages = Math.ceil(this.empresas.length / this.itemsPerPage);
      } catch (error) {
        pages = 0;
      }
      return pages;
    },
    empresas() {
      return this.$store.state.tables.empresas.items;
    },
    loading() {
      return this.$store.state.tables.empresas.loading;
    },
    svgTheme() {
      return this.$vuetify.theme.dark ? "white" : "black";
    },
  },
  created() {
    this.$store.dispatch("tables/get", { table: "empresas" });
  },
  methods: {
    empresa_url(id) {
      this.$router.push("/empresas/" + id);
    },
    nextPage() {
      if (this.page + 1 <= this.numberOfPages) this.page += 1;
    },
    formerPage() {
      if (this.page - 1 >= 1) this.page -= 1;
    },
    updateItemsPerPage(number) {
      this.itemsPerPage = number;
    },
  },
};
</script>

<style></style>
