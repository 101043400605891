<template>
    <v-col >

    <div >
        <v-list
          ref="messageList"
          class="overflow-y-auto"
          min-height="600"
          max-height="500"
          style="border-radius: 10px;"
          :class="scrollbarTheme"
        >
          <Mensaje
          v-for="(mensaje, index) in mensajes"
          :key="index"
          :usuario="mensaje.role"
          :mensaje="mensaje.message"
          usuario_name="Marco"
        />
        </v-list>
      </div>


      <v-form @submit.prevent="enviarMensaje" @submit="enviarMensaje" v-on:keydown.enter.prevent="enviarMensaje">
        <v-row no-gutters>
          <v-text-field
            class="ma-3"
            filled
            rounded
            v-model="newMessage"
            label="Ingrese Mensaje"
            single-line
            hide-details
          ></v-text-field>
          <v-btn
            fab
            elevation="0"
            color="primary"
            @click="enviarMensaje"
            class="ma-3"
            ><v-icon dark>send</v-icon></v-btn
          >
        </v-row>
      </v-form>
    </v-col>
  </template>
  
  <script>
  import Mensaje from "@/components/chatbot/subcomponent/mensaje";
  import axios from "axios";

  export default {
    components: {
      Mensaje,
    },
    data: () => ({
      newMessage: "",
      mensajes: [
        {
          role: "assistant",
          message: "Hola!, soy el asistente virtual del sistema de prácticas ¿En qué puedo ayudarte?",
        },


      ],
    }),
    computed: {
        current_user() {
            return this.$store.state.authentication.user;
        },
    },
    methods: {
        enviarMensaje() {
            if (this.newMessage.trim()) {
                let data = {
                    "message": this.newMessage,
                    "history": this.mensajes
                }
                axios.post(process.env.VUE_APP_ASISTENTE_URL,
                            data,
                            { headers: { 'x-api-key': process.env.VUE_APP_ASISTENTE_API_KEY} 
                                })    
                    .then((response) => {

                        this.mensajes.push({
                                                role: "assistant",
                                                message: response.data.response,
                                            })
                        this.scrollToBottom();
                    })

            this.mensajes.push({
                role: "user",
                message: this.newMessage,
            });
            this.newMessage = "";
            // Auto-scroll después de agregar el mensaje del usuario
            
            this.scrollToBottom();
            

            }
        },
        scrollToBottom() {
            this.$nextTick(() => {
                setTimeout(() => {
                let list = this.$refs.messageList.$el;
                if (list) {
                    list.scrollTop = list.scrollHeight;
                }
                }, 100); // Ajusta el tiempo si es necesario
            });

        },
        isDarkTheme() {
            return this.$vuetify.theme.dark ? true : false;
        },
        scrollbarTheme() {
            return this.$vuetify.theme.dark ? "dark" : "light";
        },
    },
  };
</script>
  
<style>
.overflow-y-auto {
  overflow-y: auto;
}
</style>
