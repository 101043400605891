<template>
  <v-container>

      En esta sección podrás hacer una solicitud para cambiar algún dato o configuración de tu práctica.

      <v-row justify="end">
        <v-btn rounded class="mt-5" depressed x-large color="primary" @click.stop="dialog = true">
          <v-icon left>add</v-icon>Añadir solicitud
        </v-btn>
      </v-row>

      <v-data-iterator 
        :items="solicitudes" 
        :items-per-page.sync="itemsPerPage" 
        :page="page" 
        hide-default-footer 
      >
        <template v-slot:default="props">
          <v-row>
            <v-col v-for="item in props.items" v-if="practica.token == item.practica.token" :key="item.name" cols="12">
    		<solicitud :solicitud="item"></solicitud>
            </v-col>
          </v-row>
        </template>

        <template v-if="n_items > 0" v-slot:footer>
          <v-row class="ma-2" align="center" justify="center">
            <span class="grey--text">Solicitudes por página</span>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn dark text color="primary" class="ml-2" v-bind="attrs" v-on="on">
                  {{ itemsPerPage }}
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(number, index) in itemsPerPageArray"
                  :key="index"
                  @click="updateItemsPerPage(number)"
                >
                  <v-list-item-title>{{ number }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <v-spacer></v-spacer>

            <span class="mr-4 grey--text">Página {{ page }} de {{ numberOfPages }}</span>
            <v-btn fab small dark color="primary" class="mr-1" @click="formerPage">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <v-btn fab small dark color="primary" class="ml-1" @click="nextPage">
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-row>
        </template>


        <template v-slot:no-data>
          <v-row class="ma-2" align="center" justify="center">
            <notFound width="256" height="256"></notFound
          ></v-row>

          <v-row class="ma-2" align="center" justify="center"
            >No has realizado ninguna solicitud</v-row
          >
        </template>

        <template v-slot:loading>
          <v-row class="ma-2" align="center" justify="center">
            <v-row justify="space-around">
              <v-progress-circular indeterminate color="primary"></v-progress-circular>
            </v-row>
          </v-row>
        </template>

      </v-data-iterator>

    <ValidationObserver v-slot="{ invalid }">
    <ActionModal :nextDisabled="invalid" :active="dialog" :cancelAction="close" title="Nueva Solicitud" :successAction="crearSolicitud">
      <nueva-solicitud 
        v-model="solicitud"
        :practica_token="practica.token"
      ></nueva-solicitud>
    </ActionModal>
    </ValidationObserver>


  </v-container>
</template>

<script>
import NotFound from '../../icons/notFound.vue'

import ActionModal from '@/components/ActionModal.vue';
import NuevaSolicitud from './solicitud/NuevaSolicitud';
import { ValidationObserver } from "vee-validate";
import Solicitud from './solicitud/Solicitud';

export default {
  components: {
    ActionModal,
    NuevaSolicitud,
    ValidationObserver,
    Solicitud, NotFound
  },
  props:['practica'],
  data: () => ({
    itemsPerPageArray: [4, 8, 12],
    page: 1,
    solicitud: {
      supervisor: {},
    },
    itemsPerPage: 4,
    dialog: false,
  }),
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  computed: {
    n_items() {
      var pages = 0;
      try {
        pages = this.solicitudes.length;
      } catch (error) {
        pages = 0;
      }
      return pages;
    },
    numberOfPages() {
      var pages = 0;
      try {
        pages = Math.ceil(this.solicitudes.length / this.itemsPerPage);
      } catch (error) {
        pages = 0;
      }
      return pages;
    },

    solicitudes() {
      return this.$store.state.tables.solicitudes.items;
    },
    loading() {
      return this.$store.state.tables.solicitudes.loading;
    },

  },
  methods: {
    nextPage() {
      if (this.page + 1 <= this.numberOfPages) this.page += 1;
    },
    formerPage() {
      if (this.page - 1 >= 1) this.page -= 1;
    },
    updateItemsPerPage(number) {
      this.itemsPerPage = number;
    },

    close() {
      this.dialog = false;
    },

    crearSolicitud() {
      this.$store.dispatch("tables/new", {
        table: "solicitudes",
        item: this.solicitud,
        error_message: "Ha ocurrido un error al añadir la solicitud.",
        success_message: "Solicitud creada correctamente.",
      });
      this.close();
    },
  },
  created() {
    this.$store.dispatch("tables/get", { table: "solicitudes" });
  },
};
</script>

<style>
</style>
