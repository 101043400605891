<template>
<div>
<v-row :justify="center? 'space-around':'start'" :class="subtitle != undefined? '':'mb-3'">
  <h3>{{text}}</h3>
  </v-row>
  <v-row  v-if="subtitle != undefined" :justify="center?'space-around':''" class="mb-3">
  <p>{{subtitle}}</p>
  </v-row>
</div>

</template>

<script>
export default {
    props:["text", "center", "subtitle"]
}
</script>
