<template>
    <v-app error>
        <main>
            <div class="app">
                <v-row style="width:100%">
                
                <!-- Versión desktop -->
                <!-- Al usar variables locales como parámetros, debes usar ":"  -->
                <v-col class="sticky hidden-sm-and-down" :cols="colsDrawer()">
                    <div v-if="showNavbar()" class="drawer">
                        <NavBar :is_expanded="is_expanded" @update-is_expanded="update_is_expanded" ></NavBar>
                    </div>

                <!-- v-if, si la condición se cumple, este componente es renderizado.
                En este caso, si existe un usuario actual y si inició sesión -->
                </v-col>
                <v-col :cols="colsMain()">
                    <v-container>
                        <!-- Versión móvil (xs, sm) -->
                        <div v-if="showNavbar()" class="hidden-md-and-up">
                            <NavBarSm></NavBarSm>
                        </div>
                        <div v-if="current_user && logged">
                            <v-row
                                justify="space-between"
                                class="pa-5 ml-1"
                            >
                            <!-- pa-5: padding 5 en todas direcciones (all)
                                ml-1: margin left 1
                                (más info: https://vuetifyjs.com/en/styles/spacing/)  -->
                                
                                <!-- Título de cada sección-->
                                <h2>{{currentRouteName}}</h2>

                                <v-btn v-if="!showNavbar()" text @click="logout()"
                                    >Cerrar Sesión</v-btn
                                >
                                
                                <!-- Menú de sesión -->
                                <v-card-actions v-else class="card-actions hidden-sm-and-down" style="margin-top: -1%; margin-right: 1%">
                                    <v-menu offset-y>
                                        <template v-slot:activator="{ on }">
                                            <v-btn
                                                :rounded="true"
                                                elevation="0"
                                                color="rgba(0, 0, 0, 0.2) !important"
                                                dark
                                                v-on="on"
                                            >
                                                {{current_user.username}}
                                                <v-icon>keyboard_arrow_down</v-icon>
                                            </v-btn>
                                        </template>
                                        <v-list>
                                            <!-- Las listas suelen ser renderizadas con v-for (es el for de componentes de Vue) -->
                                            <!-- Siempre deben llevar un key único -->
                                            <v-list-item
                                            v-for="(item, index) in sessionItems"
                                            :key="index"
                                            @click="actions(item.action)"
                                            >
                                            <v-list-item-title >{{ item.title }}</v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </v-card-actions>
                            </v-row>

                            <!-- Aquí va todo el contenido de las distintas secciones del sitio -->
                            <ChatBotButton v-if="chatbot" class="hidden-sm-and-down" />
                            <router-view class="mb-16"></router-view>


                        </div>

                        <router-view v-else></router-view>
                    </v-container>
                    
                </v-col>
                </v-row>


                <v-snackbar
                    v-model="alert.isValid"
                    :bottom="true"
                    :color="alert.color"
                    :timeout="2000"
                >
                    {{ alert.message }}
                    <v-btn dark text @click="snackbar = false">Cerrar</v-btn>
                </v-snackbar>

                <v-overlay :value="loading">
                    <v-progress-circular
                        :size="70"
                        :width="7"
                        color="primary"
                        indeterminate
                    ></v-progress-circular>
                </v-overlay>
            </div>
            <ActionModal
                :active="aboutModal"
                title="Sistema de Prácticas"
                :successAction="() => (aboutModal = false)"
                :backDisabled="true"
                :cancelAction="() => (aboutModal = false)"
            >
                Creado por Bastián Quezada Muñoz <a href="https://www.linkedin.com/in/basquezada/"> https://www.linkedin.com/in/basquezada/</a>
            <br><br><br>
                Illustration by <a href="https://storyset.com/">Freepik Storyset</a>
            </ActionModal>
        </main>
    </v-app>
</template>

<script>

// Bienvenidx! Esperando que este sea uno de los primeros archivos que veas,
// voy a comentar algunas cosas sobre Vue

// * - comentarios por José Quezada (al menos los de aquí) - *

// hint: Las carpetas que más visitarás son src/components, src/router, src/store (menos frecuente) y src/views

// Casi todos los archivos están estructurados de la misma forma: HTML (<template>), js (<script>), CSS (<style>)

// imports
import ActionModal from './components/ActionModal.vue'
import NavBar from "@/views/sistema/navigation_bar";
import NavBarSm from "@/views/sistema/navigation_bar_sm";

import ChatBotButton from "@/components/chatbot/boton-chatbot";

export default {
    // Componentes utilizados en este archivo
    components: {
        NavBar, NavBarSm, ActionModal, ChatBotButton
    },
    // Variables locales inicializadas 
    data: () => ({
        aboutModal: false,
        is_expanded: true,
        chatbot: false,
        sessionItems: [
            { title: "Cerrar Sesión", action: "0" },
            { title: localStorage.getItem("theme") == "true"? "Usar tema oscuro": "Usar tema claro", action: "1" },
            { title: "Acerca del sistema", action: "2" },
        ],
    }),

    // Variables que (si no me equivoco) van actualizándose 
    computed: {
        loading() {
            return this.$store.state.tables.loading;
        },
        logged() {
            return this.$store.state.authentication.status.loggedIn;
        },
        current_user() {
            return this.$store.state.authentication.user;
        },
        alert() {
            return this.$store.state.alert;
        },
        currentRouteName() {
            return this.$route.name;
        },
        currentRoutePath() {
            return this.$route.path;
        },
        mobile () {
            switch (this.$vuetify.breakpoint.name) {
            case 'xs': return true
            case 'sm': return true
            case 'md': return false
            case 'lg': return false
            case 'xl': return false
            }

            return false
        },
        drawer: {
      
            get() {
                return this.$store.state.drawer;
            },
            set(state) {
                if (state !== this.$store.state.drawer) {
                this.$store.dispatch("drawer");
                }
            },
        },
    },
    // Todo lo que es instanciado apenas se inicia la vista, antes que todo renderice
    created() {
        this.$store.dispatch("users/getCurrentUser");
        // Utilizar tema escogido
        if (localStorage.getItem("theme") == "true") {
            this.$vuetify.theme.dark = false;
        } else {
            this.$vuetify.theme.dark = true;
        }

        this.$store.dispatch("tables/get", {endpoint: "feature_flags/chat_bot"}).then((response) => {
                this.chatbot = response;
            });
    },
    // no estoy seguro, lo siento
    watch: {
        $route() {
            // clear alert on location change
            this.$store.dispatch("alert/clear");
        },
    },
    // Métodos que puedes llamar en este archivo
    methods: {
        logout() {
            this.$store.dispatch("authentication/logout");
        },

        showNavbar() {
            var routeNoInBlackList = true;

            if (this.currentRoutePath.startsWith("/welcome") || 
            this.currentRoutePath.startsWith("/confirmar-practica") ||
            this.currentRoutePath.startsWith("/evaluar-practica") || 
            this.currentRoutePath.startsWith("/success")) {
                routeNoInBlackList = false;
            }

            /*
            if (this.currentRoutePath.startsWith("/welcome")) {
                routeNoInBlackList = false;
            }
            if (this.currentRoutePath.startsWith("/confirmar-practica")) {
                routeNoInBlackList = false;
            }
            if (this.currentRoutePath.startsWith("/evaluar-practica")) {
                routeNoInBlackList = false;
            }
            if (this.currentRoutePath.startsWith("/success")) {
                routeNoInBlackList = false;
            } */

            return this.logged && this.current_user && routeNoInBlackList;
        },
        // listener para que componente hijo (navigation_bar) actualice el valor
        update_is_expanded(is_expanded){
            this.is_expanded = is_expanded;
        },
        colsDrawer() {
            if (this.mobile || !this.logged || !this.current_user) {
                return 0
            } else {
                return this.is_expanded ? 3 : 1
            }
        },
        colsMain(){
            if (this.mobile || !this.logged || !this.current_user) {
                return 12
            } else {
                return this.is_expanded ? 9 : 11
            }
        },
        /*change_drawer() {
            this.$store.dispatch("drawer");
        },*/
        // opciones de menú de sesión
        actions(id) {
            if (id == "0") {
                this.$store.dispatch("authentication/logout");
                
            } else if (id == "1") {
                if (localStorage.getItem("theme") == "true") {
                    localStorage.setItem("theme", "false");
                    this.$vuetify.theme.dark = false;
                    this.$router.go();

                } else {
                    localStorage.setItem("theme", "true");
                    this.$vuetify.theme.dark = true;
                    this.$router.go();
                }
            } else {
                this.aboutModal = true
            }
        },
    },
};
</script>


<style lang="scss">
.app {
    display: flex;
    main {
		flex: 1 1 0;
		padding: 3rem;
        
		@media (max-width: 1024px) {
			padding-left: 10rem;
		}
	}
}
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
.sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    align-self: flex-start;
}
.drawer {
  position: flex;
}

::-webkit-scrollbar {
    width: 15px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #eff3ff;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.3);
    border: solid 3px #eff3ff;
    border-radius: 7px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #ff9800;
}

pre {
    white-space: pre-wrap;
    font-family: "Poppins", sans-serif !important;
}
</style>
