<template>
  <DarkCard title="Alumno" icon="mdi-account">
    <div>
      <v-row>
        <v-col>
          <h5 class="mb-0 font-weight-bold">Nombre:</h5>
          <p class="mb-0 font-italic">
            {{ data.first_name + " " + data.last_name }}
          </p>
        </v-col>
        <v-col>

          <h5 class="mb-0 font-weight-bold">Usuario:</h5>
          <p class="mb-0 font-italic">
            {{ data.username}}
          </p>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <h5 class="mb-0 font-weight-bold">Rut:</h5>
          <p class="mb-0 font-italic">{{ data.rut }}</p>
        </v-col>

        <v-col>
          <h5 class="mb-0 font-weight-bold">Rol:</h5>
          <p class="mb-0 font-italic">{{ data.rol }}</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <h5 class="mb-0 font-weight-bold">Email:</h5>
          <p class="mb-0 font-italic">{{ data.email }}</p>
        </v-col>

        <v-col>
          <h5 class="mb-0 font-weight-bold">Campus:</h5>
          <p class="mb-0 font-italic">{{ data.campus.nombre }}</p>
        </v-col>
      </v-row>
    </div>
  </DarkCard>
</template>

<script>
import DarkCard from "@/components/content/DarkCard.vue";
export default {
  components: {
    DarkCard,
  },
  props: ["data"],
};
</script>

<style></style>
