<template>
  <v-row align="center" class="ml-3">
    <v-btn icon large @click="onBack">
      <v-icon fab dark>
        mdi-arrow-left
      </v-icon>
    </v-btn>
    <v-col>
      <v-card-title class="font-weight-bold text-uppercase"
        >{{title}}</v-card-title
      >
      <v-card-subtitle class="font-weight-bold text-uppercase"
        >{{subtitle}}</v-card-subtitle>
    </v-col>
  </v-row>
</template>

<script>
export default {
    props: ["title","subtitle", "onBack"]
};
</script>

<style></style>
