<template>
  <ValidationProvider
    ref="provider"
    v-slot="{ errors }"
    :name="name"
    :rules="rulesValue"
  >
    <v-file-input
      :error-messages="errors"
      :value="value"
      rounded
      filled
      chips
      show-size
      type="file"
      @change="selected"
      :accept="acceptValue"
      :label="label"
    ></v-file-input>
  </ValidationProvider>
</template>

<script>

import {ValidationProvider} from "vee-validate";


export default {
  components: {
    ValidationProvider,
  },
  props: ["value", "label", "rules", "name", "rulesFile", "accept"],
  methods: {
    async selected(value) {
      this.$emit("input", value);
    },
  },
  computed: {
    acceptValue() {
      return this.accept || ".pdf, .doc, .docx, .zip";
    },
    rulesValue() {
      return this.rules || 'required|extensionDocumentos|size';
    }
  }
};
</script>
