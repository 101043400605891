<template>
  <div div v-if="practica">
    <Practica :onBack="onBack"  :practica="practica" :revision="true" :show_alumno="true" :alumno="practica.alumno"></Practica>
  </div>
  
</template>

<script>

import Practica from "@/components/practicas/PracticaDetail"

export default {
  components: {
    Practica: Practica
  },
  computed: {
    practica() {
      return this.$store.state.tables.practica_revision.items;
    },
    loading() {
      return this.$store.state.tables.practica_revision.loading;
    }
  },
  created() {
    this.$store.dispatch("tables/get", {
      endpoint: `practicas/${this.$route.params.token}/revisar`,
      table: "practica_revision",
    });
  },
  methods:{
     onBack() {
        this.$router.push("/admin_revisiones");
      }
  }
};
</script>

<style>
</style>