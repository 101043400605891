<template>
  <DarkCard title="Empresa" icon="domain">
    <div>
      <v-row >
        <v-col>
          <h5 class="mb-0 font-weight-bold">Nombre:</h5>
          <p class="mb-0 font-italic">{{ data.empresa.nombre }}</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <h5 class="mb-0 font-weight-bold">Dirección:</h5>
          <p class="mb-0 font-italic">{{ data.direccion }}</p>
        </v-col>
        <v-col>
          <h5 class="mb-0 font-weight-bold">RUT:</h5>
          <p class="mb-0 font-italic">{{ data.empresa.rut }}</p>
        </v-col>
      </v-row>
    </div>
  </DarkCard>
</template>

<script>
import DarkCard from "@/components/content/DarkCard.vue";
export default {
  components: {
    DarkCard,
  },
  props: ["data"],
};
</script>

<style></style>
