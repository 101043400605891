<template>
  <v-dialog v-model="active" max-width="600px" content-class="round" @click:outside="cancelAction">
    <v-card>
      <v-card-title>
        <Title class="ma-2" :text="title"></Title>
      </v-card-title>
      <v-card-text class="pb-0">
        <slot />
      </v-card-text>

      <v-card-actions>

         <BottomArrowsStep
              :withoutIcons="true"
              nextText="Aceptar"
              :backText="backDisabled? undefined: 'Cancelar'"
              :backAction="cancelAction"
              :nextAction="successAction"
              :nextDisabled="nextDisabled"
            />
        <div>

        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import BottomArrowsStep from './forms/BottomArrowsStep'
import Title from './forms/Title'

export default {
  components:{
    Title,
    BottomArrowsStep
  },
  props: [
    "backDisabled",
    "active",
    "successAction",
    "cancelAction",
    "title",
    "content",
    "nextDisabled"
  ],
};
</script>

<style>
</style>