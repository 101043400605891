<template>
<!-- 
- Pasos (practicas.paso) -
    0: Sin prácticas inscritas
    1: Primera práctica inscrita (Industrial: Ing. Civil Info.
    2: Sin segunda práctica inscrita (solo Ing. Civil Info.)
    3: Segunda práctica inscrita  || Profesional: Civil inf y Ing. Ejecución Inf
 -->
    <v-container v-if="!loading" >
        <v-row justify="center">
            <v-expansion-panels v-model="panel">
                 <v-expansion-panel :key="1" v-if="current_user.carrera.id == 1">
                    <v-expansion-panel-header>

                            <v-row  align="center">
                                <v-col cols="12" class="pb-0 mb-0" >
                                    <h2>Práctica Industrial</h2>
                                </v-col>

                                <v-col cols="12" >
                                    <h4 :class="color(practicas.industrial? practicas.industrial.estado.id : 0)">{{practicas.industrial? practicas.industrial.estado.nombre: "No iniciada"}}</h4>  
                                </v-col>
                            </v-row>

                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <div v-if="practicas.paso == 0">
                            <Welcome :paso="1"></Welcome>
                        </div>

                        <Practica 
                            :practica="practicas.industrial"
                            :alumno="current_user"
                        ></Practica>
                    </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel :key="2">
                    <v-expansion-panel-header>

                        <v-row align="center">
                            <v-col cols="12" class="pb-0 mb-0" >
                                <h2>Práctica Profesional</h2>
                            </v-col>

                            <v-col cols="12" >
                                <h4 :class="color(practicas.profesional? practicas.profesional.estado.id : 0)">{{practicas.profesional? practicas.profesional.estado.nombre: "No iniciada"}}</h4>  
                            </v-col>
                        </v-row>

                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                    
                        <div v-if="practicas.paso == 2">
                            <Welcome class="mb-5" :paso="2"></Welcome>
                        </div>

                        <div
                            v-if="
                                practicas.paso == 0 &&
                                (current_user.carrera.id == 3 || current_user.carrera.id == 2)
                            "
                        >
                            <Welcome class="mb-5" :paso="3"></Welcome>
                        </div>

                        <Practica
                            class="mb-5"
                            :practica="practicas.profesional"
                            :alumno="current_user"
                        ></Practica>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-row>
    </v-container>
</template>

<script>
import Practica from "../../../components/practicas/PracticaDetail";
import welcome from "./components/Bienvenida";

export default {
    components: {
        Practica: Practica,
        Welcome: welcome,
    },
    data: () => ({
        tab: null,
        panel: null,
    }),
    computed: {
        current_user() {
            return this.$store.state.authentication.user;
        },
        practicas() {
            return this.$store.state.tables.practicas.items;
        },
        loading() {
            return this.$store.state.tables.practicas.loading;
        },
    },
    watch: {
        practicas: function () {
            if (this.practicas.paso == 0 || this.practicas.paso == 1 || this.current_user.carrera.id == 3 || this.current_user.carrera.id == 2) {
                this.panel = 0;
            }
            else if (this.practicas.paso == 2 || this.practicas.paso == 3) {
                this.panel = 1;
            }
        },
    },
    /*updated() {
        // Autoscroll
        // solo para segunda práctica
        if (this.practicas.paso == 2 || this.practicas.paso == 3 ||
        ((this.practicas.paso == 0 || this.practicas.paso == 1) && (this.current_user.carrera.id == 3 || this.current_user.carrera.id == 2))) 
        {
            const el = this.$refs.PProfesionalPanel;

            if (el) {
                el.scrollIntoView({behavior: 'smooth'});
            }
        }
    },*/

    created() {
        this.$store.dispatch("users/getCurrentUser");
        this.$store.dispatch("tables/get", {
            endpoint: "practicas/alumno",
            table: "practicas",
        });

        this.$store.dispatch("pagination", {
            items: [
                {
                    text: "Mis Prácticas",
                    disabled: true,
                    href: "/user_dashboard",
                },
            ],
        });
    },
    methods: {
        color(tipo){
            switch (tipo) {
                case 0:
                    return 'red--text'
                case 4:
                    return 'green--text'
                default:
                    return 'yellow--text'
            }
        },
        newPractica(tipo) {
            this.$router.push({
                name: "Inscribir Práctica",
                params: { tipo_practica: tipo },
            });
        },
    },
};
</script>

<style>
.v-expansion-panel::before {
    box-shadow: unset !important;
}
.v-expansion-panel {
    margin-bottom: 20px !important;
    border-radius: 15px !important;
}
.v-expansion-panel:not(:first-child)::after {
    border-top: unset !important;
}
.round {
    border-radius: 20px !important;
}
</style>
