<template>
  <div>
    <v-row class="ma-1 mb-5">
      <v-col :cols="12" :md="4">
        <card-icon-title
          icon="mdi-domain"
          title="Empresa"
          :subtitle="oferta.empresa"
        />
      </v-col>

      <v-col :cols="12" :md="4">
        <card-icon-title
          icon="mdi-map-marker"
          title="Ubicacion"
          :subtitle="`${oferta.ciudad}, ${oferta.pais}`"
        />
      </v-col>

      <v-col :cols="12" :md="4">
        <card-icon-title
          icon="mdi-email"
          title="Contactar a"
          :subtitle="oferta.email_contacto"
        />
      </v-col>
    </v-row>

    <h5 class="ml-4 mb-3 font-weight-bold">Detalles:</h5>

    <dark-card> <pre>{{ oferta.mensaje }}</pre></dark-card>

    <v-row justify="end"
      ><p class="mr-5 mt-3 font-italic">
        {{ formatDate(oferta.fecha_sistema) }}
      </p></v-row
    >
  </div>
</template>

<script>
import { formatDate } from "@/services/utils.service";

import DarkCard from "../content/DarkCard.vue";
import CardIconTitle from "../content/CardIconTitle.vue";
export default {
  components: { CardIconTitle, DarkCard },
  props: ["oferta"],
  methods: {
    formatDate,
  },
};
</script>

<style></style>
