<template>
  <v-row justify="space-around" class="mb-5">
    <ValidationProvider ref="provider" v-slot="{ errors }" :name="name" :rules="rules">
      <v-rating
        :value="value"
        @input="selected"
        size="48"
        color="primary"
        :error-messages="errors"
        half-icon="mdi-star-half-full"
        half-increments
        background-color="grey darken-1"
        fullIcon="mdi-star"
        emptyIcon="mdi-star-outline"
        hover
      ></v-rating>
    </ValidationProvider>
  </v-row>
</template>

<script>
import { ValidationProvider } from "vee-validate";

export default {
  components: {
    ValidationProvider,
  },
  props: ["value", "label", "rules", "name", ],
  methods: {
    async selected(value) {
      const valid = await this.$refs.provider.validate(value);

      if (!value) {
            return;
      }
      this.$emit("input", value);
    },
  },
};
</script>
