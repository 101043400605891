<template>
  <div v-if="items.length > 0">
    <DarkCard v-for="(item, i) in items" :key="i">
      <p class="text-button font-weight-bold text-uppercase mb-0">
        {{ item.lugar }}
      </p>
      <p v-if="kind == 'educacion'" class="text-overline mb-0">
        {{ item.descripcion }}
      </p>
      <p v-if="kind == 'profesional'" class="text-overline mb-0">
        {{ item.puesto }}
      </p>

      <p class="text-overline mb-0">
        {{ item.fecha_inicio }} -
        {{ item.fecha_termino ? item.fecha_termino : "Actualidad" }}
      </p>
      <p v-if="kind == 'profesional'" class="text-overline mb-0 font-italic">
        {{ item.descripcion }}
      </p>
    </DarkCard>
  </div>
  <div v-else>
    <Message text="NO SE HA INGRESADO INFORMACIÓN" />
  </div>
</template>

<script>
import Message from '@/components/Message.vue'
import DarkCard from '@/components/content/DarkCard.vue'
export default {
    components: { DarkCard, Message },
  props: ["items", "kind"],
};
</script>

<style></style>
