<template>
  <div>
    <DarkCard title="Práctica" icon="mdi-book">
      <div>
        <v-row >
          <v-col v-if="!hide_progreso">
            <h5 class="mb-0 font-weight-bold">Estado:</h5>
            <p class="mb-0 font-italic">{{ estado }}</p>
          </v-col>
          <v-col v-if="hide_progreso">
            <h5 class="mb-0 font-weight-bold">Tipo:</h5>
            <p class="mb-0 font-italic">{{ tipo }}</p>
          </v-col>
          <v-col >
            <h5 class="mb-0 font-weight-bold">Categoria:</h5>
            <p class="mb-0 font-italic">{{ categoria }}</p>
          </v-col>
        </v-row>
        <v-row>
          <v-col >
            <h5 class="mb-0 font-weight-bold">Modo:</h5>
            <p class="mb-0 font-italic">{{ modo }}</p>
          </v-col>
          <v-col v-if="!hide_progreso">
            <h5 class="mb-0 font-weight-bold">Progreso:</h5>
            <p class="mb-0 font-italic">{{ progreso }} Hrs</p>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <h5 class="mb-0 font-weight-bold">Inicio:</h5>
            <p class="mb-0 font-italic">
              {{ moment(fecha_inicio).format("D [de] MMMM, YYYY") }}
            </p>
          </v-col>
          <v-col v-if="fecha_termino">
            <div>
              <h5 class="mb-0 font-weight-bold">Termino:</h5>

              <p class="mb-0 font-italic">
                {{ moment(fecha_termino).format("D [de] MMMM, YYYY") }}
              </p>
            </div>
          </v-col>
        </v-row>
      </div>
    </DarkCard>
  </div>
</template>

<script>
import moment from "moment";
moment.locale("es");

import DarkCard from "@/components/content/DarkCard.vue";
export default {
  components: {
    DarkCard,
  },
  props: [
    "hide_progreso",
    "estado",
    "modo",
    "categoria",
    "progreso",
    "tipo",
    "fecha_inicio",
    "fecha_termino",
  ],
};
</script>

<style></style>
