<template>
  <v-container>
    <div v-if="!loading">
      <v-data-iterator
        :items="ofertas"
        :items-per-page.sync="itemsPerPage"
        :page="page"
        sort-by="fecha_sistema"
        :sort-desc="true"
        hide-default-footer
      >
        <template v-slot:default="props">
          <v-row>
            <v-col v-for="item in props.items" :key="item.name" cols="12">
              <v-card class="pa-5 mb-5">
                <oferta-detail :oferta="item"></oferta-detail>
              </v-card>
            </v-col>
          </v-row>
        </template>

        <template v-slot:footer>
          <v-row class="ma-2" align="center" justify="center" v-if="ofertas.lenght > 0">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  dark
                  text
                  color="primary"
                  class="ml-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ itemsPerPage }}
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(number, index) in itemsPerPageArray"
                  :key="index"
                  @click="updateItemsPerPage(number)"
                >
                  <v-list-item-title>{{ number }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <v-spacer></v-spacer>

            <span class="mr-4 grey--text"
              >Página {{ page }} de {{ numberOfPages }}</span
            >
            <v-btn
              fab
              small
              elevation="0"
              dark
              color="primary"
              class="mr-1"
              @click="formerPage"
            >
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <v-btn
              fab
              small
              elevation="0"
              dark
              color="primary"
              class="ml-1"
              @click="nextPage"
            >
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-row>
        </template>

        <template v-slot:no-data>
        <v-row class="ma-2" align="center" justify="center">
          <notFound width="256" height="256"></notFound>
        </v-row>

        <v-row class="ma-2" align="center" justify="center"
          >No hay ofertas disponibles</v-row
        >
      </template>

      </v-data-iterator>
    </div>

    <div v-else>
      <v-row justify="space-around">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import NotFound from '../../../components/icons/notFound.vue'
import OfertaDetail from "@/components/ofertas/OfertaDetail.vue";
export default {
  components: {
    OfertaDetail, NotFound
  },
  data: () => ({
    itemsPerPageArray: [4, 8, 12],
    page: 1,
    itemsPerPage: 4,
  }),
  computed: {
    numberOfPages() {
      return Math.ceil(this.ofertas.length / this.itemsPerPage);
    },
    ofertas() {
      return this.$store.state.tables.ofertas.items;
    },
    loading() {
      return this.$store.state.tables.ofertas.loading;
    },
  },
  created() {
    this.$store.dispatch("tables/get", { table: "ofertas" });
  },
  methods: {
    nextPage() {
      if (this.page + 1 <= this.numberOfPages) this.page += 1;
    },
    formerPage() {
      if (this.page - 1 >= 1) this.page -= 1;
    },
    updateItemsPerPage(number) {
      this.itemsPerPage = number;
    },
  },
};
</script>

<style></style>
