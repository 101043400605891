<template>
  <v-container>
    <v-card v-if="curriculum">
      <v-stepper v-model="step" class="stepper-transparent">
        <v-stepper-items>
          <v-stepper-content step="1">
            
              <h2>
                {{ curriculum.alumno.first_name }}
                {{ curriculum.alumno.last_name }}
              </h2>
              <h3 class="mb-3">@{{ curriculum.alumno.username }}</h3>

              <DarkCard>
                 <v-row >
                    <v-col :cols="12" :md="4">
                      <h5 class="mb-0 font-weight-bold"> Email:</h5>
                      <p class="mb-0 font-italic"> {{ curriculum.alumno.email }} </p>
                    </v-col>
                    <v-col :cols="12" :md="4">
                      <h5 class="mb-0 font-weight-bold"> Rol:</h5>
                      <p class="mb-0 font-italic"> {{ curriculum.alumno.rol }} </p>
                    </v-col>

                    <v-col :cols="12" :md="4">
                      <h5 class="mb-0 font-weight-bold"> Rut:</h5>
                      <p class="mb-0 font-italic"> {{ curriculum.alumno.rut }} </p>
                    </v-col>
                    <v-col :cols="12" :md="4">
                      <h5 class="mb-0 font-weight-bold"> Campus:</h5>
                      <p class="mb-0 font-italic"> {{ curriculum.alumno.campus }} </p>
                    </v-col>

                    <v-col :cols="12" :md="4">
                      <h5 class="mb-0 font-weight-bold"> Dirección:</h5>
                      <p class="mb-0 font-italic"> {{ curriculum.alumno.direccion }}, {{ curriculum.alumno.ciudad }}, {{ curriculum.alumno.region }} </p>
                    </v-col>
         
                    <v-col :cols="12" :md="4">
                      <h5 class="mb-0 font-weight-bold"> Teléfono:</h5>
                      <p class="mb-0 font-italic"> {{ curriculum.alumno.telefono }} </p>
                    </v-col>
                    <v-col :cols="12" :md="4">
                      <h5 class="mb-0 font-weight-bold"> Carrera:</h5>
                      <p class="mb-0 font-italic"> {{ curriculum.alumno.carrera }} </p>
                    </v-col>
                </v-row>
              </DarkCard>
              
              <MarcaAlumnos :alumno="curriculum.alumno"  v-if="is_admin"/>
              
              <v-row justify="end">
                <v-btn text color="primary" @click="step = 2" class="mr-2">
                  Experiencia
                </v-btn>

                <v-btn text color="primary" @click="step = 3" class="mr-2">
                  Educación
                </v-btn>

                <v-btn text color="primary" @click="step = 4" class="mr-2">
                  Conocimientos
                </v-btn>
              </v-row>
           
          </v-stepper-content>

          <v-stepper-content step="2">

            <BackHeader
              title="Experiencia Profesional"
              :onBack="() => (step = 1)"
            />

            <CurriculumCardList
              :items="curriculum.experiencia"
              kind="profesional"
            ></CurriculumCardList>

          </v-stepper-content>

          <v-stepper-content step="3">

            <BackHeader title="Educación" :onBack="() => (step = 1)" />
            <CurriculumCardList
              :items="curriculum.educacion"
              kind="educacion"
            ></CurriculumCardList>
          </v-stepper-content>

          <v-stepper-content step="4">
            <BackHeader title="Conocimientos" :onBack="() => (step = 1)" />

            <ChipList title="Intereses" :items="curriculum.tareas" />
            <ChipList title="Bases de Datos" :items="curriculum.bds" />
            <ChipList title="Lenguajes" :items="curriculum.lenguajes" />
            <ChipList title="Librerías" :items="curriculum.librerias" />
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-card>
  </v-container>
</template>

<script>
import BackHeader from '@/components/content/BackHeader.vue'
import DarkCard from '@/components/content/DarkCard.vue'
import ChipList from "@/components/content/ChipList";
import CurriculumCardList from "@/components/content/CurriculumCardList.vue";
import MarcaAlumnos from "@/components/MarcaAlumnos.vue";
export default {
  components: {
    ChipList,
    CurriculumCardList, DarkCard, BackHeader, MarcaAlumnos
  },
  data: () => ({
    step: 1,
  }),
  props: ["curriculum"],
  computed: {
    is_admin() {
      return this.$store.state.authentication.user.is_staff;
    },
  }

};
</script>

<style scoped>
.stepper-transparent{
  background-color: transparent !important;
}
.v-stepper__wrapper {
  min-height: unset !important;
}
.rounded-card {
  border-radius: 50px 50px 50px 50px !important;
}
</style>
