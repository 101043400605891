<template>
  <div>
    <v-card class="semiborder_1 primary rounded-0">
      <v-container>
        <v-container>
          <h2>
            <v-icon size="32" color="white" @click="toggleMobile = !toggleMobile"
              >menu</v-icon
            >
            <span class="pl-2 white--text">Sistema de Prácticas</span>
          </h2>
        </v-container>
      </v-container>
    </v-card>
    <div class="wrapper">
      <transition name="slide">
        <v-card
          class="semiborder_2 rounded-0"
          color="primary"
          v-if="toggleMobile"
          height="100%"
        >
          <v-list
            nav
            shaped
            style="padding-left:20px !important;"
            color="primary"
          >
            <v-list-item-group
              mandatory
              v-model="item"
              active-class="list_selected"
            >
              <v-list-item
                color="white"
                v-for="item in is_admin ? admin_drawer : user_drawer"
                :key="item.title"
                link
                @click="
                  is_admin
                    ? admin_actions(item.action)
                    : user_actions(item.action)
                "
              >
                <v-list-item-icon
                  style="padding-left:20px !important; margin-right:20px !important "
                >
                  <v-icon color="white">{{ item.icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold white--text">{{
                    item.title
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item
                v-for="(item, index) in sessionItems"
                :key="index"
                @click="actions(item.action)"
              >
                <v-list-item-title class="font-weight-bold white--text">{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </transition>
    </div>
    <ActionModal
      :active="aboutModal"
      title="Sistema de Prácticas"
      :successAction="() => (aboutModal = false)"
      :backDisabled="true"
      :cancelAction="() => (aboutModal = false)"
    >
      Creado por Bastián Quezada Muñoz <a href="https://www.linkedin.com/in/basquezada/"> https://www.linkedin.com/in/basquezada/</a>
<br><br><br>
      <a href="https://storyset.com/">Illustration by Freepik Storyset</a>
    </ActionModal>
  </div>
</template>

<script>
import ActionModal from '../../components/ActionModal.vue'
// import LogoDINavBar from '@/components/icons/logoDINavBar.vue';

export default {
  components: {
    /*LogoDINavBar, */ActionModal
  },
  data: () => ({
    aboutModal: false,
    toggleMobile: false,
    item: 0,
    
    admin_drawer: [
      { title: "Revisiones", 
        icon: "mdi-message-draw", 
        index: 0, 
        action: 0 
      },
      { title: "Estadísticas", 
        icon: "mdi-chart-bar", 
        index: 1, 
        action: 1 
      },
      {
        title: "Solicitudes",
        icon: "mdi-frequently-asked-questions",
        index: 2,
        action: 2,
      },
      {
        title: "Ofertas",
        icon: "mdi-bullhorn",
        index: 3,
        action: 3,
      },
/*       { title: "Base de datos", icon: "mdi-database", index: 4, action: 4 }, */
      { 
        title: "Alumnos", 
        icon: "mdi-account", 
        index: 5, 
        action: 5 
      },
      { 
        title: "Logs", 
        icon: "mdi-message-alert", 
        index: 6, 
        action: 6 
      },
      {
        title: "Empresas",
        icon: "mdi-domain",
        index: 8,
        action: 8,
      },
      {
        title: "Comentarios al DI",
        icon: "mdi-database-export",
        index: 8,
        action: 8,
      },
      {
        title: "Exportadores",
        icon: "mdi-database-export",
        index: 9,
        action: 9,
      },

    ],
    user_drawer: [
      {
        title: "Prácticas",
        icon: "mdi-book",
        index: 0,
        action: 0,
      },
      {
        title: "Mi perfil",
        icon: "mdi-file-account",
        index: 1,
        action: 1,
      },
      {
        title: "Ofertas",
        icon: "mdi-bullhorn",
        index: 2,
        action: 2,
      },
      {
        title: "Empresas",
        icon: "mdi-domain",
        index: 3,
        action: 3,
      },
      { title: "Estadísticas", icon: "mdi-chart-bar", index: 4, action: 4 },
      { title: "Informaciones", icon: "mdi-information-outline", index: 5, action: 5 },

    ],
    sessionItems: [
      { title: "Cerrar Sesión", action: "0" },
      
      { title: localStorage.getItem("theme") == "true"? "Usar tema oscuro": "Usar tema claro", action: "1" },
      { title: "Acerca del sistema", action: "3" },
    ],
  }),
  created() {
    if(this.$router.currentRoute.path.startsWith('/user_dashboard')) {
        this.item = 0;
    }

    if(this.$router.currentRoute.path.startsWith('/perfil')) {
        this.item = 1;
    }

    if(this.$router.currentRoute.path.startsWith('/ofertas')) {
        this.item = 2;
    }

    if(this.$router.currentRoute.path.startsWith('/empresas')) {
        this.item = 3;
    }

    if(this.$router.currentRoute.path.startsWith('/estadisticas')) {

      if(this.is_admin){
        this.item = 1;
      } else{
        this.item = 4;
      }
        
    }

    if(this.$router.currentRoute.path.startsWith('/informaciones')) {
        this.item = 5;
    }

    if(this.$router.currentRoute.path.startsWith('/admin_revisiones')) {
        this.item = 0;
    }

    if(this.$router.currentRoute.path.startsWith('/admin_solicitudes')) {
        this.item = 2;
    }

    if(this.$router.currentRoute.path.startsWith('/admin_ofertas')) {
        this.item = 3;
    }
    if(this.$router.currentRoute.path.startsWith('/admin_database')) {
        this.item = 4;
    }
    if(this.$router.currentRoute.path.startsWith('/alumnos')) {
        this.item = 5;
    }
    if(this.$router.currentRoute.path.startsWith('/logs')) {
        this.item = 6;
    }
    if(this.$router.currentRoute.path.startsWith('/admin_empresas')) {
        this.item = 7;
    }
    if(this.$router.currentRoute.path.startsWith('/comentarios_di')) {
        this.item = 8;
    }
    if(this.$router.currentRoute.path.startsWith('/exportadores')) {
        this.item = 9;
    }

  },
  computed: {

    drawer: {
      
      get() {
        return this.$store.state.drawer;
      },
      set(state) {
        if (state !== this.$store.state.drawer) {
          this.$store.dispatch("drawer");
        }
      },
    },
    current_user() {
      return this.$store.state.authentication.user;
    },

    is_admin() {
      return this.$store.state.authentication.user.is_staff;
    },
  },
  methods: {
    // change_drawer() {
    //   this.$store.dispatch("drawer");
    // },
    // sizecard(drawer) {
    //   if (drawer) {
    //     return 9;
    //   } else {
    //     return 12;
    //   }
    // },
    admin_actions(action) {

      let newDir = ''
      switch (action){
        case 0:
          newDir = "/admin_revisiones"
          break
        case 1:
          newDir = "/estadisticas"
          break
        case 2:
          newDir = "/admin_solicitudes"
          break
        case 3:
          newDir = "/admin_ofertas"
          break
        case 4:
          newDir = "/admin_database"
          break
        case 5:
          newDir = "/alumnos"
          break
        case 6:
          newDir = "/logs"
          break
        case 7:
          newDir = "/admin_empresas"
          break
        case 8:
          newDir = "/comentarios_di"
          break
        case 9:
          newDir = "/exportadores"
          break
        default:
          newDir = "/admin_revisiones"
      }

      this.$router.push(newDir);

      this.toggleMobile = false;
    },
    user_actions(action) {
      
      let newDir = ''
      switch (action){
        case 0:
          newDir = "/user_dashboard"
          break
        case 1:
          newDir = "/perfil"
          break
        case 2:
          newDir = "/ofertas"
          break
        case 3:
          newDir = "/empresas"
          break
        case 4:
          newDir = "/estadisticas"
          break
        case 5:
          newDir = "/informaciones"
          break
        default:
          newDir = "/user_dashboard"
      }

      this.$router.push(newDir);

      this.toggleMobile = false;
    },

    actions(id) {
      if (id == "0") {
        if(!this.is_admin){
          this.$keycloak.logout()
        }
        this.$store.dispatch("authentication/logout");
        
      } else if (id == "1") {
        if (localStorage.getItem("theme") == "true") {
          localStorage.setItem("theme", "false");
          this.$vuetify.theme.dark = false;
          this.$router.go();

        } else {
          localStorage.setItem("theme", "true");
          this.$vuetify.theme.dark = true;
          this.$router.go();
        }
      } else {
        this.aboutModal = true
      }
    },
  },
};
</script>

<style scoped>

.card-actions {
  position: absolute;
  bottom: 0;
}

v-list-item--active:hover::before,
.v-list-item--active::before {
  opacity: 0 !important;
}

.wrapper {
  overflow: hidden;
}
.slide-enter-active,
.slide-leave-active {
  transition: all 0.3s ease;
}
.slide-enter,
.slide-leave-to {
  transform: translateY(-100%);
  opacity: 0;
}

.semiborder {
  height: 100%;
}

.drawer {
  width: unset;
  z-index: 0;
  position: fixed;
  height: 100%;
}
.logoDI {
  cursor: pointer;
}
/* responsive, form small screens, use 13px font size */
@media (max-width: 960px) {
  .drawer {
    position: inherit;
    height: initial;
  }

  .semiborder_1 {
    /* border-radius: 0px 0px 0px 0px !important; */
    height: initial;
  }
  /* .semiborder_2 {
    border-radius: 0px 0px 0px 0px !important;
  } */

  .list_selected {
    background-color: #7a2b00;
  }
}
</style>
