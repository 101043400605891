var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"ofertaDiv",staticClass:"mt-3"},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('logo')],1),_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('layout',{attrs:{"width":8}},[_c('v-card',{staticClass:"pa-5"},[_c('v-container',{attrs:{"grid-list-xs":""}},[_c('h2',{staticClass:"mb-5"},[_vm._v("Ingresar una oferta de práctica")]),_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('ValidationProvider',{attrs:{"name":"Empresa","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"filled":"","rounded":"","counter":50,"error-messages":errors,"label":"Empresa","required":""},model:{value:(_vm.oferta.empresa),callback:function ($$v) {_vm.$set(_vm.oferta, "empresa", $$v)},expression:"oferta.empresa"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Email","rules":"required|email|max:30"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"filled":"","rounded":"","counter":30,"error-messages":errors,"label":"Email de contacto","required":""},model:{value:(_vm.oferta.email_contacto),callback:function ($$v) {_vm.$set(_vm.oferta, "email_contacto", $$v)},expression:"oferta.email_contacto"}})]}}],null,true)}),_c('v-autocomplete',{attrs:{"items":_vm.paises,"prepend-inner-icon":"domain","item-value":"id","rounded":"","item-text":"name","filled":"","label":"País"},on:{"change":function($event){return _vm.cargarRegiones()}},model:{value:(_vm.oferta.pais),callback:function ($$v) {_vm.$set(_vm.oferta, "pais", $$v)},expression:"oferta.pais"}}),_c('v-autocomplete',{attrs:{"items":_vm.regiones,"prepend-inner-icon":"domain","item-value":"id","rounded":"","item-text":"name","filled":"","label":"Region"},on:{"change":function($event){return _vm.cargarCiudades()}},model:{value:(_vm.oferta.region),callback:function ($$v) {_vm.$set(_vm.oferta, "region", $$v)},expression:"oferta.region"}}),_c('v-autocomplete',{attrs:{"items":_vm.ciudades,"prepend-inner-icon":"domain","item-value":"id","rounded":"","item-text":"name","filled":"","label":"Ciudad"},model:{value:(_vm.oferta.ciudad),callback:function ($$v) {_vm.$set(_vm.oferta, "ciudad", $$v)},expression:"oferta.ciudad"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('ValidationProvider',{attrs:{"name":"Descripcion","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"filled":"","rounded":"","no-resize":"","error-messages":errors,"label":"Descripción","required":"","height":"400"},model:{value:(_vm.oferta.mensaje),callback:function ($$v) {_vm.$set(_vm.oferta, "mensaje", $$v)},expression:"oferta.mensaje"}})]}}],null,true)})],1)],1),_c('ValidationProvider',{attrs:{"name":"Captcha","rules":"required|max:30"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"filled":"","rounded":"","counter":30,"error-messages":errors,"label":"Captcha","required":""},model:{value:(_vm.oferta.captcha_value),callback:function ($$v) {_vm.$set(_vm.oferta, "captcha_value", $$v)},expression:"oferta.captcha_value"}})]}}],null,true)}),_c('img',{attrs:{"alt":"captcha","src":("data:image/png;base64," + _vm.captcha_image)}}),_c('v-row',{attrs:{"justify":"end"}},[_c('v-btn',{staticClass:"mr-4",attrs:{"disabled":invalid,"elevation":"0","color":"primary"},on:{"click":function($event){return _vm.crearOferta()}}},[_vm._v("Ingresar Oferta")])],1)]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }