<template>
  <div>

    <!-- MOSTAR MARCA Y EL EDITAR -->
    <DarkCard :title="titulo()" v-if="alumno.marca != null">
      <v-row>

            <v-col cols="12" md="2">
              <h5 class="mb-0 font-weight-bold"> Fecha en que se puso:</h5>
              <p class="mb-0 font-italic"> {{ format_fecha(alumno.fecha_marca) }} </p>
            </v-col>

            <v-col>
              <h5 class="mb-0 font-weight-bold"> Comentario sobre la marca:</h5>
              <p class="mb-0 font-italic"> {{ alumno.comentario_marca }} </p>
            </v-col>
        <v-col>
          <v-row  justify="end">
            <v-col class="px-0" cols="12" md="auto">
              <button class="card" @click="() => {modal_marca = true}">
                <div class="card-info ma-1">
                  <div class="card-text">
                    Editar marca
                  </div>
                  <v-icon class="ml-1" size="40" :color="flagColor(alumno.marca)" >mdi-flag</v-icon>
                </div>
              </button>
            </v-col>
            <v-col class="px-0" cols="12" md="auto" >
              <button class="card" @click="() => {modal_eliminar = true}">
                <div class="card-info my-2">
                  <v-icon class="ml-1" size="35" color="red darken-4" >delete</v-icon>
                </div>
              </button>
            </v-col>
          </v-row>
        </v-col>
        
      </v-row>
    </DarkCard>


    <!-- AGREGAR MARCA -->
    <button class="card my-2"  v-if="alumno.marca == null" @click="() => {modal_marca = true}">
      <div class="card-info ma-1">
        <div class="card-text">
          Agregar marca
        </div>
        <v-icon class="ml-1" size="45" color="#a1a2a2" v-if="!alumno.marca">mdi-flag-outline</v-icon>
      </div>
    </button>
    

    <!-- MODAL DE EDICIÓN Y NUEVA MARCA -->
    <v-dialog v-model="modal_marca" width="550">
      <v-card>
        <div v-if="alumno.marca == null">
          <v-card-title primary-title 
            >Agregar una marca al alumno
          </v-card-title>
          
          <v-card-text
            >Por favor ingrese si la marca es positiva o negativa además de un comentario al respecto
          </v-card-text>
        </div>
        

        <div v-else>
          <v-card-title primary-title 
            >Editar la marca del alumno
          </v-card-title>
          
          <v-card-text
            >Por favor haga los cambios pertinentes a la marca, si desea eliminarla deje todos los campos en blanco
          </v-card-text>
        </div>  
        <v-row class="ma-2">
          <v-col cols="4" align="center" justify="center" @click="()=> alternarBandera(2)">

            <v-icon class="ml-1" size="45" color="red darken-4" v-if="!bandera_roja">mdi-flag-outline</v-icon>
            <v-icon class="ml-1" size="45" color="red darken-2" v-if="bandera_roja">mdi-flag</v-icon>
            <p>Negativo</p>
          </v-col>
          <v-col cols="4" align="center" justify="center" @click="()=> alternarBandera(0)">
            <v-icon class="ml-1" size="45" color="#686868" v-if="!bandera_gris">mdi-flag-outline</v-icon>
            <v-icon class="ml-1" size="45" color="#a1a2a2" v-if="bandera_gris">mdi-flag</v-icon>
            <p>Neutral</p>
          </v-col>
          <v-col cols="4" align="center" justify="center" @click="()=> alternarBandera(1)">
            <v-icon class="ml-1" size="45" color="green darken-3" v-if="!bandera_verde">mdi-flag-outline</v-icon>
            <v-icon class="ml-1" size="45" color="green darken-1" v-if="bandera_verde">mdi-flag</v-icon>
            <p>Positivo</p>
          </v-col>
        </v-row>
        <div class="mx-5">
          <TextArea 
              rules="max:500"
              label="Ingrese el comentario del motivo de la marca" 
              :value="comentario"
              v-model="comentario"
          />  
        </div>

        <v-card-actions>
          <v-btn color="primary" text @click="modal_marca = false">Cancelar</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="enviarMarca" :disabled="!checkSeleccion()">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="modal_eliminar" width="550">
      <v-card>
        <div v-if="alumno.marca == null">
          <v-card-title primary-title 
            >Agregar una marca al alumno
          </v-card-title>
          
          <v-card-text
            >Por favor ingrese si la marca es positiva o negativa además de un comentario al respecto
          </v-card-text>
        </div>
        

        <div v-else>
          <v-card-title primary-title 
            >Confirmar
          </v-card-title>
          
          <v-card-text
            > ¿Desea eliminar la marca existente?
          </v-card-text>
        </div>  


        <v-card-actions>
          <v-btn color="primary" text @click="modal_marca = false" >Cancelar</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="eliminarMarca">Eliminar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

      
  </div>
  </template>
  
  <script>
  import moment from "moment";
  moment.locale("es");
  import DarkCard from '@/components/content/DarkCard.vue'
  import ActionModal from '@/components/ActionModal.vue'
  import TextArea from "@/components/forms/TextArea.vue";
  // import axios from "axios";

  export default {
    props: ["alumno"],
    components: {
      DarkCard,
      ActionModal,
      TextArea
    },
    data: () => ({
      modal_marca: false,
      modal_eliminar: false,
      bandera_roja: false,
      bandera_gris: false,
      bandera_verde: false,
      marca: null,
      comentario: null
    }),
    created() {
      this.marca = this.alumno.marca
      if (this.alumno.marca != null) {
        this.comentario = this.alumno.comentario_marca
      }
      this.alternarBandera(this.alumno.marca)
    },
    methods: {
      eliminarMarca() {
        this.marca = null
        this.comentario= null
        this.enviarMarca()
      },

      enviarMarca() {
        this.$store.dispatch("tables/post", { 
              endpoint: `alumnos/${this.alumno.username}/marca`,
              item: {
                marca: this.marca,
                comentario_marca: this.comentario
              },               
              returnRequest: true,
        }).then(() => {
          this.alumno.marca = this.marca
          this.alumno.comentario_marca = this.comentario
        })

        this.modal_eliminar = false
        this.modal_marca = false
      },
      flagColor() {
        if (this.alumno.marca == 1) {
          return "green darken-1"
        } else if (this.alumno.marca == 2){
          return "red darken-3"
        } else {
          return "#a1a2a2"
        }
      },
      titulo() {
        if (this.alumno.marca == 1) {
          return "Alumno tiene una marca 🟩"
        } else if (this.alumno.marca == 2){
          return "Alumno tiene una marca 🟥"
        } else {
          return "Alumno tiene una marca ⬜"
        }
      },
      format_fecha(fecha) {
        return fecha ? moment(fecha).format("dddd, Do MMMM, YYYY") : "";
      },
      alternarBandera(color){    // 2= Rojo , 0= Gris, 1= Verde
                                 // Se hizo asi para poder recibir el numero desde la api
                                 // y ocupar el mismo método para activar los valores predefinidos en el modal
                                 // cuando existe una marca previa
        switch (color) {
          case 2:
            this.bandera_roja = !this.bandera_roja
            this.bandera_gris = false
            this.bandera_verde = false
            this.marca = this.bandera_roja ? 2 : null
            break;
          case 0:
            this.bandera_roja = false
            this.bandera_gris = !this.bandera_gris
            this.bandera_verde = false
            this.marca = this.bandera_gris ? 0 : null
            break;
          case 1:
            this.bandera_roja = false
            this.bandera_gris = false
            this.bandera_verde = !this.bandera_verde
            this.marca = this.bandera_verde ? 1 : null
            break;
        }
      },
      checkSeleccion() {
        return (this.bandera_roja || this.bandera_gris || this.bandera_verde)
      }
    },
  }
  </script>
  
  <style scoped>
  .card {
    margin-left: auto;
    display: flex;
    align-items: center;
    text-align: right;
    border-radius: 15px;
    padding: 5px;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  .card-info {
    display: flex;
    align-items: center;
    text-align: right;
  }

  .card-text {
    align-items: center;
    text-align: right;
    font-size: 15px;
  }
  
  .card:hover {
    background-color: rgba(0,0,0,0.2); /* Sombra más pronunciada al pasar por encima */
  }
  
  .card:active {
    background-color: rgba(255,255,255,0.25); /* Color de fondo al hacer clic */
  }


  .card:disabled {
    background-color: rgba(0,0,0,0.3); /* Color de fondo cuando está deshabilitado */
    color: rgba(255,255,255,0.2); /* Color del texto cuando está deshabilitado */
    cursor: not-allowed; /* Cambia el cursor a no permitido */
    box-shadow: none; /* Elimina la sombra */
  }   
  </style>
  