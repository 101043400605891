<template>
    <v-container>
        <ValidationObserver v-slot="{ invalid }">
            <v-card class="pa-5">
                <v-tabs
                    v-model="tab"
                    background-color="transparent"
                    color="primary"
                    grow
                    active-class="tab-class primary"
                    hide-slider
                >
                    <v-tab>Datos personales</v-tab>
                    <v-tab>Sobre ti</v-tab>
                    <v-tab>Experiencia y Educación</v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab" class="pt-5">
                    <v-tab-item>
                        <AlumnoForm
                            :disabled="disabled"
                            v-model="curriculum.alumno"
                        />
                    </v-tab-item>

                    <v-tab-item>
                        <v-row>
                            <v-col cols="12" lg="6">
                                <Title class="ml-5" text="Cuentanos de ti" />
                                <TextArea
                                    :height="500"
                                    v-model="curriculum.biografia"
                                    :disabled="disabled"
                                    label="Mini biografía"
                                />
                                 <TextField
                                    :disabled="disabled"
                                    v-model="curriculum.linkedin"
                                    label="Linkedin"
                                    rules="|max:50"
                                    counter="50"
                                    />

                            </v-col>
                            <v-col cols="12" lg="6">
                                <Title class="ml-5" text="Intereses" />
                                <MultiAutoComplete
                                    :items="tareas"
                                    v-model="curriculum.tareas"
                                    name="Las tareas realizadas"
                                    :disabled="disabled"
                                    itemValue="id"
                                    itemText="nombre"
                                    label="Selecciona multiples opciones"
                                />

                                <Title class="ml-5" text="Lenguajes" />
                                <MultiAutoComplete
                                    :items="lenguajes"
                                    v-model="curriculum.lenguajes"
                                    name="Los lenguajes"
                                    :disabled="disabled"
                                    itemValue="id"
                                    itemText="nombre"
                                    label="Selecciona multiples opciones"
                                />

                                <Title
                                    class="ml-5"
                                    text="Experiencia en Bases de datos"
                                />
                                <MultiAutoComplete
                                    :items="bds"
                                    v-model="curriculum.bds"
                                    name="La base de datos"
                                    :disabled="disabled"
                                    itemValue="id"
                                    itemText="nombre"
                                    label="Selecciona multiples opciones"
                                />

                                <Title
                                    class="ml-5"
                                    text="Experiencia en Librerías"
                                />
                                <MultiAutoComplete
                                    :items="librerias"
                                    v-model="curriculum.librerias"
                                    name="Las librerías o frameworks"
                                    :disabled="disabled"
                                    itemValue="id"
                                    itemText="nombre"
                                    label="Selecciona multiples opciones"
                                />
                            </v-col>
                        </v-row>
                    </v-tab-item>
                    <v-tab-item>
                        <v-row>
                            <v-col :cols="12">
                                <Title class="ml-5" text="Educación" />

                                <dark-card
                                    v-if="curriculum.educacion.length == 0"
                                >
                                    Aún no has añadido ningún lugar de estudio.
                                </dark-card>
                                <div
                                    v-for="(
                                        textField, i
                                    ) in curriculum.educacion"
                                    :key="i"
                                    class="text-fields-row"
                                >
                                <v-divider></v-divider>

                                <h4 class="ml-5 mt-5"> Educación {{i + 1}}</h4>
                                    <v-row>
                                        <v-col cols="11">
                                            <v-row>
                                                <v-col cols="12">
                                                    <TextField
                                                    name="El lugar"
                                                        rules="required"
                                                        v-model="
                                                            curriculum
                                                                .educacion[i]
                                                                .lugar
                                                        "
                                                        :disabled="disabled"
                                                        label="Lugar"
                                                        icon="mdi-form-textbox"
                                                    />
                                                </v-col>
     
                                            </v-row>

                                            <v-row>
                                                <v-col cols="12">
                                                    <TextField
                                                    name="La descripción"
                                                        rules="required"
                                                        v-model="
                                                            curriculum
                                                                .educacion[i]
                                                                .descripcion
                                                        "
                                                        :disabled="disabled"
                                                        label="Descripción"
                                                        icon="mdi-form-textbox"
                                                    />
                                                </v-col>
                                            </v-row>

                                            <v-row>
                                                <v-col cols="12" lg="6">
                                                    <DatePicker
                                                    name="La fecha de inicio"
                                                        rules="required"
                                                        label="Desde"
                                                        v-model="
                                                            curriculum
                                                                .educacion[i]
                                                                .fecha_inicio
                                                        "
                                                        :disabled="disabled"
                                                    ></DatePicker>


                                                   
                                                </v-col>

                                                <v-col cols="12" lg="6">

                                                  <DatePicker
                                                  name="La fecha de finalización"
                                                        label="Hasta"
                                                        :disabled="true"
                                                        v-if="
                                                            curriculum
                                                                .educacion[i]
                                                                .actualidad
                                                        "
                                                    ></DatePicker>

                                                    <DatePicker
                                                    name="La fecha de finalización"
                                                        rules="required"
                                                        label="Hasta"
                                                        v-model="
                                                            curriculum
                                                                .educacion[i]
                                                                .fecha_termino
                                                        "
                                                        :disabled="disabled"
                                                        v-if="
                                                            !curriculum
                                                                .educacion[i]
                                                                .actualidad
                                                        "
                                                    ></DatePicker>
                                                    <v-row justify="end" no-gutters>

                                                      <v-checkbox
                                                        :disabled="disabled"
                                                        v-model="
                                                            curriculum
                                                                .educacion[i]
                                                                .actualidad
                                                        "
                                                        :value="
                                                            curriculum
                                                                .educacion[i]
                                                                .actualidad == 1
                                                                ? true
                                                                : false
                                                        "
                                                        label="La actualidad"
                                                    ></v-checkbox>
                                                    </v-row>
                                                     
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="1">
                                            <v-btn
                                                v-if="!disabled"
                                                text
                                                icon
                                                @click="remove(i)"
                                            >
                                                <v-icon color="red"
                                                    >delete</v-icon
                                                >
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </div>
                                <v-row justify="center" class="pt-5">

                                  <v-btn
                                        color="primary"
                                        fab
                                        @click="add"
                                        v-if="!disabled"
                                    >
                                        <v-icon dark> add </v-icon>
                                    </v-btn>
                               
                                </v-row>
                            </v-col>
                            <v-col :cols="12">
                                <Title
                                    class="ml-5"
                                    text="Experiencia Profesional"
                                />
                                <dark-card
                                    v-if="curriculum.experiencia.length == 0"
                                >
                                    Aún no has añadido ninguna experiencia
                                    profesional.
                                </dark-card>
                                <div
                                    v-for="(
                                        textField, i
                                    ) in curriculum.experiencia"
                                    :key="i"
                                    class="text-fields-row"
                                >
                                <v-divider></v-divider>

                                <h4 class="ml-5 mt-5"> Experiencia {{i + 1}}</h4>
                                    <v-row>
                                        <v-col cols="11">
                                            <v-row>
                                                <v-col cols="12" lg="6">
                                                    <TextField
                                                        name="El lugar"
                                                        rules="required"
                                                        v-model="
                                                            curriculum
                                                                .experiencia[i]
                                                                .lugar
                                                        "
                                                        :disabled="disabled"
                                                        label="Lugar"
                                                        icon="mdi-form-textbox"
                                                    />
                                                </v-col>
                                                <v-col cols="12" lg="6">
                                                    <TextField
                                                    name="El puesto"
                                                        rules="required"
                                                        v-model="
                                                            curriculum
                                                                .experiencia[i]
                                                                .puesto
                                                        "
                                                        :disabled="disabled"
                                                        label="Puesto"
                                                        icon="mdi-form-textbox"
                                                    />
                                                </v-col>
                                            </v-row>

                                            <v-row>
                                                <v-col cols="12">
                                                    <TextField
                                                        name="La descripción"
                                                        rules="required"
                                                        v-model="
                                                            curriculum
                                                                .experiencia[i]
                                                                .descripcion
                                                        "
                                                        :disabled="disabled"
                                                        label="Descripción"
                                                        icon="mdi-form-textbox"
                                                    />
                                                </v-col>
                                            </v-row>

                                            <v-row>
                                                <v-col cols="12" lg="6">
                                                    <DatePicker
                                                        name="La fecha de inicio"
                                                        rules="required"
                                                        label="Desde"
                                                        v-model="
                                                            curriculum
                                                                .experiencia[i]
                                                                .fecha_inicio
                                                        "
                                                        :disabled="disabled"
                                                    ></DatePicker>


                                                   
                                                </v-col>

                                                <v-col cols="12" lg="6">

                                                  <DatePicker
                                                        name="La fecha de finalización"
                                                        label="Hasta"
                                                        :disabled="true"
                                                        v-if="
                                                            curriculum
                                                                .experiencia[i]
                                                                .actualidad
                                                        "
                                                    ></DatePicker>

                                                    <DatePicker
                                                        name="La fecha de finalización"
                                                        rules="required"
                                                        label="Hasta"
                                                        v-model="
                                                            curriculum
                                                                .experiencia[i]
                                                                .fecha_termino
                                                        "
                                                        :disabled="disabled"
                                                        v-if="
                                                            !curriculum
                                                                .experiencia[i]
                                                                .actualidad
                                                        "
                                                    ></DatePicker>
                                                    <v-row justify="end" no-gutters>

                                                      <v-checkbox
                                                        :disabled="disabled"
                                                        v-model="
                                                            curriculum
                                                                .experiencia[i]
                                                                .actualidad
                                                        "
                                                        :value="
                                                            curriculum
                                                                .experiencia[i]
                                                                .actualidad == 1
                                                                ? true
                                                                : false
                                                        "
                                                        label="La actualidad"
                                                    ></v-checkbox>
                                                    </v-row>
                                                     
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="1">
                                            <v-btn
                                                v-if="!disabled"
                                                text
                                                icon
                                                @click="remove_exp(i)"
                                            >
                                                <v-icon color="red"
                                                    >delete</v-icon
                                                >
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </div>
                                <v-row justify="center" class="pt-5">

                                  <v-btn
                                        color="primary"
                                        fab
                                        @click="add_exp"
                                        v-if="!disabled"
                                    >
                                        <v-icon dark> add </v-icon>
                                    </v-btn>
                               
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                </v-tabs-items>
                <v-row justify="end" class="pr-5">
                    <ButtonPrimary
                        iconLeft="save"
                        :onClick="download_pdf"
                        classButton="rounded"
                        v-if="curriculum_loaded && curriculum_loaded.curriculum"
                        >Descargar Currículo</ButtonPrimary
                    >
 
                    <ButtonPrimary
                        classButton="rounded"
                        :disabled="invalid"
                        :onClick="actualizar"
                        v-if="!disabled"
                        >ACTUALIZAR</ButtonPrimary
                    >
                </v-row>
            </v-card>
        </ValidationObserver>
    </v-container>
</template>

<script>
import AlumnoForm from "../Tutorial/AlumnoForm.vue";
import TextArea from "@/components/forms/TextArea";
import TextField from "@/components/forms/TextField";
import ButtonPrimary from "@/components/forms/ButtonPrimary";
import DatePicker from "@/components/forms/DatePicker";
import MultiAutoComplete from "@/components/forms/MultiAutoComplete";
import Title from "@/components/forms/Title";
import { ValidationObserver } from "vee-validate";
import DarkCard from "@/components/content/DarkCard.vue";

export default {
    components: {
        Title,
        MultiAutoComplete,
        DatePicker,
        ButtonPrimary,
        TextField,
        TextArea,
        AlumnoForm,
        ValidationObserver,
        DarkCard,
    },
    data: () => ({
        tab: null,
        disabled: false,
        curriculum: {
            biografia: '',
            linkedin: '',
            educacion: [],
            experiencia: [],
            alumno: {},
            librerias: [],
            lenguajes: [],
            tareas: [],
            bds: [],
        },
    }),
    methods: {
        download_pdf() {
            this.$store.dispatch("tables/getDocument", {
                endpoint: `curriculum/pdf`,
                table: "curriculum",
                params: {
                    name:
                        this.curriculum_loaded.alumno.first_name +
                        " " +
                        this.curriculum_loaded.alumno.last_name,
                },
                error_message:
                    "Ha ocurrido un error al actualizar tu Curriculum.",
                success_message: "Tu Curriculum actualizado correctamente.",
            });
        },

        actualizar() {
            this.$store.dispatch("tables/post", {
                endpoint: `curriculum`,
                table: "curriculum",
                item: this.curriculum,
                error_message:
                    "Ha ocurrido un error al actualizar tu Curriculum.",
                success_message: "Tu Curriculum actualizado correctamente.",
            });
        },

        add() {
            this.curriculum.educacion.push({
                fecha_inicio: null,
                fecha_termino: null,
                descripcion: null,
                actualidad: null,
                lugar: null,
            });
        },

        remove(i) {
            this.curriculum.educacion.splice(i, 1);
        },

        add_exp() {
            this.curriculum.experiencia.push({
                fecha_inicio: null,
                fecha_termino: null,
                descripcion: null,
                actualidad: null,
                lugar: null,
            });
        },

        remove_exp(i) {
            this.curriculum.experiencia.splice(i, 1);
        },
    },

    created() {
        this.$store.dispatch("tables/get", {
            endpoint: `curriculum`,
            table: "curriculum",
        });
        this.$store.dispatch("tables/get", {
            endpoint: `informacion/tareas`,
            table: "tareas",
        });
        this.$store.dispatch("tables/get", {
            endpoint: `informacion/lenguajes`,
            table: "lenguajes_programacion",
        });
        this.$store.dispatch("tables/get", {
            endpoint: `informacion/bds`,
            table: "bases_de_datos",
        });
        this.$store.dispatch("tables/get", {
            endpoint: `informacion/librerias`,
            table: "librerias",
        });
    },

    computed: {
        scrollbarTheme() {
            return this.$vuetify.theme.dark ? "dark" : "light";
        },
        curriculum_loaded() {
            return this.$store.state.tables.curriculum.items;
        },
        tareas() {
            return this.$store.state.tables.tareas.items;
        },
        lenguajes() {
            return this.$store.state.tables.lenguajes_programacion.items;
        },
        bds() {
            return this.$store.state.tables.bases_de_datos.items;
        },
        librerias() {
            return this.$store.state.tables.librerias.items;
        },
        current_user() {
            return this.$store.state.authentication.user;
        },
    },

    watch: {
        curriculum_loaded(value) {
            if (value.educacion.length == 0) {
                this.curriculum.educacion = [];
            } else {
                this.curriculum.educacion = value.educacion;

                for (var i = 0; i < this.curriculum.educacion.length; i++) {
                    if (this.curriculum.educacion[i].actualidad == "1") {
                        this.curriculum.educacion[i].actualidad = true;
                    } else {
                        this.curriculum.educacion[i].actualidad = false;
                    }
                }
            }
            if (value.experiencia.length == 0) {
                this.curriculum.experiencia = [];
            } else {
                this.curriculum.experiencia = value.experiencia;

                for (var j = 0; j < this.curriculum.experiencia.length; j++) {
                    if (this.curriculum.experiencia[j].actualidad == "1") {
                        this.curriculum.experiencia[j].actualidad = true;
                    } else {
                        this.curriculum.experiencia[j].actualidad = false;
                    }
                }
            }

            this.curriculum.tareas = value.tareas;
            this.curriculum.librerias = value.librerias;
            this.curriculum.lenguajes = value.lenguajes;
            this.curriculum.bds = value.bds;
            this.curriculum.biografia = value.biografia;
            this.curriculum.linkedin = value.linkedin;
        },
    },
};
</script>

<style></style>
