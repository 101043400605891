<template>
  <div v-if="practica">
    <v-card class="pa-3">
      <BackHeader
        v-if="revision"
        :onBack="onBack"
        :title="
          practica.alumno.first_name +
            ' ' +
            practica.alumno.last_name +
            ' - Práctica ' +
            practica.tipo + (practica.convalidada==0 ? '' : ' (C)')
        "
        :subtitle="'@' +practica.alumno.username"
      > </BackHeader>
      <v-tabs
        class="pl-5"
        v-model="tab"
        background-color="transparent"
        color="primary"
        grow
        active-class="tab-class primary"
        hide-slider
      >
        <v-tab>Información</v-tab>
        <v-tab v-if="practica && !practica.convalidada">Bitácoras</v-tab>
        <v-tab v-if="practica.informe">Informe</v-tab>
        <v-tab v-if="practica.evaluacion">Evaluación</v-tab>
        <v-tab v-if="practica.revision && is_admin">Revisión</v-tab>
        <v-tab v-if="practica">Logs</v-tab>
        <v-tab v-if="practica && (practica.estado.id == 1 || practica.estado.id == 2 || practica.estado.id == 11) && !is_admin">Solicitudes</v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab" class="pt-5">
        <v-tab-item>
          <v-row>
            <v-col :cols="12" :md="revision ? 12 : 6">
              <Practica
                :revision="revision"
                :practica="practica"
                :show_alumno="show_alumno"
              ></Practica>
            </v-col>

            <v-col cols="12" md="6">
              <Informacion
                v-if="!revision && !evaluador"
                :estado="practica.estado.id"
                :convalidada="practica.convalidada"
              />

              <UltimaBitacora
                v-if="
                  !revision &&
                    !practica.convalidada &&
                    practica.ultima_bitacora &&
                    practica.estado.id != 11
                "
                :data="practica.ultima_bitacora"
                :tiempo="practica.progreso"
              />

              <Convalidacion
                v-if="!revision && practica.convalidada && practica.tareas_realizadas"
                :practica="practica"
              ></Convalidacion>
            </v-col>
          </v-row>

          <v-row justify="end" no-gutters v-if="!practica.bitacoras">
            <v-btn
              class="mr-5"
              v-if="
                practica.estado.id != 11 &&
                  practica.estado.id != 4 &&
                  practica.estado.id != 6 &&
                  practica.estado.id != 9 &&
                  practica.estado.id != 2 &&
                  practica.estado.id != 3
              "
              text
              v-on:click="check_finalizar()"
            >
              <v-icon left>done</v-icon>Terminar Práctica
            </v-btn>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  icon
                  elevation="0"
                  color="error"
                  v-bind="attrs"
                  v-on="on"
                  @click="dialog_delete = !dialog_delete"
                  v-if="
                    (practica.estado.id != 4 &&
                      practica.estado.id != 6 &&
                      practica.estado.id != 9 &&
                      practica.estado.id != 2 &&
                      practica.estado.id != 3) ||
                      (practica.estado.id == 3 && practica.convalidada == 1)
                  "
                >
                  <v-icon>delete</v-icon>
                </v-btn>
              </template>
              <span>Eliminar Práctica</span>
            </v-tooltip>
          </v-row>
        </v-tab-item>

        <!-- Bitácoras -->
        <v-tab-item v-if="practica && !practica.convalidada">
          <PracticaBitacoras
            :practica="practica"
            :revision="revision"
            :fecha_inicio="
              revision || evaluador
                ? practica.fecha_inicio
                : practica.ultima_bitacora.fecha
            "
          ></PracticaBitacoras>
        </v-tab-item>

        <!-- Informe -->
        <v-tab-item v-if="practica && practica.informe">
          <Informe :evaluation="practica.informe"></Informe>
        </v-tab-item>

        <!-- Evaluación -->
        <v-tab-item v-if="practica.evaluacion">
          <v-row>
            <v-col cols="12" md="6">
              <Evaluacion :evaluacion="practica.evaluacion"></Evaluacion>
            </v-col>
            <v-col cols="12" md="6">
              <Notas :notas="practica.notas"></Notas>
            </v-col>
          </v-row>
        </v-tab-item>

        <!-- Revisión -->
        <v-tab-item v-if="practica && practica.revision && is_admin">
          <Revision :practica="practica" :alumno="practica.alumno"></Revision>
        </v-tab-item>

        <!-- Logs -->
        <v-tab-item v-if="practica">
          <Logs :practica="practica"></Logs>
        </v-tab-item>

        <!-- Solicitudes -->
        <!-- Solo mostrar si práctica está en espera de confirmación, en proceso o finalizada y esperando a evaluador-->
        <v-tab-item v-if="practica && (practica.estado.id == 1 || practica.estado.id == 2 || practica.estado.id == 11) && !is_admin">
          <solicitudes-alumno :practica='practica'></solicitudes-alumno>
        </v-tab-item>

      </v-tabs-items>

      <!-- Modal de Eliminación de Práctica -->
      <v-dialog v-model="dialog_delete" width="550">
        <v-card>
          <v-card-title primary-title
            >¿Estás segur@ que deseas eliminar la práctica?
          </v-card-title>

          <v-card-text
            >La práctica será eliminada del sistema y podrás registrar una
            nueva
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="dialog_delete = false">Cancelar</v-btn>
            <v-btn color="primary" text @click="eliminar_url()">Eliminar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Modal de advertencia termino de Práctica -->
      <v-dialog v-model="dialog_finish" width="550">
        <v-card>
          <v-card-title primary-title
            >No se puede finalizar tu práctica
          </v-card-title>

          <v-card-text
            >{{ dialog_message }}
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="dialog_finish = false">Cerrar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

    </v-card>
  </div>
</template>

<script>

// import axios from 'axios';

import Informacion from "./components/informacion";
import Notas from "./components/notas";
import Informe from "./components/informe";
import Evaluacion from "./components/evaluacion";
import Practica from "./components/practica";
import Convalidacion from "./components/sub_components/convalidacion";
import BackHeader from "../content/BackHeader";
import Revision from "./components/revision";
import Logs from "./components/logs";
import SolicitudesAlumno from './components/solicitudes_alumno'

import PracticaBitacoras from "./components/bitacora/PracticaBitacoras";
import UltimaBitacora from "./components/ultima_bitacora";

export default {
  components: {
    PracticaBitacoras,
    UltimaBitacora,
    Informacion,
    Notas,
    Informe,
    Evaluacion,
    Practica,
    Convalidacion,
    BackHeader,
    Revision,
    Logs,
    SolicitudesAlumno
  },
  props: [
    "practica",
    "revision",
    "show_alumno",
    "onBack",
    "evaluador",
  ],
  computed: {
    is_admin() {
      return this.$store.state.authentication.user.is_staff;
    },
  },
  data() {
    return {
      dialog_delete: false,
      dialog_finish: false,
      dialog_message: "",
      tab: null,
    };
  },
  methods: {
    eliminar_url() {
      this.dialog_delete = false;
      this.$store.dispatch("tables/delete", {
        endpoint: `practicas`,
        id: this.practica.token,
        invisible: true,
        table: "eliminar_practica",
        reload: true,
        error_message: 'Ha ocurrido un error al eliminar tu practica'
      });
    },
    check_hours(){
    //Si estuviste viendo el backend, si, efectivamente, solo copie y pegue el la condición de alli xD 
      // return (this.practica.modo == "180 Horas" && this.practica.progreso >= 179) || (
      //         this.practica.modo == "360 Horas" && this.practica.progreso >= 358) || (
      //         //Nuevos modos para la ley de 40 Horas
      //         this.practica.modo == "2 Meses" && this.practica.progreso >= 300) || (
      //         this.practica.modo == "1 Mes" && this.practica.progreso >= 150) || (
      //         this.practica.modo == "160 Horas" && this.practica.progreso >= 159) || (
      //         this.practica.modo == "320 Horas" && this.practica.progreso >= 318) || this.practica.progreso >= 470
      let progreso = this.practica.progreso;
      let horas_modo = this.practica.cantidad_horas_modo;
      let rango_tolerancia = horas_modo * 0.05;
      return progreso >= (horas_modo -rango_tolerancia)
      
    },
    check_finalizar() { 
      // Si no se a subido ninguna bitacora el objeto está vacio.      
      if (Object.keys(this.practica.ultima_bitacora).length === 0) {
        this.dialog_message = "No se puede finalizar tu práctica, no has subido ninguna bitácora";
        this.dialog_finish = true;
        return;
      }
      if (!this.check_hours()) {
        this.dialog_message = `No se puede finalizar tu práctica, no has completado las horas requeridas por la modalidad de ${this.practica.modo} que elegiste, recuerda que la modalidad de horas y meses deben cumplir aproximada la misma cantidad de horas de trabajo efectivas, solo cambia el tiempo en que se distribuye.`;
        this.dialog_finish = true;
        return;
      }
      this.$router.push("/finalizar_practica/" + this.practica.token);
    },
  },
};
</script>

<style>
.tab-class {
  color: #fff !important;
}

.theme--dark .v-tab {
  border-radius: 25px;
  background: rgba(255, 255, 255, 0.1) !important;;
  margin-right: 10px;
}

.theme--light .v-tab {
  border-radius: 25px;
  background: rgba(0, 0, 0, 0.1) !important;;
  margin-right: 10px;
}

.v-tab.v-tab:not(:focus)::before {
  border-radius: 25px !important;
}

.v-tab:hover {
  border-radius: 25px !important;
}

.theme--dark.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active),
.theme--dark.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) > .v-icon,
.theme--dark.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) > .v-btn,
.theme--dark.v-tabs > .v-tabs-bar .v-tab--disabled {
  color: rgba(255, 255, 255, 0.8) !important;
}

.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active),
.theme--dark.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) > .v-icon,
.theme--dark.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) > .v-btn,
.theme--dark.v-tabs > .v-tabs-bar .v-tab--disabled {
  color: rgba(0, 0, 0, 0.8) !important;
}

.v-tab--disabled {
  color: unset !important;
}
.v-tabs--grow > .v-tabs-bar .v-tab {
  flex: unset !important;
}

.theme--dark.v-tabs-items {
  background-color: transparent !important;
}

.v-tabs:not(.v-tabs--vertical):not(.v-tabs--right)
  > .v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-tabs-bar--show-arrows):not(.v-slide-group--has-affixes)
  .v-slide-group__prev {
  display: none !important;
}
</style>
