<template>
    <svg
        class="animated"
        id="freepik_stories-co-workers"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="-40 120 500 375"
        version="1.1"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        xmlns:svgjs="http://svgjs.com/svgjs"
    >
        <g
            id="freepik--background-simple--inject-15"
            class="animable animator-active"
            style="transform-origin: 259.23px 190.296px"
        >
            <path
                d="M289.46,166.76c-31.4-19-43.22-60-77-75.85-29.7-14-80.39-13.67-107.18,7.51-29.87,23.63-29.22,65.48-7.59,95.14,23.8,32.64,64.46,33.05,98.71,46.47,60,23.51,187.36,111.68,233.12,14.79,14.2-30.07,7.73-91.08-32.58-96C358.18,154.06,333.66,193.5,289.46,166.76Z"
                style="
                    fill: rgb(255, 148, 64);
                    opacity: 0.3;
                    transform-origin: 259.23px 190.296px;
                "
                id="eluzn32tqwz8k"
                class="animable"
            ></path>
            <g id="eld3t2jeup1ki">
                <path
                    d="M289.46,166.76c-31.4-19-43.22-60-77-75.85-29.7-14-80.39-13.67-107.18,7.51-29.87,23.63-29.22,65.48-7.59,95.14,23.8,32.64,64.46,33.05,98.71,46.47,60,23.51,187.36,111.68,233.12,14.79,14.2-30.07,7.73-91.08-32.58-96C358.18,154.06,333.66,193.5,289.46,166.76Z"
                    style="
                        fill: rgb(255, 148, 64);
                        opacity: 0.3;
                        transform-origin: 259.23px 190.296px;
                    "
                    class="animable"
                ></path>
            </g>
        </g>
        <g
            id="freepik--Shadow--inject-15"
            class="animable"
            style="transform-origin: 250px 416.24px"
        >
            <ellipse
                id="freepik--path--inject-15"
                cx="250"
                cy="416.24"
                rx="193.89"
                ry="11.32"
                style="
                    fill: rgb(245, 245, 245);
                    transform-origin: 250px 416.24px;
                "
                class="animable"
            ></ellipse>
        </g>
        <g
            id="freepik--Chat--inject-15"
            class="animable"
            style="transform-origin: 254.735px 139.595px"
        >
            <path
                d="M303.49,137.75H251a8,8,0,0,0-8,8v20a8,8,0,0,0,8,8h38.61l9.67,9.67v-9.67h4.19a8.06,8.06,0,0,0,8-8v-20A8.05,8.05,0,0,0,303.49,137.75Z"
                style="
                    fill: rgb(255, 148, 64);
                    transform-origin: 277.235px 160.585px;
                "
                id="el0j7760pvof7g"
                class="animable"
            ></path>
            <g id="elicbery6rmhb">
                <path
                    d="M303.11,149.77H251.4a2.47,2.47,0,0,1-2.46-2.46h0a2.47,2.47,0,0,1,2.46-2.46h51.71a2.47,2.47,0,0,1,2.46,2.46h0A2.47,2.47,0,0,1,303.11,149.77Z"
                    style="
                        fill: rgb(255, 255, 255);
                        opacity: 0.4;
                        transform-origin: 277.255px 147.31px;
                    "
                    class="animable"
                ></path>
            </g>
            <g id="elnf2y6qvpom">
                <path
                    d="M292.89,159H251.4a2.46,2.46,0,0,1-2.46-2.46h0a2.47,2.47,0,0,1,2.46-2.46h41.49a2.47,2.47,0,0,1,2.46,2.46h0A2.46,2.46,0,0,1,292.89,159Z"
                    style="
                        fill: rgb(255, 255, 255);
                        opacity: 0.4;
                        transform-origin: 272.145px 156.54px;
                    "
                    class="animable"
                ></path>
            </g>
            <path
                d="M206,95.77h52.47a8,8,0,0,1,8,8v20a8,8,0,0,1-8,8H219.88l-9.67,9.67v-9.67H206a8.06,8.06,0,0,1-8-8v-20A8.06,8.06,0,0,1,206,95.77Z"
                style="
                    fill: rgb(38, 50, 56);
                    transform-origin: 232.235px 118.605px;
                "
                id="elvvzczqzpqad"
                class="animable"
            ></path>
            <g id="eljjy3qpajq2r">
                <path
                    d="M206.4,107.79h51.71a2.46,2.46,0,0,0,2.46-2.46h0a2.47,2.47,0,0,0-2.46-2.46H206.4a2.47,2.47,0,0,0-2.46,2.46h0A2.46,2.46,0,0,0,206.4,107.79Z"
                    style="
                        fill: rgb(255, 255, 255);
                        opacity: 0.4;
                        transform-origin: 232.255px 105.33px;
                    "
                    class="animable"
                ></path>
            </g>
            <g id="elx06opgywcbe">
                <path
                    d="M245.45,117h12.66a2.47,2.47,0,0,0,2.46-2.46h0a2.46,2.46,0,0,0-2.46-2.46H245.45a2.46,2.46,0,0,0-2.45,2.46h0A2.46,2.46,0,0,0,245.45,117Z"
                    style="
                        fill: rgb(255, 255, 255);
                        opacity: 0.4;
                        transform-origin: 251.785px 114.54px;
                    "
                    class="animable"
                ></path>
            </g>
            <g id="elofgxpfdrros">
                <path
                    d="M215.78,117h20.66a2.47,2.47,0,0,0,2.46-2.46h0a2.46,2.46,0,0,0-2.46-2.46H215.78a2.46,2.46,0,0,0-2.46,2.46h0A2.46,2.46,0,0,0,215.78,117Z"
                    style="
                        fill: rgb(255, 255, 255);
                        opacity: 0.4;
                        transform-origin: 226.11px 114.54px;
                    "
                    class="animable"
                ></path>
            </g>
        </g>
        <g
            id="freepik--character-2--inject-15"
            class="animable"
            style="transform-origin: 302.155px 290.124px"
        >
            <path
                d="M355,330.22H265.85c3.75-8.16,10.57-13.16,17.94-13.16H349Z"
                style="
                    fill: rgb(38, 50, 56);
                    transform-origin: 310.425px 323.64px;
                "
                id="el4w8jw2oqoq"
                class="animable"
            ></path>
            <polygon
                points="312.62 412.97 316.45 412.97 309.37 330.21 305.54 330.21 312.62 412.97"
                style="
                    fill: rgb(255, 148, 64);
                    transform-origin: 310.995px 371.59px;
                "
                id="elk2zlm4n9zh"
                class="animable"
            ></polygon>
            <g id="el9y471vyrmq6">
                <polygon
                    points="312.62 412.97 316.45 412.97 309.37 330.21 305.54 330.21 312.62 412.97"
                    style="
                        fill: rgb(255, 255, 255);
                        opacity: 0.6;
                        transform-origin: 310.995px 371.59px;
                    "
                    class="animable"
                ></polygon>
            </g>
            <polygon
                points="273.44 412.97 269.61 412.97 276.69 330.21 280.52 330.21 273.44 412.97"
                style="
                    fill: rgb(255, 148, 64);
                    transform-origin: 275.065px 371.59px;
                "
                id="elrt71oq9303"
                class="animable"
            ></polygon>
            <g id="elbdpk906uwq">
                <polygon
                    points="273.44 412.97 269.61 412.97 276.69 330.21 280.52 330.21 273.44 412.97"
                    style="
                        fill: rgb(255, 255, 255);
                        opacity: 0.6;
                        transform-origin: 275.065px 371.59px;
                    "
                    class="animable"
                ></polygon>
            </g>
            <path
                d="M373.15,330.22H330.09c3.75-8.16,10.56-13.16,17.93-13.16h15.24c7.37,0,11,4.25,9.89,13.16Z"
                style="
                    fill: rgb(38, 50, 56);
                    transform-origin: 351.718px 323.64px;
                "
                id="elhp0hx8i60gu"
                class="animable"
            ></path>
            <g id="elhqn1kzz6xe">
                <path
                    d="M373.15,330.22H330.09c3.75-8.16,10.56-13.16,17.93-13.16h15.24c7.37,0,11,4.25,9.89,13.16Z"
                    style="opacity: 0.5; transform-origin: 351.718px 323.64px"
                    class="animable"
                ></path>
            </g>
            <polygon
                points="371.31 412.97 375.14 412.97 368.06 330.21 364.23 330.21 371.31 412.97"
                style="
                    fill: rgb(255, 148, 64);
                    transform-origin: 369.685px 371.59px;
                "
                id="el8ecmzyvtdrl"
                class="animable"
            ></polygon>
            <g id="elrtkuxqr1wxa">
                <polygon
                    points="371.31 412.97 375.14 412.97 368.06 330.21 364.23 330.21 371.31 412.97"
                    style="
                        fill: rgb(255, 255, 255);
                        opacity: 0.6;
                        transform-origin: 369.685px 371.59px;
                    "
                    class="animable"
                ></polygon>
            </g>
            <polygon
                points="332.13 412.97 328.3 412.97 335.38 330.21 339.21 330.21 332.13 412.97"
                style="
                    fill: rgb(255, 148, 64);
                    transform-origin: 333.755px 371.59px;
                "
                id="eloqy8sj04cjm"
                class="animable"
            ></polygon>
            <g id="elvni4cpqlgv">
                <polygon
                    points="332.13 412.97 328.3 412.97 335.38 330.21 339.21 330.21 332.13 412.97"
                    style="
                        fill: rgb(255, 255, 255);
                        opacity: 0.6;
                        transform-origin: 333.755px 371.59px;
                    "
                    class="animable"
                ></polygon>
            </g>
            <polygon
                points="249.41 404.77 241.78 401.92 245.71 383.31 253.33 386.16 249.41 404.77"
                style="
                    fill: rgb(255, 195, 189);
                    transform-origin: 247.555px 394.04px;
                "
                id="el6zzyuqafbc"
                class="animable"
            ></polygon>
            <path
                d="M241.88,399.59l8.55,3.19a.58.58,0,0,1,.38.73l-2.11,6.92a1.44,1.44,0,0,1-1.84.86c-3-1.16-4.15-1.79-7.8-3.46-2.25-1-8.68-4-11.58-5.79s-1-4.62.35-4.11c3.29,1.19,9.78,2.52,12.56,1.64A2.3,2.3,0,0,1,241.88,399.59Z"
                style="
                    fill: rgb(38, 50, 56);
                    transform-origin: 238.399px 404.622px;
                "
                id="el9y5sunzulbc"
                class="animable"
            ></path>
            <g id="elnvs5caotiz">
                <polygon
                    points="253.33 386.16 245.7 383.32 243.69 392.91 251.31 395.76 253.33 386.16"
                    style="opacity: 0.2; transform-origin: 248.51px 389.54px"
                    class="animable"
                ></polygon>
            </g>
            <path
                d="M311.69,287.77s-59.95,3.05-60.14,26.36c-.28,33.51-10.61,72.05-10.61,72.05l14.15,4.64s18.78-39.63,18.6-65.79c16.44-4.64,59.14-1.62,64.17-15.24a44.79,44.79,0,0,0,1.89-22Z"
                style="
                    fill: rgb(255, 148, 64);
                    transform-origin: 290.643px 339.295px;
                "
                id="elul3c52dghs"
                class="animable"
            ></path>
            <g id="elql4092785zm">
                <path
                    d="M280.86,309.17s1.5,6.73,2.55,14.11a66.18,66.18,0,0,0-9.73,1.75,67.06,67.06,0,0,1-.41,7.61C263.49,318.06,274,309.17,280.86,309.17Z"
                    style="opacity: 0.3; transform-origin: 276.353px 320.905px"
                    class="animable"
                ></path>
            </g>
            <polygon
                points="255.52 394.82 239.26 388.76 240.1 382.2 258.19 388.69 255.52 394.82"
                style="
                    fill: rgb(38, 50, 56);
                    transform-origin: 248.725px 388.51px;
                "
                id="elqbq2o0bwkye"
                class="animable"
            ></polygon>
            <path
                d="M238.68,399.91a10.65,10.65,0,0,0,2.13.48.18.18,0,0,0,.19-.09.17.17,0,0,0,0-.2c-.18-.26-1.78-2.54-2.85-2.52a.68.68,0,0,0-.6.35,1,1,0,0,0-.14,1A2.21,2.21,0,0,0,238.68,399.91Zm1.74,0c-1.46-.23-2.45-.65-2.67-1.14a.66.66,0,0,1,.11-.68.3.3,0,0,1,.28-.17C238.72,398,239.75,399.06,240.42,400Z"
                style="
                    fill: rgb(255, 148, 64);
                    transform-origin: 239.184px 398.986px;
                "
                id="elsowrlbddn9g"
                class="animable"
            ></path>
            <path
                d="M240.77,400.38a.14.14,0,0,0,.09,0,.21.21,0,0,0,.15-.11c0-.09,1-2.21.54-3.24a1,1,0,0,0-.66-.53.73.73,0,0,0-.85.3c-.48.76.06,2.78.65,3.51A.16.16,0,0,0,240.77,400.38Zm.11-3.47a.51.51,0,0,1,.32.29c.3.62-.11,1.89-.4,2.61-.45-.81-.76-2.3-.44-2.8a.35.35,0,0,1,.44-.13Z"
                style="
                    fill: rgb(255, 148, 64);
                    transform-origin: 240.763px 398.424px;
                "
                id="eloxwwv0t3lm"
                class="animable"
            ></path>
            <path
                d="M331.16,226c-2.09,2.44-4.19,4.63-6.35,6.89s-4.37,4.4-6.66,6.51-4.62,4.19-7,6.19-4.92,3.94-7.52,5.8l-.44.31-.19.1a8.91,8.91,0,0,1-4.14,1.06,11.76,11.76,0,0,1-2.91-.32,21.7,21.7,0,0,1-4.21-1.48,44.58,44.58,0,0,1-6.53-3.85c-2-1.39-3.87-2.86-5.67-4.39S276,239.72,274.3,238l3.75-4.82,5.9,3.14c2,1,3.93,2,5.88,2.91a44.87,44.87,0,0,0,5.69,2.27,10.12,10.12,0,0,0,2.33.5c.28,0,.49,0,.48,0s-.3-.07-.86.21l-.62.41c2.2-1.84,4.36-3.79,6.5-5.77s4.24-4.06,6.33-6.15,4.15-4.22,6.19-6.36,4.1-4.34,6.05-6.42Z"
                style="
                    fill: rgb(255, 195, 189);
                    transform-origin: 302.73px 235.391px;
                "
                id="elc7ms9a8yzrl"
                class="animable"
            ></path>
            <path
                d="M280.43,235.08l-4.47-7.4L270,235.63s3.73,4.56,8.13,4Z"
                style="
                    fill: rgb(255, 195, 189);
                    transform-origin: 275.215px 233.679px;
                "
                id="elzswn91ot108"
                class="animable"
            ></path>
            <polygon
                points="268.34 227.09 265.77 233.16 270.04 235.63 275.96 227.68 268.34 227.09"
                style="
                    fill: rgb(255, 195, 189);
                    transform-origin: 270.865px 231.36px;
                "
                id="elcfjsdt9zh8n"
                class="animable"
            ></polygon>
            <path
                d="M320.32,217.87C310.19,221.61,304.81,233,304.81,233l7.56,14.07c7.25-3.05,16.8-10.43,19.56-15.11C336.17,224.8,328.23,215,320.32,217.87Z"
                style="
                    fill: rgb(38, 50, 56);
                    transform-origin: 318.961px 232.214px;
                "
                id="el2d39pzqspy"
                class="animable"
            ></path>
            <g id="el93hmwo9fhvf">
                <path
                    d="M321.09,231.91c1.3-.69-.16,6-1.48,11.26a52.36,52.36,0,0,1-5.61,3.2C314.47,241.34,317.79,233.66,321.09,231.91Z"
                    style="opacity: 0.3; transform-origin: 317.798px 239.115px"
                    class="animable"
                ></path>
            </g>
            <polygon
                points="319.83 401.05 311.86 402.69 304.43 385 312.39 383.36 319.83 401.05"
                style="
                    fill: rgb(255, 195, 189);
                    transform-origin: 312.13px 393.025px;
                "
                id="elp1sjybsgr3"
                class="animable"
            ></polygon>
            <path
                d="M310.76,401.62,319.5,399a.57.57,0,0,1,.73.35l2.52,6.79a1.44,1.44,0,0,1-.94,1.8c-3.06.87-4.57,1.13-8.41,2.29-2.37.72-8.66,3.34-12,2.64s-2.55-4-1.07-4c3.5,0,7.56-4,9.23-6.36A2.34,2.34,0,0,1,310.76,401.62Z"
                style="
                    fill: rgb(38, 50, 56);
                    transform-origin: 310.935px 405.977px;
                "
                id="el0zyfr0slyh5n"
                class="animable"
            ></path>
            <path
                d="M308.41,403.82a10.24,10.24,0,0,0,2-.91.19.19,0,0,0,.09-.18.18.18,0,0,0-.13-.16c-.3-.1-3-.94-3.8-.28a.71.71,0,0,0-.26.64,1.05,1.05,0,0,0,.52.92A2.24,2.24,0,0,0,308.41,403.82Zm1.41-1c-1.31.7-2.35,1-2.82.71a.66.66,0,0,1-.32-.61.29.29,0,0,1,.12-.31C307.25,402.23,308.74,402.5,309.82,402.8Z"
                style="
                    fill: rgb(255, 148, 64);
                    transform-origin: 308.403px 403.009px;
                "
                id="eligy2taqikia"
                class="animable"
            ></path>
            <path
                d="M310.36,402.93a.18.18,0,0,0,.07-.05.18.18,0,0,0,.06-.17c0-.1-.53-2.39-1.54-2.91a.94.94,0,0,0-.85,0,.73.73,0,0,0-.49.75c.08.9,1.73,2.17,2.65,2.4Zm-2-2.84a.57.57,0,0,1,.44.05c.6.3,1.05,1.57,1.26,2.31-.85-.36-2-1.36-2.05-2,0-.11,0-.25.27-.36Z"
                style="
                    fill: rgb(255, 148, 64);
                    transform-origin: 309.05px 401.324px;
                "
                id="el1mu0pm653g5"
                class="animable"
            ></path>
            <g id="eluquql0y6lb9">
                <polygon
                    points="312.39 383.36 304.43 385 308.44 394.53 316.23 392.48 312.39 383.36"
                    style="opacity: 0.2; transform-origin: 310.33px 388.945px"
                    class="animable"
                ></polygon>
            </g>
            <path
                d="M332,287.83S273.2,298,275.33,315.57C278.4,340.92,304,392.72,304,392.72l14.53-3.3s-12.91-42.9-16.18-68.85c15.11,0,46.46,2.84,51.49-10.78a40.09,40.09,0,0,0,1.43-21.61Z"
                style="
                    fill: rgb(255, 148, 64);
                    transform-origin: 315.701px 340.275px;
                "
                id="elv3zjbr08nw9"
                class="animable"
            ></path>
            <polygon
                points="319.36 389.46 302.12 394.2 299.08 387.02 318.1 382.19 319.36 389.46"
                style="
                    fill: rgb(38, 50, 56);
                    transform-origin: 309.22px 388.195px;
                "
                id="eleoptq8bddx"
                class="animable"
            ></polygon>
            <path
                d="M311.74,288.18h43.48c.31-3.88,4.81-16.51,5.48-32.6A206.39,206.39,0,0,1,365,220.12l.6-2.62s-2.2-.45-5.36-.92c-2.62-.38-5.91-.77-9.13-.93a162.64,162.64,0,0,0-18.48,0c-2.84.27-5.62.74-7.83,1.17-2.83.57-4.5,1.05-4.5,1.05S316.45,259.67,311.74,288.18Z"
                style="
                    fill: rgb(38, 50, 56);
                    transform-origin: 338.67px 251.784px;
                "
                id="elro2zw3vbkyt"
                class="animable"
            ></path>
            <g id="elsui6oski6yg">
                <path
                    d="M360.7,255.59A206.38,206.38,0,0,1,365,220.12c-2.9-.25-7.87,0-8.7,4.73C355.44,229.89,355.37,241.67,360.7,255.59Z"
                    style="opacity: 0.3; transform-origin: 360.432px 237.822px"
                    class="animable"
                ></path>
            </g>
            <path
                d="M368.61,223.59c1.11,2.5,2,4.85,2.85,7.31s1.64,4.92,2.31,7.45,1.3,5.09,1.75,7.75a48,48,0,0,1,.84,8.33v.9l0,.58a9,9,0,0,1-.18,1.27,8.07,8.07,0,0,1-.45,1.43,7.58,7.58,0,0,1-1.93,2.71,7.81,7.81,0,0,1-2.47,1.48,10.07,10.07,0,0,1-3.72.6,17.05,17.05,0,0,1-2.66-.27,27.21,27.21,0,0,1-4.31-1.17c-1.32-.47-2.57-1-3.79-1.51a82.6,82.6,0,0,1-13.49-7.75l2.85-5.4c4.59,1.55,9.27,3.18,13.78,4.36a32.63,32.63,0,0,0,6.31,1.18,5.35,5.35,0,0,0,1,0c.22,0,.35-.14-.06,0a2.47,2.47,0,0,0-.88.51,3.48,3.48,0,0,0-.88,1.19,2.43,2.43,0,0,0-.18.54,1.46,1.46,0,0,0-.07.34.52.52,0,0,0,0,.11v-.11l-.05-.39a49.81,49.81,0,0,0-1.21-6.44c-.55-2.21-1.22-4.47-1.93-6.71s-1.5-4.51-2.3-6.76-1.68-4.54-2.48-6.64Z"
                style="
                    fill: rgb(255, 195, 189);
                    transform-origin: 359.86px 243.497px;
                "
                id="elba44we88c8v"
                class="animable"
            ></path>
            <path
                d="M365.62,217.5c-7.91-2.5-13,3.82-10.79,14.8s5,15.06,5,15.06l18.52-2.62C376.84,234,373.73,220.06,365.62,217.5Z"
                style="
                    fill: rgb(38, 50, 56);
                    transform-origin: 366.331px 232.158px;
                "
                id="elmj98oneh5wc"
                class="animable"
            ></path>
            <path
                d="M351.44,215.58l.4-.31,5.72.61s-2.33,6.08-10.62,9-18.34.51-18.58-4,.72-5,.72-5l4.73-.44.3.51Z"
                style="
                    fill: rgb(255, 148, 64);
                    transform-origin: 342.941px 220.713px;
                "
                id="eldmz2zg4ydna"
                class="animable"
            ></path>
            <path
                d="M350.73,194.93l-4.94,4.1-6.74,5.59a21.92,21.92,0,0,1,.43,2.72c.36,3.65-.58,7-6,8.25,0,0-5.29,4.94,5.28,5.08s13-5,13-5C347.81,211.73,349.36,200.88,350.73,194.93Z"
                style="
                    fill: rgb(255, 195, 189);
                    transform-origin: 341.958px 207.801px;
                "
                id="el4623x1iisn1"
                class="animable"
            ></path>
            <g id="elvj1du9u8j2c">
                <path
                    d="M339.05,204.62a21.92,21.92,0,0,1,.43,2.72c2.51-.5,5.87-3.18,6.22-5.75a12.11,12.11,0,0,0,.09-2.56Z"
                    style="opacity: 0.2; transform-origin: 342.435px 203.185px"
                    class="animable"
                ></path>
            </g>
            <path
                d="M334.2,188.06c-.82.55-2.75-1.72-3.35-3.39-.14-2-.89-10.14,4.33-10.38,4.19-.19,8.68,1.7,8.53,3.68C343.51,180.37,340.9,183.63,334.2,188.06Z"
                style="
                    fill: rgb(38, 50, 56);
                    transform-origin: 337.223px 181.21px;
                "
                id="el70nsa49y8wd"
                class="animable"
            ></path>
            <path
                d="M352.77,186.36c-1.72,7.91-2.32,12.61-7,16.22a10.53,10.53,0,0,1-17-7.58c-.61-7.36,2.3-19.05,10.62-21.29A10.64,10.64,0,0,1,352.77,186.36Z"
                style="
                    fill: rgb(255, 195, 189);
                    transform-origin: 340.874px 189.046px;
                "
                id="elu333s4f6pmb"
                class="animable"
            ></path>
            <path
                d="M351.2,192.33c-.83.53-5.08-.72-4.33-2.33,1.33-1.53-2.31-10.2,2.78-11.39,4.08-1,10.13.62,10.28,2.6C360.15,184.22,357.09,188.56,351.2,192.33Z"
                style="
                    fill: rgb(38, 50, 56);
                    transform-origin: 353.361px 185.38px;
                "
                id="elmzys48bze6"
                class="animable"
            ></path>
            <path
                d="M349.7,181.13c-4.57,0-16.67-2.64-17.73-8-1-5.2,6.93-5.87,6.93-5.87s-2.39,1.44-.66,2.09c6,2.25,21.68.14,21.69,6.78C359.93,179.77,357.23,181.13,349.7,181.13Z"
                style="
                    fill: rgb(38, 50, 56);
                    transform-origin: 345.906px 174.195px;
                "
                id="el2278k0t3t6h"
                class="animable"
            ></path>
            <path
                d="M357.21,179.93c.12-.29,2.13-2.21,4.7.65A27.53,27.53,0,0,0,357.21,179.93Z"
                style="
                    fill: rgb(38, 50, 56);
                    transform-origin: 359.56px 179.805px;
                "
                id="el46zquk7agil"
                class="animable"
            ></path>
            <path
                d="M357.65,179.26c.19.24,2.68,1.49,4.3-2A28,28,0,0,1,357.65,179.26Z"
                style="
                    fill: rgb(38, 50, 56);
                    transform-origin: 359.8px 178.467px;
                "
                id="elgfj0c18r5wf"
                class="animable"
            ></path>
            <path
                d="M355.2,192.34a7.22,7.22,0,0,1-3.94,3.26c-2.16.7-3.33-1.67-2.63-3.79.64-1.9,2.75-4.13,4.87-3.9S356.27,190.44,355.2,192.34Z"
                style="
                    fill: rgb(255, 195, 189);
                    transform-origin: 352.067px 191.809px;
                "
                id="els9cmm7qtj1p"
                class="animable"
            ></path>
            <path
                d="M339.2,185.92c-.1.65-.52,1.13-.95,1.08s-.69-.62-.59-1.26.53-1.14.95-1.09S339.3,185.27,339.2,185.92Z"
                style="
                    fill: rgb(38, 50, 56);
                    transform-origin: 338.43px 185.825px;
                "
                id="elkdzy53gmxx"
                class="animable"
            ></path>
            <path
                d="M331.84,185c-.1.65-.52,1.14-.95,1.09s-.69-.62-.59-1.27.53-1.13.95-1.08S331.94,184.4,331.84,185Z"
                style="
                    fill: rgb(38, 50, 56);
                    transform-origin: 331.07px 184.915px;
                "
                id="el2xnr3tc1goe"
                class="animable"
            ></path>
            <path
                d="M334.11,185.83a20.85,20.85,0,0,1-3.07,4.62,3.18,3.18,0,0,0,2.62.64Z"
                style="
                    fill: rgb(237, 132, 126);
                    transform-origin: 332.575px 188.491px;
                "
                id="eloi1cqd20nl"
                class="animable"
            ></path>
            <path
                d="M333.67,194.93a5.67,5.67,0,0,0,4.29-1.11.19.19,0,0,0-.25-.29,5.44,5.44,0,0,1-4.69.88.18.18,0,0,0-.23.14.19.19,0,0,0,.13.23A5.53,5.53,0,0,0,333.67,194.93Z"
                style="
                    fill: rgb(38, 50, 56);
                    transform-origin: 335.405px 194.238px;
                "
                id="elr2y8lxpofg"
                class="animable"
            ></path>
            <path
                d="M343.41,183.07a.56.56,0,0,0,.17-.09.38.38,0,0,0,0-.54,3.69,3.69,0,0,0-3.23-1.28.39.39,0,1,0,.13.76,2.93,2.93,0,0,1,2.53,1A.37.37,0,0,0,343.41,183.07Z"
                style="
                    fill: rgb(38, 50, 56);
                    transform-origin: 341.83px 182.109px;
                "
                id="el6urr6okye99"
                class="animable"
            ></path>
            <path
                d="M329.45,181.49a.38.38,0,0,0,.35-.07,3.24,3.24,0,0,1,2.74-.75.38.38,0,1,0,.21-.74h0a4,4,0,0,0-3.44.9.37.37,0,0,0-.05.54A.39.39,0,0,0,329.45,181.49Z"
                style="
                    fill: rgb(38, 50, 56);
                    transform-origin: 331.098px 180.676px;
                "
                id="elzh2xk0ghivo"
                class="animable"
            ></path>
            <path
                d="M348.38,248.35l-6.68-3.43-.41,8.78s6.62,3.27,8-.28Z"
                style="
                    fill: rgb(255, 195, 189);
                    transform-origin: 345.29px 249.997px;
                "
                id="elreezhewbwl"
                class="animable"
            ></path>
            <path
                d="M334.19,241.83l-.06-1.07A1,1,0,0,0,333,240l-12.25.73a1,1,0,0,0-1,.93l.92,15.23a1.35,1.35,0,0,0,1.42,1.27l11.74-.71A1.34,1.34,0,0,0,335,256l0-.58c3.4-.2,5.88-3.35,5.65-7.16S337.59,241.62,334.19,241.83Zm.7,11.58-.58-9.57c2.1-.12,4,2,4.16,4.55S337,253.28,334.89,253.41Z"
                style="
                    fill: rgb(255, 148, 64);
                    transform-origin: 330.207px 249.075px;
                "
                id="elzalrtyziadf"
                class="animable"
            ></path>
            <polygon
                points="336.37 245.06 337.42 251.43 341.29 253.7 341.7 244.93 336.37 245.06"
                style="
                    fill: rgb(255, 195, 189);
                    transform-origin: 339.035px 249.315px;
                "
                id="elkolpc9v6wqk"
                class="animable"
            ></polygon>
        </g>
        <g
            id="freepik--Desk--inject-15"
            class="animable"
            style="transform-origin: 269.605px 326.1px"
        >
            <g id="elu8h80blhjt">
                <rect
                    x="172.13"
                    y="283.07"
                    width="171.06"
                    height="5.95"
                    style="
                        fill: rgb(255, 148, 64);
                        transform-origin: 257.66px 286.045px;
                        transform: rotate(180deg);
                    "
                    class="animable"
                ></rect>
            </g>
            <rect
                x="343.19"
                y="283.07"
                width="23.88"
                height="5.95"
                style="
                    fill: rgb(255, 148, 64);
                    transform-origin: 355.13px 286.045px;
                "
                id="el5xtynffptcb"
                class="animable"
            ></rect>
            <g id="elvkcdg5kvipg">
                <rect
                    x="298.89"
                    y="283.07"
                    width="68.19"
                    height="5.95"
                    style="opacity: 0.2; transform-origin: 332.985px 286.045px"
                    class="animable"
                ></rect>
            </g>
            <polygon
                points="364.75 415.11 314.41 415.11 314.41 289.01 316.41 289.01 316.41 413.11 362.75 413.11 362.75 289.01 364.75 289.01 364.75 415.11"
                style="
                    fill: rgb(38, 50, 56);
                    transform-origin: 339.58px 352.06px;
                "
                id="ely1uqic5m8q"
                class="animable"
            ></polygon>
            <polygon
                points="224.31 415.11 173.97 415.11 173.97 289.01 175.97 289.01 175.97 413.11 222.31 413.11 222.31 289.01 224.31 289.01 224.31 415.11"
                style="
                    fill: rgb(38, 50, 56);
                    transform-origin: 199.14px 352.06px;
                "
                id="elnalgjkaw8hl"
                class="animable"
            ></polygon>
            <path
                d="M209.77,281.09h86a2,2,0,0,1,2,2h-90A2,2,0,0,1,209.77,281.09Z"
                style="
                    fill: rgb(255, 148, 64);
                    transform-origin: 252.77px 282.09px;
                "
                id="elmyt9604b6e9"
                class="animable"
            ></path>
            <g id="el7dvy3ellapf">
                <path
                    d="M209.77,281.09h86a2,2,0,0,1,2,2h-90A2,2,0,0,1,209.77,281.09Z"
                    style="
                        fill: rgb(255, 255, 255);
                        opacity: 0.2;
                        transform-origin: 252.77px 282.09px;
                    "
                    class="animable"
                ></path>
            </g>
            <path
                d="M270.71,281.09h25.11a2,2,0,0,1,2,2H268.74A2,2,0,0,1,270.71,281.09Z"
                style="
                    fill: rgb(38, 50, 56);
                    transform-origin: 283.28px 282.09px;
                "
                id="elfazmskfshx7"
                class="animable"
            ></path>
            <path
                d="M205.22,237.09h58.83a4.19,4.19,0,0,1,4,3.5l5.27,37a3,3,0,0,1-3,3.5H211.48a4.18,4.18,0,0,1-4-3.5l-5.27-37A3,3,0,0,1,205.22,237.09Z"
                style="
                    fill: rgb(38, 50, 56);
                    transform-origin: 237.765px 259.09px;
                "
                id="elsf0sp0g6zrr"
                class="animable"
            ></path>
            <path
                d="M203.22,237.09h58.83a4.19,4.19,0,0,1,4,3.5l5.27,37a3,3,0,0,1-3,3.5H209.48a4.18,4.18,0,0,1-4-3.5l-5.27-37A3,3,0,0,1,203.22,237.09Z"
                style="
                    fill: rgb(255, 148, 64);
                    transform-origin: 235.765px 259.09px;
                "
                id="ellvuk9otu9xo"
                class="animable"
            ></path>
            <g id="elg8gac62tmsh">
                <path
                    d="M203.22,237.09h58.83a4.19,4.19,0,0,1,4,3.5l5.27,37a3,3,0,0,1-3,3.5H209.48a4.18,4.18,0,0,1-4-3.5l-5.27-37A3,3,0,0,1,203.22,237.09Z"
                    style="
                        fill: rgb(255, 255, 255);
                        opacity: 0.2;
                        transform-origin: 235.765px 259.09px;
                    "
                    class="animable"
                ></path>
            </g>
        </g>
        <g
            id="freepik--character-1--inject-15"
            class="animable"
            style="transform-origin: 170.441px 270.012px"
        >
            <path
                d="M145.6,180.44l-2.12,6.33c-.72,2.11-1.44,4.23-2.11,6.34s-1.34,4.22-1.93,6.31c-.29,1.05-.58,2.08-.84,3.11l-.34,1.48a6.43,6.43,0,0,0-.1,1.05,33.47,33.47,0,0,0,.57,6c.34,2.13.79,4.29,1.25,6.46,1,4.33,2,8.72,3.11,13l-4,1.46a126.94,126.94,0,0,1-5.22-12.79c-.77-2.19-1.45-4.41-2.06-6.71a37.48,37.48,0,0,1-1.28-7.4,13.47,13.47,0,0,1,.08-2.33l.27-1.86c.21-1.19.43-2.37.69-3.51.51-2.3,1.07-4.55,1.71-6.77s1.28-4.43,2-6.61,1.43-4.35,2.27-6.53Z"
                style="
                    fill: rgb(255, 139, 123);
                    transform-origin: 138.056px 204.725px;
                "
                id="elwi0vvcbpf6"
                class="animable"
            ></path>
            <path
                d="M142.7,229.57l5,5.9-7.44,3.08s-2.9-4.49-1.61-8.2Z"
                style="
                    fill: rgb(255, 139, 123);
                    transform-origin: 143.01px 234.06px;
                "
                id="elx1wvfgfoo48"
                class="animable"
            ></path>
            <polygon
                points="146.72 242.33 141.42 242.97 140.27 238.55 147.71 235.47 146.72 242.33"
                style="
                    fill: rgb(255, 139, 123);
                    transform-origin: 143.99px 239.22px;
                "
                id="eljjxiglwt6ab"
                class="animable"
            ></polygon>
            <path
                d="M157.43,408.18a10.27,10.27,0,0,1-2.22-.3.22.22,0,0,1-.15-.16.2.2,0,0,1,.09-.2c.29-.19,2.83-1.83,3.81-1.39a.68.68,0,0,1,.39.56,1.13,1.13,0,0,1-.33,1.05A2.37,2.37,0,0,1,157.43,408.18Zm-1.65-.59c1.45.3,2.55.25,3-.14a.77.77,0,0,0,.21-.71.3.3,0,0,0-.17-.25C158.28,406.26,156.81,407,155.78,407.59Z"
                style="
                    fill: rgb(255, 148, 64);
                    transform-origin: 157.215px 407.122px;
                "
                id="elydymha0zsp"
                class="animable"
            ></path>
            <path
                d="M155.26,407.88l-.1,0a.21.21,0,0,1-.1-.17c0-.11,0-2.52.92-3.32a1,1,0,0,1,.84-.27.69.69,0,0,1,.67.55c.19,1-1.33,2.75-2.13,3.21A.18.18,0,0,1,155.26,407.88Zm1.42-3.39a.65.65,0,0,0-.43.17,4.53,4.53,0,0,0-.78,2.64c.8-.64,1.75-2,1.63-2.57,0-.09-.07-.21-.33-.24Z"
                style="
                    fill: rgb(255, 148, 64);
                    transform-origin: 156.283px 405.998px;
                "
                id="el0vemehgz9bmj"
                class="animable"
            ></path>
            <path
                d="M156,148.07c1,5,3,15-.45,18.35,0,0,1.35,5,10.59,5,10.16,0,4.85-5,4.85-5-5.54-1.32-5.4-5.43-4.43-9.3Z"
                style="
                    fill: rgb(255, 139, 123);
                    transform-origin: 163.963px 159.745px;
                "
                id="elqvks71ucrda"
                class="animable"
            ></path>
            <path
                d="M177,139a.39.39,0,0,0,.33-.14,3.15,3.15,0,0,1,2.62-1.17.4.4,0,0,0,.44-.35.39.39,0,0,0-.34-.44,3.94,3.94,0,0,0-3.32,1.45.41.41,0,0,0,0,.56A.35.35,0,0,0,177,139Z"
                style="
                    fill: rgb(38, 50, 56);
                    transform-origin: 178.507px 137.947px;
                "
                id="eld0eyh4cjsxj"
                class="animable"
            ></path>
            <path
                d="M178.82,143.88a18.2,18.2,0,0,0,1.89,4.57,2.89,2.89,0,0,1-2.44.16Z"
                style="
                    fill: rgb(255, 86, 82);
                    transform-origin: 179.49px 146.343px;
                "
                id="el6ulz8qa89yv"
                class="animable"
            ></path>
            <path
                d="M178.36,142.66c-.08.67.21,1.25.65,1.31s.86-.45.94-1.13-.22-1.25-.65-1.3S178.44,142,178.36,142.66Z"
                style="
                    fill: rgb(38, 50, 56);
                    transform-origin: 179.155px 142.756px;
                "
                id="elbqcotb3dlk"
                class="animable"
            ></path>
            <path
                d="M179.1,141.55l1.66-.27S179.79,142.44,179.1,141.55Z"
                style="
                    fill: rgb(38, 50, 56);
                    transform-origin: 179.93px 141.583px;
                "
                id="elhe88zuuazj"
                class="animable"
            ></path>
            <polygon
                points="145.52 407.69 153.9 407.69 153.24 388.29 144.86 388.29 145.52 407.69"
                style="
                    fill: rgb(255, 139, 123);
                    transform-origin: 149.38px 397.99px;
                "
                id="ela98jedxkgqm"
                class="animable"
            ></polygon>
            <path
                d="M154.56,406.72h-9.41a.66.66,0,0,0-.67.57l-1.07,7.44a1.34,1.34,0,0,0,1.34,1.49c3.28-.05,4.86-.25,9-.25,2.55,0,6.27.27,9.78.27s3.7-3.48,2.24-3.79c-6.56-1.42-7.6-3.36-9.81-5.22A2.21,2.21,0,0,0,154.56,406.72Z"
                style="
                    fill: rgb(38, 50, 56);
                    transform-origin: 155.015px 411.48px;
                "
                id="elpt6l47hxqel"
                class="animable"
            ></path>
            <g id="el1zmeoim8r8p">
                <g
                    style="opacity: 0.2; transform-origin: 149.215px 393.29px"
                    class="animable"
                >
                    <polygon
                        points="144.86 388.29 153.24 388.29 153.57 398.29 145.19 398.29 144.86 388.29"
                        id="el0080gxmk02x7y"
                        class="animable"
                        style="transform-origin: 149.215px 393.29px"
                    ></polygon>
                </g>
            </g>
            <path
                d="M156.07,137.7c-.46,8.3-.84,11.8,2.83,16.52,5.51,7.1,15.8,5.86,18.74-2.14,2.64-7.19,3-19.56-4.73-23.79A11.34,11.34,0,0,0,156.07,137.7Z"
                style="
                    fill: rgb(255, 139, 123);
                    transform-origin: 167.563px 142.872px;
                "
                id="el1m6e9flqh9o"
                class="animable"
            ></path>
            <path
                d="M172.17,219.12s-4.87,57.49-9.91,89.89c-4.07,26.17-7.29,87.35-7.29,87.35H143.55s-5.44-59.08-3.37-85c5.23-65.49-4-77.09,6.95-92.27Z"
                style="
                    fill: rgb(38, 50, 56);
                    transform-origin: 155.94px 307.725px;
                "
                id="elmwrsahnf3ze"
                class="animable"
            ></path>
            <g id="elvgioxeg5dy">
                <path
                    d="M159.37,250.15c.48,17.2,3.72,27.85,6.28,33.59,1.34-11.22,2.57-22.93,3.6-33.26C165.74,238.78,159,237.2,159.37,250.15Z"
                    style="opacity: 0.2; transform-origin: 164.303px 262.399px"
                    class="animable"
                ></path>
            </g>
            <path
                d="M165.31,404.48a.18.18,0,0,1,0-.19.21.21,0,0,1,.19-.13c.43,0,4.17.21,4.78,1.21a.64.64,0,0,1,0,.63,1.08,1.08,0,0,1-.79.65c-1.2.26-3.12-1.23-4.16-2.14Zm4.58,1.06c-.4-.52-2.36-.83-3.84-.94,1.45,1.19,2.69,1.81,3.35,1.67a.72.72,0,0,0,.52-.44.25.25,0,0,0,0-.25S169.9,405.55,169.89,405.54Z"
                style="
                    fill: rgb(255, 148, 64);
                    transform-origin: 167.823px 405.42px;
                "
                id="elapc33enxl0m"
                class="animable"
            ></path>
            <path
                d="M165.31,404.48l0,0a.23.23,0,0,1,0-.2c.06-.08,1.35-1.94,2.7-2.17a1.43,1.43,0,0,1,1.12.25c.45.32.45.65.37.86-.34.9-3,1.48-4,1.37A.16.16,0,0,1,165.31,404.48Zm3.76-1.66a1,1,0,0,0-.18-.17,1.07,1.07,0,0,0-.82-.18,4.57,4.57,0,0,0-2.22,1.71c1.19,0,3.06-.59,3.27-1.13A.23.23,0,0,0,169.07,402.82Z"
                style="
                    fill: rgb(255, 148, 64);
                    transform-origin: 167.414px 403.343px;
                "
                id="elyjl7gw42d3k"
                class="animable"
            ></path>
            <polygon
                points="157.27 401.38 165.08 404.41 168.15 397.15 172.17 387.65 172.64 386.54 164.84 383.5 164.3 384.75 160.41 393.95 157.27 401.38"
                style="
                    fill: rgb(255, 139, 123);
                    transform-origin: 164.955px 393.955px;
                "
                id="eluzdhurrz2o"
                class="animable"
            ></polygon>
            <g id="el0icwguljpzqe">
                <polygon
                    points="160.41 393.95 168.15 397.15 172.17 387.65 164.3 384.75 160.41 393.95"
                    style="opacity: 0.2; transform-origin: 166.29px 390.95px"
                    class="animable"
                ></polygon>
            </g>
            <path
                d="M181.15,219.12s12.53,57.14,12.53,86.55c0,26.57-22.36,88.62-22.36,88.62l-10.61-4.37s12.42-72.73,10.63-81.25c-5.19-24.72-13.83-78-13.74-89.55Z"
                style="
                    fill: rgb(38, 50, 56);
                    transform-origin: 175.64px 306.705px;
                "
                id="el7te0annht27"
                class="animable"
            ></path>
            <path
                d="M165.47,403.25l-8.15-4.7a.67.67,0,0,0-.87.17l-4.64,5.91a1.35,1.35,0,0,0,.42,2c2.87,1.59,4.34,2.21,7.92,4.28,2.21,1.27,7,4.6,10.48,5.28s4.31-2.69,2.93-3.28c-6.16-2.67-6.14-5.76-7.13-8.48A2.2,2.2,0,0,0,165.47,403.25Z"
                style="
                    fill: rgb(38, 50, 56);
                    transform-origin: 162.863px 407.369px;
                "
                id="elky6zr3m2gco"
                class="animable"
            ></path>
            <polygon
                points="172.14 396.4 158.49 391.36 159.78 385.85 174.39 391.24 172.14 396.4"
                style="
                    fill: rgb(255, 148, 64);
                    transform-origin: 166.44px 391.125px;
                "
                id="el7xu852syk6r"
                class="animable"
            ></polygon>
            <polygon
                points="156.54 396.58 141.99 396.58 141.22 391.48 156.62 390.96 156.54 396.58"
                style="
                    fill: rgb(255, 148, 64);
                    transform-origin: 148.92px 393.77px;
                "
                id="elrmhacb70pl"
                class="animable"
            ></polygon>
            <path
                d="M133.9,181.61l8.41,6.7,5.05,4s.53-1.41,1.17-3.43c1.22-3.9,2.81-10.08,1.71-12.92-1.73-4.5-5-8.56-9.14-7.66C137.62,169.09,133.9,181.61,133.9,181.61Z"
                style="
                    fill: rgb(255, 148, 64);
                    transform-origin: 142.255px 180.242px;
                "
                id="eln09vsc2bdpg"
                class="animable"
            ></path>
            <g id="elv6rsbzhhkzn">
                <path
                    d="M143.41,189.18l4,3.16s.53-1.41,1.17-3.43l-3-14.85Z"
                    style="opacity: 0.2; transform-origin: 145.995px 183.2px"
                    class="animable"
                ></path>
            </g>
            <path
                d="M204.19,199.84v-.69a.65.65,0,0,0-.71-.55l-5.77,0a.65.65,0,0,0-.7.56l.06,9.85a.86.86,0,0,0,.87.86l5.44,0a.88.88,0,0,0,.87-.88v-.37a4.42,4.42,0,0,0-.05-8.78Zm.05,7.48,0-6.18c1.35,0,2.51,1.4,2.52,3.07S205.59,207.31,204.24,207.32Z"
                style="
                    fill: rgb(38, 50, 56);
                    transform-origin: 202.571px 204.233px;
                "
                id="elgjvt11qw8ys"
                class="animable"
            ></path>
            <path
                d="M185.15,168.57s4,1.4-4,50.55h-34c.57-13.84.59-22.38-6-50.8a100.28,100.28,0,0,1,14.45-1.9,107.4,107.4,0,0,1,15.44,0C177.63,167,185.15,168.57,185.15,168.57Z"
                style="
                    fill: rgb(255, 148, 64);
                    transform-origin: 163.65px 192.631px;
                "
                id="elcffcsxkdgxe"
                class="animable"
            ></path>
            <path
                d="M190.07,178.18c.14,1.82.31,3.81.52,5.72s.46,3.84.73,5.74a79.2,79.2,0,0,0,2.33,11.07,30.41,30.41,0,0,0,1.89,4.88,12.07,12.07,0,0,0,2.07,3c.18.16.15.25.47.3a3.19,3.19,0,0,0,1.51-.54,17.64,17.64,0,0,0,3.74-3.38l3.77,1.93a18.63,18.63,0,0,1-1.41,3.14,14.35,14.35,0,0,1-2.14,2.92,9.29,9.29,0,0,1-3.66,2.42,7.33,7.33,0,0,1-5.37-.2,10.57,10.57,0,0,1-3.72-2.7,19.55,19.55,0,0,1-2.16-3,34.16,34.16,0,0,1-2.78-6.16,75.76,75.76,0,0,1-3-12.3q-.53-3.06-.84-6.15c-.22-2.07-.4-4.06-.49-6.23Z"
                style="
                    fill: rgb(255, 139, 123);
                    transform-origin: 194.315px 196.987px;
                "
                id="el9l8hx9lcifi"
                class="animable"
            ></path>
            <path
                d="M185.15,168.57c4.17,1,9.18,16.1,9.18,16.1L180,194.83s-5.71-15.61-4.34-20C177.09,170.19,180.1,167.34,185.15,168.57Z"
                style="
                    fill: rgb(255, 148, 64);
                    transform-origin: 184.889px 181.557px;
                "
                id="elqi1p2zfiuog"
                class="animable"
            ></path>
            <path
                d="M174.76,141.58c.75-3.53,2.28-3.82,2.53-7.58.18-2.62-2.45-5.94-7.17-8.67s-18.76.82-16.45,4.13c-4.28,0-7.32,5.82-5.14,12.65s-12.32,26.17,1.29,33.39,25.51-.22,29.67-3.11c-2.48-.64-3.64-3.56-2.88-7.78s.58-11.05-1.16-14.83S174.21,144.22,174.76,141.58Z"
                style="
                    fill: rgb(38, 50, 56);
                    transform-origin: 161.705px 151.459px;
                "
                id="elmp39fmphgzc"
                class="animable"
            ></path>
            <path
                d="M164.33,126.33c3.66-4.53,13.38-3,15.2,3.75s-6.45,6.8-10.2,4.63S161.54,129.79,164.33,126.33Z"
                style="
                    fill: rgb(38, 50, 56);
                    transform-origin: 171.595px 129.863px;
                "
                id="eljrgvhk7lfi"
                class="animable"
            ></path>
            <path
                d="M171.55,142a6.93,6.93,0,0,0,1.58,4.3c1.35,1.65,3,.88,3.35-.9.35-1.61.08-4.4-1.65-5.41S171.58,140.17,171.55,142Z"
                style="
                    fill: rgb(255, 139, 123);
                    transform-origin: 174.082px 143.415px;
                "
                id="el5j2olna0bz7"
                class="animable"
            ></path>
            <path
                d="M202.28,206.38l2.39-3.74,4.94,5.33s-3.7,3.12-7,1.61Z"
                style="
                    fill: rgb(255, 139, 123);
                    transform-origin: 205.945px 206.316px;
                "
                id="elmam8nfinogb"
                class="animable"
            ></path>
            <polygon
                points="208.79 201.33 210.37 205.79 209.61 207.97 204.67 202.64 208.79 201.33"
                style="
                    fill: rgb(255, 139, 123);
                    transform-origin: 207.52px 204.65px;
                "
                id="elqhynfselt8"
                class="animable"
            ></polygon>
            <path
                d="M146.62,217.13l-1.53,3c-.12.24.16.48.55.48h35.68c.3,0,.56-.15.58-.35l.3-3c0-.21-.24-.39-.58-.39H147.18A.61.61,0,0,0,146.62,217.13Z"
                style="
                    fill: rgb(255, 148, 64);
                    transform-origin: 163.631px 218.739px;
                "
                id="el2fh2i4wzo58"
                class="animable"
            ></path>
            <g id="elira03r69mzk">
                <path
                    d="M146.62,217.13l-1.53,3c-.12.24.16.48.55.48h35.68c.3,0,.56-.15.58-.35l.3-3c0-.21-.24-.39-.58-.39H147.18A.61.61,0,0,0,146.62,217.13Z"
                    style="opacity: 0.2; transform-origin: 163.631px 218.739px"
                    class="animable"
                ></path>
            </g>
            <path
                d="M151.25,221h-.93c-.18,0-.32-.1-.31-.21l.44-4c0-.12.17-.21.35-.21h.93c.18,0,.32.09.31.21l-.44,4C151.59,220.88,151.43,221,151.25,221Z"
                style="
                    fill: rgb(38, 50, 56);
                    transform-origin: 151.025px 218.79px;
                "
                id="elv5bmbrcna3"
                class="animable"
            ></path>
            <path
                d="M173.61,221h-.92c-.18,0-.32-.1-.31-.21l.43-4c0-.12.17-.21.36-.21h.92c.18,0,.32.09.31.21l-.43,4C174,220.88,173.8,221,173.61,221Z"
                style="
                    fill: rgb(38, 50, 56);
                    transform-origin: 173.39px 218.79px;
                "
                id="el9u441ipg8us"
                class="animable"
            ></path>
        </g>
        <defs>
            <filter id="active" height="200%">
                <feMorphology
                    in="SourceAlpha"
                    result="DILATED"
                    operator="dilate"
                    radius="2"
                ></feMorphology>
                <feFlood
                    flood-color="#32DFEC"
                    flood-opacity="1"
                    result="PINK"
                ></feFlood>
                <feComposite
                    in="PINK"
                    in2="DILATED"
                    operator="in"
                    result="OUTLINE"
                ></feComposite>
                <feMerge>
                    <feMergeNode in="OUTLINE"></feMergeNode>
                    <feMergeNode in="SourceGraphic"></feMergeNode>
                </feMerge>
            </filter>
            <filter id="hover" height="200%">
                <feMorphology
                    in="SourceAlpha"
                    result="DILATED"
                    operator="dilate"
                    radius="2"
                ></feMorphology>
                <feFlood
                    flood-color="#ff0000"
                    flood-opacity="0.5"
                    result="PINK"
                ></feFlood>
                <feComposite
                    in="PINK"
                    in2="DILATED"
                    operator="in"
                    result="OUTLINE"
                ></feComposite>
                <feMerge>
                    <feMergeNode in="OUTLINE"></feMergeNode>
                    <feMergeNode in="SourceGraphic"></feMergeNode>
                </feMerge>
                <feColorMatrix
                    type="matrix"
                    values="0   0   0   0   0                0   1   0   0   0                0   0   0   0   0                0   0   0   1   0 "
                ></feColorMatrix>
            </filter>
        </defs>
    </svg>
</template>

<script>
export default {};
</script>

    <style>

</style>
<style>
svg#freepik_stories-co-workers:not(.animated) .animable {
    opacity: 0;
}
svg#freepik_stories-co-workers.animated #freepik--background-simple--inject-15 {
    animation: 1.5s Infinite linear floating;
    animation-delay: 0s;
}
svg#freepik_stories-co-workers.animated #freepik--Chat--inject-15 {
    animation: 1.5s Infinite linear wind;
    animation-delay: 0s;
}

@keyframes floating {
    0% {
        opacity: 1;
        transform: translateY(0px);
    }
    50% {
        transform: translateY(-5px);
    }
    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}
@keyframes wind {
    0% {
        transform: rotate(0deg);
    }
    25% {
        transform: rotate(3deg);
    }
    75% {
        transform: rotate(-3deg);
    }
}
</style>
