<template>
  <v-container>
    <v-row no-gutters>
      <v-col :cols="12">
        <DarkCard title="Información" icon="mdi-information">
          <Message
            v-if="estado == 11"
            text="Se le ha enviado un correo a tu supervisor para que confirme la práctica que realizarás, la práctica dará inicio en cuanto el supervisor lo confirme."
          />
          <Message
            v-if="estado == 3 && !convalidada"
            text="Tu práctica ha finalizado, será revisada lo más pronto posible por el encargado de prácticas."
          />
          <Message
            v-if="estado == 1"
            icon="mdi-progress-clock"
            text="Tu práctica está en proceso. Ingresa tu bitácora diaria."
          />
          <Message
            v-if="estado == 2"
            text="Se le ha enviado un correo a tu supervisor para que evalúe tu rendimiento en la práctica."
          />

          <Message
            v-if="estado == 3 && convalidada"
            text="La petición de convalidación ha sido ingresada correctamente, será revisada lo más pronto posible."
          />
          <Message
            v-if="estado == 4"
            text="Tu práctica ha sido aprobada exitosamente."
            icon="mdi-information-outline"
          />
          <Message
            v-if="estado == 7"
            text="Tu práctica está con observaciones, para ver más detalle revisa la pestaña 'Logs'."
            icon="mdi-progress-clock"
          />
        </DarkCard>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Message from "../../Message";
import DarkCard from "@/components/content/DarkCard.vue";
export default {
  components: { Message, DarkCard },
  props: ["estado", "convalidada"],
};
</script>

<style></style>
