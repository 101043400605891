import Vue from 'vue'
import VueRouter from 'vue-router'

//ADMIN
import admin_revisiones from '@/views/admin/revisiones'

import admin_solicitudes from '@/views/admin/solicitudes'
import admin_ofertas from '@/views/admin/ofertas'
import admin_estadisticas from '@/views/admin/estadisticas'
import admin_alumnos from '@/views/admin/alumnos'
import admin_empresas from '@/views/admin/Empresas'
import admin_exportadores from '@/views/admin/Exportadores'
import comentarios_di from '@/views/admin/ComentariosDI'

import alumno_detalle from '@/views/alumno/Perfil/PerfilScreen'

//USER
import user_dashboard from '@/views/alumno/Practicas/PracticasScreen'
import user_curriculum from '@/views/alumno/Curriculum/CurriculumScreen'
import user_ofertas from '@/views/alumno/Ofertas/OfertasScreen'
import nueva_oferta from '@/views/alumno/Ofertas/NuevaOferta'
import welcome from '@/views/alumno/Tutorial/Tutorial'

import empresas from '@/views/alumno/Empresas/EmpresasScreen'
import empresa_detalle from '@/views/alumno/Empresas/EmpresaDetail'


import home from '@/views/Home'
import practica_revisar from '@/views/admin/practica_en_revision'
import admin_logs from '@/views/admin/Logs'

import evaluador_confirmar from '@/views/evaluador/confirmar_practica'
import evaluador_evaluar from '@/views/evaluador/evaluar_practica'

import error from '@/views/info/error'
import success from '@/views/info/success'

import new_practica from '@/views/alumno/Practicas/NuevaPractica'
import finish from '@/views/alumno/Practicas/FinalizarPractica'
import noticias from '@/views/alumno/Noticias/noticias'
import preguntas from '@/views/alumno/Preguntas/preguntas'
import info_proceso from '@/views/alumno/InfoProceso/info_proceso'



Vue.use(VueRouter);

function auth_admin(to, from, next) {
  if (localStorage.getItem('user')) {
    if (JSON.parse(localStorage.getItem('user')).is_staff) {
      next()
    } else {
      next('/');
    }
  } else {
    next('/');
  }

}

// Función que verifica si un usuario no sea staff y además haya completado el tutorial -> inicia sesión
function auth_user(to, from, next) {
  if (localStorage.getItem('user')) {
    if (!JSON.parse(localStorage.getItem('user')).is_staff) {
      if(JSON.parse(localStorage.getItem('user')).tutorial_complete){
        next()
      } else {
        next('/welcome')
      }
      
    } else {
      next('/');
    }
  } else {
    next('/');
  }
}
// Función que verifica si un usuario no sea staff y además no haya completado sesión -> lo manda a 

function auth_user_welcome(to, from, next) {
  if (localStorage.getItem('user')) {
    if (!JSON.parse(localStorage.getItem('user')).is_staff) {  // JSON.parse
      if(JSON.parse(localStorage.getItem('user')).tutorial_complete){  // JSON.parse
        next('/user_dashboard')
      } else {
        next()
      }
      
    } else {
      next('/');
    }
  } else {
    next('/');
  }
}

function auth(to, from, next) {
  if (localStorage.getItem('user')) {
      next()
  } else {
    next('/');
  }
}

const routes = [

  //ADMIN ROUTES


  {
    path: '/alumnos',
    name: 'Alumnos',
    component: admin_alumnos,
    beforeEnter: (to, from, next) => {
      auth_admin(to, from, next)
    }
  },

  {
    path: '/alumno/:username',
    name: 'Detalle Alumno',
    component: alumno_detalle,
    beforeEnter: (to, from, next) => {
      auth_admin(to, from, next)
    }
  },

  {
    path: '/practica/:token',
    name: 'Revisar Práctica',
    component: practica_revisar,
    beforeEnter: (to, from, next) => {
      auth_admin(to, from, next)
    }
  },

  {
    path: '/logs',
    name: 'Logs',
    component: admin_logs,
    beforeEnter: (to, from, next) => {
      auth_admin(to, from, next)
    }
  },

  {
    path: '/admin_empresas',
    name: 'Empresas',
    component: admin_empresas,
    beforeEnter: (to, from, next) => {
      auth_admin(to, from, next)
    }
  },

  {
    path: '/admin_revisiones',
    name: 'En revisión',
    component: admin_revisiones,
    beforeEnter: (to, from, next) => {
      auth_admin(to, from, next)
    }
  },

  {
    path: '/admin_solicitudes',
    name: 'Solicitudes Pendientes',
    component: admin_solicitudes,
    beforeEnter: (to, from, next) => {
      auth_admin(to, from, next)
    }
  },
 
  {
    path: '/admin_ofertas',
    name: 'Ofertas Pendientes',
    component: admin_ofertas,
    beforeEnter: (to, from, next) => {
      auth_admin(to, from, next)
    }
  },

  {
    path: '/comentarios_di',
    name: 'Comentarios DI',
    component: comentarios_di,
    beforeEnter: (to, from, next) => {
      auth_admin(to, from, next)
    }
  },
  
  {
    path: '/exportadores',
    name: 'Exportadores',
    component: admin_exportadores,
    beforeEnter: (to, from, next) => {
      auth_admin(to, from, next)
    }
  },


  {
    path: '/estadisticas',
    name: 'Estadísticas',
    component: admin_estadisticas,
    beforeEnter: (to, from, next) => {
      auth(to, from, next)
    }
  },


 //BOTH ROUTES
  {
    path: '/empresas',
    name: 'Lista de Empresas',
    component: empresas,
    beforeEnter: (to, from, next) => {
      auth(to, from, next)
    }
  },

  {
    path: '/oferta',
    name: 'Nueva Oferta',
    component: nueva_oferta,
  },
  {
    path: '/preguntas_frecuentes',
    name: 'Preguntas Frecuentes',
    component: preguntas,
  },

  {
    path: '/info_proceso',
    name: 'Información del Proceso',
    component: info_proceso,
  },

  //USER ROUTES

  {
    path: '/welcome',
    name: 'Te damos la bienvenida al Sístema de Práctica',
    component: welcome,
    beforeEnter: (to, from, next) => {
      auth_user_welcome(to, from, next)
    }
  },

  {
    path: '/user_dashboard',
    name: 'Mis Prácticas',
    component: user_dashboard,
    beforeEnter: (to, from, next) => {
      auth_user(to, from, next)
    }
  },


  {
    path: '/perfil',
    name: 'Mi perfil',
    props: true,
    component: user_curriculum,
    beforeEnter: (to, from, next) => {
      auth_user(to, from, next)
    }
  },

  {
    path: '/ofertas',
    name: 'Ofertas de Prácticas',
    props: true,
    component: user_ofertas,
    beforeEnter: (to, from, next) => {
      auth_user(to, from, next)
    }
  },

  {
    path: '/new_practica',
    name: 'Inscribir Práctica',
    component: new_practica,
    beforeEnter: (to, from, next) => {
      auth_user(to, from, next)
    }
  },

  {
    path: '/informaciones',
    name: 'Informaciones',
    component: noticias,
    beforeEnter: (to, from, next) => {
      auth_user(to, from, next)
    }
  },

  {
    path: '/empresas/:id',
    name: 'Detalle Empresa',
    component: empresa_detalle,
    beforeEnter: (to, from, next) => {
      auth(to, from, next)
    }
  },


  
  {
    path: '/',
    name: 'Iniciar Sesión',
    component: home,
    beforeEnter: (to, from, next) => {
      const loggedIn = localStorage.getItem('user');
      if (loggedIn != null) {
        if (JSON.parse(localStorage.getItem('user')).is_staff) {
          return next('/admin_revisiones');
        } else {
          return next('/user_dashboard');
        }
      } else {
        next()
      }
    }
  },
  {
    path: '/confirmar-practica/:token',
    component: evaluador_confirmar,
  },
  {
    path: '/evaluar-practica/:token',
    component: evaluador_evaluar,
  },
  {
    path: '/error',
    component: error,
  },
  {
    path: '/success',
    component: success,
  },
  {
    name: 'Finalizar Práctica',
    path: '/finalizar_practica/:token',
    component: finish,
    beforeEnter: (to, from, next) => {
      auth_user(to, from, next)
    }
  }
]

const router = new VueRouter({
  routes
})


export default router
