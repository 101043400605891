<template>
  <ValidationProvider
    v-slot="{ errors }"
    :name="name"
    rules="required"
  >
    <v-autocomplete
      filled
      rounded
      :value="value"
      @input="handle"
      :items="items"
      :error-messages="errors"
      :item-value="itemValue"
      :item-text="itemText"
      chips
      :disabled="disabled"
      :label="label"
      multiple
      deletable-chips
    ></v-autocomplete>
    <div class="pr-2">
<v-row justify="end" no-gutters class="pa-0">
      <v-checkbox
        v-model="disabled"
        @change="clickNone"
       :label="noneLabel"
        />
    </v-row>
      </div>
    
  </ValidationProvider>
</template>

<script>

import {ValidationProvider} from "vee-validate";


export default {
  components: {
    ValidationProvider,
  },
  data:() => ({
    disabled: false
  }),

  props: ["value", "items", "label", "name", "itemValue", "itemText", "noneLabel"],
  methods: {
    clickNone(value) {
      if(value){
        this.$emit("input", 'none');
      } else {
        this.$emit("input", []);
      }
      
    },
    handle: function (e) {
      this.$emit("input", e);
    },
  },
};
</script>

<style>

.v-input--selection-controls{
    margin-top: 0px; 
   padding-top: 0px;
}

</style>