<template>
  <ValidationProvider v-slot="{ errors }" :name="name" :rules="rules">
    <v-textarea
      prepend-inner-icon="mdi-form-textbox"
      :value="value"
      :error-messages="errors"
      @input="handle"
      rounded
      no-resize
      filled
      :disabled="disabled"
      :label="label"
      :counter="counter"
      :height="height"
    >
    </v-textarea>
  </ValidationProvider>
</template>

<script>

import { ValidationProvider} from "vee-validate";

export default {
  components: {
    ValidationProvider,
  },
  props: ["value", "disabled", "label", "rules", "name", "counter", "height"],
  methods: {
    handle: function (e) {
      this.$emit("input", e);
    },
  },
};
</script>
