<template>
  <div class="pa-5">
    <v-row align="center" justify="center" class="mb-4 mt-5">
      <serverError
        v-if="error"
        width="256"
        height="256"
        :fill="svgTheme"
      ></serverError>
    </v-row>
    <v-row align="center" justify="center">
      <h2 class="text-button font-weight-bold mb-5 text-center">
        {{ text }}
      </h2>
    </v-row>
  </div>
</template>

<script>
import serverError from "../icons/serverError";

export default {
  components: {
    serverError,
  },
  props: ["text", "error", "success"],
  computed: {
    svgTheme() {
      return this.$vuetify.theme.dark ? "white" : "black";
    },
  },
};
</script>

<style></style>
