<!-- Componente a mostar para una práctica no existente o ya realizada -->
<template>
    <v-container :style="{height: '100%'}">
        <v-row align="center" justify="center">
            <logo></logo>
        </v-row>
        <v-row justify="space-around">
            <div class="pa-5">
                <v-row class="ma-2" align="center" justify="center">
                    <NotFound width="216" height="216" ></NotFound>
                </v-row>
            </div>
        </v-row>


        <v-col cols="12">
            <v-row justify="space-around" :style="{height: '20vh'}">
                <h2 >¡Esta evaluación no se encuentra disponible!</h2>
            </v-row>
        </v-col>

    </v-container>
</template>
  
<script>
import logo from "@/components/icons/logoDI";
import NotFound from '@/components/icons/notFound.vue';

export default {
    components: {
        logo, NotFound
    },
};
</script>
  
<style>
</style>