<template>
<div >
  <Title v-if="title" :text="title" :icon="icon" ></Title>

  <v-card :color="isDarkTheme? 'rgba(0, 0, 0, 0.2)': 'rgba(0, 0, 0, 0.03)'" class="pa-4" :class="noborder? '': 'mb-3'">
      <slot/>
  </v-card>
  </div>
</template>

<script>
import Title from '@/components/content/Title.vue'
export default {
  components: { Title },
  props: ["title", 'noborder', 'icon'],
  computed:{
    isDarkTheme() {
      return this.$vuetify.theme.dark ? true : false;
    },
  }
}
</script>

<style>

</style>