<template>
    <div class="mt-3" ref="ofertaDiv">
        <v-row align="center" justify="center">
            <logo></logo>
        </v-row>
        <v-row align="center" justify="center">
            <layout :width="8">
                <v-card class="pa-5">
                    <v-container grid-list-xs>
                        <h2 class="mb-5">Ingresar una oferta de práctica</h2>

                        <ValidationObserver ref="observer" v-slot="{ invalid }">
                            <v-row>
                                <v-col cols="12" lg="6">
                                    <ValidationProvider
                                        v-slot="{ errors }"
                                        name="Empresa"
                                        rules="required"
                                    >
                                        <v-text-field
                                            v-model="oferta.empresa"
                                            filled
                                            rounded
                                            :counter="50"
                                            :error-messages="errors"
                                            label="Empresa"
                                            required
                                        ></v-text-field>
                                    </ValidationProvider>

                                    <ValidationProvider
                                        v-slot="{ errors }"
                                        name="Email"
                                        rules="required|email|max:30"
                                    >
                                        <v-text-field
                                            v-model="oferta.email_contacto"
                                            filled
                                            rounded
                                            :counter="30"
                                            :error-messages="errors"
                                            label="Email de contacto"
                                            required
                                        ></v-text-field>
                                    </ValidationProvider>

                                    <v-autocomplete
                                        :items="paises"
                                        prepend-inner-icon="domain"
                                        v-model="oferta.pais"
                                        item-value="id"
                                        rounded
                                        item-text="name"
                                        filled
                                        label="País"
                                        @change="cargarRegiones()"
                                    ></v-autocomplete>

                                    <v-autocomplete
                                        :items="regiones"
                                        prepend-inner-icon="domain"
                                        v-model="oferta.region"
                                        item-value="id"
                                        rounded
                                        item-text="name"
                                        filled
                                        label="Region"
                                        @change="cargarCiudades()"
                                    ></v-autocomplete>

                                    <v-autocomplete
                                        :items="ciudades"
                                        prepend-inner-icon="domain"
                                        v-model="oferta.ciudad"
                                        item-value="id"
                                        rounded
                                        item-text="name"
                                        filled
                                        label="Ciudad"
                                    ></v-autocomplete>
                                </v-col>

                                <v-col cols="12" lg="6">
                                    <ValidationProvider
                                        v-slot="{ errors }"
                                        name="Descripcion"
                                        rules="required"
                                    >
                                        <v-textarea
                                            v-model="oferta.mensaje"
                                            filled
                                            rounded
                                            no-resize
                                            :error-messages="errors"
                                            label="Descripción"
                                            required
                                            height="400"
                                        ></v-textarea>
                                    </ValidationProvider>
                                </v-col>
                            </v-row>

                            <ValidationProvider
                                v-slot="{ errors }"
                                name="Captcha"
                                rules="required|max:30"
                            >
                                <v-text-field
                                    v-model="oferta.captcha_value"
                                    filled
                                    rounded
                                    :counter="30"
                                    :error-messages="errors"
                                    label="Captcha"
                                    required
                                ></v-text-field>
                            </ValidationProvider>

                            <img
                                alt="captcha"
                                :src="`data:image/png;base64,${captcha_image}`"
                            />

                            <v-row justify="end">
                                <v-btn
                                    :disabled="invalid"
                                    elevation="0"
                                    class="mr-4"
                                    @click="crearOferta()"
                                    color="primary"
                                    >Ingresar Oferta</v-btn
                                >
                            </v-row>
                        </ValidationObserver>
                    </v-container>
                </v-card>
            </layout>
        </v-row>
    </div>
</template>

<script>

import logo from "@/components/icons/logoDI";
import axios from 'axios';
import {ValidationObserver,ValidationProvider} from "vee-validate";
import Layout from "@/components/content/Layout.vue";

export default {
    components: {
        ValidationProvider,
        ValidationObserver,
        logo,
        Layout,
    },

    data() {
        return {
            captcha_image: null,
            captcha_key: null,
            oferta: {
                email_contacto: null,
                mensaje: null,
                pais: 44,
                region: 2824,
                ciudad: 19111,
            },
        };
    },

    created() {
        this.$store.dispatch("tables/get", { table: "paises" });
        this.$store.dispatch("tables/get", {
            table: "regiones",
            params: "?pais=" + this.oferta.pais,
        });
        this.$store.dispatch("tables/get", {
            table: "ciudades",
            params: "?region=" + this.oferta.region,
        });



    axios({
      url: `${process.env.VUE_APP_API_URL}/captcha/`,
      method: "POST",
    }).then((response) => {
      this.captcha_image = response.data.captcha_image
      this.captcha_key = response.data.captcha_key
    });
},

    computed: {
        content_class() {
            return this.$vuetify.theme.dark ? "content_dark" : "content_light";
        },
        paises() {
            return this.$store.state.tables.paises.items;
        },
        regiones() {
            return this.$store.state.tables.regiones.items;
        },
        ciudades() {
            return this.$store.state.tables.ciudades.items;
        },
    },
    mounted() {
        const el = this.$refs.ofertaDiv;

        if (el) {
            el.scrollIntoView({behavior: 'smooth'});
        }
    },
    methods: {
        crearOferta() {
            this.$store.dispatch("tables/post", {
                endpoint: `ofertas/publicar`,
                table: "ofertas",
                item: {...this.oferta, captcha_key: this.captcha_key},
                redirect: "/success",
            });
        },
        cargarRegiones() {
            this.$store.dispatch("tables/get", {
                table: "regiones",
                params: "?pais=" + this.oferta.pais,
            });
            this.oferta.region = null;
            this.oferta.ciudad = null;
        },
        cargarCiudades() {
            this.$store.dispatch("tables/get", {
                table: "ciudades",
                params: "?region=" + this.oferta.region,
            });
            this.oferta.ciudad = null;
        },
    },
};
</script>

<style>
.content_dark {
    background-color: rgba(255, 255, 255, 0.08);
    border-radius: 20px;
}

.content_light {
    background-color: rgba(0, 0, 0, 0.06);
    border-radius: 20px;
}

*:focus {
    outline: none !important;
}

.icon_menu:hover {
    color: coral;
}
</style>