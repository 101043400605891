<template>
  <v-container>
    <v-row no-gutters v-if="evaluador || date">
      <h3 class="primary--text">
        {{ moment(bitacora.fecha).format("DD [de] MMMM [del] YYYY") }}
      </h3>
    </v-row>

    <v-row no-gutters>
      <p class="font-weight-black">
        {{
          moment(bitacora.hora_inicio, [moment.ISO_8601, "HH:mm"]).format(
            "HH:mm"
          )
        }}
        a
        {{
          moment(bitacora.hora_termino, [moment.ISO_8601, "HH:mm"]).format(
            "HH:mm"
          )
        }}
      </p>
    </v-row>

    <v-row>
      <v-col>
        <dark-card>
          <v-row no-gutters class="pl-2 pb-2">
            <h5>Actividad desarrollada:</h5>
          </v-row>

          <v-row no-gutters>
            <p class="font-italic" style="width:100%">
              {{ bitacora.actividad_desarrollada }}
            </p>
          </v-row>
          <div v-if="!evaluador">
            <v-row no-gutters class="pl-2 pb-2 pt-4">
              <h5>Actividad Pendiente:</h5>
            </v-row>
            <v-row no-gutters>
              <p class="font-italic" style="width:100%">
                {{ bitacora.actividad_pendiente }}
              </p>
            </v-row>
          </div>
        </dark-card>
      </v-col>
      <v-col cols="12" lg="2" v-if="editable && !evaluador">
        <v-row>
          <v-col class="text-center">
            <v-btn class="ma-1" elevation="0" fab @click="editItem(bitacora)">
              <v-icon>edit</v-icon>
            </v-btn>
            <h5>Editar</h5>
          </v-col>
          <v-col class="text-center">
            <v-btn class="ma-1" elevation="0" fab @click="deleteItem(bitacora)">
              <v-icon color="red">delete</v-icon>
            </v-btn>
            <h5>Eliminar</h5>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DarkCard from "../../../content/DarkCard.vue";
export default {
  components: { DarkCard },
  props: ["bitacora", "evaluador", "editable", "date"],
};
</script>

<style scoped>
.noborder {
  border-radius: 0 !important;
}
</style>
