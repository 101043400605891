import { userService } from '../services';
import router from "../router";
import Vue from 'vue'

export const users = {
    namespaced: true,
    state: {
        all_users: {},
        current_user: {}
    },
    actions: {
        getAllUsers({ commit }) {
            commit('getAllUsersRequest');

            userService.getAllUsers()
                .then(
                    all_users => commit('getAllUsersSuccess', all_users),
                    error => commit('getAllUsersFailure', error)
                );
        },
        edit({ commit }, params) {
            commit('getCurrentUserRequest');

            userService.editUser(params.item)
                .then(
                    user => {
                        commit('getCurrentUserSuccess', user);
                        if (params.reload) {
                            return router.go();
                          } else {
                            if (params.redirect) {
                              return router.push(params.redirect);
                            }
                          }
                    },
                    error => {
                        commit('getCurrentUserFailure', error)
                    }
                );
        },
        getCurrentUser({ commit }) {
            commit('getCurrentUserRequest');

            userService.getCurrentUser()
                .then(
                    user => commit('getCurrentUserSuccess', user),
                    error => {
                        commit('getCurrentUserFailure', error)
                    }
                );
        }
    },
    mutations: {
        getAllUsersRequest(state) {
            state.all_users = { loading: true };
        },
        getAllUsersSuccess(state, all_users) {
            state.all_users = { items: all_users, loading: false };
        },
        getAllUsersFailure(state, error) {
            state.all_users = { error, loading: false};
        },
        getCurrentUserRequest(state) {
            state.current_user = { loading: true };
        },
        getCurrentUserSuccess(state, user) {
            state.current_user = user;
        },
        getCurrentUserFailure(state, error) {
            state.current_user = { error };
        }
    }
}
