<template>
  <v-container>
    <v-row no-gutters>
      <v-col :cols="12">
        <DarkCard title="Convalidación">
          <div>
            <v-row no-gutters>
              <v-col>
                <h5 class="mb-0 font-weight-bold">Tareas realizadas:</h5>
                <p class="mb-0 font-italic">{{ practica.tareas_realizadas }}</p>
              </v-col>
            </v-row>
          </div>
        </DarkCard>
      </v-col>
      <v-col :cols="12" class="mt-3">
        <v-row justify="start">
          <div v-if="is_admin">
            <ButtonPrimary 
              :disabled="button_disabled(practica.curriculum)"
              :href="href_url(practica.curriculum)" 
              target="_blank"  
              >{{textos_archivos.curriculum_btn}}</ButtonPrimary>
            <ButtonPrimary 
              :disabled="button_disabled(practica.boletas)"
              :href="href_url(practica.boletas)" 
              target="_blank"  
              >{{textos_archivos.boletas_btn}}</ButtonPrimary>
            <ButtonPrimary 
              :disabled="button_disabled(practica.carta)"
              :href="href_url(practica.carta)" 
              target="_blank"  
              >{{textos_archivos.carta_btn}}</ButtonPrimary>
          </div>

          <div v-else>
            <ButtonPrimary
              :disabled="button_disabled(practica.curriculum)"
              :onClick="
                () =>
                  download( practica.curriculum,
                    practica.alumno.rol + '_' + textos_archivos.curriculum_file +'.pdf'
                  )
              "
              >{{textos_archivos.curriculum_btn}}</ButtonPrimary>
            <ButtonPrimary
            :disabled="button_disabled(practica.boletas)"
              :onClick="
                () =>
                  download( practica.boletas,
                    practica.alumno.rol + '_' + textos_archivos.boletas_file +'.pdf'
                  )
              "
              >{{textos_archivos.boletas_btn}}</ButtonPrimary>
            <ButtonPrimary
            :disabled="button_disabled(practica.carta)"
              :onClick="
                () =>
                  download( practica.carta,
                    practica.alumno.rol + '_' + textos_archivos.carta_file +'.pdf'
                  )
              "
              >{{textos_archivos.carta_btn}}</ButtonPrimary>
          </div>
        </v-row>
        <row v-if="is_admin">
          Si un botón no está disponible es porque la práctica se ingresó previo a la implementación del archivo
        </row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DarkCard from "@/components/content/DarkCard.vue";
import axios from "axios";
import ButtonPrimary from "@/components/forms/ButtonPrimary.vue";

export default {
  components: {
    DarkCard,
    ButtonPrimary,
  },
  props: ["practica"],
  computed: {
    is_admin() {
      return this.$store.state.authentication.user.is_staff;
    },
    textos_archivos() {
      if (this.practica.categoria == "Emprendimiento") {
        return {
          curriculum_btn: "Curriculum y Bitacoras",
          curriculum_file: "curriculum",
          boletas_btn: "Plan y Modelo",
          boletas_file: "plan_operacional",
          carta_btn: "Página web y RRSS",
          carta_file: "web_rrss",
        };
      } else {
        return {
          curriculum_btn: "Curriculum",
          curriculum_file: "curriculum",
          boletas_btn: "Boletas",
          boletas_file: "boletas",
          carta_btn: "Carta",
          carta_file: "carta",
        };
      }
      return this.textos_archivos;
    },
  },
  methods: {

    href_url(url) {
      return `${process.env.VUE_APP_MEDIA_URL}/${url}`;
    },
    button_disabled(url) {
      return url === null;
    },

    download(url, name) {
      axios({
        url: `${process.env.VUE_APP_MEDIA_URL}/${url}`,
        method: "GET",
        responseType: "blob",
      }).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", name);
        document.body.appendChild(fileLink);

        fileLink.click();
      });
    },
  },
};
</script>

<style></style>
