import { extend, setInteractionMode } from "vee-validate";
import { required, email, length, min, max } from "vee-validate/dist/rules";
import {  validate, clean, format, getCheckDigit } from "rut.js"

setInteractionMode("eager");

extend("required", {
    ...required,
    message: "{_field_} no puede estar vacío.",
});

extend("length", {
    ...length,
});

extend("min", {
    ...min,
    message: "{_field_} es demasiada cortas.",
});

extend("max", {
    ...max,
    message: (field_name, object) => {
        return `${object._field_} debe ser máximo de ${object.length} caractéres. Tienes ${object._value_.length}`
    }
});

extend("email", {
    ...email,
    message: "{_field_} no tiene el formato correcto.",
});

extend("rutempresa", {
    message: "El RUT no tiene formato correcto (Ej. XX.XXX.XXX-X)",
    validate: value => {
        if (value == "00.000.000-0") return true // El rut de ceros para practica de emprendimiento no es valido en rut.js
        return validate(value)
    }
})
extend("rut", {
    message: "El RUT no tiene formato correcto (Ej. XX.XXX.XXX-X)",
    validate: value => {
        return validate(value)
    }
})

extend("rol", {
    message: "El ROL no tiene formato correcto (Ej. XXXXXXXXX-X)",
    validate: value => {
        // v1: return /^[0-9]+[-|-]{1}[0-9kK]{1}$/.test(value)
        // El Rol tiene el mismo tipo de validación que un rut,
        // pero se agrega por separado para poder tener un mensaje de error distinto y
        // entendimiento en el codigo
        return validate(value) 
    }
})

extend("telefono", {
    message: "El telefono no tiene formato correcto (Ej. +569XXXXXXXX, 9 XXXX XXXX o 22 XXXXXXX)",
    validate: value => {
      // v1: return /^(\+?56)?(\s?)(0?9)(\s?)[9876543]\d{7}$/.test(value)
      // v2: return /^(\+?56)?(\s?)(22(\s?)[1-9]{1}[0-9]\d{5}|(0?9)(\s?)[9876543]\d{7})$/.test(value)
      return /^\+?[1-9]\d{7,17}$/.test(value)
    }
})

extend("size", {
    message: "Archivo no puede pesar mas de 10MB",
    validate: file => {
      if(file.size < 10000000 )
          return true
      else{
          return false
      }
    }
})

extend("extensionDocumentos", {
    message: "Solo se aceptan archivos ZIP, PDF, DOC o DOCX",
    validate: file => {
      if(file.type =="application/pdf" || file.type == "application/doc" || file.type == "application/docx" || file.type == "application/x-zip-compressed")
          return true
      else{
          return false
      }
    }
})
extend("extensionOrganigrama", {
    message: "Solo se aceptan archivos PNG, JPG, JPEG o PDF",
    validate: file => {
      if(file.type =="image/png" || file.type == "image/jpg" || file.type == "image/jpeg" || file.type == "application/pdf")
          return true
      else{
          return false
      }
    }
})

extend('numero', {
    message: "Debe ser un número (Ej. 100.000 o 100000)",
    validate: value => {
    
        return /^(\d{1,3}(?:\.\d{3})*|\d+)$/.test(value)
    }
})





