<template>
    <div>
        <pre style="font-size: 18px; text-align: justify" v-html="text"/>

    </div>
</template>

<script>
export default {
    props: ['tipoPractica', 'categoria'],


    data: () => ({
        text: "Sin requisitos ingresados.",
        requisitos: {
        comun: `Para iniciar una práctica necesitas haberte contactado con una empresa y haber sido aceptado para realizar ahí tu práctica.

Una vez ya conseguida debes obtener los siguientes datos:
<ul>
    <li class="mb-0"><strong>Nombre, RUT y dirección</strong> de la empresa.</li>
    <li class="mb-0"><strong>Nombre, e-mail y telefono</strong> de contacto de alguna persona de la empresa la cual será el supervisor o supervisora que valide que estarás realizando tu práctica allí y posteriormente evaluará tu desempeño</li>
</ul>`,


            investigativa_comun: `Para iniciar una práctica investigativa, el proceso es el mismo que con una empresa, necesitas haberte contactado con una institución y haber sido aceptado para realizar ahí tu práctica.

Una vez ya conseguida debes obtener los siguientes datos:
<ul>
    <li class="mb-0"><strong>Nombre, RUT y dirección</strong> de la institución.</li>
    <li class="mb-0"><strong>Nombre, e-mail y telefono</strong> de contacto de alguna persona de la institución la cual será el supervisor o supervisora que valide que estarás realizando tu práctica allí y posteriormente evaluará tu desempeño</li>
</ul>`,

            investigastiva_conv: `El trabajo tuvo que haber sido desarrollado en <strong>UNA</strong> sola institución para convalidar.

Es necesario tener información de la instituciónn en la que se realizó, datos como <strong>Nombre, RUT y dirección</strong>. (Si fue realizada en el extranjero puede poner como RUT 11.111.111-1)

Algunos documentos que debes tener a mano son:
<ul class="lista-espaciada">
  <li class="mb-0">Certificado de imposiciones previsionales del tiempo que se trabajó, en caso de haber emitido boletas, presentar copia de estas en donde se observen los datos de la empresa en la cual trabajaste.</li>
  <li class="mb-0">Un Currículo que detalle las actividades realizadas en aquella institución de no más de una pagina. (Si firmaste un NDA mencionar solo generalidades)</li>
  <li class="mb-0">Una carta de su Jefe o Supervisor directo en la instituci;ón en que éste avala la calidad del aporte realizado a la investigación.</li>
</ul>`,


            trabajo_profesional: `El trabajo tuvo que haber sido desarrollado en <strong>UNA</strong> sola empresa durante el <strong><u>triple del tiempo</u></strong> a convalidar.

Es necesario tener información de la empresa tal como <strong>Nombre, RUT y dirección</strong>.

Algunos documentos que debes tener a mano son:
<ul class="lista-espaciada">
  <li class="mb-0">Certificado de imposiciones previsionales del tiempo que se trabajó, en caso de haber emitido boletas, presentar copia de estas en donde se observen los datos de la empresa en la cual trabajaste.</li>
  <li class="mb-0">Un Currículo que detalle las actividades realizadas en aquella empresa de no más de una pagina.</li>
  <li class="mb-0">Una carta de su Jefe o Supervisor directo en la Empresa en que éste avala la calidad del aporte realizado.</li>
</ul>`,


            trabajo_social: `L convalidación  tiene como objetivo aportar a la comunidad, o bien, reconocer un trabajo remunerado que lo ayude a solventar su situación socioeconómica complicada

Algunos documentos que debes tener a mano son:
<ul class="lista-espaciada">
  <li class="mb-0">Certificado de imposiciones previsionales del tiempo que se trabajó, en caso de haber emitido boletas, presentar copia de estas en donde se observen los datos de la empresa en la cual trabajaste.</li>
  <li class="mb-0">Un Currículo que detalle las actividades realizadas en aquella empresa de no más de una pagina.</li>
  <li class="mb-0">Una carta de su Jefe o Supervisor directo en la Empresa en que éste avala la calidad del aporte realizado.</li>
</ul>
Además debes indicar la problematica de la situación economica en el campo de consideraciones (Este dato es totalmente privado y solamente el administrador y profesor de práctica tendrán acceso a verlo).
`,


            emprendimiento: `La convalidación abarta tanto el planteamiento como el desarrollo de un Proyecto de Emprendimiento.

Dado que en el proximo paso se exigirá un RUT, si su emprendimiento se encuentra registrado en el SII ingrese el rut que tiene, si el proyecto no tiene rut ingrese 00.000.000-0 para indicar que es de emprendimiento.

Algunos documentos que debes tener a mano son:
<ul class="lista-espaciada">
  <li class="mb-0">Un Currículo que incluya una bitácora detallada del trabajo realizado.</li>
  <li class="mb-0">Un documento que incluya el Plan Operacional (procesos claves de Producción) y Modelo de Negocios (incluye Ingresos y Egresos).</li>
  <li class="mb-0">Un documento con links a página Web y RRSS del emprendimiento con indicadores de tráfico y definiciones como son Misión, Visión y Valores.</li>
</ul>`,
        }
    }),
    watch: {
        categoria(newValue, oldValue){             
            if (this.tipoPractica == 0){
                switch (this.categoria) {
                    case 1:
                        this.text = this.requisitos.comun;
                        break;
                    case 3:
                        this.text = this.requisitos.investigativa_comun;
                        break;
                }
            }else {
                switch (this.categoria) {
                    case 2:
                        this.text = this.requisitos.trabajo_profesional;
                        break;
                    case 3:
                        this.text = this.requisitos.investigastiva_conv;
                        break;
                    case 4:
                        this.text = this.requisitos.trabajo_social;
                        break;
                    case 5:
                        this.text = this.requisitos.emprendimiento;
                        break;
                }
            }
        
    }
}
}

</script>