<template>
<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
  viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
<g>
 <g>
  <g>
   <path class="st0" d="M499.1,295.5H12.9c-7.1,0-12.9-5.8-12.9-12.9v-73.2c0-7.1,5.8-12.9,12.9-12.9h486.1
    c7.1,0,12.9,5.8,12.9,12.9v73.2C512,289.7,506.2,295.5,499.1,295.5z"/>
   <path class="st1" d="M499.1,196.4h-80.7v99.1h80.7c7.1,0,12.9-5.8,12.9-12.9v-73.2C512,202.2,506.2,196.4,499.1,196.4
    L499.1,196.4z"/>
   <path class="st2" d="M512,209.4v73.2c0,7.1-5.8,12.9-12.9,12.9h-38.8c7.1,0,12.9-5.8,12.9-12.9v-73.2c0-7.1-5.8-12.9-12.9-12.9
    h38.8C506.2,196.4,512,202.2,512,209.4z"/>
  </g>
  <g>
   <g>
    <g>
     <path class="st3" d="M433.2,465.8c-6.9,6.9-18.1,6.9-25,0L276.8,334.3l25-25l131.5,131.5C440.1,447.7,440.1,458.9,433.2,465.8z
      "/>
     <g>
      <path class="st0" d="M314.3,371.9l93.9,93.9c6.9,6.9,18.1,6.9,25,0s6.9-18.1,0-25l-93.9-93.9L314.3,371.9z"/>
     </g>
    </g>
    <circle class="st4" cx="211.8" cy="242.5" r="126.8"/>
    <g>
     <path class="st5" d="M190.7,153c-19,4.5-35.8,14.8-48.2,29.1c-5-5.2-8-12.2-8-19.9c0-15.9,12.9-28.8,28.8-28.8
      C176.1,133.3,186.9,141.6,190.7,153L190.7,153z"/>
     <path class="st5" d="M338.6,242.5c0,70-56.8,126.8-126.8,126.8c-5.3,0-10.5-0.3-15.6-1c62.6-7.7,111.2-61.1,111.2-125.8
      c0-64.7-48.5-118.1-111.2-125.8c5.1-0.6,10.3-1,15.6-1C281.8,115.7,338.6,172.4,338.6,242.5L338.6,242.5z"/>
    </g>
   </g>
   <circle class="st6" cx="211.8" cy="242.5" r="91.9"/>
   <g>
    <path class="st7" d="M192.2,162.2c0,15.9-12.9,28.8-28.8,28.8c-8.2,0-15.6-3.4-20.8-8.9c12.4-14.3,29.2-24.6,48.2-29.1
     C191.7,155.9,192.2,159,192.2,162.2L192.2,162.2z"/>
    <path class="st8" d="M303.7,242.5c0,50.8-41.1,91.9-91.9,91.9c-5.4,0-10.7-0.5-15.9-1.4c43.2-7.5,76-45.2,76-90.5
     c0-45.3-32.8-83-76-90.5c5.2-0.9,10.5-1.4,15.9-1.4C262.6,150.6,303.7,191.7,303.7,242.5L303.7,242.5z"/>
   </g>
  </g>
  <path class="st9" d="M483.9,227.3c-7.9-7.9-20.7-7.9-28.6,0c-5.9,5.9-7.4,14.7-4.4,22l-8.1,8.1c-3,3-3,7.9,0,10.9
   c1.5,1.5,3.5,2.3,5.5,2.3s4-0.8,5.5-2.3l8.1-8.1c2.5,1,5.1,1.5,7.7,1.5c5.2,0,10.3-2,14.3-5.9l0,0
   C491.7,248,491.7,235.2,483.9,227.3L483.9,227.3z M472.9,244.9c-1.9,1.9-4.9,1.9-6.7,0c-1.9-1.9-1.9-4.9,0-6.7
   c0.9-0.9,2.1-1.4,3.4-1.4s2.4,0.5,3.4,1.4C474.8,240.1,474.8,243.1,472.9,244.9L472.9,244.9z"/>
  <g>
   <path class="st10" d="M81.2,121.9c-3-3-7.9-3-10.9,0l-6.1,6.1l-6.1-6.1c-3-3-7.9-3-10.9,0c-3,3-3,7.9,0,10.9l6.1,6.1l-6.1,6.1
    c-3,3-3,7.9,0,10.9c1.5,1.5,3.5,2.3,5.5,2.3s4-0.8,5.5-2.3l6.1-6.1l6.1,6.1c1.5,1.5,3.5,2.3,5.5,2.3s4-0.8,5.5-2.3
    c3-3,3-7.9,0-10.9L75,139l6.1-6.1C84.2,129.8,84.2,124.9,81.2,121.9z"/>
   <path class="st11" d="M382.1,150.6l6.1-6.1c3-3,3-7.9,0-10.9c-3-3-7.9-3-10.9,0l-6.1,6.1l-6.1-6.1c-3-3-7.9-3-10.9,0
    c-3,3-3,7.9,0,10.9l6.1,6.1l-6.1,6.1c-3,3-3,7.9,0,10.9c1.5,1.5,3.5,2.3,5.5,2.3s4-0.8,5.5-2.3l6.1-6.1l6.1,6.1
    c1.5,1.5,3.5,2.3,5.5,2.3s4-0.8,5.5-2.3c3-3,3-7.9,0-10.9L382.1,150.6z"/>
  </g>
  <g>
   <circle class="st12" cx="109.9" cy="66.5" r="25.5"/>
   <path class="st13" d="M135.4,66.5c0,14.1-11.4,25.5-25.5,25.5c-4.7,0-9.1-1.3-12.9-3.5c10-2.3,17.5-11.3,17.5-22
    c0-10.7-7.4-19.7-17.4-22c3.8-2.2,8.2-3.5,12.9-3.5C124,41,135.4,52.5,135.4,66.5z"/>
  </g>
  <g>
   <circle class="st12" cx="324.4" cy="89.4" r="22.9"/>
   <path class="st13" d="M347.3,89.4c0,12.7-10.3,22.9-22.9,22.9c-4.2,0-8.2-1.2-11.6-3.2c9-2.1,15.7-10.1,15.7-19.7
    c0-9.6-6.7-17.7-15.7-19.7c3.4-2,7.4-3.2,11.6-3.2C337.1,66.5,347.3,76.8,347.3,89.4L347.3,89.4z"/>
  </g>
  <g>
   <path class="st10" d="M134.5,139c0-15.9,12.9-28.8,28.8-28.8s28.8,12.9,28.8,28.8s-12.9,28.8-28.8,28.8S134.5,154.9,134.5,139"/>
   <path class="st14" d="M148.7,163.8c11.3-2.6,19.8-12.7,19.8-24.9c0-12.1-8.4-22.2-19.7-24.9c4.3-2.5,9.3-4,14.6-4
    c15.9,0,28.8,12.9,28.8,28.8c0,15.9-12.9,28.9-28.8,28.9C158,167.8,153,166.4,148.7,163.8"/>
  </g>
 </g>
 <g>
  <path class="st3" d="M228.4,271.3c-1.8,0-3.6-0.6-5-1.9c-3.5-3-8.4-4.7-13.6-4.7c0,0,0,0-0.1,0c-5.1,0-10.1,1.7-13.5,4.6
   c-3.3,2.8-8.1,2.3-10.9-0.9c-2.8-3.3-2.3-8.1,0.9-10.9c6.3-5.3,14.6-8.2,23.5-8.2h0.1c8.9,0,17.3,3,23.6,8.4
   c3.2,2.8,3.6,7.6,0.9,10.9C232.7,270.3,230.6,271.3,228.4,271.3L228.4,271.3z"/>
  <g>
   <g>
    <path class="st3" d="M168.8,236.1c-4.3,0-7.8-3.5-7.8-7.7s3.4-7.7,7.7-7.7h0.1c4.3,0,7.7,3.5,7.7,7.7S173.1,236.1,168.8,236.1z"
     />
   </g>
  </g>
 </g>
</g>
<path class="st3" d="M252.9,236.1c-4.3,0-7.8-3.5-7.8-7.7s3.4-7.7,7.7-7.7h0.1c4.3,0,7.7,3.5,7.7,7.7S257.2,236.1,252.9,236.1
 L252.9,236.1z"/>
</svg>

</template>

<script>
export default {

}
</script>

<style>
.st0{fill:#D6D6D6;}
	.st1{fill:#756F6F;}
	.st2{fill:#5B5555;}
	.st3{fill:#E65100;}
	.st4{fill:#ED7608;}
	.st5{fill:#E25E24;}
	.st6{fill:#E0BCB1;}
	.st7{fill:#A0A0A0;}
	.st8{fill:#DBACA1;}
	.st9{fill:#E0D3CE;}
	.st10{fill:#BCEA73;}
	.st11{fill:#FFA05F;}
	.st12{fill:#ED7F1D;}
	.st13{fill:#FF6B06;}
	.st14{fill:#99D53B;}
</style>