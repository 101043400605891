<template>
    <Layout :width="12">
        <v-row style="margin-top: -3rem"  align="center" justify="center">
            <logo></logo>
        </v-row>
        <v-row no-gutters justify="center" >
            <v-col class="text-center" cols="12" lg="">
                <v-card class="pa-2 py-1 mb-4">
                    <v-container grid-list-xs>
                        <h2 class="mb-4">Iniciar Sesión</h2>

                        <v-btn
                            
                            elevation="0"
                            class="mr-4 mb-5 "
                            type="submit"
                            color="primary"
                            @click="onSubmit()"
                            >Acceder con cuenta DI</v-btn
                        >
                    </v-container>
                </v-card>

               
                <v-card class="pa-5 mb-4 mx-7">
                    <v-container grid-list-xs>
                        <h4 class="mb-3">
                            ¿Estás buscando un practicante? ¡Ingresa una
                            oferta de práctica!
                        </h4>
                        <v-btn
                            elevation="0"
                            class="mr-4"
                            color="primary"
                            @click="oferta_url()"
                            >CREAR OFERTA</v-btn
                        >
                    </v-container>
                </v-card>
            
            
                <noticias class=" mb-4 mx-7"/>
            
            </v-col>
            <v-col class="d-none d-sm-flex" lg="7">
                <work-2></work-2>
            </v-col>
        </v-row>
        <p class="text-center ma-0" :style="{ fontSize: '18px' }">
            Sistema de Prácticas - 2022
        </p>
        <p class="text-center ma-0" :style="{ fontSize: '16px' }">
            Contacto: ayud-pra@inf.utfsm.cl
        </p>
        <p class="text-center ma-0" :style="{ fontSize: '12px' }">
            Desarrollado por Bastían Quezada
        </p>
    </Layout>
</template>

<script>
import Work2 from "../../components/icons/Work2.vue";
import Layout from "@/components/content/Layout.vue";
import logo from "@/components/icons/logoDI";
import noticias from "@/views/alumno/Noticias/noticias.vue";

export default {
    components: {
        logo: logo,
        noticias: noticias,
        Work2,
        Layout
    },
    data() {
        return {
            // dirtyusername: null,
            // dirtypassword: null,
            username: "",
            password: "",
            // submitted: false,
        };
    },
    computed: {
        loggingIn() {
            return this.$store.state.authentication.status.loggingIn;
        },
    },
    methods: {
        oferta_url() {
            this.$router.push("/oferta");
        },
        onSubmit() {
            this.$keycloak.login();
        },
    },
};
</script>

<style>
label.theme--dark + input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: #fff !important; /* inherit only works in Safari */
    -webkit-text-size-adjust: inherit !important;
}

label.theme--light + input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: #000 !important; /* inherit only works in Safari */
    -webkit-text-size-adjust: inherit !important;
}

/* When dark theme, then make keychain icon white */
label.theme--dark + input::-webkit-credentials-auto-fill-button {
    background-color: #fff !important;
}

/* Hide credentials-auto-fill-button in password inputs, only visible on other inputs */
input[type="password"]::-webkit-credentials-auto-fill-button {
    visibility: hidden;
    display: none !important;
    pointer-events: none;
}
</style>
