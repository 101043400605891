import { tablesService } from "../services";
import router from "../router";

export const tables = {
  namespaced: true,
  state: {
    loading: false,
    empresas: {},
    paises: {},
    regiones: {},
    ciudades: {},
    carreras: {},
    asignaturas: {},
    tareas: {},
    beneficios: {},
    vias: {},
    modos_practica: {},
    tipos_practica: {},
    campus: {},
    practicas: {},
    practica: {},
    confirmation_by_token: {},
    confirm_practica: {},
    bitacoras: { Profesional: {items:[]}, Industrial: {items:[]} },
    tiempo_bitacoras: {},
    informe_practica: {},
    evaluation_by_token: {},
    lenguajes_programacion: {},
    bases_de_datos: {},
    ide: {},
    librerias: {},
    evaluar: {},
    new_practica: {},
    sucursales: {},
    practicas_por_estado: {},
    practicas_aprobadas: {},
    practica_pdf: {},
    practica_revision: {},
    tipos_solicitud: {},
    lista_practicas: {},
    ofertas: {},
    ofertas_admin: {},
    solicitudes: {},
    solicitudes_admin: {},
    curriculum: {},
    estadistica_practica: {},
    estadistica_informe: {},
    practica_convalidada: {},
    log: {},
    logs: {},
    alumnos: {},
    perfil: {},
    estado_practica: {},
    empresas_alumno: {},
    empresas_detail: {},
    email_confirmacion: {},
    email_manual: {},
    email_evaluacion: {},

  },
  actions: {
    get({ dispatch, commit }, params) {
      commit("getRequest", { endpoint: params.endpoint, table: params.table, subtable: params.subtable });

      return tablesService.getService(params.endpoint? params.endpoint: params.table, params.params).then(
        (data) => {
          commit("getSuccess", { table: params.table, subtable: params.subtable, data: data });
          return data;
        },
        (error) => {
          commit("getFailure", { table: params.table, subtable: params.subtable, error: error });
          if (params.error_message) {
            dispatch("alert/error", params.error_message, { root: true });
          }
        }
      );
    },
    getDocument({ dispatch, commit }, params) {
      commit("getDocumentRequest", { table: params.table });

      tablesService.getDocument(params.endpoint? params.endpoint: params.table, params.params).then(
        (data) => {
          commit("getDocumentSuccess", { table: params.table, data: data });
          
        },
        (error) => {
          commit("getDocumentFailure", { table: params.table, error: error });
          if (params.error_message) {
            dispatch("alert/error", params.error_message, { root: true });
          }
        }
      );
    },
    new({ dispatch, commit }, params) {
      commit("newRequest", { table: params.table });
      // Se retorna la promesa para poder espera que la request se complete cuando se llama al dispatch
      return tablesService.newService(params.endpoint? params.endpoint: params.table, params.item).then(
        (data) => {
          commit("newSuccess", {
            table: params.table,
            subtable: params.subtable,
            data: data,
            
          });
          if (params.success_message) {
            dispatch("alert/success", params.success_message, { root: true });
          }
          return data; // Devuelve los datos
        },
        (error) => {
          commit("newFailure", error);

          if (error.response.data.message) {   // Mostrar error de la API en caso de que exista
            dispatch("alert/error", error.response.data.message, { root: true });
          }else if (params.error_message) {
            dispatch("alert/error", params.error_message, { root: true });
          }
          return Promise.reject(error);        }
      );
    },
    edit({ dispatch, commit }, params) {
      commit("editRequest", { table: params.table });
      return tablesService.editService(params.endpoint? params.endpoint: params.table, params.id, params.item).then(
        (data) => {
          commit("editSuccess", {
            table: params.table,
            data: data,
            subtable: params.subtable,
            editedIndex: params.id,
            
          });
          if (params.reload) {
            return router.go();
          } else if (params.redirect) {
            return router.push(params.redirect);
          }
          if (params.success_message) {
            dispatch("alert/success", params.success_message, {
              root: true,
            });
          }
          
          
        },
        (error) => {
          commit("editFailure", error);
          if (params.error_message) {
            dispatch("alert/error", params.error_message, { root: true });
          }
        }
      );
    },
    delete({ dispatch, commit }, params) {
      commit("deleteRequest", { table: params.table });

      tablesService.deleteService(params.endpoint? params.endpoint: params.table, params.id).then(
        (data) => {
          commit("deleteSuccess", {
            table: params.table,
            subtable: params.subtable,
            deleteIndex: params.id,
            invisible: params.invisible
          });
          
          if (params.reload) {
            return router.go();
          } else {
            if (params.redirect) {
              return router.push(params.redirect);
            } else {
              if (params.success_message) {
                dispatch("alert/success", params.success_message, {
                  root: true,
                });
              }
            }
          }
        },
        (error) => {
      
          commit("deleteFailure", error);
          if (params.error_message) {
            dispatch("alert/error", params.error_message, { root: true });
          }
        }
      );
    },
    post({ dispatch, commit }, params) {
      commit("postRequest", { table: params.table });

      function postService(params) {
        tablesService.postService(params.endpoint? params.endpoint: params.table, params.item, params.params, params.files).then(
          (data) => {
            commit("postSuccess", { table: params.table });
            if (params.reload) {
              return router.go();
            } else {
              if (params.redirect) {
                return router.push(params.redirect);
              } else {
                if (params.success_message) {
                  dispatch("alert/success", params.success_message, {
                    root: true,
                  });
                }
              }
            }
          },
          (error) => {
            commit("postFailure", { table: params.table, error: error });
            if (params.error_message) {
              dispatch("alert/error", params.error_message, { root: true });
            }
          }
        );
      }

      // Se agrega la condición de retornar o no la promesa, en caso de querer esperar a que se complete la request
      // para realizar alguna accion extra como eliminar un item de la tabla, por ejemplo en 
      // solicitudes.vue al aprobar una solicitud se hace un commit extra al terminar la request
      if (params.returnRequest) {
        return postService(params);
      }else {
        postService(params);
      }


    },
  },
  mutations: {
    getRequest(state, params) {
      if(params.subtable){
        state[params.table] = {...state[params.table],[params.subtable]: {loading:true, error: false}  }
      } else if (params.table) {
        state[params.table].loading = true;
        state[params.table].error = false;
      }
      
    },
    getSuccess(state, params) {

      if(params.subtable){
        state[params.table] = {...state[params.table],[params.subtable]: {items: params.data, loading:true, error: false}  }
      } else if (params.table) {
        state[params.table] = {
          items: params.data,
          error: false,
          loading: false,
        };
      }

      
    },
    getFailure(state, params) {
      if(params.subtable){
        state[params.table] = {...state[params.table], [params.subtable]: {items: [], loading:true, error: false}  }
      } else {
        state[params.table] = {
          items: state[params.table].items,
          error: true,
          loading: false,
        };
      }

      
    },

    deleteRequest(state, params) {
      state["loading"] = true;
    },
    deleteSuccess(state, params) {
      state["loading"] = false;

      if(!params.invisible){
        if(params.subtable){
          state[params.table][params.subtable].items.forEach((value, index) => {
            if (value.id == params.deleteIndex) {
                state[params.table][params.subtable].items.splice(index, 1);
            }
          });
  
        } else {
          state[params.table].items.forEach((value, index) => {
            if (value.id == params.deleteIndex) {
                state[params.table].items.splice(index, 1);
            }
          });
        }
      }
      
      
    },

    deleteFailure(state, params) {
      state["loading"] = false;
    },

    newRequest(state, params) {
      state["loading"] = true;
    },
    newSuccess(state, params) {
      state["loading"] = false;
      if(params.subtable){
        state[params.table][params.subtable].items.push(params.data);
      } else {
        state[params.table].items.push(params.data);
      }
      
    },
    newFailure(state, params) {
      state["loading"] = false;
      try {
        state[params.table] = {
          items: state[params.table].items,
          error: params.error,
          loading: false,
        };
      } catch (error) {
        state[params.table] = { error: params.error, loading: false };
      }
    },

    editRequest(state, params) {
      state["loading"] = true;
    },
    editSuccess(state, params) {
      state["loading"] = false;

      if(params.subtable){
        state[params.table][params.subtable].items.forEach((value, index) => {
          if (value.id == params.editedIndex) {
              Object.assign(state[params.table][params.subtable].items[index], params.data);
          }
        });
      } else if (params.table) {
        state[params.table].items.forEach((value, index) => {
          if (value.id == params.editedIndex) {
              Object.assign(state[params.table].items[index], params.data);
          }
        });
      }

      
    },
    editFailure(state, params) {
      state["loading"] = false;

      state[params.table] = {
        items: state[params.table].items,
        error: params.error,
        loading: false,
      };
    },

    postRequest(state, params) {
      state["loading"] = true;
    },
    postSuccess(state, params) {
      state["loading"] = false;
    },
    postFailure(state, params) {
      state["loading"] = false;
    },

    getDocumentRequest(state, params) {
      state["loading"] = true;
    },
    getDocumentSuccess(state, params) {
      state["loading"] = false;
    },
    getDocumentFailure(state, params) {
      state["loading"] = false;
    },
  },
};
