<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 20 500 450">
        <g id="freepik--Floor--inject-62">
            <path
                id="freepik--floor--inject-62"
                d="M407.33,398.84c-87.42,50.22-229.15,50.22-316.57,0S3.35,267.21,90.76,217s229.16-50.21,316.57,0S494.75,348.63,407.33,398.84Z"
                style="fill: rgba(0, 0, 0, 0.2)"
            ></path>
        </g>
        <g id="freepik--Shadows--inject-62">
            <path
                id="freepik--Shadow--inject-62"
                d="M274.58,307.92c-18.37-10.6-48.15-10.6-66.52,0-15.57,9-17.93,22.71-7.11,33.15a7.3,7.3,0,0,0-1.75.71l-58,33.5c-2.46,1.42-2.46,3.73,0,5.15l3.64,2.1a9.17,9.17,0,0,0,8.77-.25l54-35.37a4.46,4.46,0,0,0,.62-.49c18.38,10.51,48,10.48,66.34-.09S293,318.53,274.58,307.92Z"
                style="fill: rgba(0, 0, 0, 0.3)"
            ></path>
            <path
                id="freepik--shadow--inject-62"
                d="M403.7,269.35l-73.92,42.77a6.57,6.57,0,0,1-5.95,0L195.24,237.87c-1.64-.94-1.64-2.48,0-3.43l73.92-42.77a6.57,6.57,0,0,1,5.95,0L403.7,265.92C405.34,266.86,405.34,268.4,403.7,269.35Z"
                style="fill: rgba(0, 0, 0, 0.3)"
            ></path>
            <path
                id="freepik--shadow--inject-62"
                d="M74.53,350.24,54.76,338.82c-2.39-1.38-2.39-3.62,0-5l137.57-79.43a9.57,9.57,0,0,1,8.66,0l19.78,11.42c2.39,1.38,2.39,3.62,0,5L83.19,350.24A9.57,9.57,0,0,1,74.53,350.24Z"
                style="fill: rgba(0, 0, 0, 0.3)"
            ></path>
            <ellipse
                id="freepik--shadow--inject-62"
                cx="403.9"
                cy="352.98"
                rx="36.53"
                ry="19.96"
                style="fill: rgba(0, 0, 0, 0.3)"
            ></ellipse>
        </g>
        <g id="freepik--Plants--inject-62">
            <g id="freepik--plants--inject-62">
                <path
                    d="M419.89,333.16c.35-4.43,4.29-13.34,9.35-18.72s12.61-9,17.6-6.83c4.65,2,3.63,8.25-2.51,11.93s-15.26,8.21-18.39,15.18l-6.05,8.38Z"
                    style="fill: #f27531"
                ></path>
                <path
                    d="M419.89,333.16c.35-4.43,4.29-13.34,9.35-18.72s12.61-9,17.6-6.83c4.65,2,3.63,8.25-2.51,11.93s-15.26,8.21-18.39,15.18l-6.05,8.38Z"
                    style="opacity: 0.1"
                ></path>
                <path
                    d="M419.07,339.19a.33.33,0,0,0,.32-.24c4.18-14.87,17.39-26.17,24.92-28.29a.33.33,0,0,0-.18-.63c-7.67,2.17-21.13,13.65-25.37,28.75a.32.32,0,0,0,.23.4Z"
                    style="fill: #fff"
                ></path>
                <path
                    d="M420.55,349c.91-2.43,4.28-6.29,9.47-9.29,5.74-3.31,12.76-4.48,14.58-7.28,2.19-3.35-.5-6.9-6.15-6.66s-16.07,7.07-18.56,18.42Z"
                    style="fill: #f27531"
                ></path>
                <path
                    d="M419.68,346.34a.31.31,0,0,0,.29-.19c5.68-12.57,15.92-16.32,20.54-16.55a.32.32,0,1,0,0-.64c-5.11.25-15.3,4.09-21.1,16.92a.32.32,0,0,0,.17.43A.28.28,0,0,0,419.68,346.34Z"
                    style="fill: #fff"
                ></path>
            </g>
            <g id="freepik--plants--inject-62">
                <path
                    d="M56.39,308.47s-1-9.19-5.62-16.33-10.86-11.58-16.72-12.05-10.59,4.93-4.32,8.64,17.41,7.48,22.54,21.33Z"
                    style="fill: #f27531"
                ></path>
                <path
                    d="M56.39,308.47s-1-9.19-5.62-16.33-10.86-11.58-16.72-12.05-10.59,4.93-4.32,8.64,17.41,7.48,22.54,21.33Z"
                    style="opacity: 0.1"
                ></path>
                <path
                    d="M54.67,306.08h.06a.31.31,0,0,0,.17-.41c-6.18-15.24-18-21.6-23.32-22.84a.32.32,0,0,0-.14.62c5.25,1.2,16.79,7.46,22.88,22.45A.32.32,0,0,0,54.67,306.08Z"
                    style="fill: #fff"
                ></path>
                <path
                    d="M26.55,306c.57,1.28,2,1.94,3.25,2.46a6.34,6.34,0,0,1,3.39,2.28c1.41,2.43-.9,5.85.61,8.21a4.31,4.31,0,0,0,3,1.72,28.28,28.28,0,0,0,2.92.27,12.81,12.81,0,0,0,1.39-.11,6.24,6.24,0,0,1,4,.43c1.93,1.08,2.52,3.52,3.69,5.4a9.21,9.21,0,0,0,6.51,4.08,6.43,6.43,0,0,0,3.36-.22l.19-.12h0a4.25,4.25,0,0,0,.34-.41l0-.06c.34-1.26-.22-2.57-.24-3.87v-.24c0-1.51.1-3,.13-4.52q.1-4.17.08-8.34c0-1.8.2-3.78-.56-5.48-.85-1.87-2.95-1.91-3.74-3.75-.32-.73-.49-1.51-.8-2.25a6.45,6.45,0,0,0-5.5-3.84c-3.68-.19-7,2.78-10.64,2.51a27.24,27.24,0,0,1-2.81-.53c-3-.56-6.41.29-8.08,2.8A3.85,3.85,0,0,0,26.55,306Z"
                    style="fill: #f27531"
                ></path>
                <path
                    d="M59.54,324.16h0a.3.3,0,0,0,.22-.38C54.71,304.74,34.66,302,30,302.33a.31.31,0,0,0-.29.33A.3.3,0,0,0,30,303c4.59-.33,24.21,2.36,29.17,21A.31.31,0,0,0,59.54,324.16Z"
                    style="fill: #fff"
                ></path>
                <path
                    d="M37.26,314.52a.32.32,0,0,0,.17-.09,16.65,16.65,0,0,1,13.64-3.84.33.33,0,0,0,.36-.27.31.31,0,0,0-.27-.35A17.29,17.29,0,0,0,37,314a.31.31,0,0,0,0,.44A.33.33,0,0,0,37.26,314.52Z"
                    style="fill: #fff"
                ></path>
            </g>
        </g>
        <g id="freepik--Device--inject-62">
            <g id="freepik--device--inject-62">
                <path
                    d="M401,255.5l-71.42,41.33a6.4,6.4,0,0,1-5.75,0L199.53,225.08c-1.59-.91-1.59-2.4,0-3.31L271,180.43a6.38,6.38,0,0,1,5.74,0L401,252.18C402.54,253.1,402.54,254.58,401,255.5Z"
                    style="fill: #37474f"
                ></path>
                <path
                    d="M362.79,274.45a8.83,8.83,0,0,1-8,0c-2.2-1.27-2.2-3.33,0-4.6a8.77,8.77,0,0,1,8,0C365,271.12,365,273.18,362.79,274.45Z"
                    style="fill: #263238"
                ></path>
                <path
                    d="M329.53,296.83,401,255.5c1.42-.82,1.57-2.1.45-3a6.43,6.43,0,0,1,2.43,4.66v2.7a6.35,6.35,0,0,1-2.88,5l-71.42,41.33a5.89,5.89,0,0,1-2.87.69v-9.33A5.89,5.89,0,0,0,329.53,296.83Z"
                    style="fill: #455a64"
                ></path>
                <path
                    d="M199.53,234.42a6.35,6.35,0,0,1-2.87-5v-2.7a6.43,6.43,0,0,1,2.43-4.67c-1.12.92-1,2.19.44,3l124.25,71.75a5.9,5.9,0,0,0,2.88.69v9.33a5.9,5.9,0,0,1-2.88-.69Z"
                    style="fill: #263238"
                ></path>
                <path
                    d="M317.6,288.17l67.37-39a1.05,1.05,0,0,0,0-2l-99.58-57.6a3.79,3.79,0,0,0-3.44,0l-67.4,39a1.05,1.05,0,0,0,0,2l99.6,57.6A3.81,3.81,0,0,0,317.6,288.17Z"
                    style="fill: #fafafa"
                ></path>
                <path
                    d="M225,230.34,257.18,249a4.79,4.79,0,0,0,4.33,0l56.6-32.67a1.32,1.32,0,0,0,0-2.5l-32.22-18.62a4.79,4.79,0,0,0-4.33,0L225,227.84A1.32,1.32,0,0,0,225,230.34Z"
                    style="fill: #ebebeb"
                ></path>
                <path
                    d="M248.63,221.45l8.26,1.61a2.65,2.65,0,0,0,1.54-.14l7-3c1.11-.48,2.69,0,2.67.79l-.11,5c0,.32.26.62.71.81l7.12,2.91c1.13.46.86,1.47-.47,1.71L267,232.65a1.52,1.52,0,0,0-1.1.64l-2.59,4.81c-.42.76-2.17.92-3,.27l-5-4.11a2.18,2.18,0,0,0-1.38-.41l-8.73.06c-1.39,0-2.19-.9-1.36-1.54l5.22-4a.65.65,0,0,0,.24-.89l-2.8-4.77C246.06,221.91,247.31,221.19,248.63,221.45Z"
                    style="fill: #fafafa"
                ></path>
                <path
                    d="M267,210.91l17.72-10.23a2.6,2.6,0,0,1,2.32-.16c.56.33.43.93-.28,1.34l-17.71,10.23a2.64,2.64,0,0,1-2.32.16C266.17,211.92,266.3,211.32,267,210.91Z"
                    style="fill: #fafafa"
                ></path>
                <path
                    d="M271.48,213.49l17.71-10.23a2.6,2.6,0,0,1,2.32-.16c.56.33.44.93-.28,1.34l-17.71,10.23a2.64,2.64,0,0,1-2.32.16C270.63,214.5,270.76,213.9,271.48,213.49Z"
                    style="fill: #fafafa"
                ></path>
                <path
                    d="M275.94,216.07l17.71-10.23a2.57,2.57,0,0,1,2.32-.16c.57.33.44.93-.28,1.34L278,217.25a2.64,2.64,0,0,1-2.32.16C275.1,217.08,275.22,216.48,275.94,216.07Z"
                    style="fill: #fafafa"
                ></path>
                <path
                    d="M280.4,218.64l17.72-10.22a2.55,2.55,0,0,1,2.31-.16c.57.32.44.92-.27,1.34l-17.72,10.22a2.57,2.57,0,0,1-2.32.16C279.56,219.66,279.69,219.06,280.4,218.64Z"
                    style="fill: #fafafa"
                ></path>
                <path
                    d="M284.87,221.22,302.58,211a2.57,2.57,0,0,1,2.32-.16c.56.32.44.92-.28,1.34L286.91,222.4a2.57,2.57,0,0,1-2.32.16C284,222.24,284.15,221.64,284.87,221.22Z"
                    style="fill: #fafafa"
                ></path>
                <path
                    d="M289.33,223.8,307,213.58a2.57,2.57,0,0,1,2.32-.16c.56.32.44.92-.28,1.34L291.37,225a2.57,2.57,0,0,1-2.32.16C288.49,224.82,288.61,224.22,289.33,223.8Z"
                    style="fill: #fafafa"
                ></path>
                <path
                    d="M264.71,253.3l1.46.85a2.9,2.9,0,0,0,2.6,0l34-19.61c.72-.42.72-1.09,0-1.5l-1.47-.85a2.9,2.9,0,0,0-2.6,0l-34,19.61A.79.79,0,0,0,264.71,253.3Z"
                    style="fill: #e0e0e0"
                ></path>
                <path
                    d="M325.64,218.12l1.46.85c.72.41.72,1.08,0,1.5l-13.65,7.88a2.9,2.9,0,0,1-2.6,0l-1.46-.85c-.72-.41-.72-1.08,0-1.5L323,218.12A2.9,2.9,0,0,1,325.64,218.12Z"
                    style="fill: #e0e0e0"
                ></path>
                <path
                    d="M273.74,258.49,306,277.1a4.79,4.79,0,0,0,4.33,0l56.6-32.67a1.32,1.32,0,0,0,0-2.5l-32.22-18.62a4.79,4.79,0,0,0-4.33,0L273.74,256A1.32,1.32,0,0,0,273.74,258.49Z"
                    style="fill: #ebebeb"
                ></path>
                <path
                    d="M297.4,249.59l8.27,1.62a2.66,2.66,0,0,0,1.54-.14l7-3c1.12-.48,2.7,0,2.68.78l-.11,5c0,.32.25.62.7.8l7.13,2.91c1.13.47.85,1.48-.47,1.72l-8.34,1.49a1.56,1.56,0,0,0-1.1.64l-2.59,4.81c-.41.76-2.16.92-3,.27l-5-4.11a2.21,2.21,0,0,0-1.39-.41l-8.73.06c-1.38,0-2.19-.9-1.36-1.54l5.22-4a.67.67,0,0,0,.25-.89l-2.81-4.77C294.84,250.06,296.09,249.34,297.4,249.59Z"
                    style="fill: #fafafa"
                ></path>
                <path
                    d="M315.79,239.06l17.71-10.23a2.6,2.6,0,0,1,2.32-.16c.57.33.44.93-.28,1.34l-17.71,10.23a2.64,2.64,0,0,1-2.32.16C315,240.07,315.07,239.47,315.79,239.06Z"
                    style="fill: #fafafa"
                ></path>
                <path
                    d="M320.25,241.64,338,231.41a2.6,2.6,0,0,1,2.32-.16c.56.33.43.93-.28,1.34l-17.72,10.23A2.61,2.61,0,0,1,320,243C319.41,242.65,319.54,242.05,320.25,241.64Z"
                    style="fill: #fafafa"
                ></path>
                <path
                    d="M324.72,244.21,342.43,234a2.57,2.57,0,0,1,2.32-.16c.56.32.44.92-.28,1.34L326.76,245.4a2.64,2.64,0,0,1-2.32.16C323.87,245.23,324,244.63,324.72,244.21Z"
                    style="fill: #fafafa"
                ></path>
                <path
                    d="M329.18,246.79l17.71-10.22a2.57,2.57,0,0,1,2.32-.16c.57.32.44.92-.28,1.34L331.22,248a2.57,2.57,0,0,1-2.32.16C328.34,247.81,328.46,247.21,329.18,246.79Z"
                    style="fill: #fafafa"
                ></path>
                <path
                    d="M333.64,249.37l17.72-10.22a2.55,2.55,0,0,1,2.31-.16c.57.32.44.92-.27,1.34l-17.72,10.22a2.57,2.57,0,0,1-2.32.16C332.8,250.39,332.93,249.79,333.64,249.37Z"
                    style="fill: #fafafa"
                ></path>
                <path
                    d="M338.11,252l17.71-10.22a2.57,2.57,0,0,1,2.32-.16c.56.32.44.92-.28,1.34l-17.71,10.22a2.57,2.57,0,0,1-2.32.16C337.26,253,337.39,252.37,338.11,252Z"
                    style="fill: #fafafa"
                ></path>
                <path
                    d="M313.48,281.45l1.47.85a2.9,2.9,0,0,0,2.6,0l34-19.61a.79.79,0,0,0,0-1.5l-1.47-.85a2.9,2.9,0,0,0-2.6,0l-34,19.61A.79.79,0,0,0,313.48,281.45Z"
                    style="fill: #e0e0e0"
                ></path>
                <path
                    d="M374.41,246.27l1.47.85c.72.41.72,1.08,0,1.5l-13.65,7.88a2.9,2.9,0,0,1-2.6,0l-1.47-.85a.79.79,0,0,1,0-1.5l13.66-7.88A2.88,2.88,0,0,1,374.41,246.27Z"
                    style="fill: #e0e0e0"
                ></path>
                <path
                    d="M241.28,204.19a2,2,0,0,1-1.76,0,.53.53,0,0,1,0-1,2,2,0,0,1,1.76,0C241.77,203.46,241.77,203.91,241.28,204.19Z"
                    style="fill: #263238"
                ></path>
                <path
                    d="M238.47,212.18a3.9,3.9,0,0,1-3.52,0,1.08,1.08,0,0,1,0-2,3.9,3.9,0,0,1,3.52,0A1.08,1.08,0,0,1,238.47,212.18Z"
                    style="fill: #263238"
                ></path>
                <path
                    d="M241,208.64c-.65-.38-.61-1,.08-1.4l11.58-6.69a2.67,2.67,0,0,1,2.43,0c.64.37.61,1-.08,1.4l-11.58,6.68A2.7,2.7,0,0,1,241,208.64Z"
                    style="fill: #263238"
                ></path>
            </g>
        </g>
        <g id="freepik--Newspaper--inject-62">
            <g id="freepik--news-paper--inject-62">
                <path
                    d="M212.91,110.81v2.35l-8.12,4.69h0l-12.18,7L85.09,187a4.48,4.48,0,0,0-1.53,1.61,4.37,4.37,0,0,0-.64,2.14v142.9c0,7.77-5.46,10.92-12.18,7s-12.19-13.33-12.19-21.1V176.64a4.79,4.79,0,0,1,2.17-3.75l119.69-69.11L192,97.1a4.43,4.43,0,0,1,.62,2.15v2.18l-127.84,74a4.27,4.27,0,0,0-1.45,1.5,4.4,4.4,0,0,0-.71,2.25V321.89c0,5.18,3.64,11.47,8.13,14.06s8.12.49,8.12-4.69V188.36A4.76,4.76,0,0,1,81,184.61l131.28-75.8A4.16,4.16,0,0,1,212.91,110.81Z"
                    style="fill: #e0e0e0"
                ></path>
                <path
                    d="M200.72,103.93v6.88L77,182.27A4.79,4.79,0,0,0,74.79,186v142.9c0,2.59-1.81,3.64-4.05,2.34a8.94,8.94,0,0,1-4.06-7V181.33a4.47,4.47,0,0,1,.63-2.14,4.66,4.66,0,0,1,1.53-1.61l131.27-75.79A4.48,4.48,0,0,1,200.72,103.93Z"
                    style="fill: #e0e0e0"
                ></path>
                <path
                    d="M87.61,190.92a4.4,4.4,0,0,0-.63,2.13V334.72a23.1,23.1,0,0,1-.2,3.08q-.06.4-.12.78c0,.24-.09.47-.14.71s-.1.42-.16.62-.1.39-.16.58a10,10,0,0,1-4.36,5.86c-2.91,1.67-6.81,1.47-11.1-1-9-5.18-16.25-18.34-16.25-29.38V174.29a4.4,4.4,0,0,1,.63-2.13l4.07,2.34a4.37,4.37,0,0,0-.64,2.14v142.9c0,7.77,5.46,17.22,12.19,21.1s12.18.74,12.18-7V190.71a4.37,4.37,0,0,1,.64-2.14Z"
                    style="fill: #ebebeb"
                ></path>
                <path
                    d="M79.49,186.23a4.43,4.43,0,0,0-.63,2.13v142.9c0,5.18-3.64,7.28-8.12,4.69s-8.13-8.88-8.13-14.06V179.14a4.4,4.4,0,0,1,.71-2.25l4,2.3a4.47,4.47,0,0,0-.63,2.14v142.9a8.94,8.94,0,0,0,4.06,7c2.24,1.3,4.05.25,4.05-2.34V186a4.37,4.37,0,0,1,.64-2.14Z"
                    style="fill: #ebebeb"
                ></path>
                <path
                    d="M192,97.1l-11.57,6.68L60.72,172.89a4.4,4.4,0,0,0-1.53,1.61l-4.07-2.34a4.43,4.43,0,0,1,1.53-1.62l119.7-69.11,2.31-1.34L181,98.77l2-1.16,1.19-.69,2.21-1.27a4.64,4.64,0,0,1,4.2-.08A4.05,4.05,0,0,1,192,97.1Z"
                    style="fill: #fafafa"
                ></path>
                <path
                    d="M220.41,113.51h0l-11.57,6.68-20.31,11.72L89.15,189.3a4.54,4.54,0,0,0-1.54,1.62l-4-2.35A4.48,4.48,0,0,1,85.09,187L192.6,124.89l12.18-7h0l8.12-4.69,1.89-1.09L215,112a4.63,4.63,0,0,1,4,0l0,0,.21.13A4.27,4.27,0,0,1,220.41,113.51Z"
                    style="fill: #fafafa"
                ></path>
                <path
                    d="M200.11,101.79,68.84,177.58a4.66,4.66,0,0,0-1.53,1.61l-4-2.3a4.27,4.27,0,0,1,1.45-1.5l127.84-74,1.88-1.09a4.64,4.64,0,0,1,4.2-.08A4.13,4.13,0,0,1,200.11,101.79Z"
                    style="fill: #fafafa"
                ></path>
                <path
                    d="M212.31,108.81,81,184.61a4.46,4.46,0,0,0-1.54,1.62l-4.06-2.35A4.4,4.4,0,0,1,77,182.27l123.76-71.46,6-3.44a4.64,4.64,0,0,1,4.2-.07A4,4,0,0,1,212.31,108.81Z"
                    style="fill: #fafafa"
                ></path>
                <path
                    d="M221,115.66V257.33c0,5.81-2,9.88-5.23,11.68L81.65,346.45a10.22,10.22,0,0,0,4.71-6.54c.06-.2.11-.41.16-.62s.09-.47.14-.71.08-.51.12-.78a23.1,23.1,0,0,0,.2-3.08V193.05a4.4,4.4,0,0,1,.63-2.13,4.54,4.54,0,0,1,1.54-1.62l99.38-57.38,20.31-11.72,11.57-6.68A4.39,4.39,0,0,1,221,115.66Z"
                    style="fill: #f5f5f5"
                ></path>
                <path
                    d="M101.34,191.65l20-11.57c1.2-.69,2.16-.14,2.16,1.25v27.83a4.77,4.77,0,0,1-2.16,3.75l-20,11.57c-1.2.69-2.17.13-2.17-1.25V195.4A4.78,4.78,0,0,1,101.34,191.65Z"
                    style="fill: #455a64"
                ></path>
                <polygon
                    points="99.17 235.11 208.85 171.79 208.85 176.48 99.17 239.8 99.17 235.11"
                    style="fill: #f27531"
                ></polygon>
                <polygon
                    points="99.17 235.11 208.85 171.79 208.85 176.48 99.17 239.8 99.17 235.11"
                    style="fill: #fff; opacity: 0.4"
                ></polygon>
                <path
                    d="M100.47,248.43l21.78-12.57c.71-.42,1.29-.08,1.29.75v43.9a2.87,2.87,0,0,1-1.29,2.25l-21.78,12.57c-.72.42-1.3.08-1.3-.75v-43.9A2.86,2.86,0,0,1,100.47,248.43Z"
                    style="fill: #f0f0f0"
                ></path>
                <path
                    d="M100.47,300l21.78-12.58c.71-.41,1.29-.07,1.29.75v34.36L99.17,336.63V302.28A2.86,2.86,0,0,1,100.47,300Z"
                    style="fill: #f0f0f0"
                ></path>
                <path
                    d="M128.91,232l21.77-12.57c.72-.41,1.3-.08,1.3.75v58a2.87,2.87,0,0,1-1.3,2.25L128.91,293c-.72.41-1.3.08-1.3-.75v-58A2.89,2.89,0,0,1,128.91,232Z"
                    style="fill: #f0f0f0"
                ></path>
                <path
                    d="M157.34,215.6,179.11,203c.72-.42,1.3-.08,1.3.75v43.9a2.86,2.86,0,0,1-1.3,2.25L157.34,262.5c-.72.42-1.3.08-1.3-.75v-43.9A2.86,2.86,0,0,1,157.34,215.6Z"
                    style="fill: #f0f0f0"
                ></path>
                <path
                    d="M185.78,199.18l21.77-12.57c.72-.42,1.3-.08,1.3.75v58a2.89,2.89,0,0,1-1.3,2.25l-21.77,12.57c-.72.41-1.3.08-1.3-.75v-58A2.86,2.86,0,0,1,185.78,199.18Z"
                    style="fill: #f0f0f0"
                ></path>
                <path
                    d="M128.91,297.68l21.77-12.57c.72-.42,1.3-.08,1.3.75v20.28l-24.37,14.08V299.93A2.86,2.86,0,0,1,128.91,297.68Z"
                    style="fill: #f0f0f0"
                ></path>
                <path
                    d="M156,269.44V303.8l24.37-14.07V255.37c0-.83-.58-1.16-1.3-.75l-21.77,12.57A2.87,2.87,0,0,0,156,269.44Z"
                    style="fill: #f0f0f0"
                ></path>
                <path
                    d="M185.77,264.85l21.78-12.57c.72-.42,1.3-.08,1.3.75v20.28l-24.37,14.07V267.1A2.87,2.87,0,0,1,185.77,264.85Z"
                    style="fill: #f0f0f0"
                ></path>
                <path
                    d="M133,300l13.65-7.88c.72-.42,1.3-.08,1.3.75V304a2.86,2.86,0,0,1-1.3,2.25L133,314.11c-.72.41-1.3.08-1.3-.75V302.29A2.86,2.86,0,0,1,133,300Z"
                    style="fill: #e0e0e0"
                ></path>
                <path
                    d="M189.84,267.21l13.65-7.88c.71-.42,1.3-.08,1.3.75v11.07a2.9,2.9,0,0,1-1.3,2.25l-13.65,7.88c-.72.41-1.3.08-1.3-.75V269.46A2.86,2.86,0,0,1,189.84,267.21Z"
                    style="fill: #e0e0e0"
                ></path>
                <path
                    d="M104.53,250.78l13.65-7.89c.72-.41,1.3-.07,1.3.75v11.08a2.89,2.89,0,0,1-1.3,2.25l-13.65,7.88c-.71.41-1.3.08-1.3-.75V253A2.87,2.87,0,0,1,104.53,250.78Z"
                    style="fill: #e0e0e0"
                ></path>
                <path
                    d="M104.1,267.44l14.52-8.38c.48-.27.86-.05.86.5v.35a1.91,1.91,0,0,1-.86,1.5l-14.52,8.38c-.48.28-.87,0-.87-.5v-.35A1.92,1.92,0,0,1,104.1,267.44Z"
                    style="fill: #e0e0e0"
                ></path>
                <path
                    d="M104.1,272.13l14.52-8.38c.48-.27.86-.05.86.5v.35a1.91,1.91,0,0,1-.86,1.5l-14.52,8.38c-.48.28-.87,0-.87-.5v-.35A1.92,1.92,0,0,1,104.1,272.13Z"
                    style="fill: #e0e0e0"
                ></path>
                <path
                    d="M104.1,276.82l14.52-8.38c.48-.27.86-.05.86.5v.35a1.91,1.91,0,0,1-.86,1.5l-14.52,8.38c-.48.28-.87.05-.87-.5v-.35A1.92,1.92,0,0,1,104.1,276.82Z"
                    style="fill: #e0e0e0"
                ></path>
                <path
                    d="M104.1,281.51l14.52-8.38c.48-.27.86-.05.86.5V274a1.91,1.91,0,0,1-.86,1.5l-14.52,8.38c-.48.28-.87.05-.87-.5V283A1.92,1.92,0,0,1,104.1,281.51Z"
                    style="fill: #e0e0e0"
                ></path>
                <path
                    d="M104.1,286.2l14.52-8.38c.48-.27.86-.05.86.5v.35a1.91,1.91,0,0,1-.86,1.5l-14.52,8.38c-.48.28-.87.05-.87-.5v-.35A1.92,1.92,0,0,1,104.1,286.2Z"
                    style="fill: #e0e0e0"
                ></path>
                <path
                    d="M133,234.36l13.65-7.88c.72-.42,1.3-.08,1.3.75V238.3a2.87,2.87,0,0,1-1.3,2.25L133,248.43c-.72.41-1.3.08-1.3-.75V236.61A2.86,2.86,0,0,1,133,234.36Z"
                    style="fill: #e0e0e0"
                ></path>
                <path
                    d="M132.53,251l14.52-8.38c.48-.28.87-.06.87.5v.34a1.91,1.91,0,0,1-.87,1.5l-14.52,8.38c-.47.28-.86,0-.86-.5v-.34A1.91,1.91,0,0,1,132.53,251Z"
                    style="fill: #e0e0e0"
                ></path>
                <path
                    d="M132.53,255.72l14.52-8.38c.48-.28.87-.06.87.5v.34a1.91,1.91,0,0,1-.87,1.5l-14.52,8.38c-.47.28-.86.05-.86-.5v-.34A1.91,1.91,0,0,1,132.53,255.72Z"
                    style="fill: #e0e0e0"
                ></path>
                <path
                    d="M132.53,260.41,147.05,252c.48-.28.87-.06.87.5v.34a1.91,1.91,0,0,1-.87,1.5l-14.52,8.38c-.47.28-.86.05-.86-.5v-.34A1.91,1.91,0,0,1,132.53,260.41Z"
                    style="fill: #e0e0e0"
                ></path>
                <path
                    d="M132.53,265.1l14.52-8.38c.48-.28.87-.06.87.5v.34a1.91,1.91,0,0,1-.87,1.5l-14.52,8.38c-.47.28-.86.05-.86-.5v-.34A1.91,1.91,0,0,1,132.53,265.1Z"
                    style="fill: #e0e0e0"
                ></path>
                <path
                    d="M132.53,269.79l14.52-8.38c.48-.28.87-.06.87.5v.34a1.91,1.91,0,0,1-.87,1.5l-14.52,8.38c-.47.28-.86.05-.86-.5v-.34A1.91,1.91,0,0,1,132.53,269.79Z"
                    style="fill: #e0e0e0"
                ></path>
                <path
                    d="M132.53,274.48l14.52-8.38c.48-.28.87-.05.87.5v.34a1.89,1.89,0,0,1-.87,1.5l-14.52,8.38c-.47.28-.86.06-.86-.5V276A1.94,1.94,0,0,1,132.53,274.48Z"
                    style="fill: #e0e0e0"
                ></path>
                <path
                    d="M132.53,279.17l14.52-8.38c.48-.28.87-.05.87.5v.34a1.89,1.89,0,0,1-.87,1.5l-14.52,8.38c-.47.28-.86.06-.86-.5v-.34A1.94,1.94,0,0,1,132.53,279.17Z"
                    style="fill: #e0e0e0"
                ></path>
                <path
                    d="M132.53,283.86l14.52-8.38c.48-.28.87-.05.87.5v.34a1.89,1.89,0,0,1-.87,1.5l-14.52,8.38c-.47.28-.86.06-.86-.5v-.34A1.94,1.94,0,0,1,132.53,283.86Z"
                    style="fill: #e0e0e0"
                ></path>
                <path
                    d="M104.53,302.38l13.65-7.89c.72-.41,1.3-.07,1.3.75v11.08a2.87,2.87,0,0,1-1.3,2.25l-13.65,7.88c-.71.41-1.3.08-1.3-.75V304.63A2.87,2.87,0,0,1,104.53,302.38Z"
                    style="fill: #e0e0e0"
                ></path>
                <path
                    d="M104.1,319l14.52-8.38c.48-.27.86-.05.86.5v.35a1.91,1.91,0,0,1-.86,1.5l-14.52,8.38c-.48.28-.87.05-.87-.5v-.35A1.92,1.92,0,0,1,104.1,319Z"
                    style="fill: #e0e0e0"
                ></path>
                <path
                    d="M104.1,323.73l14.52-8.38c.48-.27.86-.05.86.5v.35a1.91,1.91,0,0,1-.86,1.5l-14.52,8.38c-.48.28-.87.05-.87-.5v-.35A1.92,1.92,0,0,1,104.1,323.73Z"
                    style="fill: #e0e0e0"
                ></path>
                <path
                    d="M104.1,328.43,118.62,320c.48-.27.86-.05.86.5v.35a1.91,1.91,0,0,1-.86,1.5l-14.52,8.38c-.48.28-.87.05-.87-.5v-.34A1.89,1.89,0,0,1,104.1,328.43Z"
                    style="fill: #e0e0e0"
                ></path>
                <path
                    d="M161.4,217.94l13.65-7.88c.72-.41,1.3-.08,1.3.75v11.07a2.86,2.86,0,0,1-1.3,2.25L161.4,232c-.71.41-1.3.07-1.3-.75V220.19A2.91,2.91,0,0,1,161.4,217.94Z"
                    style="fill: #e0e0e0"
                ></path>
                <path
                    d="M161,234.61l14.52-8.38c.47-.27.86,0,.86.5v.35a1.94,1.94,0,0,1-.86,1.5L161,237c-.48.27-.87,0-.87-.5v-.35A1.92,1.92,0,0,1,161,234.61Z"
                    style="fill: #e0e0e0"
                ></path>
                <path
                    d="M161,239.3l14.52-8.38c.47-.27.86,0,.86.5v.35a1.94,1.94,0,0,1-.86,1.5L161,241.65c-.48.27-.87,0-.87-.5v-.35A1.92,1.92,0,0,1,161,239.3Z"
                    style="fill: #e0e0e0"
                ></path>
                <path
                    d="M161,244l14.52-8.38c.47-.27.86-.05.86.5v.35a1.94,1.94,0,0,1-.86,1.5L161,246.34c-.48.27-.87,0-.87-.5v-.35A1.92,1.92,0,0,1,161,244Z"
                    style="fill: #e0e0e0"
                ></path>
                <path
                    d="M161,248.68l14.52-8.38c.47-.27.86-.05.86.5v.35a1.94,1.94,0,0,1-.86,1.5L161,251c-.48.27-.87.05-.87-.5v-.35A1.92,1.92,0,0,1,161,248.68Z"
                    style="fill: #e0e0e0"
                ></path>
                <path
                    d="M161,253.37,175.49,245c.47-.27.86-.05.86.5v.35a1.94,1.94,0,0,1-.86,1.5L161,255.72c-.48.27-.87.05-.87-.5v-.35A1.92,1.92,0,0,1,161,253.37Z"
                    style="fill: #e0e0e0"
                ></path>
                <path
                    d="M189.84,201.53l13.65-7.88c.71-.42,1.3-.08,1.3.75v11.07a2.9,2.9,0,0,1-1.3,2.25l-13.65,7.88c-.72.41-1.3.08-1.3-.75V203.78A2.86,2.86,0,0,1,189.84,201.53Z"
                    style="fill: #e0e0e0"
                ></path>
                <path
                    d="M189.4,218.19l14.52-8.38c.48-.27.87-.05.87.5v.35a1.92,1.92,0,0,1-.87,1.5l-14.52,8.38c-.47.28-.86.05-.86-.5v-.35A1.94,1.94,0,0,1,189.4,218.19Z"
                    style="fill: #e0e0e0"
                ></path>
                <path
                    d="M189.4,222.89l14.52-8.39c.48-.27.87-.05.87.5v.35a1.92,1.92,0,0,1-.87,1.5l-14.52,8.38c-.47.28-.86.05-.86-.5v-.34A1.91,1.91,0,0,1,189.4,222.89Z"
                    style="fill: #e0e0e0"
                ></path>
                <path
                    d="M189.4,227.58l14.52-8.39c.48-.27.87-.05.87.5V220a1.92,1.92,0,0,1-.87,1.5l-14.52,8.38c-.47.28-.86.05-.86-.5v-.34A1.91,1.91,0,0,1,189.4,227.58Z"
                    style="fill: #e0e0e0"
                ></path>
                <path
                    d="M189.4,232.27l14.52-8.39c.48-.27.87,0,.87.5v.35a1.92,1.92,0,0,1-.87,1.5l-14.52,8.38c-.47.28-.86,0-.86-.5v-.34A1.91,1.91,0,0,1,189.4,232.27Z"
                    style="fill: #e0e0e0"
                ></path>
                <path
                    d="M189.4,237l14.52-8.39c.48-.27.87,0,.87.5v.35a1.92,1.92,0,0,1-.87,1.5L189.4,239.3c-.47.28-.86,0-.86-.5v-.34A1.91,1.91,0,0,1,189.4,237Z"
                    style="fill: #e0e0e0"
                ></path>
                <path
                    d="M189.4,241.65l14.52-8.38c.48-.28.87-.06.87.5v.34a1.91,1.91,0,0,1-.87,1.5L189.4,244c-.47.28-.86,0-.86-.5v-.34A1.92,1.92,0,0,1,189.4,241.65Z"
                    style="fill: #e0e0e0"
                ></path>
                <path
                    d="M189.4,246.34,203.92,238c.48-.28.87-.06.87.5v.34a1.91,1.91,0,0,1-.87,1.5l-14.52,8.38c-.47.28-.86,0-.86-.5v-.34A1.92,1.92,0,0,1,189.4,246.34Z"
                    style="fill: #e0e0e0"
                ></path>
                <path
                    d="M189.4,251l14.52-8.38c.48-.28.87-.06.87.5v.34a1.91,1.91,0,0,1-.87,1.5l-14.52,8.38c-.47.28-.86.06-.86-.5v-.34A1.92,1.92,0,0,1,189.4,251Z"
                    style="fill: #e0e0e0"
                ></path>
                <path
                    d="M161.4,269.54l13.65-7.88c.72-.41,1.3-.08,1.3.75v11.07a2.86,2.86,0,0,1-1.3,2.25l-13.65,7.89c-.71.41-1.3.07-1.3-.75V271.79A2.91,2.91,0,0,1,161.4,269.54Z"
                    style="fill: #e0e0e0"
                ></path>
                <path
                    d="M161,286.21l14.52-8.38c.47-.27.86-.05.86.5v.35a1.94,1.94,0,0,1-.86,1.5L161,288.56c-.48.27-.87.05-.87-.5v-.35A1.92,1.92,0,0,1,161,286.21Z"
                    style="fill: #e0e0e0"
                ></path>
                <path
                    d="M161,290.9l14.52-8.38c.47-.27.86,0,.86.5v.35a1.94,1.94,0,0,1-.86,1.5L161,293.25c-.48.27-.87.05-.87-.5v-.35A1.92,1.92,0,0,1,161,290.9Z"
                    style="fill: #e0e0e0"
                ></path>
                <path
                    d="M161,295.59l14.52-8.38c.47-.27.86,0,.86.5v.35a1.94,1.94,0,0,1-.86,1.5L161,297.94c-.48.27-.87.05-.87-.5v-.35A1.92,1.92,0,0,1,161,295.59Z"
                    style="fill: #e0e0e0"
                ></path>
                <path
                    d="M132.3,204.06q-.3-.1-.3-.57a2.85,2.85,0,0,1,.18-.89l1.08-3.44a1.67,1.67,0,0,1,.45-.82.5.5,0,0,1,.48,0,3.59,3.59,0,0,0,3.43-.36,4.8,4.8,0,0,0,2-2.27,9.15,9.15,0,0,0,.65-3.68V171.86a4.1,4.1,0,0,1,.31-1.7A2.58,2.58,0,0,1,141.7,169l2.22-1.28c.5-.29.86-.33,1.07-.11a2,2,0,0,1,.32,1.35V189.2a19,19,0,0,1-1.76,8.23,12.57,12.57,0,0,1-5.15,5.65Q134.79,205.17,132.3,204.06Z"
                    style="fill: #455a64"
                ></path>
                <path
                    d="M150.31,191.91q-2.69-2.76-2.69-10.57a32.5,32.5,0,0,1,2.69-13.67,18.94,18.94,0,0,1,7.79-8.8c3.39-2,6-2,7.77-.18s2.69,5.35,2.69,10.56a32.5,32.5,0,0,1-2.69,13.67,18.93,18.93,0,0,1-7.77,8.79Q153,194.65,150.31,191.91Zm11.92-10.84a24.84,24.84,0,0,0,1.4-9q0-5.41-1.4-7.36c-.93-1.3-2.3-1.42-4.13-.37a9.87,9.87,0,0,0-4.16,5.16,24.78,24.78,0,0,0-1.39,9c0,3.6.46,6.06,1.39,7.35s2.32,1.42,4.16.35A9.72,9.72,0,0,0,162.23,181.07Z"
                    style="fill: #455a64"
                ></path>
                <path
                    d="M171.34,182.89a1.92,1.92,0,0,1-.32-1.32V154.14a4.11,4.11,0,0,1,.32-1.71,2.38,2.38,0,0,1,1-1.1l8.8-5.09q3.32-1.91,5-.52t1.65,5.5a13.9,13.9,0,0,1-.67,4.27,10.58,10.58,0,0,1-1.85,3.52q3.09-.21,3.09,5.2a17.68,17.68,0,0,1-.79,5.22,15.61,15.61,0,0,1-2.41,4.74,12.83,12.83,0,0,1-4,3.52l-8.8,5.09C171.89,183.05,171.55,183.09,171.34,182.89Zm9-23.44a4.74,4.74,0,0,0,1.86-2,6.59,6.59,0,0,0,.67-3.07c0-1.26-.21-2-.62-2.35s-1.05-.2-1.91.29L176,154.84V162Zm.48,12.86a4.67,4.67,0,0,0,2-2.23,7.85,7.85,0,0,0,.65-3.22c0-1.32-.25-2.17-.75-2.54s-1.21-.3-2.11.22L176,167.19v7.91Z"
                    style="fill: #455a64"
                ></path>
                <path
                    d="M192.81,170.9a5.43,5.43,0,0,1-3.47-.14.75.75,0,0,1-.45-.67,2.47,2.47,0,0,1,.15-.73l1.24-3.72a1.23,1.23,0,0,1,.39-.69.48.48,0,0,1,.45,0,5.79,5.79,0,0,0,2.55.1,8.78,8.78,0,0,0,2.91-1.08,7.28,7.28,0,0,0,2.59-2.35,5.88,5.88,0,0,0,.84-3.19,3.08,3.08,0,0,0-.41-1.8,1.83,1.83,0,0,0-1.2-.7,18.24,18.24,0,0,0-2.45-.16,11.81,11.81,0,0,1-3.26-.38,3.85,3.85,0,0,1-2.19-1.72,7.43,7.43,0,0,1-.86-4,15.35,15.35,0,0,1,2.08-7.92,14.83,14.83,0,0,1,5.46-5.49c3-1.73,5.27-2.18,6.85-1.37a.83.83,0,0,1,.45.67,2.47,2.47,0,0,1-.15.73l-1.23,3.72a1.19,1.19,0,0,1-.39.68.45.45,0,0,1-.45,0q-1.83-.79-4.44.71a6,6,0,0,0-3.25,5.49,3.12,3.12,0,0,0,.38,1.74,1.63,1.63,0,0,0,1,.7,10.73,10.73,0,0,0,2.08.17l.84,0a11.79,11.79,0,0,1,3.45.53,3.45,3.45,0,0,1,1.94,1.77,9.13,9.13,0,0,1,.67,4,15.17,15.17,0,0,1-2,7.54,14.79,14.79,0,0,1-5.7,5.67A15.41,15.41,0,0,1,192.81,170.9Z"
                    style="fill: #455a64"
                ></path>
            </g>
        </g>
        <g id="freepik--speech-bubbles--inject-62">
            <g id="freepik--Tab--inject-62">
                <path
                    d="M317.77,74.67h0l-4.06-2.35h0a1.82,1.82,0,0,0-1.84.18l-51.67,29.9a5.77,5.77,0,0,0-2.6,4.5v106.5a1.82,1.82,0,0,0,.76,1.68h0l4,2.34,0,0,.06,0h0a1.84,1.84,0,0,0,1.76-.21l13.65-7.88v8.21c0,1.45.23,2.29.61,2.52h0l4,2.33h0l0,0h0c.46.25,1.13-.39,1.88-2l9.68-20.5,21.77-12.64a5.77,5.77,0,0,0,2.6-4.5V76.34A1.83,1.83,0,0,0,317.77,74.67Z"
                    style="fill: #f27531"
                ></path>
                <path
                    d="M317.67,74.61a1.92,1.92,0,0,0-1.74.23l-51.68,29.91a5.21,5.21,0,0,0-1.8,1.89l-4.06-2.35a5.13,5.13,0,0,1,1.8-1.89l51.67-29.91a2.38,2.38,0,0,1,1.15-.35,1.38,1.38,0,0,1,.69.17Z"
                    style="fill: #fff; opacity: 0.4"
                ></path>
                <path
                    d="M278.51,220.1h0c-.37-.23-.6-1.07-.6-2.52v-8.2L282,207v12.9c0,1.38.21,2.22.54,2.5Z"
                    style="opacity: 0.2"
                ></path>
                <path
                    d="M262.44,217.43h0l-4.06-2.34h0a1.85,1.85,0,0,1-.75-1.67V106.9a5.35,5.35,0,0,1,.8-2.61l4.06,2.35a5.33,5.33,0,0,0-.79,2.61v106.5A1.81,1.81,0,0,0,262.44,217.43Z"
                    style="opacity: 0.1"
                ></path>
                <path
                    d="M271.94,109.68l36.29-21c1.2-.69,2.17-.13,2.17,1.25V113a4.8,4.8,0,0,1-2.17,3.76l-36.29,21c-1.19.69-2.16.13-2.16-1.25V113.43A4.8,4.8,0,0,1,271.94,109.68Z"
                    style="fill: #455a64"
                ></path>
                <path
                    d="M310.4,122.59a4.64,4.64,0,0,1-2.17,3.6l-36.29,20.95c-1.19.69-2.16.2-2.16-1.1a4.65,4.65,0,0,1,2.16-3.59l36.29-20.95C309.43,120.81,310.4,121.3,310.4,122.59Z"
                    style="fill: #455a64"
                ></path>
                <path
                    d="M310.4,132a4.64,4.64,0,0,1-2.17,3.6l-36.29,21c-1.19.69-2.16.2-2.16-1.1a4.65,4.65,0,0,1,2.16-3.59l36.29-21C309.43,130.19,310.4,130.68,310.4,132Z"
                    style="fill: #455a64"
                ></path>
                <path
                    d="M310.4,141.35a4.64,4.64,0,0,1-2.17,3.6l-36.29,21c-1.19.69-2.16.2-2.16-1.1a4.65,4.65,0,0,1,2.16-3.59l36.29-21C309.43,139.57,310.4,140.06,310.4,141.35Z"
                    style="fill: #455a64"
                ></path>
                <path
                    d="M310.4,150.73a4.64,4.64,0,0,1-2.17,3.6l-36.29,20.95c-1.19.69-2.16.2-2.16-1.1a4.65,4.65,0,0,1,2.16-3.59l36.29-21C309.43,149,310.4,149.44,310.4,150.73Z"
                    style="fill: #455a64"
                ></path>
                <path
                    d="M269.78,183.57c0,1.29,1,1.78,2.16,1.09l3.8-2.19a4.65,4.65,0,0,0,2.16-3.59c0-1.3-1-1.79-2.16-1.1l-3.8,2.19A4.65,4.65,0,0,0,269.78,183.57Z"
                    style="fill: #455a64"
                ></path>
                <path
                    d="M283.4,175.72c0,1.29,1,1.78,2.17,1.09l22.66-13.1a4.64,4.64,0,0,0,2.17-3.6c0-1.29-1-1.78-2.17-1.09l-22.66,13.1A4.64,4.64,0,0,0,283.4,175.72Z"
                    style="fill: #455a64"
                ></path>
                <path
                    d="M269.78,193c0,1.29,1,1.78,2.16,1.09l3.8-2.19a4.65,4.65,0,0,0,2.16-3.59c0-1.3-1-1.79-2.16-1.1l-3.8,2.19A4.65,4.65,0,0,0,269.78,193Z"
                    style="fill: #455a64"
                ></path>
                <path
                    d="M283.4,185.1c0,1.29,1,1.78,2.17,1.09l22.66-13.1a4.64,4.64,0,0,0,2.17-3.6c0-1.29-1-1.78-2.17-1.09l-22.66,13.1A4.64,4.64,0,0,0,283.4,185.1Z"
                    style="fill: #455a64"
                ></path>
                <path
                    d="M269.78,202.33c0,1.29,1,1.78,2.16,1.09l3.8-2.19a4.65,4.65,0,0,0,2.16-3.59c0-1.3-1-1.79-2.16-1.1l-3.8,2.19A4.65,4.65,0,0,0,269.78,202.33Z"
                    style="fill: #455a64"
                ></path>
                <path
                    d="M283.4,194.48c0,1.29,1,1.78,2.17,1.09l22.66-13.1a4.64,4.64,0,0,0,2.17-3.59c0-1.3-1-1.79-2.17-1.1l-22.66,13.1A4.64,4.64,0,0,0,283.4,194.48Z"
                    style="fill: #455a64"
                ></path>
            </g>
            <g id="freepik--tab--inject-62">
                <path
                    d="M366.54,102.75h0l-4-2.35h0a1.82,1.82,0,0,0-1.84.18L309,130.47a5.77,5.77,0,0,0-2.6,4.5v106.5a1.82,1.82,0,0,0,.76,1.68h0l4.05,2.34,0,0,.05,0h0a1.85,1.85,0,0,0,1.77-.21l13.65-7.88v8.21c0,1.45.23,2.29.6,2.52h0l4,2.33h0l.05,0h0c.46.25,1.13-.39,1.87-2l9.69-20.5,21.77-12.64a5.77,5.77,0,0,0,2.6-4.5V104.42A1.83,1.83,0,0,0,366.54,102.75Z"
                    style="fill: #f27531"
                ></path>
                <path
                    d="M366.43,102.69a1.92,1.92,0,0,0-1.74.23L313,132.83a5.21,5.21,0,0,0-1.8,1.89l-4.06-2.35a5.21,5.21,0,0,1,1.8-1.89l51.68-29.91a2.33,2.33,0,0,1,1.15-.35,1.37,1.37,0,0,1,.68.17Z"
                    style="fill: #fff; opacity: 0.4"
                ></path>
                <path
                    d="M327.27,248.18h0c-.37-.23-.6-1.07-.6-2.52v-8.2l4.06-2.35V248c0,1.38.21,2.22.55,2.5Z"
                    style="opacity: 0.2"
                ></path>
                <path
                    d="M311.2,245.51h0l-4.05-2.34h0a1.83,1.83,0,0,1-.75-1.67V135a5.33,5.33,0,0,1,.79-2.61l4.06,2.35a5.33,5.33,0,0,0-.79,2.61v106.5A1.81,1.81,0,0,0,311.2,245.51Z"
                    style="opacity: 0.1"
                ></path>
                <path
                    d="M320.71,137.76l36.29-21c1.19-.69,2.16-.13,2.16,1.25v23.13a4.81,4.81,0,0,1-2.16,3.76l-36.29,21c-1.2.69-2.17.13-2.17-1.25V141.51A4.78,4.78,0,0,1,320.71,137.76Z"
                    style="fill: #455a64"
                ></path>
                <path
                    d="M359.16,150.67a4.65,4.65,0,0,1-2.16,3.6l-36.29,20.95c-1.2.69-2.17.2-2.17-1.1a4.64,4.64,0,0,1,2.17-3.59L357,149.58C358.19,148.89,359.16,149.38,359.16,150.67Z"
                    style="fill: #455a64"
                ></path>
                <path
                    d="M359.16,160.05a4.65,4.65,0,0,1-2.16,3.6L320.71,184.6c-1.2.69-2.17.2-2.17-1.1a4.64,4.64,0,0,1,2.17-3.59L357,159C358.19,158.27,359.16,158.76,359.16,160.05Z"
                    style="fill: #455a64"
                ></path>
                <path
                    d="M359.16,169.43A4.65,4.65,0,0,1,357,173L320.71,194c-1.2.69-2.17.2-2.17-1.1a4.64,4.64,0,0,1,2.17-3.59L357,168.34C358.19,167.65,359.16,168.14,359.16,169.43Z"
                    style="fill: #455a64"
                ></path>
                <path
                    d="M359.16,178.81a4.65,4.65,0,0,1-2.16,3.6l-36.29,21c-1.2.69-2.17.2-2.17-1.1a4.64,4.64,0,0,1,2.17-3.59L357,177.72C358.19,177,359.16,177.52,359.16,178.81Z"
                    style="fill: #455a64"
                ></path>
                <path
                    d="M318.54,211.65c0,1.29,1,1.78,2.17,1.09l3.79-2.19a4.64,4.64,0,0,0,2.17-3.59c0-1.3-1-1.79-2.17-1.1l-3.79,2.19A4.64,4.64,0,0,0,318.54,211.65Z"
                    style="fill: #455a64"
                ></path>
                <path
                    d="M332.17,203.8c0,1.29,1,1.78,2.17,1.09L357,191.79a4.65,4.65,0,0,0,2.16-3.6c0-1.29-1-1.78-2.16-1.09l-22.67,13.1A4.65,4.65,0,0,0,332.17,203.8Z"
                    style="fill: #455a64"
                ></path>
                <path
                    d="M318.54,221c0,1.29,1,1.78,2.17,1.09l3.79-2.19a4.64,4.64,0,0,0,2.17-3.59c0-1.3-1-1.79-2.17-1.1l-3.79,2.19A4.64,4.64,0,0,0,318.54,221Z"
                    style="fill: #455a64"
                ></path>
                <path
                    d="M332.17,213.18c0,1.29,1,1.78,2.17,1.09L357,201.17a4.65,4.65,0,0,0,2.16-3.6c0-1.29-1-1.78-2.16-1.09l-22.67,13.1A4.65,4.65,0,0,0,332.17,213.18Z"
                    style="fill: #455a64"
                ></path>
                <path
                    d="M318.54,230.41c0,1.29,1,1.78,2.17,1.09l3.79-2.19a4.64,4.64,0,0,0,2.17-3.59c0-1.3-1-1.79-2.17-1.1l-3.79,2.19A4.64,4.64,0,0,0,318.54,230.41Z"
                    style="fill: #455a64"
                ></path>
                <path
                    d="M332.17,222.56c0,1.29,1,1.78,2.17,1.09L357,210.55a4.65,4.65,0,0,0,2.16-3.59c0-1.3-1-1.79-2.16-1.1L334.33,219A4.65,4.65,0,0,0,332.17,222.56Z"
                    style="fill: #455a64"
                ></path>
            </g>
        </g>
        <g id="freepik--Icons--inject-62">
            <path
                d="M419.38,61.47,414,58.39h0l-.05,0h0c-2.37-1.33-5.62-1.13-9.22.94-7.25,4.19-13.12,14.37-13.12,22.74,0,4.17,1.46,7.11,3.83,8.48h0l4.86,2.78,1.16-2a13.21,13.21,0,0,0,3.77-1.46c7.24-4.18,13.12-14.36,13.12-22.73a14.29,14.29,0,0,0-.41-3.35Z"
                style="fill: #f27531"
            ></path>
            <path
                d="M391.64,82c0,4.18,1.46,7.12,3.83,8.49h0l4.86,2.78,1.16-1.94a13.56,13.56,0,0,0,3.77-1.47A27.26,27.26,0,0,0,415,79.22l-19-11A27.21,27.21,0,0,0,391.64,82Z"
                style="fill: #263238"
            ></path>
            <path
                d="M419.39,61.47l-5.34-3.08h0l-.05,0h0c-2.37-1.34-5.62-1.14-9.22.94A26.21,26.21,0,0,0,396,68.24l19,11a26.23,26.23,0,0,0,3.36-12,14.29,14.29,0,0,0-.41-3.35Z"
                style="fill: #455a64"
            ></path>
            <path
                d="M409.83,62.22C402.59,66.4,396.71,76.58,396.71,85s5.88,11.76,13.12,7.57S423,78.16,423,69.79,417.08,58,409.83,62.22Z"
                style="fill: #37474f"
            ></path>
            <path
                d="M410.79,69.15a2.35,2.35,0,0,1,1.21-.09,1.92,1.92,0,0,1,.88.47,2.16,2.16,0,0,1,.53.83,3.21,3.21,0,0,1,.22,1,.94.94,0,0,1-.11.48.75.75,0,0,1-.28.32l-1,.57c-.16.1-.28.12-.36.07a.58.58,0,0,1-.22-.26A1.11,1.11,0,0,0,411,72a1.67,1.67,0,0,0-1.27.34,3.43,3.43,0,0,0-.7.52,3.73,3.73,0,0,0-.58.68,3.16,3.16,0,0,0-.39.78,2.57,2.57,0,0,0-.14.82,1.27,1.27,0,0,0,.16.73.63.63,0,0,0,.5.25,3.09,3.09,0,0,0,.89-.09l1.32-.31a4.91,4.91,0,0,1,1.43-.16,1.65,1.65,0,0,1,1,.36,1.78,1.78,0,0,1,.54.92,6.23,6.23,0,0,1,.16,1.49A7.15,7.15,0,0,1,413.7,80a8.32,8.32,0,0,1-.62,1.68,8.42,8.42,0,0,1-1,1.55,8.06,8.06,0,0,1-1.31,1.3V86a1.06,1.06,0,0,1-.12.47.7.7,0,0,1-.26.33l-1.15.66a.2.2,0,0,1-.27,0,.46.46,0,0,1-.11-.34V85.64a3.23,3.23,0,0,1-1.19.18,2,2,0,0,1-1-.32,2.05,2.05,0,0,1-.67-.82,3.47,3.47,0,0,1-.28-1.35.89.89,0,0,1,.11-.48.77.77,0,0,1,.27-.33l1-.57c.16-.09.28-.12.37-.08a.51.51,0,0,1,.21.27,3.62,3.62,0,0,0,.24.43.78.78,0,0,0,.38.28,1.32,1.32,0,0,0,.6,0,2.86,2.86,0,0,0,.9-.38,6.06,6.06,0,0,0,.82-.57,4.68,4.68,0,0,0,.69-.72,3.78,3.78,0,0,0,.48-.85,2.42,2.42,0,0,0,.18-.94.88.88,0,0,0-.2-.67.81.81,0,0,0-.6-.17,4.88,4.88,0,0,0-1,.16c-.4.1-.85.19-1.37.29a3.92,3.92,0,0,1-1.24.09,1.44,1.44,0,0,1-.87-.37,2.08,2.08,0,0,1-.52-.93,6.27,6.27,0,0,1-.17-1.59,6.54,6.54,0,0,1,.22-1.69,8.8,8.8,0,0,1,.58-1.63,7.82,7.82,0,0,1,.9-1.47,8.31,8.31,0,0,1,1.14-1.22V68.72a1.19,1.19,0,0,1,.11-.47.77.77,0,0,1,.27-.33l1.15-.66a.19.19,0,0,1,.26,0,.43.43,0,0,1,.12.34Z"
                style="fill: #fafafa"
            ></path>
            <path
                d="M419.38,159.32,414,156.24h0l-.05,0h0c-2.37-1.34-5.62-1.14-9.22.94-7.25,4.18-13.12,14.36-13.12,22.73,0,4.18,1.46,7.11,3.83,8.49h0l4.86,2.77,1.16-1.94a13.52,13.52,0,0,0,3.77-1.46c7.24-4.19,13.12-14.37,13.12-22.74a14.36,14.36,0,0,0-.41-3.35Z"
                style="fill: #f27531"
            ></path>
            <path
                d="M391.64,179.89c0,4.18,1.46,7.11,3.83,8.49h0l4.86,2.77,1.16-1.94a13.56,13.56,0,0,0,3.77-1.47A27.23,27.23,0,0,0,415,177.08l-19-11A27.27,27.27,0,0,0,391.64,179.89Z"
                style="fill: #263238"
            ></path>
            <path
                d="M419.39,159.32l-5.34-3.08h0l-.05,0h0c-2.37-1.34-5.62-1.14-9.22.94a26.21,26.21,0,0,0-8.76,8.93l19,11A26.3,26.3,0,0,0,418.39,165a14.36,14.36,0,0,0-.41-3.35Z"
                style="fill: #455a64"
            ></path>
            <path
                d="M409.83,160.07c-7.24,4.18-13.12,14.36-13.12,22.73s5.88,11.76,13.12,7.58S423,176,423,167.65,417.08,155.89,409.83,160.07Z"
                style="fill: #37474f"
            ></path>
            <path
                d="M416.51,178.21l-13.35,7.71c-.16.09-.28,0-.28-.27V173.81a.74.74,0,0,1,.28-.6l13.35-7.71c.15-.09.28,0,.28.27v11.85A.74.74,0,0,1,416.51,178.21Z"
                style="fill: #fafafa"
            ></path>
            <path
                d="M412.46,168.62c-.15.09-.28,0-.28-.27v-1.8l-4.69,2.71v1.8a.74.74,0,0,1-.29.6c-.15.09-.28,0-.28-.27v-2.24a.74.74,0,0,1,.28-.59l5.26-3c.16-.09.28,0,.28.27V168A.72.72,0,0,1,412.46,168.62Z"
                style="fill: #fafafa"
            ></path>
            <path
                d="M412.46,166.39l-5.26,3c-.15.09-.28,0-.28-.27v-1a.77.77,0,0,1,.28-.6l5.26-3c.16-.09.28,0,.28.27v1A.74.74,0,0,1,412.46,166.39Z"
                style="fill: #fafafa"
            ></path>
            <path
                d="M411.74,173.78l-3.82,2.2c-.11.07-.2,0-.2-.2v-.72a.54.54,0,0,1,.2-.43l3.82-2.2c.12-.07.21,0,.21.19v.72A.54.54,0,0,1,411.74,173.78Z"
                style="fill: #37474f"
            ></path>
            <path
                d="M419.38,110.4,414,107.31h0l-.05,0h0c-2.37-1.34-5.62-1.14-9.22.94-7.25,4.19-13.12,14.36-13.12,22.73,0,4.18,1.46,7.12,3.83,8.49h0l4.86,2.77,1.16-1.94a13.21,13.21,0,0,0,3.77-1.46c7.24-4.19,13.12-14.36,13.12-22.73a14.38,14.38,0,0,0-.41-3.36Z"
                style="fill: #f27531"
            ></path>
            <path
                d="M391.64,131c0,4.18,1.46,7.12,3.83,8.49h0l4.86,2.77,1.16-1.94a13.21,13.21,0,0,0,3.77-1.46A27.26,27.26,0,0,0,415,128.15l-19-11A27.27,27.27,0,0,0,391.64,131Z"
                style="fill: #263238"
            ></path>
            <path
                d="M419.39,110.4l-5.34-3.09h0l-.05,0h0c-2.37-1.33-5.62-1.14-9.22.94a26.21,26.21,0,0,0-8.76,8.93l19,11a26.29,26.29,0,0,0,3.36-12.05,14.38,14.38,0,0,0-.41-3.36Z"
                style="fill: #455a64"
            ></path>
            <path
                d="M409.83,111.14c-7.24,4.19-13.12,14.36-13.12,22.73s5.88,11.77,13.12,7.58S423,127.09,423,118.72,417.08,107,409.83,111.14Z"
                style="fill: #37474f"
            ></path>
            <path
                d="M405,124.46c0-1.9,1-4,2.24-4.74s2.24.25,2.24,2.16-1,4-2.24,4.74S405,126.37,405,124.46Z"
                style="fill: #fff"
            ></path>
            <path
                d="M411.24,134.06,403,138.82l.44-5.48c.19-2.33,1.42-4.72,2.88-5.56l1.59-.92c1.46-.84,2.69.12,2.88,2.24Z"
                style="fill: #fff"
            ></path>
            <path
                d="M410.44,118.75c0-1.9,1-4,2.24-4.74s2.24.25,2.24,2.16-1,4-2.24,4.74S410.44,120.66,410.44,118.75Z"
                style="fill: #fff"
            ></path>
            <path
                d="M416.66,128.35l-5.05,2.92-.23-2.62c-.17-1.84-1-3-2.1-3a6.41,6.41,0,0,1,2.47-3.55l1.59-.91c1.46-.85,2.69.11,2.88,2.23Z"
                style="fill: #fff"
            ></path>
        </g>
        <g id="freepik--magnifying-glass--inject-62">
            <g id="freepik--magnifying-glass--inject-62">
                <path
                    d="M282.5,314.82c0-6.2-4.1-12.4-12.3-17.13-16.39-9.47-43-9.47-59.36,0-8.2,4.73-12.3,10.93-12.3,17.13h0v11.43c0,6.2,4.1,12.4,12.3,17.14,16.39,9.46,43,9.46,59.36,0,8.2-4.74,12.3-10.94,12.3-17.14V314.82ZM212.87,301.2c7.32-4.23,17.14-6.55,27.65-6.55s20.33,2.32,27.65,6.55c6.62,3.82,10.27,8.66,10.27,13.62s-3.65,9.8-10.27,13.62C260.85,332.67,251,335,240.52,335s-20.33-2.33-27.65-6.56c-6.62-3.82-10.27-8.66-10.27-13.62S206.25,305,212.87,301.2Z"
                    style="fill: #f27531"
                ></path>
                <path
                    d="M210.83,332c16.4,9.46,43,9.46,59.37,0s16.39-24.81,0-34.27-43-9.47-59.37,0S194.44,322.5,210.83,332Zm-8.23-17.14c0-5,3.64-9.8,10.26-13.62,7.32-4.23,17.15-6.55,27.66-6.55s20.33,2.32,27.65,6.55c6.62,3.82,10.26,8.66,10.26,13.62s-3.64,9.8-10.26,13.62C260.85,332.67,251,335,240.52,335s-20.34-2.33-27.66-6.56C206.24,324.62,202.6,319.78,202.6,314.82Z"
                    style="fill: #fff; opacity: 0.30000000000000004"
                ></path>
                <path
                    d="M202.6,314.82a11.27,11.27,0,0,0,1.64,5.72c1.66-2.88,4.59-5.59,8.63-7.91,7.31-4.23,17.14-6.56,27.65-6.56s20.33,2.33,27.65,6.56a23.08,23.08,0,0,1,8.64,7.91,11.35,11.35,0,0,0,1.63-5.72c0-5-3.65-9.8-10.27-13.62-7.32-4.22-17.14-6.55-27.65-6.55s-20.34,2.33-27.65,6.55C206.24,305,202.6,309.86,202.6,314.82Z"
                    style="fill: #f27531"
                ></path>
                <path
                    d="M202.6,314.82a11.27,11.27,0,0,0,1.64,5.72c1.66-2.88,4.59-5.59,8.63-7.91,7.31-4.23,17.14-6.56,27.65-6.56s20.33,2.33,27.65,6.56a23.08,23.08,0,0,1,8.64,7.91,11.35,11.35,0,0,0,1.63-5.72c0-5-3.65-9.8-10.27-13.62-7.32-4.22-17.14-6.55-27.65-6.55s-20.34,2.33-27.65,6.55C206.24,305,202.6,309.86,202.6,314.82Z"
                    style="opacity: 0.1"
                ></path>
                <path
                    d="M212.86,306.37c7.32-4.22,17.15-6.55,27.66-6.55s20.33,2.33,27.65,6.55c5.46,3.16,8.89,7,9.93,11-1,4-4.47,7.88-9.93,11C260.85,332.67,251,335,240.52,335s-20.34-2.33-27.66-6.56c-5.46-3.15-8.89-7-9.92-11C204,313.37,207.4,309.53,212.86,306.37Z"
                    style="fill: #fff; opacity: 0.4"
                ></path>
                <path
                    d="M230.63,338.38V349.8a55.09,55.09,0,0,1-19.79-6.41c-8.2-4.74-12.3-10.94-12.3-17.14V314.82a14.51,14.51,0,0,1,.5-3.74h0c-2,7.38,1.94,15.19,11.79,20.88A55.1,55.1,0,0,0,230.63,338.38Z"
                    style="opacity: 0.1"
                ></path>
                <path
                    d="M212.08,339.23a6.49,6.49,0,0,0-2.92-5.1,2.07,2.07,0,0,0-2.07-.22h0L193,342h0a2,2,0,0,0-.86,1.89A6.5,6.5,0,0,0,195,349a2.06,2.06,0,0,0,2.08.21h0l14.13-8.06h0A2.08,2.08,0,0,0,212.08,339.23Z"
                    style="fill: #37474f"
                ></path>
                <path
                    d="M211.21,341.14a2.08,2.08,0,0,0,.87-1.91,5.59,5.59,0,0,0-.49-2.2l-18.23,10.42A5.68,5.68,0,0,0,195,349a2.06,2.06,0,0,0,2.08.21l14.13-8.06Z"
                    style="fill: #263238"
                ></path>
                <path
                    d="M197.38,349a8.45,8.45,0,0,0-3.8-6.65,2.69,2.69,0,0,0-2.66-.29h0l0,0h0l-46.87,25,7,12.36,45.24-28A2.67,2.67,0,0,0,197.38,349Z"
                    style="fill: #f27531"
                ></path>
                <path
                    d="M151,379.41l45.24-28a2.67,2.67,0,0,0,1.13-2.47,7.94,7.94,0,0,0-1.27-4L147.4,373Z"
                    style="opacity: 0.1"
                ></path>
                <path
                    d="M147.47,379a11.1,11.1,0,0,1-5-8.72c0-3.2,2.26-4.49,5-2.88a11.08,11.08,0,0,1,5,8.71C152.49,379.36,150.24,380.65,147.47,379Z"
                    style="fill: #f27531"
                ></path>
                <g style="opacity: 0.2">
                    <path
                        d="M147.47,379a11.1,11.1,0,0,1-5-8.72c0-3.2,2.26-4.49,5-2.88a11.08,11.08,0,0,1,5,8.71C152.49,379.36,150.24,380.65,147.47,379Z"
                    ></path>
                </g>
            </g>
        </g>
        <g id="freepik--Character--inject-62">
            <g id="freepik--character--inject-62">
                <path
                    d="M423.73,268.56c.51-19-1-23.57-6.07-27.06l-2,11s2.31,13,2.06,17.31c-.21,3.8-.58,8.56-1.48,11.63a2.33,2.33,0,0,1-1,1.3,13.51,13.51,0,0,0-2.78,2.65c-.76.8-1.41,1-1.31,1.25a1.25,1.25,0,0,0,1.6.57,8.55,8.55,0,0,0,1.83-1,11.74,11.74,0,0,1-1.87,3.22c-1.81,2.21-1.89,3.57,1.76,3.61,2.29,0,4.12-1.39,5.84-6.43a43.11,43.11,0,0,0,1.61-5.66A102.19,102.19,0,0,0,423.73,268.56Z"
                    style="fill: #ffa8a7"
                ></path>
                <path
                    d="M413.4,240.06a7.29,7.29,0,0,1,7.82,3.95c2.12,3.79,2.64,10.32,2.88,16.81,0,0-4.21,3.53-9.27,1.8Z"
                    style="fill: #f27531"
                ></path>
                <path
                    d="M420.51,344.66c.38.15.48.88.54,2.33,0,1.1.27,3-1,3.41s-3.18.18-4-.85c-1-1.3-1.73-2.43-3.2-4-1.28-1.37-2.66-2.65-3.17-4.57-.47-1.74-.08-2.49,1.1-3,1.63-.69,4.19,1.58,5.47,1.93C417.33,340.21,420.12,344.52,420.51,344.66Z"
                    style="fill: #263238"
                ></path>
                <path
                    d="M420.51,343.32v1.78a4.36,4.36,0,0,1-4.51.12c-.08-.17-.47-2.55-.47-2.55Z"
                    style="fill: #ffa8a7"
                ></path>
                <path
                    d="M406.05,355a27.93,27.93,0,0,1,.79,2.91c.22,1.08.54,2.88.16,3.69s-1.85,1.34-4.14,1.32a13.37,13.37,0,0,0-5.36,1.87,14.13,14.13,0,0,1-6.75,1.07c-2-.23-4.14-1.41-4.47-2.25s.11-1.72,4.9-3.76c0,0,6.66-2.17,9-4.73Z"
                    style="fill: #263238"
                ></path>
                <path
                    d="M406.05,353v2.08c0,.76-.88,1.89-3.2,1.8-1.62-.06-2.76-.49-2.69-1.83v-1.93Z"
                    style="fill: #ffa8a7"
                ></path>
                <path
                    d="M421.67,319.64a48.55,48.55,0,0,0-2.11-9.3s.64-8,.94-15.62c.33-8.21,0-18.54.14-20.59,0,0-26.5-5.36-27,4.14s1,33.66,1.36,37.83,1,7.85,1.89,14.61,3.24,22.43,3.24,22.43a6.24,6.24,0,0,0,5.89-.12s1.87-19,1.67-23.79a41.33,41.33,0,0,0-1.82-10.51s.5-7.83.94-14.64.92-10.08,1-13l.57-.28s.58,11.75.9,15.92,1,8.17,2.18,15.91c1.05,6.72,4.16,20.94,4.16,20.94,3,1.64,4.84-.21,4.84-.21S422.48,325.47,421.67,319.64Z"
                    style="fill: #37474f"
                ></path>
                <path
                    d="M407.86,291s6.43-3.17,9.65-6c0,0-3,4.21-6.91,6.07,0,0-1.4,14.6-1.39,14.31L408.4,291Z"
                    style="fill: #263238"
                ></path>
                <polygon
                    points="402.14 237.05 403.71 245.68 411.5 245.11 409.87 235.33 402.14 237.05"
                    style="fill: #ffa8a7"
                ></polygon>
                <path
                    d="M419.74,244.48c-.78-2.35-3.75-4.9-6.73-4.35A88.66,88.66,0,0,0,400,243.39c-2.73,1-5.21,3.33-5.44,12.3,0,0-.91,20.92-.93,25a33.09,33.09,0,0,0,17.91,1.77c8.2-1.79,9.19-5.19,9.19-5.19s.1-15-.14-21.54C420.34,250.18,420.27,246.09,419.74,244.48Z"
                    style="fill: #f5f5f5"
                ></path>
                <path
                    d="M396.12,226.55s-2,3.15-1.73,3.45,1.75.54,1.75.54Z"
                    style="fill: #ffa8a7"
                ></path>
                <path
                    d="M401.61,217.83c-4.12,1-5.27,2.82-5.52,9.4-.25,6.89.5,9,1.43,9.94.62.61,4,.65,5.71.14,2.13-.64,6.92-2.46,9.11-6,2.57-4.13,3.15-9.66.24-11.86C408.5,216.39,403.29,217.44,401.61,217.83Z"
                    style="fill: #ffa8a7"
                ></path>
                <path
                    d="M396.14,219.55a8.41,8.41,0,0,0,3.84,3.06c.79.9.35,4.41,1.74,5,0,0,.6-2.56,2.75-2.29s2.51,3,1.19,4.72-2.6,1.09-2.6,1.09.15,3.63,1.81,5.65a11,11,0,0,0,5.19-.24c2.06-.49,4.93-3.15,6.1-5.92,2.48-5.85,2.54-11.07-1.2-12.55-.46-2.67-2.59-3.82-5-4.31-5.19-1.07-8.53,2.17-12.52,1.88C395.15,215.45,394.72,217.12,396.14,219.55Z"
                    style="fill: #263238"
                ></path>
                <path
                    d="M415.05,218.93l2.44-1.52a1.44,1.44,0,1,0-2.44,1.52Z"
                    style="fill: #263238"
                ></path>
                <path
                    d="M367,258.93c1.28.57,16.38,11.73,18.74,10.43s14.43-15.42,14.43-15.42a9.75,9.75,0,0,0,0-10.59,10,10,0,0,0-5.9,3.79c-2,3.06-10.78,14-10.78,14-2.16-1.08-4.32-2.18-6.46-3.29-1.77-.92-3.55-1.83-5.28-2.82a6.16,6.16,0,0,1-1.36-.84c-.86-.84-.71-2.89-.69-4a13.3,13.3,0,0,1,.07-1.55c.08-.64.2-2.19-.95-1.7a1.49,1.49,0,0,0-.54.46,2.53,2.53,0,0,0-.41.78c-.22.67-.15,1.41-.34,2.08a.56.56,0,0,1-.15.27.46.46,0,0,1-.36.11c-1.66-.05-3.24-2.38-4-3.62a3.47,3.47,0,0,0-1.26-1.44,2.26,2.26,0,0,0-2.59,1,5.27,5.27,0,0,0-.83,3.67,7.3,7.3,0,0,0,2.17,4.6A22.31,22.31,0,0,0,367,258.93Z"
                    style="fill: #ffa8a7"
                ></path>
                <path
                    d="M401.52,242.9c-1.66.19-4-.12-7.26,3.39a102.61,102.61,0,0,0-7.35,9.45,9.28,9.28,0,0,0,7.31,6.88s5.71-5.86,7.3-9.08S403.49,245.08,401.52,242.9Z"
                    style="fill: #f27531"
                ></path>
            </g>
        </g>
    </svg>
</template>

<script>
export default {};
</script>

