<template>
  <v-container>
    <v-row >
      <v-col :cols="12" :md="5" v-if="practica.revision.revisada">
        <v-row class="ml-3 mb-3">
          <div v-if="practica.revision.fecha">
            <v-row align="center">
              <v-avatar size="32" color="green" class="ml-3 mr-3">
                <v-icon class="white--text">mdi-check</v-icon>
              </v-avatar>
              <p class="mb-0 font-weight-bold">
                Fecha de practica correcta
              </p>
            </v-row>
          </div>

          <div v-else>
            <v-row align="center">
              <v-avatar size="32" color="red" class="ml-3 mr-3">
                <v-icon class="white--text">mdi-close</v-icon>
              </v-avatar>
              <p class="mb-0 font-weight-bold">
                Fecha de practica en periodo de clases
              </p>
            </v-row>
          </div>
        </v-row>
        <DarkCard>
          <v-row>
            <v-col>
              <h5 class="mb-0 font-weight-bold">Inicio:</h5>
              <p class="mb-0 font-italic">
                {{ moment(practica.fecha_inicio).format("D [de] MMMM, YYYY") }}
              </p>
            </v-col>
            <v-col v-if="practica.fecha_termino">
              <div>
                <h5 class="mb-0 font-weight-bold">Termino:</h5>

                <p class="mb-0 font-italic">
                  {{
                    moment(practica.fecha_termino).format("D [de] MMMM, YYYY")
                  }}
                </p>
              </div>
            </v-col>
          </v-row>
        </DarkCard>

         <v-row class="ml-3 mb-3">
          <div v-if="practica.revision.horas">
            <v-row align="center">
              <v-avatar size="32" color="green" class="ml-3 mr-3">
                <v-icon class="white--text">mdi-check</v-icon>
              </v-avatar>
              <p class="mb-0 font-weight-bold">
                Horas realizadas corresponde al modo de práctica
              </p>
            </v-row>
          </div>

          <div v-else>
            <v-row align="center">
              <v-avatar size="32" color="red" class="ml-3 mr-3">
                <v-icon class="white--text">mdi-close</v-icon>
              </v-avatar>
              <p class="mb-0 font-weight-bold">
                Horas realizadas son menores a lo esperado
              </p>
            </v-row>
          </div>
        </v-row>
        <DarkCard>
          <v-row>
            <v-col>
              <h5 class="mb-0 font-weight-bold">Modo:</h5>
              <p class="mb-0 font-italic">
                {{ practica.modo }}
              </p>
            </v-col>
            <v-col>
              <div>
                <h5 class="mb-0 font-weight-bold">Horas realizadas:</h5>

                <p class="mb-0 font-italic">
                  {{ practica.progreso + " Horas" }}
                </p>
              </div>
            </v-col>
          </v-row>
        </DarkCard>

        <v-row class="ml-3 mb-3">
          <div v-if="!practica.revision.notas_bajas">
            <v-row align="center">
              <v-avatar size="32" color="green" class="ml-3 mr-3">
                <v-icon class="white--text">mdi-check</v-icon>
              </v-avatar>
              <p class="mb-0 font-weight-bold">
                No posee más de dos notas bajo 4
              </p>
            </v-row>
          </div>

          <div v-else>
            <v-row align="center">
              <v-avatar size="32" color="red" class="ml-3 mr-3">
                <v-icon class="white--text">mdi-close</v-icon>
              </v-avatar>
              <p class="mb-0 font-weight-bold">
                Posee más de dos notas bajo 4
              </p>
            </v-row>
          </div>
        </v-row>
        <DarkCard v-if="practica.revision.notas_bajas">
          <v-row v-for="nota in practica.notas" :key="nota.id">
            <v-chip v-if="nota.nota < 4" class="mx-2 my-0" text-color="white">
              <v-avatar :color="getColor(nota.nota)">
                {{ nota.nota }}
              </v-avatar>
              <p class="mb-0 ml-2">{{ nota.competencia_evaluada }}</p>
            </v-chip>
          </v-row>
        </DarkCard>



        <v-row class="ml-3 mb-3">
          <div v-if="practica.revision.evaluador">
            <v-row align="center">
              <v-avatar size="32" color="green" class="ml-3 mr-3">
                <v-icon class="white--text">mdi-check</v-icon>
              </v-avatar>
              <p class="mb-0 font-weight-bold">
                El evaluador aprobó la práctica
              </p>
            </v-row>
          </div>

          <div v-else>
            <v-row align="center">
              <v-avatar size="32" color="red" class="ml-3 mr-3">
                <v-icon class="white--text">mdi-close</v-icon>
              </v-avatar>
              <p class="mb-0 font-weight-bold">
                El evaluador rechazó la práctica
              </p>
            </v-row>
          </div>
        </v-row>
      </v-col>
  
  

      <v-col :cols="12" :md="practica.revision.revisada? 7: 12">
       

           <h3 class="ma-3">Cambiar estado de Práctica</h3>
    <Select
      label="Estado Practica"
      :items="estado_practica"
      v-model="nuevo_estado"
      itemValue="id"
      itemText="nombre"
      icon="mdi-city"
      name="El estado"
      rules="required"
      :onChange="() => onChange(nuevo_estado)"
    />
    <div v-if="practica.estado.id != nuevo_estado">

        <text-area :height="400" v-model="log"> </text-area>

        <v-row justify="end">

          <v-dialog
            v-model="dialog_cambio_estado"
            width="30vw"
            >
            <v-overlay :value="loading">
                    <v-progress-circular
                        :size="70"
                        :width="7"
                        color="primary"
                        indeterminate
                    ></v-progress-circular>
                </v-overlay>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                class="ma-3"
                v-bind="attrs"
                v-on="on"
              >
                Cambiar Estado
              </v-btn>
            </template>

            <v-card>
              <v-card-title >
                Confirmación de cambio de estado de práctica
              </v-card-title>

              <v-card-text>
                ¿Estás seguro de cambiar el estado de la práctica a "{{estado_practica.find(estado => estado.id === this.nuevo_estado).nombre}}"?
              </v-card-text>

              <v-card-actions>
                <v-btn
                  color="primary"
                  text
                  @click="dialog_cambio_estado = false"
                >
                  Cancelar
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  text
                  @click="cambiar_estado()"

                >
                  Aceptar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
         
    </div>
    

    
    <DarkCard title="Activar evaluación para ser reevaluado" v-if="practica.estado.id == 3 && !practica.convalidada">
      <p> Al apretar el botón la práctica volvera al estado "Esperando Evaluador" y el token de evaluación se pondra en modo reevaluación</p>
      <v-dialog
        v-model="dialog_reevaluar"
        width="500"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="primary"
            class="ma-3"
            v-bind="attrs"
            v-on="on"
          >
            Activar reevaluación práctica
          </v-btn>
        </template>

        <v-card>
          <v-card-title >
            ¿Activar reevaluación?
          </v-card-title>

          <v-card-text>
            Al apretar el botón la práctica volvera al estado "Esperando Evaluador" y el token de evaluación se pondra en modo reevaluación.
            <strong>No se enviará correo con la evaluación</strong>, solamente se activará el token y enlace ya existentes.
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="reevaluar"
            >
              Aceptar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </DarkCard>

    <DarkCard
      title="Descargar"
      v-if="
        (practica.estado.id == 4) |
          (practica.estado.id == 6) |
          (practica.estado.id == 9)
      "
    >
      <button-primary
        v-if="
          (practica.estado.id == 4) |
            (practica.estado.id == 6) |
            (practica.estado.id == 9)
        "
        :onClick="downloadPDF"
      >
        Descargar PDF
      </button-primary>
    </DarkCard>

    <DarkCard title="Consideraciones especiales del alumno" >
      <p v-if="practica.consideracion"> {{practica.consideracion}} </p>
      <p v-else > Sin consideraciones ingresada en esta práctica</p>
    </DarkCard>
    <!-- Si es una de carrera Civil industrial, es práctica Profesional y además tiene reparos que vienen de la industrial -->
    <!-- Entonces se muestra una tarjeta con la información de la práctica anterior -->
    <div v-if="!practica.convalidada">
      <DarkCard v-if="practica.alumno.carrera.id == 1 && practica.tipo == 'Profesional' && practica.reparos_industrial" title="Supervisor práctica anterior confirmó que hay que hacer reparos" >
        <v-row>
          <v-col cols="12" md="7">
            <p> Feedback recibido en práctica Industrial</p>
            <v-card class="pa-4">
              <p>
                {{practica.evaluacion_industrial.evaluacion.observaciones_alumno}}
              </p>
            </v-card>
          </v-col>

          <v-col cols="12" md="5">
            <p> Evaluación obtenida en la práctica Industrial: </p>
            <v-row v-for="nota in practica.evaluacion_industrial.notas" :key="nota.id">
              <v-chip class="mx-2 my-0" text-color="white" color="#00000000">
                <v-avatar :color="getColor(nota.nota)">
                  {{ nota.nota }}
                </v-avatar>
                <p class="mb-0 ml-2">{{ nota.competencia_evaluada }}</p>
              </v-chip>
            </v-row>
          </v-col>
        </v-row>
      </DarkCard>
      <DarkCard v-if="practica.estado.id == 3 && practica.evaluacion.reparos && !practica.reparos_industrial" title="Supervisor práctica anterior confirmó que hay que hacer reparos" >
        <v-row>
          <v-col cols="12" md="7">
            <p v-if="practica.alumno.carrera.id == 1 && practica.tipo == 'Industrial'"> El supervisor da aviso que los puntos da evaluación de esta práctica más el feedback dado deben ser mejorados en la proxima práctica profesional (Se mostrarán automaticamente cuando se revise la proxima práctica.)</p>
            <p v-if="practica.alumno.carrera.id == 3 && practica.tipo == 'Profesional'"> El supervisor da aviso que los puntos da evaluación de esta práctica más el feedback dado deben ser considerados en la aprobación de esta práctica</p>
            Feedbaack del supervisor a tener en cuenta:
            <v-card class="pa-4">
              <p>
                {{practica.evaluacion.observaciones_alumno}}
              </p>
            </v-card>
          </v-col>
        </v-row>
      </DarkCard>
    </div>


    <DarkCard
      title="Reenviar correo de evaluacion"
      v-if="practica.estado.id == 2"
    >

      <h3 class="mb-5 ml-3 mt-5">Enviar email a través del sistema</h3>

      <button-primary
        v-if="practica.estado.id == 2"
        :onClick="() => email_evaluacion()"
      >
        Enviar Email de evaluacion
      </button-primary>
      <div v-if="practica.supervisor && practica.token_evaluacion ">
 <h3 class="mb-5 ml-3 mt-5">Enviar email manual</h3>
      <text-field :label="'E-mail'" :value="practica.supervisor.email"></text-field>
      <text-field :label="'Asunto'" :value="'Evaluación Práctica - ' + practica.alumno.first_name + ' ' + practica.alumno.last_name + ' [NO RESPONDER]'"></text-field>

      <text-area :label="'Mensaje'" :height="700" :value="'Estimada/o supervisor ' + practica.supervisor.nombre + ' ' + practica.supervisor.apellido + '.'  + '\n\nNuestro/a estudiante ' + practica.alumno.first_name + ' ' + practica.alumno.last_name + '  ha dado por finalizada la práctica en nuestro sistema, por lo que es necesaria la evaluación de su parte.'+ '\n\nLos puntos a evaluar son los siguientes con escala de 1 a 5 donde 1 es el peor puntaje y 5 el mejor: \n- '+ armar_competencias() +'\n\nPara evaluar ingrese a: https://practicas.inf.utfsm.cl/#/evaluar-practica/' + practica.token_evaluacion.token + '\n\nMuchas gracias.'"> </text-area>

      </div>
      <button-primary
        :onClick="() => email_manual()"
      >
        He enviado el email manualmente
      </button-primary>
    </DarkCard>

    <DarkCard
      title="Reenviar correo de confirmacion"
      v-if="practica.estado.id == 11"
    >
      <h3 class="mb-5 ml-3 mt-5">Enviar email a través del sistema</h3>
      <button-primary
        v-if="practica.estado.id == 11"
        :onClick="() => email_confirmacion()"
      >
        Enviar Email de Confirmacion
      </button-primary>
      <div v-if="practica.supervisor && practica.token_confirmacion ">

      <h3 class="mb-5 ml-3 mt-5">Enviar email manual</h3>
      <text-field :label="'E-mail'" :value="practica.supervisor.email"></text-field>
      <text-field :label="'Asunto'" :value="'Confirmación Práctica - ' + practica.alumno.first_name + ' ' + practica.alumno.last_name + ' [NO RESPONDER]'"></text-field>

      <text-area :label="'Mensaje'" :height="700" :value="'Estimada/o supervisor ' + practica.supervisor.nombre + ' ' + practica.supervisor.apellido + '.'  + '\n\nNuestro/a estudiante ' + practica.alumno.first_name + ' ' + practica.alumno.last_name + ' ha ingresado en nuestro sistema una práctica en la empresa ' + practica.sucursal.empresa.nombre + '.\n\nPara que pueda hacer inicio de ésta práctica se necesita su confirmación. \n\nPara confirmar ingrese a: https://practicas.inf.utfsm.cl/#/confirmar-practica/' + practica.token_confirmacion.token + '\n\nLas competencias que se busca evaluar en el desarollo de esta práctica son las siguientes: \n- '+ armar_competencias() + '\n\nMuchas gracias.'"> </text-area>

         <button-primary
        :onClick="() => email_manual()"
      >
        He enviado el email manualmente
      </button-primary>
         </div>
    </DarkCard>

      </v-col>
    </v-row>

   
  </v-container>
</template>

<script>
import DarkCard from "@/components/content/DarkCard.vue";
import axios from "axios";

import Select from "../../forms/Select.vue";
import ButtonPrimary from "@/components/forms/ButtonPrimary.vue";
import TextArea from "@/components/forms/TextArea.vue";

import moment from "moment";
import TextField from '@/components/forms/TextField.vue';
moment.locale("es");

export default {
  components: { DarkCard,    Select,
    ButtonPrimary,
    TextArea,
    TextField, },
  props: ["practica", "alumno"],
   data: () => ({
    log: "",
    nuevo_estado: null,
    dialog_reevaluar: false,
    dialog_cambio_estado: false,
    loading: false,
    competencias: [],
  }),
  computed: {
    estado_practica() {
      return this.$store.state.tables.estado_practica.items;
    },
  },
  watch: {
    practica: {
      immediate: true,
      deep: true,
      handler() {
        this.nuevo_estado = this.practica.estado.id;
      },
    },
  },

  methods: {
    getColor(nota) {
      if (nota == 5) {
        return "green darken-1";
      } else if (nota == 4) {
        return "lime darken-1";
      } else if (nota == 3) {
        return "yellow darken-2";
      } else if (nota == 2) {
        return "orange darken-2";
      } else {
        return "red darken-3";
      }
    },

    onChange(estado) {
      switch (estado) {
        case 1:
          this.log = "Tu práctica ha pasado a estar en proceso.";
          break;
        case 2:
          this.log =
            "Tu práctica ha pasado a estar en espera de la evaluacion de tu supervisor.";
          break;
        case 3:
          this.log =
            "Tu práctica ha pasado a estar en espera de revisión del encargado de prácticas.";
          break;
        case 4:
          this.log =
            "Tu práctica ha sido aprobada exitosamente, será despachada lo más pronto posible a Dirección de Estudios para su aparición en SIGA.";
          break;
        case 5:
          this.log =
            "Tu práctica ha sido rechazada, es importante para tu próxima practica enmendar la situación ahora presentada pues el despemño laboral es clave para tu desarrollo profesional.";
          break;
        case 6:
          this.log =
            "Tu práctica ha sido despachada a Dirección de Estudios con estado de Aprobada.";
          break;
        case 7:
          var notas_bajas = 0;
          this.practica.notas.forEach((nota) => {
            if (nota.nota < 4) {
              notas_bajas += 1;
            }
          });

          this.log = `Tu práctica no ha podido ser aprobada, esto debido a que en ${notas_bajas} competencias tienes una nota bajo el promedio de Informática, se requiere nos expliques porque según tu criterio la evaluación fue así.
          `;
          this.log += `
Las competencias fueron:

`;

          this.practica.notas.forEach((nota) => {
            if (nota.nota < 4) {
              this.log += `(${nota.nota}) - ${nota.competencia_evaluada}
`;
            }
          });

          this.log += `
1: Insuficiente - 2: Suficiente - 3: Aceptable - 4: Bueno - 5: Excelente`;

          break;
        case 8:
          this.log =
            "Tu práctica ha sido despachada a Dirección de Estudios con estado de Rechazada.";
          break;
        case 9:
          this.log = "Tu convalidación de práctica ha sido aprobada.";
          break;
        case 10:
          this.log =
            "Tu práctica ha sido eliminada del sistema, ya eres capaz de inscribir otra práctica.";
          break;
        case 11:
          this.log =
            "Tu práctica ha pasado a estar en espera de la confirmación de tu supervisor.";
          break;

        default:
          break;
      }
    },


    email_manual() {
      this.$store.dispatch("tables/post", {
        table: "email_manual",
        item: {
          practica: this.practica.token,
        },
        success_message: "Hora de ultimo email actualizada",
      });
    },

    email_confirmacion() {
      this.$store.dispatch("tables/post", {
        table: "email_confirmacion",
        item: {
          practica: this.practica.token,
        },
        success_message: "Email de confirmación enviado correctamente",
      });
    },

    email_evaluacion() {
      this.$store.dispatch("tables/post", {
        table: "email_evaluacion",
        item: {
          practica: this.practica.token,
        },
        success_message: "Email de evaluación enviado correctamente",
      });
    },


    downloadPDF() {
      this.download(
        `${process.env.VUE_APP_API_URL}/practicas/${this.practica.token}/pdf/`,
        `${this.alumno.rol}_${this.practica.tipo}_${this.practica.campus}.pdf`,
        false
      )
    },

    cambiar_estado() {
      this.loading =  true
      if (this.nuevo_estado == 4) {
        this.download(
          `${process.env.VUE_APP_API_URL}/practicas/${this.practica.token}/aprobar/`,
          `${this.alumno.rol}_${this.practica.tipo}_${this.practica.campus}.pdf`,
          true
        );
      } else {
        this.$store.dispatch("tables/post", {
          endpoint: `practicas/${this.practica.token}/cambiar_estado`,
          table: "cambiar_estado",
          item: {
            nuevo_estado: this.nuevo_estado,
            log: this.log,
          },
          reload: true,
        }).then(() => {
          this.loading =  false
        }

        )
      }
      dialog_cambio_estado = false
    },

    download(url, name, redirect) {
      axios({
        url: url,
        method: "GET",
        responseType: "blob",
      }).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", name);
        document.body.appendChild(fileLink);

        fileLink.click();
        this.loading =  false
        if (redirect) {
          this.$router.push("/admin_revisiones");
        }
      });
    },
    reevaluar() {
      this.$store.dispatch("tables/edit", {
        endpoint: `practicas/${this.practica.token_evaluacion.token}/reevaluar`,
        item: {},
        reload: true,
      });
      this.dialog_reevaluar = false
    },

    armar_competencias() {
      return this.competencias.join("\n- ")
    }

  },

  created() {
    this.$store.dispatch("tables/get", {
      endpoint: "practica/estados",
      table: "estado_practica",
    }).then(() => {
      // this.estado_practica = this.$store.state.tables.estado_practica.items;
      if (!this.practica.convalidada && !this.practica.evaluacion) {
        var no_mostrar= ["Aprobada", "Rechazada", ];
        this.$store.state.tables.estado_practica.items = this.$store.state.tables.estado_practica.items.filter(estado => !no_mostrar.includes(estado.nombre));;
      }
    });
    return axios.get(`${process.env.VUE_APP_API_URL}/competencias_tipo?tipo=${this.practica.tipo}`).then(response => {

      this.competencias = response.data.tipos

    })
  },

};
</script>

<style scoped></style>
