import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#FF9800",
        secondary: '#E3E6F2',
        error: '#b71c1c'
      },
      dark: {  
        primary: "#E65100",
        secondary: '#1E1E1E',
        error: '#FF0000'
      },
    },
  },

  })