<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 45 500 450">
        <g id="freepik--Floor--inject-73">
            <ellipse
                id="freepik--floor--inject-73"
                cx="253.11"
                cy="357.63"
                rx="227.88"
                ry="125.27"
                style="fill: rgba(0, 0, 0, 0.2)"
            ></ellipse>
        </g>
        <g id="freepik--Shadows--inject-73">
            <ellipse
                id="freepik--Shadow--inject-73"
                cx="346.07"
                cy="380.96"
                rx="58.69"
                ry="33.88"
                style="fill: rgba(0, 0, 0, 0.3)"
            ></ellipse>
            <ellipse
                id="freepik--shadow--inject-73"
                cx="205.58"
                cy="382.89"
                rx="56.27"
                ry="32.49"
                style="fill: rgba(0, 0, 0, 0.3)"
            ></ellipse>
            <path
                id="freepik--shadow--inject-73"
                d="M85.86,363.29l50.63,29.23c13,7.49,34.17,7.49,47.14,0L226,368.09c9.57-5.52,9.58-14.56,0-20.09l-50.63-29.28c-12.95-7.49-34.15-7.5-47.11,0L85.86,343.21C76.29,348.73,76.29,357.77,85.86,363.29Z"
                style="fill: rgba(0, 0, 0, 0.3)"
            ></path>
        </g>
        <g id="freepik--speech-bubble--inject-73">
            <path
                d="M283.1,96.5a42.23,42.23,0,1,0-70,23.43c-2.11,4.63-6,11.38-11.13,13.43,0,0,12,6,22.56-6a42.24,42.24,0,0,0,53.78-17.86c14.27,4.94,21.82-5.49,21.82-5.49C294.16,104.66,287,99.64,283.1,96.5Z"
                style="fill: #f27531"
            ></path>
            <path
                d="M249.06,89l1.59-4.63a.62.62,0,0,0-.33-.74l-4-1.86a1,1,0,0,1-.47-.86,15.27,15.27,0,0,0-.15-2.54,1,1,0,0,1,.24-.92L249.9,75c.2-.13.22-.53,0-.91l-1.95-4c-.18-.37-.51-.6-.74-.52l-4.36,1.56a1,1,0,0,1-.88-.37,13.36,13.36,0,0,0-1.91-1.68,1,1,0,0,1-.38-.9l1-4.27a.62.62,0,0,0-.38-.72l-4.62-1.59a.59.59,0,0,0-.72.28l-1.91,4.07a.79.79,0,0,1-.69.42,13.76,13.76,0,0,0-2.94.19,1,1,0,0,1-.83-.55l-2.14-3.4c-.22-.35-.57-.54-.79-.44l-4.57,2.23c-.21.11-.27.5-.13.89l1.36,3.79a1,1,0,0,1-.09,1,14.22,14.22,0,0,0-2,2.21.79.79,0,0,1-.76.27l-4.38-1a.58.58,0,0,0-.66.39l-1.6,4.62a.63.63,0,0,0,.34.74l4,1.87a1,1,0,0,1,.47.86,14.38,14.38,0,0,0,.15,2.54,1,1,0,0,1-.24.91l-3.92,2.48c-.21.13-.22.53,0,.9l1.95,4c.18.38.51.61.74.53l4.36-1.57a1,1,0,0,1,.87.37,14,14,0,0,0,1.91,1.69,1,1,0,0,1,.39.9l-1,4.27a.62.62,0,0,0,.38.72l4.62,1.59a.58.58,0,0,0,.71-.28L230.49,95a.77.77,0,0,1,.69-.42,14.3,14.3,0,0,0,2.93-.19,1,1,0,0,1,.83.54l2.15,3.41c.22.34.57.54.78.43l4.57-2.23c.22-.1.28-.5.14-.88l-1.36-3.79a1,1,0,0,1,.08-1,14.2,14.2,0,0,0,2-2.2.8.8,0,0,1,.76-.28l4.38,1A.59.59,0,0,0,249.06,89Zm-20.38.44a9.5,9.5,0,1,1,12.08-5.89A9.5,9.5,0,0,1,228.68,89.44Zm1-2.79A6.55,6.55,0,1,1,238,82.59,6.55,6.55,0,0,1,229.64,86.65Z"
                style="fill: #fafafa"
            ></path>
            <path
                d="M265.32,112.71l2.43-2.61a.44.44,0,0,0,0-.59l-2.09-2.42a.69.69,0,0,1-.06-.71,10.47,10.47,0,0,0,.65-1.74c.07-.27.26-.52.43-.54l3.35-.49c.18,0,.31-.29.3-.59l-.12-3.25c0-.3-.16-.56-.33-.57L266.5,99c-.17,0-.38-.24-.47-.5a10.76,10.76,0,0,0-.78-1.69.72.72,0,0,1,0-.71l1.91-2.57a.44.44,0,0,0,0-.59l-2.61-2.43a.43.43,0,0,0-.56,0l-2.48,2.15a.6.6,0,0,1-.59.08,10.6,10.6,0,0,0-2-.75c-.18,0-.35-.31-.39-.6l-.43-2.91c0-.3-.22-.53-.39-.52L254,88c-.18,0-.33.25-.36.55l-.21,2.93a.72.72,0,0,1-.35.63,11,11,0,0,0-1.95.89.57.57,0,0,1-.59,0l-2.63-2a.43.43,0,0,0-.56.07l-2.43,2.61a.45.45,0,0,0,0,.59L247,96.7a.69.69,0,0,1,.06.71,11.44,11.44,0,0,0-.65,1.74c-.07.27-.26.52-.43.54l-3.35.49c-.18,0-.31.29-.3.59l.12,3.25c0,.3.16.56.34.57l3.37.25c.18,0,.38.24.47.5a10.76,10.76,0,0,0,.78,1.69.71.71,0,0,1,0,.72l-1.91,2.56a.44.44,0,0,0,0,.59l2.61,2.43a.43.43,0,0,0,.56,0l2.48-2.15a.6.6,0,0,1,.59-.08,10.6,10.6,0,0,0,2,.75c.18,0,.35.31.39.6l.43,2.91c0,.3.22.53.39.52l3.71-.13c.18,0,.33-.25.36-.55l.21-2.93a.72.72,0,0,1,.35-.63,10.37,10.37,0,0,0,2-.89.57.57,0,0,1,.59,0l2.63,2A.43.43,0,0,0,265.32,112.71ZM251.59,107a6.93,6.93,0,1,1,9.8-.35A6.94,6.94,0,0,1,251.59,107Zm1.77-1.9a4.34,4.34,0,1,1,6.13-.22A4.34,4.34,0,0,1,253.36,105.07Z"
                style="fill: #fafafa"
            ></path>
        </g>
        <g id="freepik--character-2--inject-73">
            <path
                d="M278.26,178.84c2.81,0,37.38,3.71,42.33.92,4.69-2.65,19.61-39.17,19.61-39.17,1.55-13.51-2.94-17.25-2.94-17.25s-7.21-1.67-12.42,10.49c-2.9,6.76-12.76,31.86-12.76,31.86s-29.09,2.65-32.35.5c-1.66-.81-2.48-3-3.08-4.52-1-2.52-.5-5.37-.59-6.41-.16-1.82-2.81-.57-3.53,2.44-.53,2.19-.11,4.18-.93,4.62a18,18,0,0,1-3.86-.87c-1.79-.71-5-1.79-6.84-.46a2.37,2.37,0,0,0-1,2,9,9,0,0,1,0,3.23,6.21,6.21,0,0,0-.49,1.55c0,.71.39,1.17.49,1.84.14.93-.7,1.78-.62,2.72.06.67.59,1.22.75,1.88.1.42,0,.88.16,1.3a3,3,0,0,0,1.77,1.75,21.62,21.62,0,0,0,8.15,1.76C272.54,179.17,275.59,178.87,278.26,178.84Z"
                style="fill: #ffa8a7"
            ></path>
            <path
                d="M383.07,362a59.57,59.57,0,0,1,.93,9.75c.11,5.92,1.2,8,1.34,12.88.17,5.55-.79,7.63-3,10.75s-8.17,5.95-10.67.68-2.17-8.73-1.38-14.18c.83-5.63.94-8.74,1.41-13,.43-3.79.93-6.66.93-6.66Z"
                style="fill: #263238"
            ></path>
            <path
                d="M366.27,315.4l19.5-.35c-.29,13-2.36,34.69-2.7,46.9-.06,2.3.06,17.86-.81,22.6-1.3,7.1-9.74,6.14-10.19,1.89s.55-22.43.52-24.3c.08-7.83-3.6-28.28-5.52-42.34C367,319.11,366.36,316.08,366.27,315.4Z"
                style="fill: #ffa8a7"
            ></path>
            <path
                d="M337.61,351.82s1,.06,1.16.88c.19,1,.24,2.52.64,4.12a23.21,23.21,0,0,1,.7,7.73c-.34,1.81-4.49,4.52-7.33,5s-6.84,3.28-9.06,5.37a21.82,21.82,0,0,1-12.61,5c-4.74,0-8-1.58-9.6-4.12s1.42-2.84,6.84-6.35l4.2-2.73c8.58-5.6,11.72-8.61,14.14-14.21Z"
                style="fill: #263238"
            ></path>
            <path
                d="M326.62,348.77c.15-4-1.92-33.72-1.92-33.72l16.78,1.89c-4,23.67-4.29,31.73-3.84,35.18h0a3.44,3.44,0,0,1,.11,1c0,4.6-5.21,7.08-9.26,10.14-1.6,1.21-5.71,4.94-10.52,6.58-3.12,1.07-8.88,1.47-9.62-.42,4.93-3.77,11.63-8.68,15.55-13C325.77,354.38,326.48,352.8,326.62,348.77Z"
                style="fill: #ffa8a7"
            ></path>
            <path
                d="M371.8,174.89a70.92,70.92,0,0,1,12.28,25.27c5.53,22.36-3.17,70.2-3.17,70.2.28,3.24,4,9.63,5.37,23.9,1.63,16.87-3,62-3,62s-4.46,3.33-10.92.23c0,0-11.81-66.34-13.92-83.11-1.85-14.64-5.61-45-5.61-45l-8.45,45s1.18,8.2,1.79,14.44c.95,9.87-8.65,59.37-8.65,59.37s-4.5,2.37-10.88-.21c0,0-4.26-64-5.15-75.84-.92-12.23,2.26-40.73,4.6-63.42.71-6.84,4.17-27.94,5.38-33.8Z"
                style="fill: #455a64"
            ></path>
            <path
                d="M352.82,228.36l-2-13.85a34.41,34.41,0,0,1-16.3-5.27s5,6.82,13.65,7.63l1.27,12.39-4,38.62Z"
                style="fill: #37474f"
            ></path>
            <path
                d="M330.45,160.11c.21,4.07,1,13.75,1,13.75,16.61,11.24,40.34,1,40.34,1l3.46-23.61s-8.67-12.13-.53-24.92l-13-2.56-15.48-1.27-9,.81c-5.07,3.72-8.45,11.29-10.08,15.46S323.07,151.43,330.45,160.11Z"
                style="fill: #f27531"
            ></path>
            <path
                d="M330.45,160.11c.21,4.07,1,13.75,1,13.75,16.61,11.24,40.34,1,40.34,1l3.46-23.61s-8.67-12.13-.53-24.92l-13-2.56-15.48-1.27-9,.81c-5.07,3.72-8.45,11.29-10.08,15.46S323.07,151.43,330.45,160.11Z"
                style="fill: #fff; opacity: 0.6"
            ></path>
            <polygon
                points="295.76 171.53 337.82 174.36 359.2 221.76 319.02 218.03 295.76 171.53"
                style="fill: #f27531"
            ></polygon>
            <polygon
                points="295.76 171.53 337.82 174.36 359.2 221.76 319.02 218.03 295.76 171.53"
                style="opacity: 0.1"
            ></polygon>
            <path
                d="M334.68,204.32a9.52,9.52,0,0,0,7.51,6c5.57.73,10.93-2,10.93-2l-1.79-4Z"
                style="opacity: 0.15"
            ></path>
            <path
                d="M308.87,176.46l-.63-1.46c-.34-.81,2-1.3,5.23-1.09l6.19.39c3.24.21,6.14,1,6.48,1.84l.63,1.46Z"
                style="opacity: 0.15"
            ></path>
            <path
                d="M305,171.24c-.22-.51.71-.83,2.07-.72l2.46.2s-1.33-3.17,4.07-3,7.26,3.69,7.26,3.69l2.63.23c1.45.12,2.84.63,3.1,1.12l.48.89-21.66-1.46Z"
                style="fill: #263238"
            ></path>
            <path
                d="M308.14,172.34c2,.09,2.81.19,3.34,1.44.61,1.42.95,2.48.95,2.48l9.59.66s-.8-1.8-1.08-2.5-.06-1.21,1.47-1.11v-.6l-15-.89Z"
                style="fill: #263238"
            ></path>
            <path
                d="M308.64,176.65s-.27-.65-.62-1.46,2-1.3,5.23-1.09l6.19.39c3.24.21,6.14,1,6.48,1.84l.63,1.46Z"
                style="fill: #37474f"
            ></path>
            <path
                d="M339.44,197.78a26.54,26.54,0,0,0,8.14-2.36,19.79,19.79,0,0,1-4.57-1.24,2.84,2.84,0,0,1-1.75-3.45c.23-.57,1.61.12,4.11,0,3.2-.15,5.35-.7,8.74,0,1.26.26,3,1.14,4.12.5,6.3-3.65,22.92-15.41,24.26-16.84h0l-7.38-22.12S364.93,140.21,373,126c7.79.63,13,1.74,16.76,15.07,3.94,14,9.43,32.27,9.18,38s-15.42,11.76-26.77,17.41c-4.79,2.38-12.71,6.93-15.81,8.54-10.75,5.6-16,5.05-19.7,1.43C330.71,200.7,333,198.59,339.44,197.78Z"
                style="fill: #ffa8a7"
            ></path>
            <path
                d="M387.91,117.15h0a14.52,14.52,0,0,1-14.51-14.52V76h0a14.52,14.52,0,0,1,14.51,14.52Z"
                style="fill: #f27531"
            ></path>
            <path
                d="M387.91,117.15h0a14.52,14.52,0,0,1-14.51-14.52V76h0a14.52,14.52,0,0,1,14.51,14.52Z"
                style="opacity: 0.6000000000000001"
            ></path>
            <path
                d="M328.41,95.72a18.29,18.29,0,0,1-1.9-14.12l0-.08c3-10.52,11.69-18.33,23.54-18.42a27,27,0,0,1,19.84,8.36,3.35,3.35,0,0,1,.13-1.17,3.44,3.44,0,0,1,4.43-2.16l-1.55,5a3.54,3.54,0,0,1,1.68-1.22A3.45,3.45,0,0,1,379,74.11L373.4,76a25.73,25.73,0,0,1,3.68,13.06h0V90a22.24,22.24,0,0,1-22.22,22.23h-7.22C339.43,112.22,332.27,102.3,328.41,95.72Z"
                style="fill: #f27531"
            ></path>
            <g style="opacity: 0.55">
                <path
                    d="M328.41,95.72a18.29,18.29,0,0,1-1.9-14.12l0-.08c3-10.52,11.69-18.33,23.54-18.42a27,27,0,0,1,19.84,8.36,3.35,3.35,0,0,1,.13-1.17,3.44,3.44,0,0,1,4.43-2.16l-1.55,5a3.54,3.54,0,0,1,1.68-1.22A3.45,3.45,0,0,1,379,74.11L373.4,76a25.73,25.73,0,0,1,3.68,13.06h0V90a22.24,22.24,0,0,1-22.22,22.23h-7.22C339.43,112.22,332.27,102.3,328.41,95.72Z"
                ></path>
            </g>
            <path
                d="M334.53,79c-2.92,1.62-5.42,3.74-5.48,18.92-.05,12.86,4,16.15,6.1,17.12s6.12.53,10.06,0l-.1,6.33A28.09,28.09,0,0,0,342,140.65c8.07-6.25,20.17-16.83,20.17-16.83l.39-17.83s2.24,2.31,6.25-1c3.31-2.74,4.53-7.47,2.08-10.1a5.64,5.64,0,0,0-9,1.54c-8.41,0-11.6-10.7-12.14-16.54C345.1,75.07,339.42,87.69,334.53,79Z"
                style="fill: #ffa8a7"
            ></path>
            <path
                d="M341.86,140.65,359,126.38c1.44-1.2,3.19-2.48,3.23-4.35l.06-2.62a8.79,8.79,0,0,1,1.59,3c.8,2.24,2,6.37-3.48,11.29C354.48,139,341.86,140.65,341.86,140.65Z"
                style="fill: #37474f"
            ></path>
            <path
                d="M345.17,119.41a11.87,11.87,0,0,0-3.8,2.49c-1.76,1.7-5.38,5.86-4.56,11.17a10.84,10.84,0,0,0,5.05,7.58,34.39,34.39,0,0,1,0-10.26,38.68,38.68,0,0,1,3.25-9.07Z"
                style="fill: #37474f"
            ></path>
            <path
                d="M360.67,101.32l2-6.59c-1.84-.63-4.35-.17-4.48,1.72C358.06,98.55,358.84,100.69,360.67,101.32Z"
                style="fill: #f27531"
            ></path>
            <path
                d="M360.67,101.32l2-6.59c-1.84-.63-4.35-.17-4.48,1.72C358.06,98.55,358.84,100.69,360.67,101.32Z"
                style="opacity: 0.55"
            ></path>
            <path
                d="M331.08,78S333.17,90,350,90.4V76.73Z"
                style="fill: #f27531"
            ></path>
            <path
                d="M331.08,78S333.17,90,350,90.4V76.73Z"
                style="opacity: 0.55"
            ></path>
            <path
                d="M342.71,94.83a1.59,1.59,0,1,0,1.72-1.53A1.62,1.62,0,0,0,342.71,94.83Z"
                style="fill: #263238"
            ></path>
            <path
                d="M343.51,106.08l-4.37,1.26a2.24,2.24,0,0,0,2.78,1.63A2.38,2.38,0,0,0,343.51,106.08Z"
                style="fill: #f28f8f"
            ></path>
            <path
                d="M330.68,89.82,334,87.68a1.88,1.88,0,0,0-2.66-.62A2.06,2.06,0,0,0,330.68,89.82Z"
                style="fill: #263238"
            ></path>
            <path
                d="M330.49,93.68a1.59,1.59,0,1,0,1.71-1.53A1.62,1.62,0,0,0,330.49,93.68Z"
                style="fill: #263238"
            ></path>
            <polygon
                points="338.21 93.3 338.02 103.66 333.03 101.96 338.21 93.3"
                style="fill: #f28f8f"
            ></polygon>
            <path
                d="M345.27,115.43c4.08-.43,12.51-2.6,13.9-5.87a8.6,8.6,0,0,1-3,4.14c-2.56,2.07-10.89,4.26-10.89,4.26Z"
                style="fill: #f28f8f"
            ></path>
        </g>
        <g id="freepik--character-1--inject-73">
            <path
                d="M243.73,172.49c-.29-.61-.2-1.22-.62-1.8a6.5,6.5,0,0,0-1.26-1A8.9,8.9,0,0,1,240,167a2.38,2.38,0,0,0-1.91-1.07c-2.29-.07-4.33,2.61-5.41,4.19A19,19,0,0,1,230,173c-.93.09-1.69-1.79-3.35-3.32-2.28-2.09-5.18-1.65-4.29-.05.5.91,2.5,3,3.08,5.65.35,1.58,1.08,4.46.15,6.05l8.1,9.1c2.23-1.47,4.93-2.93,6.83-4.43a21.51,21.51,0,0,0,5.78-6,3,3,0,0,0,.5-2.44c-.15-.41-.45-.76-.6-1.17-.23-.64-.1-1.39-.43-2C245.31,173.58,244.14,173.34,243.73,172.49Z"
                style="fill: #b16668"
            ></path>
            <path
                d="M147.74,190.5s.41-.75.74-1.27c4.6-7.3,8.53-10.66,15.69-8.44s33.13,19.39,33.13,19.39c15-9.18,22.13-14,28.28-18.85,4.85-3.81,15.93,3.52,9,8.49-8.27,5.9-26.78,27.09-32.14,29S147.74,190.5,147.74,190.5Z"
                style="fill: #b16668"
            ></path>
            <path
                d="M147.74,190.5a30.09,30.09,0,0,1,3-5.15c2.16-3.1,5.26-6,10.69-5.17s11.59,3.66,19.8,8.66,16.08,9.68,16.08,9.68l20.1-11.18,8.17,10.39S212,212.35,206,217.13s-4.13,3.74-12.9-.16S147.74,190.5,147.74,190.5Z"
                style="fill: #ebebeb"
            ></path>
            <path
                d="M220.83,202.74c-4-1.83-9.16-6.66-9.28-12.14l11.39-7.24c-.08,3.91,5.39,9.16,8.47,9Z"
                style="fill: #e0e0e0"
            ></path>
            <path
                d="M156.55,385.48a1.35,1.35,0,0,1-1.36-1.35V296.87a1.36,1.36,0,1,1,2.72,0v87.26A1.35,1.35,0,0,1,156.55,385.48Z"
                style="fill: #455a64"
            ></path>
            <path
                d="M210.54,353.72a1.35,1.35,0,0,1-1.26-.86l-26.76-67.72a1.36,1.36,0,1,1,2.52-1l26.76,67.73a1.35,1.35,0,0,1-.76,1.76A1.43,1.43,0,0,1,210.54,353.72Z"
                style="fill: #455a64"
            ></path>
            <path
                d="M207.33,345.59a1.34,1.34,0,0,1-1-.43l-49.79-52.68-49.7,52.46a1.35,1.35,0,0,1-1.92.05,1.37,1.37,0,0,1-.05-1.92l50.69-53.5a1.35,1.35,0,0,1,1-.43h0a1.37,1.37,0,0,1,1,.43l50.77,53.73a1.36,1.36,0,0,1-.05,1.92A1.34,1.34,0,0,1,207.33,345.59Z"
                style="fill: #455a64"
            ></path>
            <path
                d="M102.56,353.72a1.41,1.41,0,0,1-.49-.09,1.37,1.37,0,0,1-.77-1.76l26.76-67.73a1.36,1.36,0,0,1,2.52,1l-26.75,67.72A1.36,1.36,0,0,1,102.56,353.72Z"
                style="fill: #455a64"
            ></path>
            <path
                d="M208.7,343.5l3.53,9.33a1,1,0,0,1,0,.24v.17a1.15,1.15,0,0,1-.54.59,2.39,2.39,0,0,1-2.15,0,.92.92,0,0,1-.42-.44v0l0-.12-3.28-8.76Z"
                style="fill: #f27531"
            ></path>
            <path
                d="M104.41,343.5l-3.54,9.33a1,1,0,0,0,0,.24.48.48,0,0,0,0,.17,1.08,1.08,0,0,0,.53.59,2.39,2.39,0,0,0,2.15,0,.92.92,0,0,0,.42-.44v0l0-.12,3.28-8.76Z"
                style="fill: #f27531"
            ></path>
            <path
                d="M158.12,377.37v7.72a.79.79,0,0,1-.46.64,2.4,2.4,0,0,1-2.21,0,.77.77,0,0,1-.46-.64v-7.72A3.34,3.34,0,0,1,158.12,377.37Z"
                style="fill: #f27531"
            ></path>
            <path
                d="M217.24,284.22c0-2.43-2.14-5-6.64-7.94-8.32-5.4-32.53-18.92-44.17-25.37a.14.14,0,0,0-.1-.09c-9-5.44-11.68-28.64-17.88-48.31-2.11-6.71-4.7-10.5-7.77-12.28-.73-.43-3.41-1.9-4-2.26-6.08-3.65-14.07.73-24,6.17-9.16,5-16.32,11.44-17.76,28.47-.88,10.37,0,30.18,2.06,45.2h0c3.05,25.09,6.79,25.13,12.22,29.33,9.36,7.23,30.42,17.38,39.3,20.87,16.1,6.34,29.26-3.43,39.06-8.27a227.19,227.19,0,0,0,26-15.4c2.46-1.78,3.73-3.58,3.7-5.48C217.22,288.09,217.24,285,217.24,284.22Z"
                style="fill: #263238"
            ></path>
            <path
                d="M187.54,305.06a227.19,227.19,0,0,0,26-15.4c5.58-4.05,5.07-8.19-2.93-13.38-11.11-7.21-50.57-28.9-50.57-28.9s-57.25,33.79-54.79,40,32.07,21.53,43.24,25.93C164.58,319.67,177.74,309.9,187.54,305.06Z"
                style="fill: #455a64"
            ></path>
            <path
                d="M140.32,190c-6-3.16-13.86,1.14-23.54,6.45-6.21,3.4-11.5,7.43-14.71,15L98,209.08c3.21-7.51,8.49-11.54,14.67-14.94,9.95-5.44,17.93-9.81,24-6.17C137.2,188.28,139.31,189.45,140.32,190Z"
                style="fill: #455a64"
            ></path>
            <path
                d="M116.77,196.47c14.89-8.15,25.38-13.93,31.68,6,6.2,19.67,8.92,42.87,17.88,48.31,0,0,2.73.23-19.55,17.06-7.09,5.36-15.69,9.75-21.34,13.11-11.07,6.6-17.78,9.25-18.84,8.37-5.86-4.86-9-47.39-7.59-64.42S107.61,201.49,116.77,196.47Z"
                style="fill: #37474f"
            ></path>
            <rect
                x="183.86"
                y="361.38"
                width="11.1"
                height="19.31"
                transform="translate(378.82 742.07) rotate(180)"
                style="fill: #b16668"
            ></rect>
            <path
                d="M194.93,377.63v-.95c1.43,0,6.51,10.75,11.37,15.18,3.87,3.53,10.67,8.36,10.5,11.7-.21,4.32-6.37,6.23-11.68,5-4.15-1-10.07-3.48-12.91-6.55s-3.77-7.1-5.26-9.11-4.9-4.25-5.7-6.58c-.44-1.27.08-4.07.7-6.43.57-2.17,1.18-4.55,1.92-4.35v1.34c.89,1,3.35,2,6.13,2.07C191.8,379,194.94,379,194.93,377.63Z"
                style="fill: #263238"
            ></path>
            <rect
                x="213.24"
                y="347.27"
                width="11.1"
                height="19.31"
                transform="translate(437.57 713.84) rotate(180)"
                style="fill: #b16668"
            ></rect>
            <path
                d="M224.31,363.52v-1c1.42,0,6.51,10.75,11.37,15.19,3.87,3.53,10.67,8.35,10.5,11.7-.21,4.31-6.37,6.23-11.68,5-4.15-1-10.07-3.49-12.91-6.56s-3.77-7.1-5.26-9.11-4.9-4.25-5.7-6.57c-.45-1.28.08-4.07.7-6.43.57-2.17,1.18-4.56,1.92-4.35v1.33c.89,1,3.35,2,6.13,2.08C221.17,364.88,224.32,364.89,224.31,363.52Z"
                style="fill: #263238"
            ></path>
            <path
                d="M119.89,258.21c.77,17.61-.84,22.16,18.1,36.11,8.94,6.59,38,23.07,38,23.07a178.48,178.48,0,0,0,.76,19.33c.93,10.95,6.69,33.64,6.69,33.64s7.19,3.09,12.33-.17c0,0,4.12-59.57,3.16-63.62-.68-2.87-21.3-21.59-21.3-21.59l28,17.53a71.71,71.71,0,0,0-.1,14.25c1.63,14,3.26,21.94,7.13,39.63,0,0,8.14,2.92,12.21-.24,0,0,4.81-60,3.41-65.14-3.82-14.1-47.4-34.93-54.09-40.58C170.42,247.21,119.89,258.21,119.89,258.21Z"
                style="fill: #f27531"
            ></path>
            <path
                d="M119.89,258.21c.77,17.61-.84,22.16,18.1,36.11,8.94,6.59,38,23.07,38,23.07a178.48,178.48,0,0,0,.76,19.33c.93,10.95,6.69,33.64,6.69,33.64s7.19,3.09,12.33-.17c0,0,4.12-59.57,3.16-63.62-.68-2.87-21.3-21.59-21.3-21.59l28,17.53a71.71,71.71,0,0,0-.1,14.25c1.63,14,3.26,21.94,7.13,39.63,0,0,8.14,2.92,12.21-.24,0,0,4.81-60,3.41-65.14-3.82-14.1-47.4-34.93-54.09-40.58C170.42,247.21,119.89,258.21,119.89,258.21Z"
                style="opacity: 0.4"
            ></path>
            <g id="freepik--Character--inject-73">
                <path
                    d="M136.68,181.21c-.51.13-12.2,2.16-12.2,2.16-5,1.13-8.17,7.58-8.17,7.58a148.36,148.36,0,0,0,4,40.93L119.42,260c7.7,8.38,50.76,8.68,55.5-9.13,0,0-1.48-39.16-2.18-47.69-1.05-13-5.75-20.6-12.47-22.52,0,0-4.87,0-6.64.07Z"
                    style="fill: #fafafa"
                ></path>
                <path
                    d="M157.92,191.64c-1.17,2.77-1.52,5.76-4.32,7.52-3.9.43-7.65-4.24-9.8-6.5l6.19-3.55,3.06,5.81,1.88-6.09Z"
                    style="fill: #37474f"
                ></path>
                <path
                    d="M169.58,141.09c.54,8.71-5.57,16.18-13.64,16.67s-15.06-6.15-15.59-14.86,5.56-16.17,13.64-16.67S169,132.38,169.58,141.09Z"
                    style="fill: #263238"
                ></path>
                <path
                    d="M126.47,153.55c-1.93-8-2.44-11.6-.6-13.73a5.84,5.84,0,0,1,4.73-2l2.21,14.45h0l2.62,10.28.76,9.11a4.44,4.44,0,0,1-4.08-2.45c-1.26-2.17-4.12-10.91-5.21-14.3h-.1c-.11-.46-.22-.91-.33-1.34Z"
                    style="fill: #263238"
                ></path>
                <path
                    d="M134.69,155.16c-.92.62-2.39-1.12-3.64-2.28s-5.19-2.6-6.85,1.55,2.27,9.43,5.35,10.19c5.3,1.31,5.88-2.07,5.88-2.07L137,181.29a50.71,50.71,0,0,0,10.9,9.64c1,.66,1.7-.74,2.82-.2,1,.47,1.71,3,2.34,4.19,1.84-3.21,3-11.79.83-15.18l-.37-5.71a32.67,32.67,0,0,0,7-.07c3.79-.83,6-4,6.91-8.21,1.45-6.76,2.25-12.37-.24-24.51-2.85-13.94-19.63-14-28.28-7.77S134.69,155.16,134.69,155.16Z"
                    style="fill: #b16668"
                ></path>
                <path
                    d="M134.79,156.76c-.48,0-2.49-2.87-3.74-3.88-1.74-1.39-.45-15.06-.45-15.06s-2-5,1.05-8.63c3.28-3.89,9.64-3.5,14.2-3.82,5.91-.41,11.65-.69,16.74-4.11a2.85,2.85,0,0,1,2.21-.73c2.67.78.47,5.88.13,7.42a5.89,5.89,0,0,0,2.89-1.53c.63-.54,1.45-1.35,1.76,0,1.21,5.05-1.86,9.74-6.36,11.87-3.14,1.48-6.72,1.61-10.18,1.5-2.39-.08-4.78-.42-7.15-.36a58.83,58.83,0,0,1-5.93-.27c-1.78-.13-2.06,1.61-2.72,6.34C136.66,149.62,136.39,156.63,134.79,156.76Z"
                    style="fill: #263238"
                ></path>
                <path
                    d="M130.67,138.87l-5.95-2.73a3.18,3.18,0,0,1,4.27-1.71A3.43,3.43,0,0,1,130.67,138.87Z"
                    style="fill: #263238"
                ></path>
                <path
                    d="M153.51,174s-7.25-1.35-10.33-2.76a9.8,9.8,0,0,1-4.38-3.83,13.44,13.44,0,0,0,2.64,4.68c2.35,2.65,12.21,4.19,12.21,4.19Z"
                    style="fill: #9a4a4d"
                ></path>
                <path
                    d="M152.91,152.34a1.72,1.72,0,1,1-1.71-1.79A1.75,1.75,0,0,1,152.91,152.34Z"
                    style="fill: #263238"
                ></path>
                <path
                    d="M150,146.28l-3.34,2.53a2.22,2.22,0,0,1,.43-3A2,2,0,0,1,150,146.28Z"
                    style="fill: #263238"
                ></path>
                <path
                    d="M154.83,163.38l4.61,1.73a2.41,2.41,0,0,1-3.13,1.53A2.57,2.57,0,0,1,154.83,163.38Z"
                    style="fill: #9a4a4d"
                ></path>
                <path
                    d="M166.34,145.38l-4-1.44a2,2,0,0,1,2.63-1.3A2.21,2.21,0,0,1,166.34,145.38Z"
                    style="fill: #263238"
                ></path>
                <path
                    d="M166.12,150.25a1.72,1.72,0,1,1-1.71-1.78A1.74,1.74,0,0,1,166.12,150.25Z"
                    style="fill: #263238"
                ></path>
                <polygon
                    points="158.38 150.04 158.94 160.35 164.32 158.52 158.38 150.04"
                    style="fill: #9a4a4d"
                ></polygon>
                <path
                    d="M153,194.93s-.85-3.93-1.86-5.41-2.76-1.06-6.43-3c-5.36-2.77-8.07-7.6-8.11-9.29l0-2.15a3.19,3.19,0,0,0-2.25,1.57c-.92,1.48-2.68,4.24-3.47,5.57s1.81,5.4,6.1,9.58c3.23,3.15,8.16,5.06,9.82,4.45.62-.24,3-4.26,3.69-4.45S153,194.93,153,194.93Z"
                    style="fill: #ebebeb"
                ></path>
                <path
                    d="M153.62,176.07a4.7,4.7,0,0,1,3.14,3.44c.57,1.7,1.32,5.67,1.9,8s-.74,6.27-1.06,6.77c-.2.32-.72-2.1-1.28-3.49s-1-.43-1.17-.13a19.12,19.12,0,0,1-2.16,4.28,14.69,14.69,0,0,0,.88-5.86c-.28-2.88-.24-8.35-.24-8.35Z"
                    style="fill: #ebebeb"
                ></path>
                <path
                    d="M155.55,193.43c1.36.4,2.56,1.87,1.41,6.33-.67,2.63-1.05,2.78-1.05,2.78a4.75,4.75,0,0,1-4.19.45c-2.07-1.9-4.16-6.43-2.23-8.36S154.45,193.1,155.55,193.43Z"
                    style="fill: #455a64"
                ></path>
                <path
                    d="M155.91,202.54s6.31,34.82,6.56,37.63-3.62,12.8-6,12.89-7.13-9-7.2-11.51S151.72,203,151.72,203Z"
                    style="fill: #455a64"
                ></path>
                <path
                    d="M151.72,203a4.2,4.2,0,0,0,4.19-.45l.1.55a4.8,4.8,0,0,1-4.36.86Z"
                    style="fill: #37474f"
                ></path>
            </g>
            <path
                d="M142.7,284.65v1.15a3.25,3.25,0,0,0,1.63,2.82l31,17.86a3.29,3.29,0,0,0,3.25,0l56.93-32.87a3.24,3.24,0,0,0,1.63-2.82V270L177,304.75Z"
                style="fill: #37474f"
            ></path>
            <path
                d="M237.17,270l-33.75-19.48a1.92,1.92,0,0,0-1.94,0l-58.56,33.81a.43.43,0,0,0,0,.75L177,304.75Z"
                style="fill: #455a64"
            ></path>
            <polygon
                points="176.68 301.88 158.47 291.38 211.71 260.65 230.01 271.09 176.68 301.88"
                style="fill: #263238"
            ></polygon>
            <polygon
                points="167.73 272.67 168.06 272.87 176.99 278.02 192.86 268.86 193.19 268.67 183.93 263.32 167.73 272.67"
                style="fill: #263238"
            ></polygon>
            <polygon
                points="168.06 272.87 176.99 278.02 192.86 268.86 183.93 263.7 168.06 272.87"
                style="fill: #37474f"
            ></polygon>
            <path
                d="M177,304.75v2.16a3.22,3.22,0,0,0,1.62-.43l56.93-32.87a3.24,3.24,0,0,0,1.63-2.82V270Z"
                style="fill: #37474f"
            ></path>
            <path
                d="M177,304.75v2.16a3.22,3.22,0,0,0,1.62-.43l56.93-32.87a3.24,3.24,0,0,0,1.63-2.82V270Z"
                style="fill: #263238"
            ></path>
            <path
                d="M177.91,305.23a3.88,3.88,0,0,0,3.32-.26l56.68-32.72a3.87,3.87,0,0,0,1.87-2.7l6.21-37a4.46,4.46,0,0,0,0-.63,3.85,3.85,0,0,0-1.14-2.74l-58.9,34a2.86,2.86,0,0,0-1.39,2Z"
                style="fill: #37474f"
            ></path>
            <path
                d="M177,304.75l.39.22a4.34,4.34,0,0,0,.53.26l6.7-40.05a2.86,2.86,0,0,1,1.39-2l58.9-34a3.92,3.92,0,0,0-.79-.6l-59,34.07a2.87,2.87,0,0,0-1.38,2Z"
                style="fill: #455a64"
            ></path>
            <path
                d="M209.83,271.07c.41-2.48,3-5.77,5.71-7.35s4.62-.86,4.21,1.62-3,5.77-5.71,7.35S209.41,273.55,209.83,271.07Z"
                style="fill: #f27531"
            ></path>
            <path
                d="M147.29,277.86c-6.92-3-29.79-13.07-37-17.13s-8.42-6.7-8-11.42c1.17-11.85,3.69-41.37,6.05-50.26,2.08-7.79,5-13.79,16.08-15.68,3.41,3.49,3.8,20.82-1,28.43-1.64,10.17-3.2,34.8-3.2,34.8s9.16,6.23,20.59,13.11c5,3,10.07,6.16,14.15,7.56,3.16,1.08,8.8,1.79,12.28,3.93,1.47.9,9,8.44,10.2,10.39s1.93,5.94-2.07,7.2c-3.66,1.16-6.63-.4-9.6-2.44a38,38,0,0,1-4-3.28,14.55,14.55,0,0,1-8.57-1.7C151.07,280.09,147.29,277.86,147.29,277.86Z"
                style="fill: #b16668"
            ></path>
            <path
                d="M126.74,183s-10.57.74-15.09,6.75c-4.8,6.39-6.49,21.09-7.88,35.18s-2.1,25.87-1.51,29.49c.51,3.13,1.65,4.71,9.74,8.69C118,266,138.46,274,138.46,274s1.77-7.95,6.24-12L120.32,246.6l4.7-33.46s3.91-8.66,3.73-17.64S126.74,183,126.74,183Z"
                style="fill: #ebebeb"
            ></path>
            <path
                d="M134.49,272.45c-.2-4.42,1.85-8.86,5.88-13.31l12.1,6.87c-3.12.48-6.37,9.42-4.8,12.07Z"
                style="fill: #e0e0e0"
            ></path>
        </g>
    </svg>
</template>

<script>
export default {};
</script>

