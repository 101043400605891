<template>
    <div class="mt-3" ref="preguntasDiv">
        <v-row align="center" justify="center" v-if="this.$route.params.show_logo">
            <LogoDI></LogoDI>
        </v-row>

        <BackHeader title="Informacion del proceso de práctica" :onBack="volver"></BackHeader>

        <v-card class="ma-5">
            <v-col align="left">
                <h2 class="ma-5">Bienvenidos al proceso de práticas</h2>
                <p class="ma-5" style="max-width: 70vw;" v-html="index">
                </p>
                <v-row class="ma-2">

                    <v-col class="text-center" >
                        <h4>Profesor Encargado Luis Hevia</h4>
                        <h4>lhevia@inf.utfsm.cl</h4>
                    </v-col>
                    <v-col class="text-center">
                        <h4>Ayudante de prácticas</h4>
                        <h4>ayud-pra@inf.utfsm.cl </h4>
                    </v-col>
                </v-row>
            </v-col>
        </v-card>
        <div class="ma-5">
            <div>
                <v-expansion-panels multiple >
                    <v-expansion-panel v-for="(values) in data_preguntas" @change="toggleActive(values.orden)">
                        <v-expansion-panel-header> <h2 :class="{ active: isActive[values.orden] }"">{{values.titulo}}</h2> </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <div class="my-3 mx-16" v-html="values.contenido"></div>
                            <!-- sdfg -->
                        </v-expansion-panel-content>
                    </v-expansion-panel>
            </v-expansion-panels>
            </div>
        </div>

    </div>

</template>

<script>
import LogoDI from "@/components/icons/logoDI";
import axios from 'axios';
import BackHeader from "@/components/content/BackHeader";

export default {
    components: {LogoDI,BackHeader},

    data() {
        return {
            data_preguntas:[],
            numero:0,
            isActive: [],
            index: "",
        };
    },
    created(){
        axios({
            url: `${process.env.VUE_APP_API_URL}/infoproceso`,
            method: "GET",
        }).then((response) => {
            this.data_preguntas = response.data;
            // El elemento con titulo "index" se guarda en la variable index para ser mostrado en la parte superior
            var posicion_index = this.data_preguntas.findIndex(item => item.titulo === 'index');
            if (posicion_index !== -1) {
                this.index = this.data_preguntas[posicion_index].contenido;
                this.data_preguntas.splice(posicion_index, 1);
            }
        });
    },

    methods:{
        volver() {
            if (this.$route.params.back_info){
                this.$router.push(this.$route.params.back_info);
            } else
            this.$router.push("/");
        },
        toggleActive(index,) {
            // var old_value = this.isActive[index];
            // this.isActive.fill(false);
            // this.$set(this.isActive, index, old_value);
            this.$set(this.isActive, index, !this.isActive[index]);
        },
        
    }
};
</script>

<style>
.active {
  color: #E65100;
}
</style>
