<template>
    <v-row no-gutters>
        <v-card-title class="pa-0 mb-3 ml-2">
         <v-icon v-if="icon" class="mr-3">{{icon}}</v-icon> {{text}}
       </v-card-title>
    </v-row>
</template>

<script>
export default {
  props: ["text", "icon"]
};
</script>

<style>
</style>