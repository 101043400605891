<template>
  <v-container>
    <v-data-iterator
      :items="solicitudes"
      :items-per-page.sync="itemsPerPage"
      :page="page"
      :loading="loading"
      hide-default-footer
    >
      <template v-slot:default="props">
        <v-row>
          <v-col v-for="item in props.items" :key="item.name" cols="12">
            <v-card>
              <v-card class="pa-5">
                <v-row>
                  <v-col md="10">
                    <h4>
                      {{ item.alumno.first_name }} {{ item.alumno.last_name }}
                    </h4>
                    <h4>
                      @{{ item.alumno.username }}
                    </h4>
                  </v-col>
                  <v-col class="text-end" >
                      <h4 >{{formatJustHour(item.fecha_sistema)}}</h4>
                      <h4>{{formatJustDate(item.fecha_sistema)}}</h4>
                  </v-col>
                </v-row>
              </v-card>

              <v-divider></v-divider>

              <SolicitudDetails
                :solicitud="item"
                :admin="true"
              ></SolicitudDetails>

              <v-card-actions>
                <v-row class="pa-3" justify="center">
                  <v-btn
                    class="mr-5 white--text"
                    elevation="0"
                    color="green"
                    @click="aprobar(item.id)"
                    >Aprobar</v-btn
                  >
                  <v-btn
                    class="ml-5 white--text"
                    elevation="0"
                    color="red"
                    @click="remove(item.id)"
                    >Rechazar</v-btn
                  >
                </v-row>
                <v-row justify="end">
                <button-primary :onClick="()=>go(item.practica.token)">
                  Ir a la práctica
                </button-primary>
              </v-row>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </template>

      <template v-slot:footer>
        <v-row
          v-if="solicitudes && solicitudes.length != 0"
          class="ma-2"
          align="center"
          justify="center"
        >
          <span class="grey--text">Solicitudes por página</span>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                dark
                text
                color="primary"
                class="ml-2"
                v-bind="attrs"
                v-on="on"
              >
                {{ itemsPerPage }}
                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(number, index) in itemsPerPageArray"
                :key="index"
                @click="updateItemsPerPage(number)"
              >
                <v-list-item-title>{{ number }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-spacer></v-spacer>

          <span class="mr-4 grey--text"
            >Página {{ page }} de {{ numberOfPages }}</span
          >
          <v-btn
            fab
            small
            dark
            color="primary"
            elevation="0"
            class="mr-1"
            @click="formerPage"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn
            fab
            small
            dark
            color="primary"
            elevation="0"
            class="ml-1"
            @click="nextPage"
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-row>
      </template>

      <template v-slot:no-data>
        <v-row class="ma-2" align="center" justify="center">
          <notFound width="256" height="256" ></notFound
        ></v-row>

        <v-row class="ma-2" align="center" justify="center"
          >No hay solicitudes pendientes</v-row
        >
      </template>

      <template v-slot:loading>
        <v-row class="ma-2" align="center" justify="center">
          <v-row justify="space-around">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-row>
        </v-row>
      </template>
    </v-data-iterator>
  </v-container>
</template>

<script>
import ButtonPrimary from '../../components/forms/ButtonPrimary.vue'
import notFound from "@/components/icons/notFound";
import SolicitudDetails from "../../components/practicas/components/solicitud/SolicitudDetails.vue";
import { formatJustDate, formatJustHour } from "@/services/utils.service";

export default {
  components: {
    notFound,
    SolicitudDetails, ButtonPrimary
  },
  data: () => ({
    itemsPerPageArray: [4, 8, 12],
    page: 1,
    itemsPerPage: 4,
  }),
  computed: {
    solicitudes() {
      if (this.$store.state.tables.solicitudes_admin.items){
        return this.$store.state.tables.solicitudes_admin.items.sort((a, b) => {
            const dateA = new Date(a.fecha_sistema);
            const dateB = new Date(b.fecha_sistema);
            return dateB - dateA;
          });
      }
      return this.$store.state.tables.solicitudes_admin.items

    },
    numberOfPages() {
      return Math.ceil(this.solicitudes?.length / this.itemsPerPage);
    },
    loading() {
      return this.$store.state.tables.solicitudes_admin.loading;
    },
    svgTheme() {
      return this.$vuetify.theme.dark ? "white" : "black";
    },
  },
  created() {
    this.$store.dispatch("tables/get", { table: "solicitudes_admin" });
  },
  methods: {
    go(token){
      this.$router.push(`/practica/${token}`)
    },
    nextPage() {
      if (this.page + 1 <= this.numberOfPages) this.page += 1;
    },
    formerPage() {
      if (this.page - 1 >= 1) this.page -= 1;
    },
    updateItemsPerPage(number) {
      this.itemsPerPage = number;
    },
    remove(id) {
      this.$store.dispatch("tables/delete", {
        table: "solicitudes_admin",
        id: id,
        editedIndex: this.editedIndex,
        error_message: "Ha ocurrido un error al eliminar la solicitud.",
        success_message: "Solicitud eliminada correctamente.",
      });
    },

    aprobar(id) {
      this.$store.dispatch("tables/post", {
        table: "solicitudes_admin",
        item: {},
        params: id,
        returnRequest: true, // parametro para esperar a la request antes de eliminarlo de la tabla
      }).then(() => {
        this.$store.commit("tables/deleteSuccess", {
          table: "solicitudes_admin",
          deleteIndex: id,
          invisible: false
        });
      })         
    },
    formatJustDate,
    formatJustHour,
  },
};
</script>

<style></style>
