<template>
  <v-card  class="pl-4 pr-4">
    <v-row align="center">
      <v-avatar class="ma-1" color="primary"> <v-icon class="ma-3 white--text">{{ icon }}</v-icon></v-avatar>
      
      <v-col>
        <h5 class="mb-0 font-weight-bold">{{ title }}:</h5>
        <p class="mb-0 font-italic">
          {{ subtitle }}
        </p>
        <p class="mb-0 font-italic" v-if="subtitle2">
          {{ subtitle2 }}
        </p>
        <p class="mb-0 font-italic" v-if="subtitle3">
          {{ subtitle3 }}
        </p>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  props: ["icon", "title", "subtitle", 'subtitle2', 'subtitle3'],
};
</script>

<style></style>
