<template>
    <div :class="margin()" >
        <v-col no-gutters :class="textAlign()">
            <v-row no-gutters :class="margin()">
                <strong>
                    {{ usuario == "assistant"? "Asistente": usuario_name }}
                </strong>
            </v-row>
            <dark-card class="pa-2 card" >
                {{ mensaje }}
            </dark-card>

        </v-col>
    </div>
</template>
  
<script>

// import ChatBotChat from "@/components/chatbot/chat-chatbot";
import DarkCard from "@/components/content/DarkCard.vue";


export default {
    components: {
        DarkCard
    },
    props: ["usuario","mensaje", "usuario_name"],
    methods: {
        margin() {
            // return this.usuario == "assistant" ? "ml-1 mr-10 d-flex justify-start" : "ml-10 mr-1  d-flex justify-end"
            return this.usuario == "assistant" ? "d-flex justify-start" : " d-flex justify-end"
        },
        textAlign() {
            return this.usuario === "assistant" ? "text-start" : "text-end";
        },
    }
};
</script>

<style>
.card{
    display: inline-block;
}

</style>