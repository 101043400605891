<template>
    <div class="area ma-0 pa-0 ">
        <v-card>
            
        
        <div :style="isDarkTheme()">

            <v-row v-if="show_chat" class="chat-area" no-gutters>
                <v-col>
                    <v-row style="justify-content: flex-end;" > 
                        <v-col>
                            <h3 >Asistente de prácticas</h3>
                        </v-col>
                        <v-icon @click="toggleChat" class="ma-1" size="35">mdi-close</v-icon>
                    </v-row>
                    
                    <ChatBotChat />
                    
                </v-col>
            </v-row>
        </div>
        </v-card>
            
            <v-row class="boton-area" no-gutters>
                <button class="boton-chatbot" @click="toggleChat">
                    <v-icon class="ml-1" size="40" color="#fff">mdi-message-text</v-icon>
                </button>
            </v-row>
    </div>

</template>
  
<script>

import ChatBotChat from "@/components/chatbot/chat-chatbot";
import DarkCard from "@/components/content/DarkCard.vue";


export default {
    components: {
        ChatBotChat,
        DarkCard
    },
    data: () => ({
      show_chat: false,
    }),
    methods: {
        toggleChat() {
            this.show_chat = !this.show_chat;
        },
        isDarkTheme() {
            return this.$vuetify.theme.dark ? "background-color: rgba(0, 0, 0, 0.3);" : "background-color: rgba(0, 0, 0, 0.08);";
        },
    },
    
};
</script>

<style>



.area {
    
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 2;
    width: 500px;
    height: auto;
}

.chat-area {
    width: 100%;
    min-height: 300px;
    max-height: 800px;
    border-radius: 20px;
}

.boton-area {
    width: 100%;
    height: 10%;
    /* background: rgba(0, 255, 0, 0.5); */
    justify-content: flex-end;
}

.boton-chatbot {

    width: 70px;
    height: 70px;
    background: rgb(232, 95, 20);
    border-radius: 100%;
    margin: 10px;
    transition: background-color 0.2s ease;
}

.boton-chatbot:hover {
background-color: rgb(230, 130, 77); /* Sombra más pronunciada al pasar por encima */
}
  
.boton-chatbot:active {
background-color: rgb(175, 80, 29); /* Color de fondo al hacer clic */
}
</style>