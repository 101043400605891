<template>
  <v-container>
    <DarkCard title="Notas">
      <v-row no-gutters class="mb-4" v-for="nota in notas" :key="nota.id">
        <v-col :cols="10" align-self="center">{{
          nota.competencia_evaluada
        }}</v-col>
        <v-col :cols="2"
          ><v-avatar :color="getColor(nota.nota)" class="white--text">
            {{ nota.nota }}</v-avatar
          ></v-col
        >
      </v-row>
    </DarkCard>
  </v-container>
</template>

<script>
import DarkCard from "@/components/content/DarkCard.vue";
export default {
  components: {
    DarkCard,
  },
  props: ["notas"],
  methods: {
    getColor(nota) {
      if (nota == 5) {
        return "green darken-1";
      } else if (nota == 4) {
        return "lime darken-1";
      } else if (nota == 3) {
        return "yellow darken-2";
      } else if (nota == 2) {
        return "orange darken-2";
      } else {
        return "red darken-3";
      }
    },
  },
};
</script>

<style></style>
