<template>
      <aside :class="`${is_expanded ? 'is-expanded' : ''}`">
        <v-container class="logoDI" @click.stop="onLogoClick">
          <LogoDINavBar :is_expanded="is_expanded"></LogoDINavBar>
        </v-container>

        <div class="menu-toggle-wrap mt-2">
          <button class="menu-toggle" @click="ToggleMenu">
            <span class="material-icons">keyboard_double_arrow_right</span>
          </button>
        </div>

        <div class="menu">
          <v-list-item-group mandatory v-model="item" active-class="primary">
            <v-list-item
              color="white"
              v-for="item in is_admin ? admin_drawer : user_drawer"
              :key="item.title"
              link
              @click="
                is_admin
                  ? admin_actions(item.action)
                  : user_actions(item.action)
              "
            >
              <v-list-item-icon
                style="padding-left:20px !important; margin-right:20px !important "
              >
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>

                <v-list-item-title>{{
                  item.title
                }}</v-list-item-title>


              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </div>
      </aside>
</template>

<script>

import LogoDINavBar from '@/components/icons/logoDINavBar.vue';

export default {
  components: {
    LogoDINavBar,
  },
  props: ["is_expanded"],
  data: () => ({
    toggle: true,
    toggleMobile: false,
    item: 0,
    
    admin_drawer: [
      { title: "Revisiones", 
        icon: "mdi-message-draw", 
        index: 0, 
        action: 0 
      },
      { title: "Estadísticas", 
        icon: "mdi-chart-bar", 
        index: 1, 
        action: 1 
      },
      {
        title: "Solicitudes",
        icon: "mdi-frequently-asked-questions",
        index: 2,
        action: 2,
      },
      {
        title: "Ofertas",
        icon: "mdi-bullhorn",
        index: 3,
        action: 3,
      },
/*       { title: "Base de datos", icon: "mdi-database", index: 4, action: 4 }, */
      { 
        title: "Alumnos", 
        icon: "mdi-account", 
        index: 5, 
        action: 5 
      },
      { 
        title: "Logs", 
        icon: "mdi-message-alert", 
        index: 6, 
        action: 6 
      },
      {
        title: "Empresas",
        icon: "mdi-domain",
        index: 7,
        action: 7,
      },
      {
        title: "Comentarios al DI",
        icon: "mdi-message-alert",
        index: 8,
        action: 8,
      },
      {
        title: "Exportadores",
        icon: "mdi-database-export",
        index: 9,
        action: 9,
      },

    ],
    user_drawer: [
      {
        title: "Prácticas",
        icon: "mdi-book",
        index: 0,
        action: 0,
      },
      {
        title: "Mi perfil",
        icon: "mdi-file-account",
        index: 1,
        action: 1,
      },
      {
        title: "Ofertas",
        icon: "mdi-bullhorn",
        index: 2,
        action: 2,
      },
      {
        title: "Empresas",
        icon: "mdi-domain",
        index: 3,
        action: 3,
      },
      { title: "Estadísticas", icon: "mdi-chart-bar", index: 4, action: 4 },
      { title: "Informaciones", icon: "mdi-information-outline", index: 5, action: 5 },

    ],
  }),

  created() {
    if(this.$router.currentRoute.path.startsWith('/user_dashboard')) {
        this.item = 0;
    }

    if(this.$router.currentRoute.path.startsWith('/perfil')) {
        this.item = 1;
    }

    if(this.$router.currentRoute.path.startsWith('/ofertas')) {
        this.item = 2;
    }

    if(this.$router.currentRoute.path.startsWith('/empresas')) {
        this.item = 3;
    }

    if(this.$router.currentRoute.path.startsWith('/estadisticas')) {

      if(this.is_admin){
        this.item = 1;
      } else{
        this.item = 4;
      }
        
    }

    if(this.$router.currentRoute.path.startsWith('/informaciones')) {
        this.item = 5;
    }

    if(this.$router.currentRoute.path.startsWith('/admin_revisiones')) {
        this.item = 0;
    }

    if(this.$router.currentRoute.path.startsWith('/admin_solicitudes')) {
        this.item = 2;
    }

    if(this.$router.currentRoute.path.startsWith('/admin_ofertas')) {
        this.item = 3;
    }
    // if(this.$router.currentRoute.path.startsWith('/admin_database')) {
    //     this.item = 4;
    // }
    if(this.$router.currentRoute.path.startsWith('/alumnos')) {
        this.item = 5;
    }
    if(this.$router.currentRoute.path.startsWith('/logs')) {
        this.item = 6;
    }
    if(this.$router.currentRoute.path.startsWith('/admin_empresas')) {
        this.item = 7;
    }
    if(this.$router.currentRoute.path.startsWith('/comentarios_di')) {
        this.item = 8;
    }
    if(this.$router.currentRoute.path.startsWith('/exportadores')) {
        this.item = 9;
    }

  },
  computed: {

    tablet () {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return false
          case 'sm': return true
          case 'md': return true
          case 'lg': return false
          case 'xl': return false
        }

        return false
      },

    desktop () {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return false
          case 'sm': return false
          case 'md': return false
          case 'lg': return true
          case 'xl': return true
        }

        return false
      },

    current_user() {
      return this.$store.state.authentication.user;
    },

    is_admin() {
      return this.$store.state.authentication.user.is_staff;
    },
  },
  methods: {
    /*change_drawer() {
      this.$store.dispatch("drawer");
    },*/
    /*sizecard(drawer) {
      if (drawer) {
        return 9;
      } else {
        return 12;
      }
    },*/
    admin_actions(action) {

      let newDir = ''
      switch (action){
        case 0:
          newDir = "/admin_revisiones"
          break
        case 1:
          newDir = "/estadisticas"
          break
        case 2:
          newDir = "/admin_solicitudes"
          break
        case 3:
          newDir = "/admin_ofertas"
          break
        // case 4:
        //   newDir = "/admin_database"
        //   break
        case 5:
          newDir = "/alumnos"
          break
        case 6:
          newDir = "/logs"
          break
        case 7:
          newDir = "/admin_empresas"
          break
        case 8:
          newDir = "/comentarios_di"
          break
        case 9:
          newDir = "/exportadores"
          break
        default:
          newDir = "/admin_revisiones"
      }

      this.$router.push(newDir);

      this.toggleMobile = false;
    },
    user_actions(action) {
      
      let newDir = ''
      switch (action){
        case 0:
          newDir = "/user_dashboard"
          break
        case 1:
          newDir = "/perfil"
          break
        case 2:
          newDir = "/ofertas"
          break
        case 3:
          newDir = "/empresas"
          break
        case 4:
          newDir = "/estadisticas"
          break
        case 5:
          newDir = "/informaciones"
          break
        default:
          newDir = "/user_dashboard"
      }

      this.$router.push(newDir);

      this.toggleMobile = false;
    },
    onLogoClick(){
      this.item = 0;
      if (this.is_admin){
	      this.admin_actions(0)
      } else {
	      this.user_actions(0)
      }
    },
    ToggleMenu () {
      let expanded_var = !this.is_expanded
      // se envía el valor actualizado al componente padre, que se lo va a entregar correctamente a navigation_bar
      this.$emit("update-is_expanded", expanded_var);
    }
  },
};
</script>

<style lang="scss" scoped>
.card-actions {
  position: absolute;
  bottom: 0;
}

v-list-item--active:hover::before,
.v-list-item--active::before {
  opacity: 0 !important;
}

.wrapper {
  overflow: hidden;
}
.slide-enter-active,
.slide-leave-active {
  transition: all 0.3s ease;
}
.slide-enter,
.slide-leave-to {
  transform: translateY(-100%);
  opacity: 0;
}

.logoDI {
  cursor: pointer;
} 

aside {
	display: flex;
	flex-direction: column;
	background-color: var(--dark);
	color: var(--light);
	width: calc(5rem + 32px);
	overflow: hidden;
	min-height: 100vh;
	padding: 1rem;
	transition: 0.2s ease-in-out;
	.flex {
		flex: 1 1 0%;
	}
	.menu-toggle-wrap {
		display: flex;
		justify-content: flex-end;
		position: relative;
		top: 0;
    right:1rem;
		transition: 0.2s ease-in-out;
		.menu-toggle {
			transition: 0.2s ease-in-out;
			.material-icons {
				font-size: 2rem;
				color: var(--light);
				transition: 0.2s ease-out;
			}
			
			&:hover {
				.material-icons {
					color: var(--primary);
					transform: translateX(0.5rem);
				}
			}
		}
	}
	h3, .button .text {
		opacity: 0;
		transition: opacity 0.3s ease-in-out;
	}
	h3 {
		color: var(--grey);
		font-size: 0.875rem;
		margin-bottom: 0.5rem;
		text-transform: uppercase;
	}
	.menu {
		.button {
			display: flex;
			align-items: center;
			text-decoration: none;
			transition: 0.2s ease-in-out;
			padding: 0.5rem 1rem;
			.material-icons {
				font-size: 2rem;
				color: var(--light);
				transition: 0.2s ease-in-out;
			}
			.text {
				color: var(--light);
				transition: 0.2s ease-in-out;
			}
			&:hover {
				background-color: var(--dark-alt);
				.material-icons, .text {
					color: var(--primary);
				}
			}
			&.router-link-exact-active {
				background-color: var(--dark-alt);
				border-right: 5px solid var(--primary);
				.material-icons, .text {
					color: var(--primary);
				}
			}
		}
	}
	.footer {
		opacity: 0;
		transition: opacity 0.3s ease-in-out;
		p {
			font-size: 0.875rem;
			color: var(--grey);
		}
	}
	&.is-expanded {
		width: var(--sidebar-width);
		.menu-toggle-wrap {
      right:-1%;
			
			.menu-toggle {
				transform: rotate(-180deg);
			}
		}
		h3, .button .text {
			opacity: 1;
		}
		.button {
			.material-icons {
				margin-right: 1rem;
			}
		}
		.footer {
			opacity: 0;
		}
	}
	@media (max-width: 1024px) {
		position: absolute;
		z-index: 99;
	}
}
</style>
