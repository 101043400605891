var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pa-5"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.practicas.items,"server-items-length":_vm.practicas.total,"options":_vm.options,"footer-props":{
            'items-per-page-text':'Elementos por página',
            pageText: '{0}-{1} de {2}'}},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-form',{on:{"submit":_vm.searchAPI}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-text-field',{staticClass:"ma-3",attrs:{"filled":"","rounded":"","prepend-inner-icon":"search","label":"Buscar","single-line":"","hide-details":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return _vm.searchAPI.apply(null, arguments)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{staticClass:"ma-3 ml-0",attrs:{"fab":"","elevation":"0","color":"primary"},on:{"click":_vm.searchAPI}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("search")])],1),_c('Select',{staticClass:"ma-3",attrs:{"label":"Estado","items":_vm.estado_practica,"item-value":"id","item-text":"nombre"},model:{value:(_vm.estado_seleccionado),callback:function ($$v) {_vm.estado_seleccionado=$$v},expression:"estado_seleccionado"}})],1)],1)]},proxy:true},{key:"item.fecha_email",fn:function(ref){
            var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.fecha_email? 'Hace ' + _vm.moment(new Date(item.fecha_email)).fromNow(true) : 'No enviado')+" ")])]}},{key:"item.tipo",fn:function(ref){
            var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.convalidada==0 ? item.tipo : item.tipo + " (C)")+" ")])]}},{key:"item.action",fn:function(ref){
            var item = ref.item;
return [_c('v-btn',{attrs:{"color":"primary","href":("/#/practica/" + (item.token))},on:{"click":function($event){return _vm.practica_url(item.token)}}},[_vm._v("Revisar")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }