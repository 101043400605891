<template>
    <ValidationObserver v-slot="{ invalid }">
        <v-row>
            <v-col cols="12" lg="6">
                <h4 class="ma-2">Datos personales:</h4>
                <TextField
                    :disabled="disabled"
                    v-model="value.first_name"
                    label="Nombre"
                    name="Tu nombre"
                    rules="required|max:50"
                    counter="50"
                />
                <TextField
                    :disabled="disabled"
                    v-model="value.last_name"
                    label="Apellidos"
                    name="Tus apellidos"
                    rules="required|max:50"
                    counter="50"
                />

                <!-- :idRut activa un handler que verifica y formatea el rut como corresponde -->
                <TextField
                    :disabled="disabled"
                    v-model="value.rut"
                    :isRut="true"  
                    label="RUT"
                    name="Tu RUT"
                    rules="required|min:8|max:13|rut"
                    counter="13"
                />
                <!-- :idRol activa un handler que verifica y formatea el rol como corresponde -->

                <TextField
                    :disabled="disabled"
                    v-model="value.rol"
                    :isRol="true"
                    label="ROL USM"
                    name="Tu ROL"
                    rules="required|max:11|rol"
                    counter="11"
                />

                <Select
                    :disabled="disabled"
                    label="Sexo"
                    :items="opciones_sexo"
                    v-model="value.sexo"
                    itemValue="value"
                    itemText="name"
                    name="Campo sexo"
                    rules="required"
                />


                <h4 class="ma-2"> Datos Universitarios</h4>
                <Select
                    :disabled="disabled"
                    label="Carrera"
                    :items="carreras.filter(item => item.id != 2)"
                    v-model="value.carrera_id"
                    itemValue="id"
                    itemText="nombre"
                    icon="mdi-school"
                    name="Tu carrera"
                    rules="required"
                />

                <Select
                    :disabled="disabled"
                    label="Campus"
                    :items="campus"
                    v-model="value.campus_id"
                    itemValue="id"
                    itemText="nombre"
                    icon="mdi-city"
                    name="Tu campus"
                    rules="required"
                />

            </v-col>

            <v-col cols="12" lg="6">
                <h4 class="ma-2"> Datos de contacto:</h4>
                <TextField
                    :disabled="disabled"
                    v-model="value.email"
                    label="Email"
                    name="Tu email"
                    rules="required|email|max:50"
                    counter="50"
                />


                <TextField
                    :disabled="disabled"
                    v-model="value.telefono"
                    label="Teléfono"
                    name="Tu teléfono"
                    counter="18"
                    rules="required|max:18|telefono"
                />



                <Select
                    :disabled="disabled"
                    label="Region"
                    :items="regiones"
                    v-model="value.region_id"
                    itemValue="id"
                    itemText="name"
                    icon="mdi-city"
                    name="Tu región"
                    rules="required"
                    :onChange="cargarCiudades"
                />

                <Select
                    :disabled="disabled"
                    label="Ciudad"
                    :items="ciudades"
                    v-model="value.ciudad_id"
                    itemValue="id"
                    itemText="name"
                    icon="mdi-city"
                    name="Tu ciudad"
                    rules="required"
                />
                
                <TextField
                    :disabled="disabled"
                    v-model="value.direccion"
                    label="Direccion"
                    name="Tu dirección"
                    rules="required|max:50"
                    counter="50"
                />
                <v-checkbox
                    class="mx-3"
                    :disabled="disabled"
                    @change="receive_email_changed()"
                    v-model="receive_email"
                    label="Deseo recibir ofertas de prácticas a mi e-mail"
                ></v-checkbox>

            </v-col>
        </v-row>

        <BottomArrowsStep
            v-if="backAction || nextAction"
            nextText="Continuar"
            backText="Atrás"
            :backAction="backAction"
            :nextAction="nextAction"
            :nextDisabled="invalid"
        />
    </ValidationObserver>
</template>

<script>
import BottomArrowsStep from "../../../components/forms/BottomArrowsStep.vue";
import Select from "@/components/forms/Select.vue";
import TextField from "@/components/forms/TextField.vue";
import { ValidationObserver } from "vee-validate";

export default {
    components: {
        TextField,
        Select,
        BottomArrowsStep,
        ValidationObserver,
    },
    data: () => ({
        receive_email: false,
        opciones_sexo: [{"value":"M","name":"Masculino"}, {"value":"F","name":"Femenino"},{"value":"N/A","name":"Prefiero no decirlo"}],
    }),
    props: ["value", "disabled", "backAction", "nextAction"],
    created() {
        this.$store.dispatch("users/getCurrentUser");

        this.$store.dispatch("tables/get", {
            table: "regiones",
            params: "?pais=44",
        });
        this.$store.dispatch("tables/get", {
            table: "ciudades",
            params: "?region=" + this.value.region_id,
        });

        this.$store.dispatch("tables/get", {
            endpoint: `campus`,
            table: "campus",
        });

        this.$store.dispatch("tables/get", {
            endpoint: `carreras`,
            table: "carreras",
        });
    },
    computed: {
        current_user() {
            return this.$store.state.users.current_user;
        },
        logged() {
            return this.$store.state.authentication.status.loggedIn;
        },
        campus() {
            return this.$store.state.tables.campus.items;
        },
        carreras() {
            // Ingeniería Informática ya no existe al parecer
            return this.$store.state.tables.carreras.items;//.filter(item => item.id != 2);
        },
        regiones() {
            return this.$store.state.tables.regiones.items;
        },
        ciudades() {
            return this.$store.state.tables.ciudades.items;
        },
    },
    watch: {
        current_user: {
            immediate: true,
            deep: true,
            handler() {
                this.value.first_name = this.current_user.first_name;
                this.value.last_name = this.current_user.last_name;
                this.value.sexo = this.current_user.sexo;
                this.value.rut = this.current_user.rut;
                this.value.rol = this.current_user.rol;
                this.value.email = this.current_user.email;
                this.value.direccion = this.current_user.direccion;
                this.value.telefono = this.current_user.telefono;
                this.value.campus_id = this.current_user.campus?.id;
                this.value.carrera_id = this.current_user.carrera?.id;
                this.value.region_id = this.current_user.region?.id;
                this.value.ciudad_id = this.current_user.ciudad?.id;
                this.value.receive_email = this.current_user.receive_email;
                if (this.current_user.receive_email == 1) {
                    this.receive_email = true;
                } else {
                    this.receive_email = false;
                }
            },
        },

        regiones: {
            immediate: true,
            deep: true,
            handler() {
                this.value.region_id = this.current_user.region?.id;
            },
        },

        ciudades: {
            immediate: true,
            deep: true,
            handler() {
                this.value.ciudad_id = this.current_user.ciudad?.id;
            },
        },
    },

    methods: {
        cargarCiudades() {
            this.$store.dispatch("tables/get", {
                table: "ciudades",
                params: "?region=" + this.value.region_id,
            });
            this.value.ciudad_id = null;
        },

        receive_email_changed() {
            if (this.receive_email) {
                this.value.receive_email = 1;
            } else {
                this.value.receive_email = 0;
            }
        },
    },
};
</script>

<style>
</style>