<template>
  <v-btn fab small dark elevation="0" color="primary" @click.stop="onClick">
    <v-icon>{{icon}}</v-icon>
  </v-btn>
</template>

<script>
export default {
  props: ["onClick", "icon"],
};
</script>

<style>
</style>