<template>
    <div>
        <v-overlay :value="loading">
                    <v-progress-circular
                        :size="70"
                        :width="7"
                        color="primary"
                        indeterminate
                    ></v-progress-circular>
        </v-overlay>
        <v-row no-gutters width="70vw">
            <v-col cols="12">
                        <h3 class="mb-5">Rango de Fechas</h3>
                        <p class="mb-5">Seleccione el rango de fechas en que quiere obtener la información</p>
            </v-col>
            <v-col cols="12" lg="6">
            <v-menu v-model="menu1" :close-on-content-click="false" max-width="290">
                <template v-slot:activator="{ on, attrs }">
                <v-text-field class="pa-1"
                    rounded
                    filled
                    :value="format_date_initial"
                    clearable
                    label="Fecha de inicio"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    @click:clear="clearInicio"
                ></v-text-field>
                </template>
                <v-date-picker v-model="date_initial" @change="selectType"  min="2008-01-01" :max="new Date().toISOString()" locale="es-CL"></v-date-picker>
            </v-menu>
            </v-col>
            <v-col cols="12" lg="6">
            <v-menu v-model="menu2" :close-on-content-click="false" max-width="290">
                <template v-slot:activator="{ on, attrs }">
                <v-text-field class="pa-1"
                    rounded
                    filled
                    :value="format_date_final"
                    clearable
                    label="Fecha de termino"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    @click:clear="clearTermino"
                    
                ></v-text-field>
                </template>
                <v-date-picker v-model="date_final" @change="selectType"  min="2008-01-01" :max="new Date().toISOString()" locale="es-CL"></v-date-picker>
            </v-menu>
            </v-col>
        </v-row>
        <v-row v-if="datesSelected()">
            <v-col cols="6">
                <Title :text="`Comentarios hechos a la formación en el DI (${comentario_sistema.length})`"/>
                <p v-for="comentario in comentarios_usm">{{comentario}}</p>
            </v-col>
            <v-col cols="6">
                <Title  :text="`Comentarios hechos al sistema de prácticas (${comentario_sistema.length})`"/>
                <p v-for="comentario in comentario_sistema">{{comentario}}</p>
            </v-col>
        </v-row>
        <div v-else align="center">
            <p>Por favor seleccione el rango de fechas a ver comentarios</p>
        </div>
    </div>
  </template>
  
  <script>
  import ButtonPrimary from "@/components/forms/ButtonPrimary.vue";
  import ExporterButton from "@/components/forms/ExporterButton.vue";
  import Title from '@/components/forms/Title'


  import axios from "axios";
  import moment from "moment";

  export default {
    components: {
        ButtonPrimary,
        ExporterButton,
        Title
    },
    data: () => ({
        menu1: false,
        menu2: false,
        date_initial: null,
        date_final: null,
        loading: false,
        comentarios_usm: [],
        comentario_sistema: [],

    }),
  
    computed: {
        format_date_initial() {
        
        return this.date_initial
            ? moment(this.date_initial).format("dddd, Do MMMM, YYYY")
            : "";
        },

        format_date_final() {
        return this.date_final
            ? moment(this.date_final).format("dddd, Do MMMM, YYYY")
            : "";
        },
    },
    methods: {


        clearInicio() {
            this.date_initial = null;
            this.selectType();
        },
        clearTermino() {
            this.date_final = null;
            this.selectType();
        },
        selectType() {
            this.menu1 = false;
            this.menu2 = false;
            this.getComentarios();
        },
        datesSelected() {
            return (this.date_initial && this.date_final);
        },
        
        getComentarios() {
            if (this.datesSelected()) {
                this.$store.dispatch("tables/get", {
                    endpoint: 'opiniones_di',
                    params: `?fecha_inicio=${this.date_initial}&fecha_fin=${this.date_final}`
                }).then((response) => {
                    this.comentarios_usm = response["observaciones_usm"];
                    this.comentario_sistema = response["observaciones_sistema"];
                    
                });
            }

            

        },
        
            
    },
  };
  </script>
  
  <style>
  </style>