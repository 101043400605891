<template>
  <v-card v-if="alumnos"> 
    <v-card class="pa-5">
      <v-data-table
        :headers="headers"
        :items="alumnos.items"
        :server-items-length="alumnos.total"
        :options.sync="options"
        :footer-props="{
          'items-per-page-text':'Elementos por página',
          pageText: '{0}-{1} de {2}' }"
      >
        <template v-slot:top>
          <v-form @submit="searchAPI">
            <v-row no-gutters>
              <v-text-field
                class="ma-3"
                filled
                rounded
                v-model="search"
                prepend-inner-icon="search"
                label="Buscar"
                single-line
                hide-details
                v-on:keydown.enter.prevent="searchAPI"
              ></v-text-field>
              <v-btn
                fab
                elevation="0"
                color="primary"
                @click="searchAPI"
                class="ma-3"
                ><v-icon dark>search</v-icon></v-btn
              >
            </v-row>
          </v-form>
        </template>
        <template v-slot:item.action="{ item }">
          <v-btn color="primary" elevation="0" @click="alumno_url(item.username)"
            >Ver Detalles</v-btn
          >
        </template>
      </v-data-table>
    </v-card>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    search: "",
    options: {
      itemsPerPage: 15,
    },
    headers: [
      {
        text: "Nombre",
        align: "left",
        sortable: false,
        value: "first_name",
      },
      {
        text: "Apellidos",
        align: "left",
        sortable: false,
        value: "last_name",
      },
      {
        text: "Usuario",
        align: "left",
        sortable: false,
        value: "username",
      },
      {
        text: "Rol",
        align: "left",
        sortable: false,
        value: "rol",
      },

      { text: "Acciones", value: "action", sortable: false, width: "8%" },
    ],
  }),

  computed: {
    alumnos() {
      return this.$store.state.tables.alumnos.items;
    },
    loading() {
      return this.$store.state.tables.alumnos.loading;
    },
  },

  watch: {
    options: {
      handler() {
        this.$store.dispatch("tables/get", {
          table: "alumnos",
          params: `?q=${this.search}&itemsPerPage=${this.options.itemsPerPage}&page=${this.options.page}`,
        });
      },
      deep: true,
    },
  },
  mounted() {
    this.$store.dispatch("tables/get", {
      table: "alumnos",
      params: `?q=${this.search}&itemsPerPage=15&page=1`,
    });
  },
  methods: {
    searchAPI() {
      this.$store.dispatch("tables/get", {
        table: "alumnos",
        params: `?q=${this.search}&itemsPerPage=${this.options.itemsPerPage}&page=${this.options.page}`,
      });
    },
    alumno_url(username) {
      this.$router.push("/alumno/" + username);
    },
  },
};
</script>

<style></style>
