<template>
  <v-container :style="{height: '100%'}">
    <v-row align="center" justify="center">
        <logo></logo>
    </v-row>
    <v-row justify="space-around">
      <div class="pa-5">
        <v-row class="ma-2" align="center" justify="center">
          <check width="216" height="216" ></check>
        </v-row>
      </div>
    </v-row>

    
    <v-row justify="space-around" :style="{height: '20vh'}">
      <h1 >¡Listo! ¡Muchas Gracias!</h1>
    </v-row>
      <v-row justify="center" align="center" >
<v-card class="pa-5">
          <v-container grid-list-xs>
                <v-row justify="space-around">

            <h3 class="mb-3">
              ¿Estás buscando un nuevo practicante? ¡Ingresa una oferta de práctica!
            </h3>
                </v-row>
                    <v-row justify="space-around">

            <v-btn
              elevation="0"
              class="mr-4"
              color="primary"
              @click="oferta_url()"
              >CREAR OFERTA</v-btn
            >
                    </v-row>
          </v-container>
        </v-card>
        </v-row>
  </v-container>
</template>

<script>
import logo from "@/components/icons/logoDI";
import Check from '@/components/icons/check.vue';

export default {
  components: {
    logo, Check
  },
    methods: {
    oferta_url() {
      this.$router.push("/oferta");
    },

  },
};
</script>

<style>
</style>