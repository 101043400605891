<template>
  <v-container>
    <DarkCard title="Sintesis de las tareas">
      <pre>{{ evaluacion.sintesis_tareas }}</pre>
    </DarkCard>

    <DarkCard title="Observaciones al Alumno">
      <pre>{{ evaluacion.observaciones_alumno }}</pre>
    </DarkCard>

    <DarkCard title="Observaciones de la Evaluación">
      <pre>{{evaluacion.observaciones_evaluacion? evaluacion.observaciones_evaluacion:"Sin Observaciones"}}</pre>
    </DarkCard>
  </v-container>
</template>

<script>
import DarkCard from "@/components/content/DarkCard.vue";
export default {
  components: {
    DarkCard,
  },
  props: ["evaluacion"],
};
</script>

<style scoped></style>
