<template>
    <v-card class="pa-5">
        <v-data-table
            :headers="headers"
            :items="practicas.items"
            :server-items-length="practicas.total"
            :options.sync="options"
            :footer-props="{
                'items-per-page-text':'Elementos por página',
                pageText: '{0}-{1} de {2}'}"
        >
            <template v-slot:top>
                <v-form @submit="searchAPI">

                    <v-row no-gutters>
                        <v-text-field
                            class="ma-3"
                            filled
                            rounded
                            v-model="search"
                            prepend-inner-icon="search"
                            label="Buscar"
                            single-line
                            hide-details
                            v-on:keydown.enter.prevent="searchAPI"
                        ></v-text-field>
                        <v-btn
                            fab
                            elevation="0"
                            color="primary"
                            @click="searchAPI"
                            class="ma-3 ml-0"
                            ><v-icon dark>search</v-icon></v-btn
                        >
                        <Select
                            label="Estado"
                            class="ma-3"
                            v-model="estado_seleccionado"
                            :items="estado_practica"
                            item-value="id"
                            item-text="nombre"
                        ></Select>
                    </v-row>
                </v-form>
            </template>

            <template v-slot:item.fecha_email="{ item }">
                <span> {{item.fecha_email? 'Hace ' +   moment(new Date(item.fecha_email)).fromNow(true) : 'No enviado'}}  </span>
            </template>
            
            <template v-slot:item.tipo="{ item }">
                <span> {{ item.convalidada==0 ? item.tipo : item.tipo + " (C)"}}  </span>
            </template>

            <template v-slot:item.action="{ item }">
                <v-btn
                    color="primary"
                    :href="`/#/practica/${item.token}`"
                    @click="practica_url(item.token)"
                    >Revisar</v-btn
                >
            </template>
        </v-data-table>
    </v-card>

</template>

<script>
import Select from "../../components/forms/Select.vue";
import moment from "moment";
moment.locale("es");

export default {
    components: { Select },
    data: () => ({
        search: "",
        options: {
            itemsPerPage: 15,
        },
        estado_seleccionado: 3,
    }),
    computed: {
        headers() {
            return [
                {
                    text: "Nombre",
                    align: "left",
                    sortable: true,
                    value: "alumno.first_name",
                },
                {
                    text: "Apellidos",
                    align: "left",
                    sortable: true,
                    value: "alumno.last_name",
                },
                {
                    text: "Usuario",
                    align: "left",
                    sortable: true,
                    value: "alumno.username",
                },
                {
                    text: "Rol",
                    align: "left",
                    sortable: true,
                    value: "alumno.rol",
                },

                ...(this.estado_seleccionado != 4
                    ? [
                          {
                              text: "Fecha Termino",
                              align: "left",
                              sortable: true,
                              value: "fecha_termino",
                          },
                      ]
                    : []),

                ...(this.estado_seleccionado == 4
                    ? [
                          {
                              text: "Fecha Aprobacion",
                              align: "left",
                              sortable: true,
                              value: "fecha_aprobacion",
                          },
                      ]
                    : []),
                ...(this.estado_seleccionado == 2 ||
                this.estado_seleccionado == 11
                    ? [
                          {
                              text: "Fecha Ult. Email",
                              align: "left",
                              sortable: true,
                              value: "fecha_email",
                          },
                      ]
                    : []),

                {
                    text: "Tipo",
                    align: "left",
                    sortable: true,
                    value: "tipo",
                },

                {
                    text: "Acciones",
                    value: "action",
                    sortable: false,
                    width: "8%",
                },
            ];
        },
        estado_practica() {
            return this.$store.state.tables.estado_practica.items.filter(item => item.estado).sort((a, b) => a.nombre.localeCompare(b.nombre));        
        },
        practicas() {
            return this.$store.state.tables.practicas_por_estado.items;
        },
        loading() {
            return this.$store.state.tables.practicas_por_estado.loading;
        },
    },
    watch: {
        estado_seleccionado() {
            this.$store.dispatch("tables/get", {
                endpoint: "practica/all/por_estado",
                table: "practicas_por_estado",
                params: `?estado_id=${this.estado_seleccionado}&q=${this.search}&itemsPerPage=${this.options.itemsPerPage}&page=${this.options.page}`,
            });
            
        },
        options: {
             handler() {
                let params = `?estado_id=${this.estado_seleccionado}&q=${this.search}&itemsPerPage=${this.options.itemsPerPage}&page=${this.options.page}`;
                if (this.options.sortBy[0]) {
                
                    var sortBy = this.options.sortBy[0].replace('.', '__');
                    if (this.options.sortDesc[0]) {
                        sortBy = '-' + sortBy;
                    }
                    params += `&sort_by=${sortBy}`;
                }
                this.$store.dispatch("tables/get", {
                    endpoint: "practica/all/por_estado",
                    table: "practicas_por_estado",
                    params: params,
                });
             },
             deep: true,
         },
    },
    mounted() {
        this.$store.dispatch("tables/get", {
            endpoint: "practica/estados",
            table: "estado_practica",
        }).then(() => {
            this.$store.dispatch("tables/get", {
                endpoint: "practica/all/por_estado",
                table: "practicas_por_estado",
                params: `?estado_id=${this.estado_seleccionado}&q=${this.search}&itemsPerPage=15&page=1`,
            });
        });

    },
    methods: {
        searchAPI() {
        this.$store.dispatch("tables/get", {
            endpoint: "practica/all/por_estado",
            table: "practicas_por_estado",
            params: `?estado_id=${this.estado_seleccionado}&q=${this.search}&itemsPerPage=${this.options.itemsPerPage}&page=${this.options.page}`,
        });
        
        },
        practica_url(token) {
            this.$router.push("/practica/" + token);
        },
    },
};
</script>

<style></style>
