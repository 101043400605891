<template>
  <v-card class="pa-5">
    <v-container >
      <v-row no-gutters>
        <v-col cols="12">
                    <h3 class="mb-5">Rango de Fechas</h3>
                    <p class="mb-5">Estas fechas son el rango de tiempo de los datos a visualizar de ambos graficos de abajo</p>
        </v-col>
        <v-col cols="12" lg="6">
          <v-menu v-model="menu1" :close-on-content-click="false" max-width="290">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field class="pa-1"
                rounded
                filled
                :value="format_date_initial"
                clearable
                label="Fecha de inicio"
                readonly
                v-bind="attrs"
                v-on="on"
                @click:clear="clearInicio"
              ></v-text-field>
            </template>
            <v-date-picker v-model="date_initial" @change="selectType" locale="es-CL"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" lg="6">
          <v-menu v-model="menu2" :close-on-content-click="false" max-width="290">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field class="pa-1"
                rounded
                filled
                :value="format_date_final"
                clearable
                label="Fecha de termino"
                readonly
                v-bind="attrs"
                v-on="on"
                @click:clear="clearTermino"
              ></v-text-field>
            </template>
            <v-date-picker v-model="date_final" @change="selectType" locale="es-CL"></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-row class="mx-10">
        <v-col cols="12" md="6">
          <h3 class="mb-5">Número de Prácticas</h3>

          <v-select
            rounded
            filled
            @change="selectType"
            v-model="grafico"
            :items="items"
            item-text="title"
            value="value"
            label="Tipo de Gráfico"
          ></v-select>
        </v-col>
        <v-col cols="12" md="5">
          <apexchart class="black--text" type="donut" :options="options" :series="series"></apexchart>
        </v-col>
      </v-row>
      <InformeGraph class="mx-10"  :date_initial="date_initial" :date_final="date_final"></InformeGraph>
    </v-container>
  </v-card>
</template>

<script>
import moment from "moment";
import InformeGraph from '@/components/estadisticas/InformeGraph.vue';

export default {
  components: { InformeGraph },
  data: () => ({
    menu1: false,
    menu2: false,
    date_initial: null,
    date_final: null,

    tipo_grafico: "practica_numero",
    items: [
      {
        value: "tipo",
        title: "Por Tipo",
      },
      {
        value: "modo",
        title: "Por Modo",
      },
      {
        value: "campus",
        title: "Por Campus",
      },
      {
        value: "carrera",
        title: "Por Carrera",
      },
    ],
    grafico: "tipo",
    params: "",

    options: {
      noData: {
        text: "Cargando...",
      },
    },
    series: [1, 1, 1, 1, 1],

  }),

  computed: {
    format_date_initial() {
      return this.date_initial
        ? moment(this.date_initial).format("dddd, Do MMMM, YYYY")
        : "";
    },

    format_date_final() {
      return this.date_final
        ? moment(this.date_final).format("dddd, Do MMMM, YYYY")
        : "";
    },
    estadistica_practica() {
      return this.$store.state.tables.estadistica_practica.items;
    },

  },

  watch: {
    estadistica_practica(val) {
      this.options = {
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                total: {
                  show: true,
                  color: this.$vuetify.theme.dark ? "white" : "black",
                },
              },
            },
          },
        },
        legend: {
          position: "bottom",
          onItemHover: {
            highlightDataSeries: true,
          },
        },
        labels: Object.keys(val),

        chart: {
          foreColor: this.$vuetify.theme.dark ? "white" : "black",
        },
      };
      this.series = Object.values(val);
    },

  },

  created() {
    //GRAFICO NUMERO DE PRACTICAS
    if (this.grafico != null) {
      this.params += "grafico=" + this.grafico;
    }
    if (this.date_initial != null) {
      this.params += "&fecha_inicio=" + this.date_initial;
    }
    this.$store.dispatch("tables/get", {
      endpoint: "estadisticas/practicas",
      table: "estadistica_practica",
      params: "?" + this.params,
    });

  },

  methods: {
    clearInicio() {
      this.date_initial = null;
      this.selectType();
    },
    clearTermino() {
      this.date_final = null;
      this.selectType();
    },


    clearTipo() {
      this.graph2_tipo = null;
      this.selectType();
    },
    clearModo() {
      this.graph2_modo = null;
      this.selectType();
    },
    clearCampus() {
      this.graph2_campus = null;
      this.selectType();
    },
    clearCarrera() {
      this.graph2_carrera = null;
      this.selectType();
    },


    selectType() {
      this.menu1 = false;
      this.menu2 = false;

      //GRAFICO 1
      this.params = "";
      if (this.grafico != null) {
        this.params += "grafico=" + this.grafico;
      }
      if (this.date_initial != null) {
        this.params += "&fecha_inicio=" + this.date_initial;
      }
      if (this.date_final != null) {
        this.params += "&fecha_termino=" + this.date_final;
      }
      this.$store.dispatch("tables/get", {
        endpoint: "estadisticas/practicas",
        table: "estadistica_practica",
        params: "?" + this.params,
      });

    },
  },
};
</script>

<style>
</style>