<template>
  <v-row>
    <v-col cols="12" md="4">
      <h2 class="mb-5">Más Populares</h2>

      <v-select
            rounded
            filled
            @change="selectType"
            v-model="grafico"
            :items="items"
            item-text="title"
            value="value"
            label="Tipo de Gráfico"
          ></v-select>

      <v-select
        clearable
        :items="modos"
        rounded
        
        v-model="graph_modo"
        prepend-inner-icon="mdi-calendar-clock"
        item-value="id"
        item-text="nombre"
        filled
        label="Modo de Práctica"
        @click:clear="clearModo"
        @change="selectType"
      ></v-select>

      <v-select
        clearable
        :items="tipos"
        rounded
        
        v-model="graph_tipo"
        prepend-inner-icon="mdi-calendar-clock"
        item-value="id"
        item-text="nombre"
        filled
        label="Tipo de Práctica"
        @click:clear="clearTipo"
        @change="selectType"
      ></v-select>

      <v-select
        clearable
        :items="campus"
        rounded
        
        v-model="graph_campus"
        prepend-inner-icon="mdi-calendar-clock"
        item-value="id"
        item-text="nombre"
        filled
        label="Campus"
        @click:clear="clearCampus"
        @change="selectType"
      ></v-select>

      <v-select
        clearable
        :items="carreras"
        rounded
        
        v-model="graph_carrera"
        prepend-inner-icon="mdi-calendar-clock"
        item-value="id"
        item-text="nombre"
        filled
        label="Carrera"
        @click:clear="clearCarrera"
        @change="selectType"
      ></v-select>
    </v-col>
    <v-col cols="12" md="8">
      <apexchart
        class="black--text"
        type="bar"
        :options="options"
        :series="series"
      ></apexchart>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: ["date_initial", "date_final"],
  data: () => ({
    graph_tipo: null,
    graph_modo: null,
    graph_carrera: null,
    graph_campus: null,

    grafico: "lenguajes",
    items: [
      {
        value: "region",
        title: "Numero de Práctica por Region",
      },
      {
        value: "lenguajes",
        title: "Lenguajes de Programación más utilizados",
      },
      {
        value: "beneficios",
        title: "Beneficios más obtenidos",
      },
      {
        value: "pago_mensual",
        title: "Promedo de Pago por Práctica",
      },
      {
        value: "asignaturas",
        title: "Asignaturas más útiles",
      },
      {
        value: "ides",
        title: "Editores más utilizados",
      },
      {
        value: "librerias",
        title: "Librerias más utilizadas",
      },
      {
        value: "bds",
        title: "Bases de Datos más utilizadas",
      },
      {
        value: "tareas",
        title: "Tareas más realizadas",
      },
      {
        value: "empresa",
        title: "Empresas",
      },
    ],

    query: "",

    options: {
      xaxis: {
        labels: {
          rotate: -45,
        },
        categories: [],
        tickPlacement: "on",
      },
      noData: {
        text: "No hay datos",
      },
    },

    series: [
      {
        name: "Practicas",
        data: [],
      },
    ],
  }),

  computed: {
    campus() {
      return this.$store.state.tables.campus.items;
    },
    carreras() {
      return this.$store.state.tables.carreras.items;
    },
    modos() {
      return this.$store.state.tables.modos_practica.items;
    },
    tipos() {
      return this.$store.state.tables.tipos_practica.items;
    },

    estadistica_informe() {
      return this.$store.state.tables.estadistica_informe.items;
    },
  },

  watch: {
    date_initial: {
      immediate: true,
      handler(val, oldVal) {
        this.date_initial = val;
        this.selectType()
      },
    },

    date_final: {
      immediate: true,
      handler(val, oldVal) {
        this.date_final = val;
        this.selectType()
      },
    },
    
    estadistica_informe(val) {
      this.options = {
        xaxis: {
          labels: {
            rotate: -70,
          },
          categories: Object.keys(val),
          tickPlacement: "on",
        },
        chart: {
          foreColor: this.$vuetify.theme.dark ? "white" : "black",
        },
      };
      this.series = [
        {
          name: "Practicas",
          data: Object.values(val),
        },
      ];
    },
  },

  created() {
    this.$store.dispatch("tables/get", { endpoint: "campus", table: "campus" });
    this.$store.dispatch("tables/get", { endpoint: "carreras", table: "carreras" });
    this.$store.dispatch("tables/get", { endpoint: "practica/tipos", table: "tipos_practica" });
    this.$store.dispatch("tables/get", { endpoint: 'practica/modos', table: "modos_practica" });

    //GRAFICO POR REGION

    if (this.grafico != null) {
      this.query += "&grafico=" + this.grafico;
    }

    if (this.date_initial != null) {
      this.query += "&fecha_inicio=" + this.date_initial;
    }

    this.$store.dispatch("tables/get", {
      endpoint: 'estadisticas/populares',
      table: "estadistica_informe",
      params: "?" + this.query,
    });
  },

  methods: {
    clearTipo() {
      this.graph_tipo = null;
      this.selectType();
    },
    clearModo() {
      this.graph_modo = null;
      this.selectType();
    },
    clearCampus() {
      this.graph_campus = null;
      this.selectType();
    },
    clearCarrera() {
      this.graph_carrera = null;
      this.selectType();
    },

    selectType() {

      //GRAFICO 2
      this.query = "";

      if (this.grafico != null) {
        this.query += "&grafico=" + this.grafico;
      }

      if (this.date_initial != null) {
        this.query += "&fecha_inicio=" + this.date_initial;
      }

      if (this.date_final != null) {
        this.query += "&fecha_termino=" + this.date_final;
      }

      if (this.graph_campus != null) {
        this.query += "&campus=" + this.graph_campus;
      }

      if (this.graph_carrera != null) {
        this.query += "&carrera=" + this.graph_carrera;
      }

      if (this.graph_tipo != null) {
        this.query += "&tipo=" + this.graph_tipo;
      }

      if (this.graph_modo != null) {
        this.query += "&modo=" + this.graph_modo;
      }

      this.$store.dispatch("tables/get", {
        endpoint: 'estadisticas/populares',
        table: "estadistica_informe",
        params: "?" + this.query,
      });
    },
  },
};
</script>

<style></style>
