<template>
  <ValidationProvider v-slot="{ errors }" :name="name" :rules="rules">
    <v-text-field
      :prepend-inner-icon="icon"
      :value="value"
      :error-messages="errors"
      @input="handle"
      rounded
      :prefix="prefix"
      :type="type"
      filled
      :counter="counter"
      :disabled="disabled"
      :label="label"
    ></v-text-field>
  </ValidationProvider>
</template>

<script>


import { ValidationProvider } from "vee-validate";
import { format}  from "rut.js";
import { format_zero } from '@/helpers/rut-zero.js';

export default {
  components: {
    ValidationProvider,
  },
  props: ["value",
          "disabled", 
          "label", 
          "icon", 
          "rules", 
          "name", 
          "type", 
          "prefix", 
          "counter", 
          "isRut", 
          "isRol", 
          "isRutEmpresa"],
  methods: {
    
    handle: function (e) {
      if(this.isRut){
        e = format(e)
      }

      if(this.isRol){
        e = format(e,{ dots: false })
      }

      if(this.isRutEmpresa){
        if (/^0/.test(e)){ // Si el rut empieza con 0 es práctica de emprendimiento
          e = format_zero(e);
        }else{
          e = format(e);
        }
      }
      this.$emit("input", e);
    },
  },
};
</script>
