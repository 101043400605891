<template>
  <v-expansion-panels v-model="panel">
    <v-expansion-panel>
      <v-expansion-panel-header>
        <v-card class="pl-5 pr-5">
          <v-row justify="space-between" align="center">
            <div>
              <h4>{{ solicitud.tipo.nombre }}</h4>
            </div>
            <v-chip class="ma-2" color="primary">
              {{ estadoSolicitud(solicitud.estado) }}
            </v-chip>
          </v-row>
        </v-card>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-divider></v-divider>
        <solicitud-details :solicitud="solicitud"></solicitud-details>
        <v-row justify="end" class="mr-6">
          <v-tooltip bottom>
              <template v-slot:activator="{ attrs }">
                <v-btn
                  v-if="solicitud.estado == 0"
                  elevation="0"
                  color="error"
                  v-bind="attrs"
                  @click="dialog = !dialog"
                >
                  Cancelar solicitud
                  <v-icon class="ml-2">cancel</v-icon>
                </v-btn>
              </template>
            </v-tooltip>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>

    <v-dialog v-model="dialog" width="550">
      <v-card>
        <v-card-title primary-title
          >¿Estás segur@ que deseas cancelar la solicitud?</v-card-title
        >

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false">Cancelar</v-btn>
          <v-btn color="primary" text @click="cancelarSolicitud()">Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-expansion-panels>
</template>

<script>
import SolicitudDetails from './SolicitudDetails.vue'


export default {
  components: {
     SolicitudDetails
  },
  data: () => ({
    panel: null,
    dialog: false,
  }),
  props: ["solicitud"],
  methods: {
    estadoSolicitud(id) {
      switch (id) {
        case 0:
          return "En espera de aprobación";
        case 1:
          return "Aprobada";
        case 2:
          return "Rechazada";
        default:
          // estado 3
          return "Cancelada";
      }
    },
    cancelarSolicitud() {
      this.dialog = false;
      this.$store.dispatch("tables/delete", {
        endpoint: `solicitudes`,
        id: this.solicitud.id,
        invisible: true,
        reload: true,
        table: "solicitudes",
        error_message: 'Ha ocurrido un error al cancelar tu solicitud'
      });
      // ELIMINAR RELOAD: TRUE POR FAVOR
      // this.$emit("updateIterator", this.solicitud) < esto debía funcionar x.x
    },
  },
};
</script>

<style></style>
