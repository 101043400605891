<template>
<v-container>
    <Login></Login>
</v-container>
</template>

<script>
// import Layout from "@/components/content/Layout.vue";
import Login from "./auth/login";

export default {
  components: {
    Login,
  },
};
</script>

<style>
.v-expansion-panel-content__wrap {
  padding: 0 !important;
}
</style>
