/*
Si bien para la validación del RUT se utiliza rut.js, existe una excepción para
el RUT de ceros, el cual se ocupa en las prácticas por empredimiento, pero 
no es valido por la libreria rut.js. Para solucionar esto se copiaron los mismos
metodos de la libreria pero en el metodo clean se omitió en la REGEX que omita los ceros iniciales.

Además se incluye la misma función format ya que esta hace un llamado interno.
*/


function clean (rut) {
    return typeof rut === 'string'
      ? rut.replace(/[^0-9kK]+/g, '').toUpperCase()
      : ''
}

export function format_zero (rut, options = {dots: true}) {
    rut = clean(rut)
  
    let result
    if (options.dots) {
      result = rut.slice(-4, -1) + '-' + rut.substr(rut.length - 1)
      for (let i = 4; i < rut.length; i += 3) {
        result = rut.slice(-3 - i, -i) + '.' + result
      }
    } else {
      result = rut.slice(0, -1) + '-' + rut.substr(rut.length - 1)
    }
  
    return result
  }