import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import VueApexCharts from 'vue-apexcharts'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import axios from 'axios';
import { Promise } from "es6-promise";
import { tokenService } from "./services";
import VueMoment from "vue-moment";
import { Settings } from 'luxon'
import moment from 'moment-timezone'
import Keycloak from 'keycloak-js'
import "./helpers/text-validations.js"

moment.tz.setDefault('America/Santiago')

Vue.config.productionTip = false
Vue.prototype.moment = moment
Settings.defaultLocale = 'es'


 axios.interceptors.response.use((response) => {
  // Return a successful response back to the calling service
  return response;
}, (error) => {
  // Return any error which is not due to authentication back to the calling service
  if (error.response.status !== 401) {
    return new Promise((resolve, reject) => {
      reject(error);
    });
  }
  // Logout user if token refresh didn't work or user is disabled
  if (error.config.url == `${process.env.VUE_APP_API_URL}/token/refresh/` || error.response.message == 'Account is disabled.') {

    tokenService.clear();
    Vue.prototype.$keycloak.logout()

    return new Promise((resolve, reject) => {
      reject(error);
    });
  }

  // Try request again with new token
  return tokenService.getNewToken()
    .then((token) => {
      

      // New request with new token
      const config = error.config;
      config.headers['Authorization'] = `Bearer ${token}`;

      return new Promise((resolve, reject) => {
        axios.request(config).then(response => {
          resolve(response);
        }).catch((error) => {
          
          reject(error);
        })
      });

    })
    .catch((error) => {
      Promise.reject(error);
    });
}) 

Vue.component('apexchart', VueApexCharts);

Vue.use(VueMoment, { moment });



let initOptions = {
    onLoad:'check-sso',
    url: process.env.VUE_APP_KEYCLOAK_URL,
    clientId: process.env.VUE_APP_KEYCLOAK_CLIENT_ID,
    realm: process.env.VUE_APP_KEYCLOAK_REALM
  }

let keycloak = Keycloak(initOptions);

Vue.prototype.$keycloak = keycloak

keycloak.init({ onLoad: initOptions.onLoad }).then((auth) =>{

  setInterval(() =>{
    keycloak.updateToken(70).then((refreshed)=>{
    })
  }, 60000)

  // No me convence del todo esta forma de de hacerlo pero it works
  // Sucede que si dejo la creación de App fuera de la condición del if o fuera del init,
  // puede pasar que la App se renderice antes de obtener los datos correctamente
  // o incluso antes de obtener el token correctamente desde la API.
  // De esta forma se asegura de renderizar cuando se inicie sesión y obtenga el token correctamente
  // y si no existe el token se renderiza de todas formas para mostrar la pagina de inicio
  if(keycloak.token){
    store.dispatch("authentication/login", { username: 'user', password: keycloak.token }).then(() => {
      new Vue({
        router,
        store,
        vuetify,
        render: h => h(App)
      }).$mount('#app')
    });
  }else{
    new Vue({
      router,
      store,
      vuetify,
      render: h => h(App)
    }).$mount('#app')
  }

}).catch((error) =>{
  console.error("Servicio de Keycloak no disponible")
  
});

