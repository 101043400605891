import Vue from 'vue'
import Vuex from 'vuex'

import { alert } from './alert.module';
import { authentication } from './authentication.module';
import { users } from './users.module';
import { tables } from './tables.module';

Vue.use(Vuex)


export default new Vuex.Store({
  state: {
    drawer: false,
    items: [],
  },
  mutations: {
    drawer(state) {
      state.drawer = !state.drawer;
    },
    pagination(state, params){
      state.items = params.items;
    }
  },
  actions: {
    drawer({ commit }) {
      commit('drawer');
    },
    pagination({ commit }, params){
      commit('pagination', {items: params.items})
    }
  },
  modules: {
    alert,
    authentication,
    users,
    tables
  }
})
