<template>
  <v-container>
   
    <v-row justify="center">

      <div></div>

      <ButtonPrimary
        classButton="rounded mt-5"
        :iconRight="withoutIcons? '': 'mdi-chevron-right'"
        :onClick="nextAction"
        :disabled="nextDisabled"
      >
        {{ nextText }}
      </ButtonPrimary>
    </v-row>

     <v-row justify="center">
      
      <v-btn
        v-if="backText"
        text
        :disabled="backDisabled"
        @click="backAction"
      >
        {{ backText }}
      </v-btn>
    </v-row>

  </v-container>
</template>

<script>
import ButtonPrimary from "./ButtonPrimary";

export default {
  components:{
    ButtonPrimary
  },
  props: ["nextText", "backText", "backAction", "nextAction", "backDisabled", "nextDisabled", "withoutIcons"],
};
</script>

<style></style>
