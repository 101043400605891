import axios from 'axios';
import { tokenService } from './token.service';

export const userService = {
    getAllUsers,
    getCurrentUser,
    editUser,
};

function getAllUsers() {
    return new Promise((resolve, reject) => {
        axios
            .get(`${process.env.VUE_APP_API_URL}/users/`)
            .then(response => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

function editUser(item) {
    return new Promise((resolve, reject) => {
        axios
            .patch(`${process.env.VUE_APP_API_URL}/user/`,item, tokenService.getAuthentication())
            .then(response => {
                localStorage.setItem('user', JSON.stringify(response.data));
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

function getCurrentUser() {
    return new Promise((resolve, reject) => {
        axios
            .get(`${process.env.VUE_APP_API_URL}/user/`, tokenService.getAuthentication())
            .then(response => {
                localStorage.setItem('user', JSON.stringify(response.data));
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

