<template>
  <v-container>
    <DarkCard title="Última bitácora" icon="mdi-calendar-arrow-left">
      <div v-if="tiempo">
        <BitacoraDetail
          :bitacora="data"
          :evaluador="false"
          :editable="false"
          :date="true"
        ></BitacoraDetail>
      </div>
      <div v-else>
        <Message text="Aún no has ingresado ninguna bitácora." />
      </div>
    </DarkCard>
  </v-container>
</template>

<script>
import DarkCard from "@/components/content/DarkCard";
import Message from "../../Message";
import BitacoraDetail from "./bitacora/BitacoraDetail";
export default {
  components: {
    Message,
    DarkCard,
    BitacoraDetail,
  },
  props: ["data", "tiempo"],
  computed: {
    theme() {
      return this.$vuetify.theme.dark ? "secondary" : "white";
    },
  },
};
</script>

<style></style>
