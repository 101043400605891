<template>
    <button class="card ma-3" @click="onClick" :disabled="disabled" :href="href" :target="target">
      <div class="card-icon">
        <!-- Puedes usar cualquier icono aquí, por ejemplo, un icono de FontAwesome -->
        <v-icon size="43"> {{iconLeft}}</v-icon>

      </div>
      <div class="card-content">
        <h3 class="card-title">{{title}}</h3>
        <p class="card-description">{{description}}</p>
      </div>
    </button>
  </template>
  
  <script>
  export default {
    name: 'CustomCard',
    props: ["onClick", "iconLeft", "disabled", "href", "target", "title", "description"],
  }
  </script>
  
  <style scoped>
  .card {
    display: flex;
    align-items: center;
    text-align: left;
    border-radius: 6px;
    padding: 15px;
    width: 350px;
    cursor: pointer;
    transition: box-shadow 0.3s ease, background-color 0.3s ease;
    background: rgba(0,0,0,0.2); /* Fondo inicial blanco */
    border: none; /* Elimina el borde predeterminado del botón */
    outline: none; /* Elimina el contorno predeterminado del botón */
  }
  
  .card:hover {
    background-color: rgba(255,255,255,0.1); /* Sombra más pronunciada al pasar por encima */
  }
  
  .card:active {
    background-color: rgba(255,255,255,0.25); /* Color de fondo al hacer clic */
  }
  
  .card-icon {
    margin-right: 16px;
  }
  
  .card-icon i {
    color: #F27531; /* Cambia el color si es necesario */
  }
  
  .card-title {
    margin: 0;
    font-size: 19px;
  }
  
  .card-description {
    font-size: 12px;
    margin: 0;
    color: #666;
  }

  .card:disabled {
    background-color: rgba(0,0,0,0.3); /* Color de fondo cuando está deshabilitado */
    color: rgba(255,255,255,0.2); /* Color del texto cuando está deshabilitado */
    cursor: not-allowed; /* Cambia el cursor a no permitido */
    box-shadow: none; /* Elimina la sombra */
  }   
  .card:disabled .card-icon i {
    color: rgba(255,255,255,0.2); /* Cambia el color si es necesario */
  }
  </style>
  