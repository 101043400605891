<template>
  <div class="pa-5"> 
              <v-row align="center" justify="center" class="mb-4 mt-5">
                    <v-icon size="64"> {{ icon? icon: "mdi-text-box-search-outline"}}</v-icon>
                </v-row>
                <v-row align="center" justify="center">
                     <p class="text-button font-weight-bold text-uppercase mb-5 text-center" >{{text}}</p> 
                </v-row>
                

             </div>
</template>

<script>
export default {
    
    props: ["text", "icon"]
}
</script>

<style>

</style>