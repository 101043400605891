<template>
  <v-card class="pa-3">
    <h4 class="pa-3">
        {{ solicitud.tipo.nombre }}
    </h4>
    <v-row>
      <v-col v-if="[1,2,3].includes(solicitud.tipo.id)" :cols="12" md="5">
        <div class="pa-3">
          <v-sheet v-if="solicitud.tipo.id == 1">
            <card-icon-title
              class="mb-3"
              icon="mdi-account-tie"
              title="Supervisor nuevo"
              :subtitle="
                `${solicitud.nombre_supervisor} ${solicitud.apellido_supervisor}`
              "
              :subtitle2="solicitud.email_supervisor"
              :subtitle3="solicitud.telefono_supervisor"
            />

            <card-icon-title
              v-if="admin"
              icon="mdi-account-tie"
              title="Supervisor Anterior"
              :subtitle="
                `${solicitud.practica.supervisor.nombre} ${solicitud.practica.supervisor.apellido}`
              "
              :subtitle2="solicitud.practica.supervisor.email"
              :subtitle3="solicitud.practica.supervisor.telefono"
            />
          </v-sheet>

          <v-sheet v-if="solicitud.tipo.id == 2">
            <card-icon-title
              class="mb-3"
              icon="mdi-calendar"
              title="Fecha de inicio nueva"
              :subtitle="formatDate(solicitud.fecha_inicio)"
            />
            <card-icon-title
              v-if="admin"
              icon="mdi-calendar"
              title="Fecha de inicio anterior"
              :subtitle="formatDate(solicitud.practica.fecha_inicio)"
            />
          </v-sheet>

          <v-sheet v-if="solicitud.tipo.id == 3">
            <card-icon-title
              class="mb-3"
              icon="mdi-book"
              title="Modo de práctica nuevo"
              :subtitle="solicitud.modo_practica.nombre"
            />
            <card-icon-title
              v-if="admin"
              icon="mdi-book"
              title="Modo de práctica anterior"
              :subtitle="solicitud.practica.modo"
            />
          </v-sheet>
        </div>
      </v-col>
      <v-col :cols="12" :md="[1,2,3].includes(solicitud.tipo.id)? 7 : 12">
        <h5>Razón:</h5>
        <dark-card>
          {{ solicitud.mensaje }}
        </dark-card>
      </v-col>

    </v-row>
  </v-card>
</template>

<script>
import { formatDate } from "@/services/utils.service";

import DarkCard from "../../../content/DarkCard.vue";
import CardIconTitle from "../../../content/CardIconTitle.vue";
export default {
  components: { CardIconTitle, DarkCard },
  props: ["solicitud", "admin"],
  methods: {
    formatDate,
  },
};
</script>

<style></style>
